import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions, thunks } from 'store/toolkit';

import { useProviderDetailsToString } from 'hooks/Provider';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Grid, List, TextField, IconButton, makeStyles } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ReplayIcon from '@mui/icons-material/Replay';

import Modal from 'components/Modals/Modal';
import ChatMessage from 'components/Modals/ChatModal/ChatMessage';
import ActionButtons from 'components/Modals/ChatModal/ActionButtons';
import ChatFeedback from 'components/Modals/ChatModal/Feedback/ChatFeedback';
import ChatDevTools from 'components/Modals/ChatModal/DevTools/ChatDevTools';
import {
  RESTART,
  DISMISS_EMERGENCY,
  SEARCH_IN_PG,
  START_OVER,
  TRY_AGAIN,
  RETRY,
  CONVERSATION_STARTER,
  USER,
} from 'store/slices/chat/chatConstants';
import ChatHeader from 'components/Modals/ChatModal/ChatHeader';
import ChatSearchTabs from 'components/Modals/ChatModal/ChatSearchTabs';
import PromotionById from './Promotion/PromotionById';
import TermsAgreement from './TermsAgreement';
import ChatLoading from './ChatLoading';

const useStyles = (viewHeight) =>
  makeStyles((theme) => ({
    modalHeader: {
      textAlign: 'left',
      textTransform: 'uppercase',
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    modalStyles: {
      '& [data-reach-dialog-content]': {
        padding: '15px 40px',
        maxWidth: 'none',
        width: 900,
        overflow: 'clip',
        [theme.breakpoints.down('sm')]: {
          maxHeight: '100%',
          minHeight: 0,
          height: viewHeight || '100%',
          padding: 15,
          transition: 'height .2s ease-in-out',
        },
      },
    },
    chatText: {
      width: '100%',
      marginBottom: '10px',
      '& textarea': {
        lineHeight: 1.5,
      },
      '& fieldset': {
        borderRadius: 15,
      },
    },
    resText: {
      width: '90%',
      marginRight: '30px',
      marginBottom: '10px',
      '& textarea': {
        lineHeight: 1.5,
      },
      '& textarea.Mui-disabled': {
        color: theme.palette.primary.main,
      },
    },
    messageList: {
      width: '100%',
    },
    conversationContainer: {
      backgroundColor: '#f7f8f8',
      padding: theme.spacing(2),
      borderRadius: 15,
      maxHeight: '66vh',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'none',
        transition: 'height .2s ease',
        height: '100%',
      },
    },
    chatContents: {
      position: 'relative',
      padding: 20,
      minHeight: '100%',
    },
    providersContainer: {
      marginTop: 8,
    },
    loadingProvidersText: {
      marginLeft: 16,
      backgroundColor: 'white',
      borderRadius: 8,
      padding: 8,
      display: 'flex',
      alignItems: 'center',
    },
  }));

function ChatModal() {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const showChatModal = useSelector(select.ui.chatModalOpen);
  const enableChatFeedback = useSelector(select.featureFlags.enableChatFeedback);
  const dispatch = useDispatch();

  const enableDevTools = useSelector(select.chat.devTools);
  const conversation = useSelector(select.chat.conversation);
  const errorMessage = useSelector(select.chat.errorMessage);
  const searchParameters = useSelector(select.chat.searchParameters);
  const actionButtonKeys = useSelector(select.chat.actionButtonKeys);
  const termsAccepted = useSelector(select.chat.termsAccepted);
  const feedbackSuccess = useSelector(select.chat.feedbackSuccess);
  const chatKey = useSelector(select.chat.chatKey);
  const showNoResultsMessage = useSelector(select.chat.showNoResultsMessage);
  const promotionId = useSelector(select.chat.promotionId);
  const endChat = useSelector(select.chat.endChat);
  const searchesHaveBeenPerformed = useSelector(select.chat.searchesHaveBeenPerformed);
  const requests = useSelector(select.chat.requestsWithResults);
  const sendingChatMessage = useSelector(select.chat.sendingChatMessage);

  const [viewHeight, setViewHeight] = useState(0);
  const [inputText, setInputText] = useState('');
  const [showDismissDisclaimer, setShowDismissDisclaimer] = useState(false);
  const listEndRef = useRef(null);
  const chatInputRef = useRef(null);
  const classes = useStyles(viewHeight)();
  // @TODO: use selector TECH-3695
  const providerDetailsToString = useProviderDetailsToString();

  const handleTermsAccepted = () => {
    dispatch(actions.chat.acceptTerms());
  };

  const closeChat = useCallback(() => {
    dispatch(actions.ui.closeModal('chat'));
  }, [dispatch]);

  const handleChange = ({ target: { value } }) => {
    setInputText(value);
  };

  const handleClearChat = useCallback(
    (e) => {
      e.preventDefault();
      setInputText('');
      dispatch(actions.chat.resetChat());
    },
    [dispatch]
  );

  const addUserMessage = () => {
    setInputText('');
    const userMessage = {
      role: USER,
      content: inputText,
    };
    dispatch(actions.chat.addMessageToConversation(userMessage));
  };

  // conditionally chain thunks askEva > analyzeChat > performAllSearches > chatExpandSearch
  const searchAndExpand = useCallback(async () => {
    dispatch(thunks.chat.performAllSearches())
      .unwrap()
      .then((searches) => {
        if (searches.some((search) => search.results.length === 0))
          dispatch(thunks.chat.chatExpandSearch());
      });
  }, [dispatch]);

  // conditionally chain thunks askEva > analyzeChat > performAllSearches
  const analyzeChat = useCallback(async () => {
    dispatch(thunks.chat.analyzeChat())
      .unwrap()
      .then(({ end, isEmergency }) => {
        // It is possible to return end = false or isEmergency = true from the analyze-chat endpoint in which case we want to continue the conversation
        if (end && !isEmergency) {
          searchAndExpand();
        }
      });
  }, [dispatch, searchAndExpand]);

  // conditionally chain thunks askEva > analyzeChat
  const submitConversationToChat = useCallback(async () => {
    dispatch(thunks.chat.askEva())
      .unwrap()
      .then(({ end, isEmergency }) => {
        if (end && !isEmergency) {
          analyzeChat();
        }
      });
  }, [dispatch, analyzeChat]);

  const overrideEmergency = useCallback(async () => {
    const overrideMessage = {
      role: USER,
      content: 'Dismiss and continue',
    };
    await dispatch(actions.chat.setDismissEmergency(true));
    await dispatch(actions.chat.addMessageToConversation(overrideMessage));
    await dispatch(thunks.chat.askEva());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (!inputText.length) return;

    addUserMessage();
    submitConversationToChat();
  };

  const actionButtonsMap = useMemo(
    () => ({
      [RESTART]: {
        label: 'Restart Chat',
        callback: handleClearChat,
      },
      [DISMISS_EMERGENCY]: {
        label: 'Dismiss and continue',
        callback: overrideEmergency,
      },
      [START_OVER]: {
        label: 'Start over',
        callback: handleClearChat,
      },
      [SEARCH_IN_PG]: {
        label: 'Search In Provider Guide',
        callback: (e) => {
          handleClearChat(e);
          closeChat();
        },
      },
      [TRY_AGAIN]: {
        label: 'Try again',
        callback: () => {
          dispatch(actions.chat.removeMessageFromConversation());
          chatInputRef.current?.focus();
          dispatch(actions.chat.resetErrorState());
        },
      },
      [RETRY]: {
        label: 'Retry',
        callback: () => {
          dispatch(actions.chat.resetErrorState());
          submitConversationToChat();
        },
      },
    }),
    [closeChat, handleClearChat, dispatch, submitConversationToChat, overrideEmergency]
  );

  const actionButtons = useMemo(
    () => actionButtonKeys.map((key) => actionButtonsMap[key]),
    [actionButtonKeys, actionButtonsMap]
  );

  const scrollToBottom = useCallback(() => {
    if (showChatModal && listEndRef?.current)
      listEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [listEndRef, showChatModal]);

  useEffect(() => {
    if (actionButtonKeys.includes(DISMISS_EMERGENCY)) {
      setShowDismissDisclaimer(true);
    } else {
      setShowDismissDisclaimer(false);
    }
  }, [actionButtonKeys]);

  useEffect(() => {
    const timeout = setTimeout(scrollToBottom, 100);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatModal]);

  useEffect(() => {
    listEndRef.current?.scrollIntoView({ behavior: 'smooth' });

    const timeout = setTimeout(() => {
      if (!searchesHaveBeenPerformed && chatInputRef?.current) {
        chatInputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [conversation.length, searchesHaveBeenPerformed, actionButtons.length]);

  useEffect(() => {
    listEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (showChatModal) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [viewHeight, showChatModal]);

  const updateViewHeight = () => {
    setViewHeight(window.visualViewport.height);
  };

  useEffect(() => {
    if (smDown && typeof window !== 'undefined' && typeof window.visualViewport !== 'undefined') {
      window.visualViewport.addEventListener('resize', updateViewHeight);
    }

    return () => window.visualViewport?.removeEventListener('resize', updateViewHeight);
  }, [smDown, showChatModal]);

  const getConversationAsString = useCallback(() => {
    let convo = 'Conversation:\n';
    conversation.forEach((message) => {
      convo += `Role: ${message.role}\nMessage: ${message.content}\n\n`;
    });
    if (searchesHaveBeenPerformed) {
      const filteredSpecialties = searchParameters?.specialties?.filter(
        (specialty) =>
          !searchParameters?.subspecialties?.find(
            (subspecialty) => subspecialty.specialtyId === specialty.specialtyId
          )
      );

      if (filteredSpecialties.length > 0) {
        convo += '\n\nSpecialties Searched:\n';
        filteredSpecialties.forEach((specialty) => {
          convo += `${specialty.specialtyName}\n`;
        });
      }

      if (searchParameters?.subspecialties?.length > 0) {
        convo += '\n\nSubspecialties Searched:\n';
        searchParameters.subspecialties.forEach((subspecialty) => {
          convo += `${subspecialty.subspecialtyName}\n`;
        });
      }

      convo += '\n\nProviders:\n';
      // eslint-disable-next-line no-restricted-syntax
      for (const request of requests) {
        convo += `${request.subspecialtyName || request.specialtyName}:\n`;
        // eslint-disable-next-line no-loop-func
        request.results.forEach((provider, index) => {
          convo += `${index + 1}.\n${providerDetailsToString(provider)}\n\n`;
        });
      }

      convo += `Explore In Provider Guide:\n`;
      if (searchParameters?.specialties?.length > 0) {
        convo += '\nSpecialties:\n';
        searchParameters.specialties.forEach((specialty) => {
          convo += `${specialty.specialtyName}\n`;
        });
      }

      if (searchParameters?.subspecialties?.length > 0) {
        convo += '\nSubspecialties:\n';
        searchParameters.subspecialties.forEach((subspecialty) => {
          convo += `${subspecialty.subspecialtyName}\n`;
        });
      }
    }
    return convo;
  }, [
    conversation,
    requests,
    searchParameters,
    providerDetailsToString,
    searchesHaveBeenPerformed,
  ]);

  return (
    <Modal
      handleClose={closeChat}
      open={showChatModal}
      ariaId="chat-modal"
      customStyles={classes.modalStyles}
      fullScreen={smDown}
    >
      <>
        <ChatHeader onReset={handleClearChat} getConversationAsString={getConversationAsString} />
        {/* fixed height */}
        <Grid className={classes.conversationContainer}>
          {!termsAccepted ? (
            <TermsAgreement handleTermsAccepted={handleTermsAccepted} closeChat={closeChat} />
          ) : (
            <List className={classes.messageList}>
              <ChatMessage message={CONVERSATION_STARTER} />
              {conversation.map((message, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ChatMessage key={i} message={message} />
              ))}
              {errorMessage && <ChatMessage message={errorMessage} error />}
            </List>
          )}
          <div ref={listEndRef} />
          {promotionId && <PromotionById id={promotionId} />}

          {showNoResultsMessage && (
            <Grid style={{ marginLeft: 16 }}>
              <Typography>
                {`We're sorry, we couldn't find any providers within your search criteria`}
              </Typography>
            </Grid>
          )}
          <ChatLoading scrollToBottom={scrollToBottom} />

          {searchesHaveBeenPerformed && <ChatSearchTabs />}
          <ActionButtons actionButtons={actionButtons} />
          {showDismissDisclaimer && (
            <Grid style={{ marginLeft: 16 }}>
              <Typography>
                By clicking “Dismiss and continue”, you acknowledge that you have been alerted to a
                potential emergency situation based on your message and are choosing to continue
                with your conversation.
              </Typography>
            </Grid>
          )}

          {endChat && enableChatFeedback && (
            <ChatFeedback
              chatKey={chatKey}
              chatLog={getConversationAsString()}
              success={feedbackSuccess}
            />
          )}
        </Grid>
        {/* Input field */}
        {termsAccepted && (
          <TextField
            id="chat-message"
            multiline
            minRows={1}
            maxRows={3}
            margin="dense"
            variant="outlined"
            size="small"
            className={classes.chatText}
            value={inputText}
            placeholder={searchesHaveBeenPerformed ? 'Start new chat' : 'Enter your message..'}
            onChange={handleChange}
            autoFocus
            disabled={sendingChatMessage || endChat || !termsAccepted}
            inputRef={chatInputRef}
            onKeyDown={(e) => {
              // On Enter
              if (e.keyCode === 13) {
                handleSubmit(e);
              }
            }}
            InputProps={{
              inputProps: {
                maxLength: 150,
              },
              endAdornment: searchesHaveBeenPerformed ? (
                <IconButton
                  variant="contained"
                  onClick={handleClearChat}
                  style={{
                    display: 'block',
                    padding: 0,
                  }}
                >
                  <ReplayIcon size="small" />
                </IconButton>
              ) : (
                <IconButton
                  disabled={sendingChatMessage || endChat || !termsAccepted || !inputText.length}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    display: 'block',
                    padding: 0,
                  }}
                >
                  <SendIcon size="small" />
                </IconButton>
              ),
            }}
          />
        )}
      </>

      {enableDevTools && <ChatDevTools />}
    </Modal>
  );
}

export default ChatModal;
