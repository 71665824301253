import React, { useCallback, useMemo, useState } from 'react';
import PropTypes, { RefType } from 'propTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';
import EventIcon from '@material-ui/icons/Event';

import formatPrintProfile from 'utils/formatPrintProfile';
import useReturnFocus from 'hooks/useReturnFocus';
import useFocusAnchors from 'utils/FocusRefContext';
import { actions, select } from 'store/toolkit';
import selectProvider from 'store/slices/results/selectProvider';
import { isValidUrl } from 'utils/utils';
import { _analyticsActions } from 'analytics/index';

const SPINNER_SIZE = 16;
const useStyles = makeStyles((theme) => ({
  container: {
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& > *': {
        flex: 1,
      },
    },
  },
  buttonRoot: {
    display: 'flex',
    alignItems: 'center',
    width: 160,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 90,
    },
    padding: theme.spacing(1),
  },
  buttonLabel: {
    fontSize: '.875rem',
    '& svg': {
      height: 14,
    },
  },
  spinner: {
    height: SPINNER_SIZE,
    width: SPINNER_SIZE,
    marginRight: 5,
  },
}));

function ProviderProfileHeaderButtons({ providerId, printRef }) {
  const classes = useStyles();
  const focusAnchors = useFocusAnchors();
  const providerData = useSelector(selectProvider(providerId).data);
  const showShareButton = useSelector(select.featureFlags.showShare);
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);

  const dispatch = useDispatch();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();
  const { ref: printButtonRef, returnFocus: focusPrintButton } = useReturnFocus();

  const [isPrinting, setIsPrinting] = useState(false);

  const { pathname } = useLocation();

  const isSharePage = pathname.startsWith('/share/');

  const handleCall = () => {
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: providerData }));
  };

  const handleShare = () => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [providerData] }));
  };

  const handleScheduleEvent = () =>
    dispatch(_analyticsActions.scheduleClickFromNeedHelpButton(providerData.entityId));

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false);
    focusPrintButton();
  }, [focusPrintButton]);

  const printProfile = useReactToPrint({
    content: () => formatPrintProfile(printRef),
    documentTitle: providerData.entityName || 'Embold Health Provider',
    onBeforeGetContent: () => setIsPrinting(true),
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = useCallback(() => {
    setIsPrinting(true);
    dispatch(_analyticsActions.printProfile(providerId));
    // need to wait for profile accordion animations to finish before ingesting content
    setTimeout(printProfile, 470);
  }, [dispatch, printProfile, providerId]);

  const buttonProps = useMemo(
    () => ({
      variant: 'outlined',
      color: 'primary',
      classes: { root: classes.buttonRoot, label: classes.buttonLabel },
    }),
    [classes]
  );

  const schedulingButton = isValidUrl(providerData.schedulingUrl) ? (
    // some providers have a schedulingUrl property which is the preferred method for scheduling
    <Button
      {...buttonProps}
      href={providerData.schedulingUrl}
      onClick={handleScheduleEvent}
      target="_blank"
      rel="noreferrer"
    >
      <EventIcon />
      Schedule
    </Button>
  ) : (
    // when providers do NOT have a schedulingUrl open the call modal for scheduling through their network support
    <Button {...buttonProps} onClick={handleCall} ref={callButtonRef}>
      Need Help?
    </Button>
  );

  return (
    <Grid container classes={{ root: classes.container }}>
      <Grid item>
        <Button {...buttonProps} onClick={handlePrint} ref={printButtonRef}>
          {isPrinting ? (
            <>
              <CircularProgress
                thickness={3}
                size={SPINNER_SIZE}
                aria-label=""
                className={classes.spinner}
              />
              Printing
            </>
          ) : (
            <>
              <PrintIcon />
              Print
            </>
          )}
        </Button>
      </Grid>

      {!isSharePage && (
        <>
          {showShareButton && (
            <Grid item>
              <Button {...buttonProps} onClick={handleShare} ref={shareButtonRef}>
                <ShareIcon />
                Share
              </Button>
            </Grid>
          )}

          {/* {showScheduleButton && <Grid item>{schedulingButton}</Grid>} */}
        </>
      )}
    </Grid>
  );
}

export default ProviderProfileHeaderButtons;

ProviderProfileHeaderButtons.propTypes = {
  providerId: PropTypes.string.isRequired,
  printRef: RefType.isRequired,
};
