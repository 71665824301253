import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    left: 0,
    right: 0,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'rgba(255,255,255,0.75)',
    zIndex: 2,
  },
  spinner: {
    margin: '1rem auto 0',
  },
}));

function LoadingSpinner({ loading, style, verticalCenter, className }) {
  const classes = useStyles();
  const styles = style || {};

  if (verticalCenter) {
    styles.height = '100%';
  }

  if (!loading) {
    return null;
  }

  return (
    <Container
      align="center"
      className={`${classes.container} loading-spinner-container ${className}`}
      style={styles}
    >
      <Typography aria-live="assertive">Loading...</Typography>

      <CircularProgress
        size={50}
        thickness={2}
        aria-label="Loading"
        className={`${classes.spinner} loading-spinner`}
      />
    </Container>
  );
}

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  verticalCenter: PropTypes.bool,
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  verticalCenter: false,
  className: '',
};

export default LoadingSpinner;
