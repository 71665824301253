import * as config from './slices/config/configSlice';
import * as filters from './slices/filters/filtersSlice';
import { promotionsActions as promotions } from './slices/promotions/promotionsSlice';
import * as results from './slices/results/resultsSlice';
import { specialtiesActions as specialties } from './slices/specialties/specialtiesSlice';
import * as location from './slices/location/locationSlice';
import * as tooltip from './slices/tooltip/tooltipSlice';
import * as ui from './slices/ui/uiSlice';
import * as app from './appActions';
import * as map from './slices/map/mapSlice';
import * as notifications from './slices/notifications/notificationsSlice';
import * as chat from './slices/chat/chatSlice';
import * as search from './slices/search/searchSlice';

const actions = {
  config,
  filters,
  promotions,
  results,
  specialties,
  tooltip,
  ui,
  app,
  map,
  notifications,
  chat,
  search,
  location,
};

export default actions;
