import { DEFAULT_LOCATIONS, DOLLAR_GENERAL } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 8efcfae735d260e15f87805b150604ccba55b7d3',
  staging: 'Token f235f9d162b9e9f7008c8ae6180235864d3e52a1',
  prod: 'Token 6baf677e3de9c9681e2f592834f7f0b8346d3a15',
};

const token = selectToken(apiTokens);

export const DG_LOGIN_REGEX = /^(dlgtn|dgiga|dgqnh|degmoks|dlzwi|dlhnj|pdgok|jdldc|dgsfl)$/;

export const dollarGeneralConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'dgbcbst',
  EMBOLD_CLIENT: DOLLAR_GENERAL,
  EMBOLD_CLIENT_SLUG: 'dgbcbst',
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Goodlettsville,
  USE_BROWSER_LOCATION: true,
  CLOSED_ACCESS: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_NETWORK_NAME: 'dollarGeneralUserId',
  LOGIN_COOKIE_AUTH_REGEX: DG_LOGIN_REGEX, // https://regex101.com/
};
