import {
  SPECIALISTS_TAB,
  NAMES_TAB,
  FACILITIES_TAB,
  EMPTY_FILTER_COUNTS,
  LOGIN_NETWORK_NAME_VERSION,
} from 'utils/constants';
import { getLocalStorageExpire } from 'utils/utils';

import Config from 'config';

const { DEFAULT_LOCATION, SHOW_DR_SCORE, LOGIN_COOKIE_AUTH_REGEX, LOGIN_NETWORK_NAME } = Config;

// setup cookie-based initial state

const loginRegex = new RegExp(LOGIN_COOKIE_AUTH_REGEX);
const cookieValue = getLocalStorageExpire(LOGIN_NETWORK_NAME, LOGIN_NETWORK_NAME_VERSION);
const validLoginCookie = loginRegex.test(cookieValue);

// checkout for ?rep=<repname> so we can filter out traffic
const params = new URLSearchParams(window.location.search);
const rep = params.get('rep') || localStorage.getItem('rep') || 'NA';

// sets localStorage with a string of max length of 30
localStorage.setItem('rep', rep?.substring(0, 30));

let memberId = null;
let networkSlug = null;

if (LOGIN_NETWORK_NAME === 'msftUserId') {
  memberId = validLoginCookie ? cookieValue : null;
  networkSlug = validLoginCookie ? memberId?.substring(0, 3)?.toLowerCase() : null;
} else {
  networkSlug = validLoginCookie ? cookieValue : null;
}

/* eslint-disable import/prefer-default-export */
export const initialState = {
  // search state
  triggerSearch: false, // RTK TECH-3035 resultsSlice
  use_healthplan: networkSlug === 'YMJ', // TODO This looks like it should be a selector that checks configSlice.currentNetworkSlug === 'ymj', but which select module does it belong in?
  locationInput: `${DEFAULT_LOCATION.city}, ${DEFAULT_LOCATION.state}`,
  locationComponents: {
    city: DEFAULT_LOCATION.city, // RTK TECH-2993 locationSlice
    zip: null, // RTK TECH-2993 locationSlice
    state: DEFAULT_LOCATION.state, // RTK TECH-2993 locationSlice
  },
  locationResolved: true, // RTK TECH-2993 locationSlice
  fetchingGeoError: false, // RTK TECH-2993 locationSlice
  fetchingGeo: false, // RTK TECH-2993 locationSlice
  fetchingGeoErrorModal: false, // RTK TECH-2993 locationSlice
  paginationLink: '', // RTK TECH-3035 resultsSlice
  urlDirectSearch: false, // RTK TECH-3035 resultsSlice

  // result state (provider/place)
  resultType: null, // RTK TECH-3035 resultsSlice
  results: [], // RTK TECH-3035 resultsSlice
  promotion: null,
  service: null, // RTK TECH-3031 searchSlice
  resultCount: 0, // RTK TECH-3035 resultsSlice
  filterCounts: EMPTY_FILTER_COUNTS, // RTK TECH-3035 resultsSlice
  activeResult: {}, // RTK TECH-3035 resultsSlice
  lastOpenResult: null, // RTK TECH-3035 resultsSlice
  holdResult: null, // RTK TECH-3035 resultsSlice
  selectedPlaceSpecialtyIndex: 'null',
  selectedPlaceSpecialty: {},
  placeProviderLoading: false,
  returnFocus: null,
  compareList: [], // RTK TECH-3035 resultsSlice

  // freeform results state
  activeTab: null,
  freeformResults: {
    total: 0,
    specialtyId: null,
    [SPECIALISTS_TAB]: {
      count: 0,
      filterCounts: EMPTY_FILTER_COUNTS,
      results: [],
    },
    [NAMES_TAB]: {
      count: 0,
      filterCounts: EMPTY_FILTER_COUNTS,
      results: [],
    },
    [FACILITIES_TAB]: {
      count: 0,
      filterCounts: EMPTY_FILTER_COUNTS,
      results: [],
    },
  },

  // map state
  latLong: { latitude: DEFAULT_LOCATION.lat, longitude: DEFAULT_LOCATION.long }, // RTK TECH-2993 locationSlice
  clusterTabs: [], // RTK TECH-3035 resultsSlice
  clusterTabsIndex: 0, // RTK TECH-3035 resultsSlice
  showActiveResultLocations: false,

  // ui state
  unsupportedBrowser: false,
  isCssEnabled: true,
  isListCollapsed: false,
  loadingResults: false,
  selectedPlaceProvider: null,
  showDrScore: SHOW_DR_SCORE,

  // login state
  inProgressLoginData: null,
  showLogin: !validLoginCookie,
  memberId,
  networkSlug,

  // tracking state
  userScopes: {
    rep,
  },
  // hide functionality of footer + header in staticshare
};
