import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { actions, select, thunks } from 'store/toolkit';

import { Toolbar, Button } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';

import useFocusAnchors, { useFocusAnchorKeys, useFocusTo } from 'utils/FocusRefContext';

import Modal from 'components/Modals/Modal';
import useFilterOptionsQuery from 'hooks/filters/useFilterOptionsQuery';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

import ApplyFiltersButton from './ApplyFiltersButton';
import PlaceFilterMenu from './PlaceFilterMenu';
import ProviderFilterMenu from './ProviderFilterMenu';

const useStyles = (wasPlaceSearch) =>
  makeStyles((theme) => ({
    button: {
      fontWeight: 'normal',
      lineHeight: 1.2,
      '& svg': {
        marginRight: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    },
    chip: {
      marginLeft: 10,
      height: 25,
      lineHeight: 25,
    },
    toolbarHeader: {
      fontWeight: 500,
      marginLeft: theme.spacing(1),
    },
    clearButton: {
      fontWeight: 'heavy',
      fontSize: '0.9em',
      textDecoration: 'underline',
      color: theme.palette.black,
    },
    distanceLabel: {
      fontSize: 12,
      fontWeight: 700,
      color: '#636363',
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
    },
    sliderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      padding: 10,
    },
    slider: {
      width: '90%',
    },
    popupButton: {
      border: `2px solid ${theme.palette.mediumGray}`,
      color: theme.palette.black,
      fontWeight: '600',
      borderRadius: 25,
      background: 'white',
      fontSize: 13,
      padding: '4px 10px',
      marginRight: '15px',
      '& svg': {
        paddingLeft: 3,
        color: theme.palette.black,
      },
      '&.Mui-disabled:disabled': {
        background: theme.palette.lightGray,
        color: theme.palette.darkGray,
      },
      overflow: 'visible',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    popupButtonActiveFilter: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    filterLabel: {
      '&.Mui-focused': {
        color: '#222',
      },
    },
    filterButtonCount: {
      position: 'absolute',
      right: -14,
      top: -10,
      background: theme.palette.primary.main,
      color: theme.palette.white,
      borderRadius: '50%',
      width: '16px',
      height: '16px',
      fontSize: '0.8em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      padding: '24px 40px 16px',
      flex: '1 1 auto',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: 16,
      },
    },
    modalOverrides: {
      '& [data-reach-dialog-content]': {
        padding: 0,
      },
      '& .modal-content-wrapper': {
        overflowY: 'hidden',
      },
    },
    toolbarWrapper: {
      minHeight: 70,
      flex: '0 1 70px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    toolbarContent: {
      margin: '0 20px',
      borderTop: 'solid',
      borderTopColor: '#C4C4C4',
      width: '100%',
      minHeight: 70,
      display: 'flex',
      justifyContent: wasPlaceSearch ? 'end' : 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        padding: 10,
        justifyContent: wasPlaceSearch ? 'center' : 'space-between',
      },
    },
  }));

function FilterMenu() {
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const classes = useStyles(wasPlaceSearch)();

  const focusAnchorKeys = useFocusAnchorKeys();
  const [modalOpen, setModalOpen] = useState(false);
  const [chipOpen, setChipOpen] = useState(false);

  const defaultSearchRadius = useSelector(select.config.defaultSearchRadius);
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();

  const totalAppliedFilters = useSelector(select.results.totalAppliedFilters); // The total number of filters applied to the result state
  const resultFilters = useSelector(select.results.filters);
  const resultLocation = useSelector(select.results.location);
  const optionsInvalidated = useSelector(select.filters.optionsInvalidated);
  const resultsSearchRadius = useSelector(select.results.searchRadius);
  const resultCount = useSelector(select.results.count);
  const isLoading = useSelector(select.results.isLoading);

  const focusAnchors = useFocusAnchors();
  const focusTo = useFocusTo();
  const smDown = useSelector(select.ui.isSmDown);

  useEffect(() => {
    // reset filter & location state to match that of result set
    if (modalOpen) {
      dispatch(
        actions.filters.updateFromResults({ filters: resultFilters, radius: resultsSearchRadius })
      );
      setChipOpen(!resultLocation.radius);
      dispatch(actions.filters.invalidateOptions());
    }
    // only execute when `modalOpen` changed, and is true
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [modalOpen]);

  const handleManualClose = useCallback(() => {
    // when modal is closed without applying new filters,
    // reset filter & location state to match that of result set
    dispatch(
      actions.filters.updateFromResults({ filters: resultFilters, radius: resultsSearchRadius })
    );
    setModalOpen(false);
  }, [setModalOpen, resultFilters, dispatch, resultsSearchRadius]);

  const queryString = useFilterOptionsQuery();
  useEffect(() => {
    if (modalOpen && optionsInvalidated) {
      dispatch(thunks.filters.fetchFilterOptions(queryString));
    }
  }, [dispatch, optionsInvalidated, modalOpen, queryString]);

  const toggleModalOpen = useCallback(
    () => setModalOpen((prevValue) => !prevValue),
    [setModalOpen]
  );

  const handleExit = useCallback(() => {
    focusTo.filterButton();
  }, [focusTo]);

  const handleCloseChip = () => {
    setChipOpen(false);
    dispatch(actions.filters.setFilterRadius(defaultSearchRadius));
  };

  const handleClearFilters = () => {
    dispatch(actions.filters.clear());
  };

  const handleApplyFilters = () => {
    searchDispatch(thunks.results.applyFilterSearch({ isBoundsSearch: chipOpen }));
    dispatch(actions.ui.setReturnFocusToKey(focusAnchorKeys.filterButton));
    setModalOpen(false);
  };

  const disabled = useMemo(
    () => (resultCount <= 1 && totalAppliedFilters === 0) || isLoading,
    [resultCount, totalAppliedFilters, isLoading]
  );

  return (
    <>
      <Button
        onClick={toggleModalOpen}
        className={`${classes.popupButton} ${classes.button} ${
          totalAppliedFilters > 0 ? classes.popupButtonActiveFilter : ''
        } filters-button-filter`}
        aria-label={`Filter results, open submenu. ${
          totalAppliedFilters > 0 ? `${totalAppliedFilters} filters currently selected` : ''
        }`}
        id="filter-button"
        disabled={disabled}
        ref={focusAnchors.filterButton}
      >
        {totalAppliedFilters > 0 && (
          <div className={classes.filterButtonCount}>
            <div>{totalAppliedFilters}</div>
          </div>
        )}
        <TuneIcon />
        Filters
      </Button>

      <Modal
        open={modalOpen}
        handleClose={handleManualClose}
        exitFunction={handleExit}
        fullWidth
        fullScreen={smDown}
        ariaId="filter-modal"
        customStyles={classes.modalOverrides}
      >
        {wasPlaceSearch ? (
          <PlaceFilterMenu
            handleCloseChip={handleCloseChip}
            chipOpen={chipOpen}
            modalOpen={modalOpen}
            classes={classes}
          />
        ) : (
          <ProviderFilterMenu
            handleCloseChip={handleCloseChip}
            chipOpen={chipOpen}
            modalOpen={modalOpen}
            classes={classes}
          />
        )}
        <Toolbar
          classes={{
            root: classes.toolbarWrapper,
          }}
        >
          <div className={classes.toolbarContent}>
            {!wasPlaceSearch && (
              <div>
                <Button
                  color="primary"
                  onClick={handleClearFilters}
                  className={classes.clearButton}
                  role="button"
                  aria-label="clear all selected filters"
                >
                  Clear All
                </Button>
              </div>
            )}
            <div>
              <ApplyFiltersButton handleApplyFilters={handleApplyFilters} />
            </div>
          </div>
        </Toolbar>
      </Modal>
    </>
  );
}

export default FilterMenu;
