import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const SectionSubheader = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    color: theme.palette.darkGray,
  },
}))(Typography);

export default SectionSubheader;
