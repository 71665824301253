import React, { useCallback } from 'react';
import PropTypes from 'propTypes';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';
import { select, actions } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    margin: 0,
  },
  label: {
    fontSize: '.875rem', // 14px
    color: theme.palette.mediumDarkGray,
  },
  checkboxRoot: {
    padding: '0 0 0 6px',
    color: theme.palette.mediumDarkGray,
  },
}));

function CompareCheckbox({ resultId, labelClasses, labelRootClasses, checkboxRootClasses }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCompareListFull = useSelector(select.results.isCompareListFull);
  const isSelectedForComparison = useSelector(select.results.isIdInCompareList(resultId));

  const handleCompareCheckbox = useCallback(() => {
    dispatch(actions.results.toggleIdInCompareList(resultId));
  }, [dispatch, resultId]);

  return (
    <FormControlLabel
      htmlFor={`compare-checkbox-${resultId}`}
      classes={{
        root: `${labelRootClasses} ${classes.labelRoot}`,
        label: `${labelClasses} ${classes.label}`,
      }}
      control={
        <Checkbox
          classes={{
            root: `${checkboxRootClasses} ${classes.checkboxRoot}`,
          }}
          disabled={!isSelectedForComparison && isCompareListFull}
          onChange={handleCompareCheckbox}
          value="compare"
          checked={isSelectedForComparison}
          inputProps={{ id: `compare-checkbox-${resultId}` }}
        />
      }
      label="Compare"
      labelPlacement="start"
      aria-label={
        (isSelectedForComparison ? 'remove ' : 'add ') +
        (isSelectedForComparison ? ` from compare list` : ` to compare list`)
      }
    />
  );
}

export default React.memo(CompareCheckbox);

CompareCheckbox.propTypes = {
  resultId: PropTypes.string.isRequired,
  labelClasses: PropTypes.string,
  labelRootClasses: PropTypes.string,
  checkboxRootClasses: PropTypes.string,
};

CompareCheckbox.defaultProps = {
  labelClasses: '',
  labelRootClasses: '',
  checkboxRootClasses: '',
};
