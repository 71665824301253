import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EMBOLD_RECOMMENDED } from 'utils/constants';
import useProviderScore from 'hooks/Provider/useProviderScore';
import ScoreIcon from 'components/ScoreIcon';
import useReturnFocus from 'hooks/useReturnFocus';
import Badge from './Badge';
import BadgeModal from './BadgeModal';

export default function ScoreBadge({ provider, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { ref, returnFocus } = useReturnFocus();
  const score = useProviderScore(provider);

  if (!score) return null;

  const modalText = (
    <>
      We {score.name === EMBOLD_RECOMMENDED ? 'recommend providers' : 'give providers a score'}{' '}
      based on standards identified by medical experts. View the <strong>Provider Strengths</strong>{' '}
      section on this provider’s details page to learn more about the provider’s performance on
      Diagnosing, Treatment, and Outcomes in patient care.
    </>
  );

  return (
    <>
      <Badge
        customSvg={<ScoreIcon score={score.value} />}
        onClick={() => setModalIsOpen(true)}
        ref={ref}
        {...props}
      >
        {score.name}
      </Badge>

      <BadgeModal
        open={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        returnFocus={returnFocus}
        title={score.name}
        text={modalText}
      />
    </>
  );
}

ScoreBadge.propTypes = {
  provider: PropTypes.shape({
    scoreOverallQuality: PropTypes.number,
  }).isRequired,
};
