import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import ProviderCompareTable from './ProviderCompareTable';
import PlaceCompareTable from './PlaceCompareTable';

export default function CompareTable({ resultIds, ...props }) {
  const isCompareProviderType = useSelector(select.results.isCompareListProviders);

  if (!resultIds?.length) return null;
  if (isCompareProviderType) return <ProviderCompareTable resultIds={resultIds} {...props} />;
  return <PlaceCompareTable resultIds={resultIds} {...props} />;
}
CompareTable.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
