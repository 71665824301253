import { createAsyncThunk } from '@reduxjs/toolkit';

import getAxiosInstanceFromState from 'fetch/getAxiosInstanceFromState';
import { logDevMessage } from 'utils/utils';
import { FILTERS_SLICE_NAME } from '../slicesNames';

export const fetchPredictedResultCount = createAsyncThunk(
  `${FILTERS_SLICE_NAME}/fetchPredictedResultCount`,
  async (args, thunkApi) => {
    const { queryString, wasPlaceSearch } = args;
    const { getState } = thunkApi;
    const state = getState();

    if (!queryString) throw new Error('No queryString provided');

    const axios = getAxiosInstanceFromState(state);

    const resultCountQuery = wasPlaceSearch ? '/places/count/?' : '/providers/count/?';
    const response = await axios.get(resultCountQuery.concat(queryString));

    return response.data;
  }
);

function transformFilterOptions(filterOptionsResponseData) {
  const result = {};

  // **if new dynamic filters are added, they need to be added to the transform map**
  // the transform map is responsible for transforming each filter key into an array of options with
  // shape { label: string, value: any }. The label being the option displayed in the filter menu,
  // the value being the value that used to compose the filter query string on searches

  // TODO TECH-3823 Add experience_counts to transformMap
  const transformMap = {
    specialtyCounts: {
      filter: 'specialties',
      transform: (specialtyArray) =>
        specialtyArray.map((specialty) => ({
          label: specialty.specialty,
          value: String(specialty.specialtyId), // this needs to be a string because the <Checkbox /> component converts it's value to a string.
        })),
    },
    availableLanguages: {
      filter: 'languages',
      transform: (languagesArray) =>
        languagesArray
          .filter((lang) => lang !== 'English')
          .map((lang) => ({ label: lang, value: lang })),
    },
    availableCredentials: {
      filter: 'credentials',
      transform: (credentialsArray) =>
        credentialsArray.map((cred) => ({ label: cred, value: cred })),
    },
  };

  for (const key in filterOptionsResponseData) {
    if (transformMap[key]) {
      result[transformMap[key].filter] = transformMap[key].transform(
        filterOptionsResponseData[key]
      );
    } else {
      logDevMessage(
        `Unhandled filter key in transformFilterOptions: ${key}. No corresponding transformMap available. To ingest this filter options data into the filtersSlice a transformMap must be provided.`
      );
    }
  }
  return result;
}

export const fetchFilterOptions = createAsyncThunk(
  `${FILTERS_SLICE_NAME}/fetchFilterOptions`,
  async (queryString, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();

    if (!queryString) throw new Error('No query string provided');

    const axios = getAxiosInstanceFromState(state);

    // fetch filter options
    const result = await axios.get(`/filter-options/?${queryString}`);

    // transform response into format that the front-end uses
    const transformedResults = transformFilterOptions(result.data);

    // returned the transformed response to the filtersSlice
    return transformedResults;
  }
);
