import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStateValue } from 'state/index';
import { useConfigValue } from 'config/state/index';
import { SHOW_CLIENT_FEATURED } from 'utils/constants';
import useReturnFocus from 'hooks/useReturnFocus';
import blueBanner from 'assets/blue_banner_badge.svg';
import Badge from './Badge';
import BadgeModal from './BadgeModal';

function FeaturedResultBadge({ isFeatured, isPlace, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { ref, returnFocus } = useReturnFocus();
  const [{ networkSlug }] = useStateValue();
  const { config, getNetworkConfigValue } = useConfigValue();
  const showClientFeatured = getNetworkConfigValue(networkSlug, SHOW_CLIENT_FEATURED) ?? false;

  const {
    FEATURED_PROVIDER_BANNER_TEXT,
    FEATURED_PROVIDER_TOOLTIP_TEXT,
    FEATURED_FACILITY_BANNER_TEXT,
    FEATURED_FACILITY_TOOLTIP_TEXT,
  } = config;
  const bannerText = isPlace ? FEATURED_FACILITY_BANNER_TEXT : FEATURED_PROVIDER_BANNER_TEXT;
  const tooltipText = isPlace ? FEATURED_FACILITY_TOOLTIP_TEXT : FEATURED_PROVIDER_TOOLTIP_TEXT;

  const hasModal = Boolean(tooltipText);

  if (!bannerText || !isFeatured || !showClientFeatured) return null;

  return (
    <>
      <Badge src={blueBanner} onClick={() => setModalIsOpen(true)} ref={ref} {...props}>
        {bannerText}
      </Badge>
      {hasModal && (
        <BadgeModal
          open={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          returnFocus={returnFocus}
          title={bannerText}
          text={tooltipText}
        />
      )}
    </>
  );
}

FeaturedResultBadge.propTypes = {
  isFeatured: PropTypes.bool,
  isPlace: PropTypes.bool,
};

FeaturedResultBadge.defaultProps = {
  isFeatured: false,
  isPlace: false,
};

export default FeaturedResultBadge;
