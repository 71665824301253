/* eslint-disable import/prefer-default-export */
import emsanaHeaderLogo from 'assets/emsanacare-header-logo.png';
import emsanaLoginLogo from 'assets/emsanacare-login-logo.png';
import { DROPDOWN_AUTOCOMPLETE } from 'utils/constants';

export const emsanaCareOverrides = {
  SCHEDULE_PHONE_NUMBER: '',
  SCHEDULE_COPY: '',
  HEADER_LOGO: emsanaHeaderLogo,
  LOCAL_LOGO_ASSET: emsanaLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
  SUPPORT_LINK: 'https://emsanacare.emboldhealth.com/portal/',
  WELCOME_MESSAGE: `EmsanaCare and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};
