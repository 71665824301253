import React from 'react';
import { useDispatch } from 'react-redux';
import ProfileSectionWrap from 'components/Profile/ProfileSections/ProfileSectionWrap';
import emboldIcon from 'assets/embold-icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import costLowerFlag from 'assets/cost-lower-flag.svg';
import costAvgFlag from 'assets/cost-average-flag.svg';
import costHigherFlag from 'assets/cost-higher-flag.svg';
import PropTypes from 'prop-types';

import { actions } from 'store/toolkit';
import Tooltip from 'components/Tooltip';

function CostText(scoreCost) {
  let text;
  if (scoreCost === 3) {
    text =
      'More efficient providers tend to practice in lower cost facilities, order fewer tests and procedures, and have fewer office visits per patient on average.';
  }

  if (scoreCost === 2) {
    text =
      'Average efficiency providers tend to practice in average cost facilities, order an average number of tests and procedures, and have an average number of office visits per patient.';
  }

  if (scoreCost === 1) {
    text =
      'Less efficient providers tend to practice in higher cost facilities, order more tests and procedures, and have more office visits per patient on average.';
  }

  return text;
}

function Cost({ scoreCost, profileData, isPrinting }) {
  const dispatch = useDispatch();

  const costStyles = (scoreCostUrl, borderColor) => ({
    position: 'relative',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderColor: `#f00 #0f0 #00f ${borderColor}`,
    borderStyle: 'solid solid solid solid',
    borderWidth: '0 0 0 5px',
    backgroundImage: `url(${scoreCostUrl})`,
    color: scoreCostUrl ? '#000000' : '#6B6E71',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90px 58px',
    width: '95px',
    height: '58px',
    wordSpacing: '100vw',
    lineHeight: '15px',
    paddingRight: '10px',
  });

  const useStyles = makeStyles((theme) => ({
    costWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      gap: '2px',
      paddingTop: '13.5px',
      boxSizing: 'border-box',
    },
    costLow: costStyles(scoreCost === 3 ? costLowerFlag : undefined, '#39BB6B'),
    costAvg: costStyles(scoreCost === 2 ? costAvgFlag : undefined, '#989B9D'),
    costHigh: costStyles(scoreCost === 1 ? costHigherFlag : undefined, '#F54650'),
    inlineButton: {
      fontWeight: 400,
      color: theme.palette.linkBlue,
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
      cursor: 'pointer',
    },
    textWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
      marginLeft: '-10px',
      height: '200px',
      overflow: 'hidden',
    },
    item: {
      padding: 'none',
    },
    hoverCursor: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }));

  const handleClick = function () {
    dispatch(actions.ui.openProfileModal({ type: 'cost', data: profileData }));
  };

  const classes = useStyles();
  return (
    <ProfileSectionWrap
      title="Cost Efficiency"
      extraClass={classes.hoverCursor}
      onClick={() => handleClick()}
      icon={emboldIcon}
      isPrinting={isPrinting}
      titleTooltip={
        <Tooltip
          title="Cost Efficiency"
          message="Embold recommends that you first look for a high-quality provider and then consider their Cost Efficiency. High-quality and highly cost-efficient providers deliver the best care while using the least resources."
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={5} className={classes.item} nomargin>
          <div className={classes.costWrapper}>
            <div
              className={classes.costLow}
              style={{ cursor: scoreCost === 3 ? 'pointer' : 'default' }}
              onClick={scoreCost === 3 ? handleClick : null}
              onKeyPress={scoreCost === 3 ? handleClick : null}
            >
              More Efficient
            </div>
            <div
              className={classes.costAvg}
              style={{ cursor: scoreCost === 2 ? 'pointer' : 'default' }}
              onClick={scoreCost === 2 ? handleClick : null}
              onKeyPress={scoreCost === 2 ? handleClick : null}
            >
              Average
            </div>
            <div
              className={classes.costHigh}
              style={{ cursor: scoreCost === 1 ? 'pointer' : 'default' }}
              onClick={scoreCost === 1 ? handleClick : null}
              onKeyPress={scoreCost === 1 ? handleClick : null}
            >
              Less Efficient
            </div>
          </div>
        </Grid>
        <Grid item xs={7} className={classes.textWrapper}>
          <Typography style={{ padding: '40px 6px 50px', fontSize: '12.8px' }}>
            {CostText(scoreCost)}{' '}
            <span
              onClick={handleClick}
              onKeyPress={handleClick}
              role="button"
              tabIndex="0"
              className={classes.inlineButton}
            >
              Find out why cost efficiency matters.
            </span>
          </Typography>
        </Grid>
      </Grid>
    </ProfileSectionWrap>
  );
}

Cost.propTypes = {
  scoreCost: PropTypes.number.isRequired,
  profileData: PropTypes.shape({}).isRequired,
  isPrinting: PropTypes.bool.isRequired,
};

/** @deprecated */
export default withRouter(Cost);
