import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography, Toolbar, TextField, MenuItem, Button } from '@material-ui/core';

import {
  CallOutlined,
  SchoolOutlined,
  BookOutlined,
  OpenInBrowserOutlined,
  EmailOutlined,
  DirectionsOutlined,
  RecordVoiceOverOutlined,
  AccessTimeOutlined,
  AccessibleForwardOutlined,
  Search,
  NearMe,
  NearMeOutlined,
  CheckCircleOutlined,
  HighlightOff,
  MonetizationOnOutlined,
  MonetizationOn,
  LocalHospitalOutlined,
  GradeRounded,
  InfoOutlined,
  VerifiedUser,
  Error as ErrorIcon,
  Language,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';
import StyleguideCanvas from 'components/Pages/StyleGuide/StyleguideCanvas';
import CheckOrXIcon from 'icons/CheckOrXIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  toolbar: {
    justifyContent: 'center',
    columnGap: theme.spacing(2),
  },
  iconContainer: {
    margin: theme.spacing(1),
    borderRadius: 4,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

const inputVariant = 'outlined';

const muiExamples = [
  CallOutlined,
  SchoolOutlined,
  BookOutlined,
  OpenInBrowserOutlined,
  EmailOutlined,
  DirectionsOutlined,
  RecordVoiceOverOutlined,
  AccessTimeOutlined,
  AccessibleForwardOutlined,
  Search,
  NearMe,
  NearMeOutlined,
  CheckCircleOutlined,
  HighlightOff,
  MonetizationOnOutlined,
  MonetizationOn,
  LocalHospitalOutlined,
  GradeRounded,
  InfoOutlined,
  VerifiedUser,
  ErrorIcon,
  Language,
];

export default function IconsStyleguide() {
  const classes = useStyles();
  const [color, setColor] = useState('inherit');
  const [fontSize, setFontSize] = useState('medium');
  const [htmlColor, setHtmlColor] = useState(undefined);

  const handleColorChange = useCallback((evt) => setColor(evt.target.value), [setColor]);
  const handleFontChange = useCallback((evt) => setFontSize(evt.target.value), [setFontSize]);
  const handleHtmlColorChange = useCallback(
    (evt) => {
      setHtmlColor(evt.target.value || undefined);
    },
    [setHtmlColor]
  );

  const iconProps = useMemo(() => ({ color, fontSize, htmlColor }), [color, fontSize, htmlColor]);

  return (
    <Box className={classes.root}>
      <Typography variant="h2" align="center">
        Icons
      </Typography>

      <Toolbar className={classes.toolbar}>
        <TextField
          select
          label="color"
          variant={inputVariant}
          value={color}
          onChange={handleColorChange}
        >
          {['inherit', 'primary', 'secondary', 'error', 'disabled', 'action'].map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="fontSize"
          variant={inputVariant}
          value={fontSize}
          onChange={handleFontChange}
        >
          {['default', 'inherit', 'large', 'medium', 'small'].map((val) => (
            <MenuItem key={val} value={val}>
              {val}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="htmlColor"
          placeholder="#f7f7f7 or darkblue"
          variant={inputVariant}
          value={htmlColor}
          onChange={handleHtmlColorChange}
        />
      </Toolbar>

      <StyleguideCanvas
        name="MUI Icon Examples"
        display="row"
        description={`
        These are some of the preferred icons used throughout the app from the MUI library.
        The full library can be found here: https://v4.mui.com/components/material-icons/#material-icons

        Click icon to copy import code
      `}
      >
        {muiExamples.map((Icon) => (
          <Button
            key={Icon.type.render.displayName}
            className={classes.iconContainer}
            onClick={() =>
              navigator.clipboard.writeText(
                `import ${Icon.type.render.displayName} from '@material-ui/icons/${Icon.type.render.displayName}';`
              )
            }
          >
            <Icon {...iconProps} />
            <Typography component="span" variant="caption">
              {Icon.type.render.displayName}
            </Typography>
          </Button>
        ))}
      </StyleguideCanvas>

      <StyleguideCanvas
        name="CheckOrXIcon"
        justify="center"
        display="row"
        canvasStyle={{ columnGap: 16 }}
        description={`
        This component accepts a "checked" prop to determine which icon it renders. Additionally it dynamically sets the htmlColor prop.
        It extends all of the props that other icons accept.
        `}
      >
        <div>
          <CheckOrXIcon checked={false} fontSize={fontSize} color={color} />
          <pre>{`checked={false}`}</pre>
        </div>
        <div>
          <CheckOrXIcon checked fontSize={fontSize} color={color} />
          <pre>{`checked={true}`}</pre>
        </div>
      </StyleguideCanvas>
    </Box>
  );
}

IconsStyleguide.propTypes = {};

IconsStyleguide.defaultProps = {};
