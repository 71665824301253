import * as Sentry from '@sentry/react';

import actions from 'store/actions';

import fetchClient from 'utils/fetchClient';
import { PROVIDER_RESULT_TYPE, PLACE_RESULT_TYPE } from 'utils/constants';

/**
 * This function fetches the specialties from the api and dispatches the results to the redux store
 * @param {Function} dispatch The dispatch function from a redux store object
 */
export default function fetchSpecialties(dispatch) {
  dispatch(actions.specialties.setLoadingSpecialties(true));
  fetchClient('/specialties/')
    .then((res) => {
      if (res.data && res.data.length) {
        const typeMap = {
          P: PROVIDER_RESULT_TYPE,
          F: PLACE_RESULT_TYPE,
        };
        const specialtiesMap = {
          [PROVIDER_RESULT_TYPE]: {},
          [PLACE_RESULT_TYPE]: {},
        };
        const subspecialtiesMap = {
          [PROVIDER_RESULT_TYPE]: {},
          [PLACE_RESULT_TYPE]: {},
        };
        res.data.forEach((specialty) => {
          specialtiesMap[typeMap[specialty.type]][specialty.specialtyId] = specialty;
          if (specialty.subspecialties.length > 0) {
            specialty.subspecialties.forEach((subspecialty) => {
              // eslint-disable-next-line no-param-reassign
              subspecialty.parentSpecialtyId = specialty.specialtyId;
              subspecialtiesMap[typeMap[specialty.type]][subspecialty.subspecialtyId] =
                subspecialty;
            });
          }
        });
        dispatch(actions.specialties.setSpecialties(specialtiesMap));
        dispatch(actions.specialties.setSubspecialties(subspecialtiesMap));
        dispatch(actions.specialties.setFailedToLoadSpecialties(false));
      }
    })
    .catch((error) => {
      dispatch(actions.specialties.setFailedToLoadSpecialties(true));
      Sentry.captureException(error);
    })
    .finally(() => {
      dispatch(actions.specialties.setLoadingSpecialties(false));
    });
}
