/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';

import { getEnv, isValidHttpUrl } from 'utils/utils';
import { delayByAttempt, MAX_RETRIES } from 'utils/fetchRetryDelay';
import * as selectConfig from './selectConfig';

/**
 * Formats the data from Fusions Client Config JSON into format that PG uses
 */
function formatClientConfigData(responseData) {
  const result = {};

  result.PROVIDER_LOOKUP = Boolean(responseData.is_provider_lookup);
  result.CLOSED_ACCESS = responseData.is_closed_access;
  result.SHOW_DISCLAIMER = responseData.show_disclaimer;
  result.HAS_PORTAL_INTEGRATION = responseData.has_portal_integration;
  result.SHOW_FILTER_COUNTS = responseData.show_filter_count;
  result.USE_NEW_SPECIALTY_AUTOCOMPLETE = responseData.use_new_specialty_autocomplete ?? false;
  result.DISCLAIMER_TEXT = responseData.disclaimer_text;
  result.SHOW_SHARE = responseData.show_share ?? true;
  result.SHOW_SCHEDULE_BUTTON = responseData.show_schedule_button ?? true;
  result.SHOW_COST = responseData.show_cost;
  result.SHOW_PROVIDER_CREDENTIALS_FILTER = responseData.show_provider_credentials_filter;
  // Benefits Change
  result.BENEFITS_CHANGE_DATE_RANGE = responseData.benefits_change_date_range;
  result.SHOW_SERVICE_COST = responseData.show_service_cost ?? false;
  result.SHOW_SUBSPECIALTY_SCORES = responseData.show_subspecialty_scores ?? false;
  result.SHOW_EMBOLD_RECOMMENDED = responseData.show_embold_recommended ?? false;

  result.SHOW_CHAT = responseData.show_chat ?? false;
  result.SHOW_CHAT_FEEDBACK = responseData.show_chat_feedback ?? true;
  result.SHOW_CHAT_SCENARIO = responseData.show_chat_scenario ?? false;

  result.REGION_SELECTOR_CODES =
    responseData.networks &&
    responseData.networks.reduce((acc, val) => {
      acc[val.slug] = {
        text: val.display_name,
        slug: val.slug,
        networkGroupName: val.network_group_name || null,
      };
      if (val.config && !isEmpty(val.config)) {
        // eslint-disable-next-line no-shadow
        acc[val.slug].config = Object.entries(val.config).reduce((config, [key, val]) => {
          // eslint-disable-next-line no-param-reassign
          config[key.toUpperCase()] = val;
          return config;
        }, {});
      }
      return acc;
    }, {});

  result.UPDATE_REGION_TEXT = responseData.network_select_text;
  result.SCHEDULE_PHONE_NUMBER = responseData.schedule_phone_number;
  result.SUPPORT_LINK = responseData.support_link;
  if (responseData.maps_url) {
    result.MAPS_URL = responseData.maps_url;
  }
  if (responseData.search_error_text) {
    result.SEARCH_ERROR_TEXT = responseData.search_error_text;
  }

  if (responseData.show_hospital_affiliations) {
    result.SHOW_HOSPITAL_AFFILIATIONS = responseData.show_hospital_affiliations;
  }

  if (responseData.welcome_message) {
    result.WELCOME_MESSAGE = responseData.welcome_message;
  }
  // Benefits Change
  result.BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT =
    responseData.benefits_change_coverage_decreasing_text || '';
  result.BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP =
    responseData.benefits_change_coverage_decreasing_tooltip || '';
  result.BENEFITS_CHANGE_COVERAGE_CHANGE_TITLE =
    responseData.benefits_change_coverage_change_title || '';
  result.BENEFITS_CHANGE_HIGHER_COVERAGE_GAUGE_TEXT =
    responseData.benefits_change_higher_coverage_gauge_text || '';
  result.BENEFITS_CHANGE_LOWER_COVERAGE_GAUGE_TEXT =
    responseData.benefits_change_lower_coverage_gauge_text || '';
  result.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TITLE =
    responseData.benefits_change_higher_benefit_modal_title || '';
  result.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TEXT =
    responseData.benefits_change_higher_benefit_modal_text || '';
  result.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TITLE =
    responseData.benefits_change_lower_benefit_modal_title || '';
  result.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TEXT =
    responseData.benefits_change_lower_benefit_modal_text || '';
  result.SURGERY_PLUS_PHONE_NUMBER = responseData.surgery_plus_phone_number;

  if (responseData.header_image) {
    result.HEADER_IMAGE = responseData.header_image;
  }

  if (responseData.login_image) {
    result.LOGIN_IMAGE = responseData.login_image;
  }

  // Featured provider banner config
  result.FEATURED_PROVIDER_BANNER_TEXT = responseData.featured_provider_banner_text || '';
  result.FEATURED_PROVIDER_TOOLTIP_TEXT = responseData.featured_provider_tooltip_text || '';
  result.FEATURED_PROVIDER_LINK = responseData.featured_provider_link || '';
  result.FEATURED_FACILITY_BANNER_TEXT = responseData.featured_facility_banner_text || '';
  result.FEATURED_FACILITY_TOOLTIP_TEXT = responseData.featured_facility_tooltip_text || '';
  result.FEATURED_PROVIDER_COLOR = responseData.featured_provider_color || undefined;
  result.FEATURED_PROVIDER_ICON = responseData.featured_provider_icon || undefined;

  return result;
}

const fetchClientConfig = createAsyncThunk('fetchClientConfig', async (args, thunkAPI) => {
  const { network } = args;
  const { getState, dispatch } = thunkAPI;

  if (network) {
    // if a network was gathered from params or local storage
    dispatch({ type: 'config/setNetworkSlug', payload: network });
  }

  const state = getState();
  // get the config name, this is REQUIRED field on the local client config object and should be the name of the json file we fetch
  const configName = selectConfig.name(state);

  if (!configName) throw new Error('Missing Client Config Name');

  // determine url for the correct env
  let hostPrefix;
  switch (getEnv()) {
    case 'local':
      hostPrefix = 'testing.';
      break;
    case 'testing':
      hostPrefix = 'testing.';
      break;
    case 'staging':
      hostPrefix = 'staging.';
      break;
    case 'production':
      hostPrefix = '';
      break;
    default:
      throw new Error('Unhandled case for host prefix env');
  }

  const url = `https://static.${hostPrefix}emboldhealth.com/client-config/${configName}.json`;
  if (!isValidHttpUrl(url)) throw new Error(`Invalid URL ${url}`);

  let attempts = 0;
  let responseData;
  let error;
  // here we setup a loop to attempt to get the client config. If it fails we can try more attempts
  while (attempts < MAX_RETRIES) {
    try {
      await delayByAttempt(attempts);
      const response = await fetch(url);
      responseData = await response.json();
      // if fetch succeeds, break out of loop
      break;
    } catch (e) {
      error = e;
      Sentry.captureMessage(`Failed to fetch Client Config. Attempting again. Error: ${e}`);
      console.error(`Failed to fetch Client Config. Attempting again. Error: ${e}`);
    } finally {
      attempts += 1;
    }
  }

  // if we do not have a responseData at this point, then all attempts failed. Break out by throwing the last stored error
  if (!responseData) {
    Sentry.captureException(
      new Error(`Unable to fetch Client Config after ${attempts} attempts. Error: ${error}`)
    );
    console.error(`Unable to fetch Client Config after ${attempts} attempts. Error: ${error}`);
    throw error;
  }

  // finally we returned a formatted version of the response data
  return formatClientConfigData(responseData);
});

export default {
  fetchClientConfig,
};
