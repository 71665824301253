import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Link } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';

import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';
import { thunks, select } from 'store/toolkit';
import useUpdateTitle from 'hooks/useUpdateTitle';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
import ProfileSkeleton from 'components/ModernExperience/LoadingSkeletons/ProfileSkeleton';
import ResultProfile from 'components/ModernExperience/Profile/ResultProfile';
import ResultProfileTabs from 'components/ModernExperience/Profile/ResultProfileTabs';
import CompareTable from 'components/ModernExperience/CompareTable/CompareTable';
import PrintButton from 'components/PrintButton';
import SplitCompareTableForPrint from 'components/ModernExperience/CompareTable/SplitCompareTableForPrint';
import CollapsingProfileList from 'components/ModernExperience/Profile/CollapsingProfileList';
import { _analyticsActions } from 'analytics/index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorPage: {
    justifyContent: 'center',
    color: theme.palette.primary.main,
    textAlign: 'center',
    '& p': { fontWeight: 'bold' },
    '& a': { marginTop: theme.spacing(2) },
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: theme.typography.h1.fontSize,
  },
  printButtonRoot: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  printButtonLabel: {
    display: 'flex',
  },
  collapsingProfileList: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

function SharePageSkeleton() {
  const classes = useStyles();
  const animation = 'wave';
  return (
    <div className={classes.root}>
      <Skeleton animation={animation}>
        {/* This text does not actually render but it gives the Skeleton a height and width to match this dummy text */}
        <Typography variant="h2" animation={animation}>
          XX Providers Were Shared With You
        </Typography>
      </Skeleton>
      <ProfileSkeleton animation={animation} />
    </div>
  );
}

function SharePageError() {
  const classes = useStyles();
  const errorMessage = useSelector(select.results.error);
  return (
    <div className={`${classes.root} ${classes.errorPage}`}>
      <ErrorIcon className={classes.errorIcon} />
      <Typography variant="h2">No Results</Typography>

      {/* This error message stored in Redux is a user facing message set in the reducer (resultsSlice.jsx) based on the response status code */}
      <Typography>{errorMessage}</Typography>

      <Typography>Would you like to continue by creating a new search?</Typography>
      <Link component={RouterLink} to="/">
        Log In and start a new search
      </Link>
    </div>
  );
}

function ShareContent({ resultIds = [], profileCompareThreshold }) {
  const classes = useStyles();
  const isMobile = useSelector(select.ui.isMdDown);

  if (resultIds.length === 0) return null;

  // single profile view
  if (resultIds.length === 1) {
    return <ResultProfile id={resultIds[0]} />;
  }

  // tabbed profile view
  if (resultIds.length <= profileCompareThreshold) {
    return <ResultProfileTabs ids={resultIds} />;
  }

  // compare view desktop
  if (!isMobile) return <CompareTable resultIds={resultIds} showActionButtons />;

  return <CollapsingProfileList resultIds={resultIds} className={classes.collapsingProfileList} />;
}

function ShareHeader({ resultIds = [], profileCompareThreshold, ...props }) {
  const classes = useStyles();
  const [isPrinting, setIsPrinting] = useState(false);
  const shareType = useSelector(select.results.shareType) || 'Result';
  const isMobile = useSelector(select.ui.isMdDown);
  const dispatch = useDispatch();

  if (resultIds.length === 0) return null;

  // single profile view
  if (resultIds.length === 1) {
    return <Typography {...props}>1 {shareType} Shared With You</Typography>;
  }

  // tabbed profile view
  if (resultIds.length <= profileCompareThreshold) {
    return (
      <Typography {...props}>
        {resultIds.length} {shareType}s Shared With You
      </Typography>
    );
  }

  // compare view
  const text = `${resultIds.length} ${shareType}s Shared With You`;

  return (
    <Typography {...props}>
      {text}

      {!isMobile && (
        <PrintButton
          variant="outlined"
          color="primary"
          classes={{ label: classes.printButtonLabel, root: classes.printButtonRoot }}
          startIcon={isPrinting && <EmboldLoadingSpinner height={20} width={20} />}
          disabled={isPrinting}
          includeWatermark
          printComponent={
            // This is what will actually print
            <SplitCompareTableForPrint
              resultIds={resultIds}
              header={<Typography {...props}>Embold Health - {text}</Typography>}
            />
          }
          options={{
            documentTitle: `Embold Health - ${text}`,
            onBeforePrint: () => {
              dispatch(_analyticsActions.printShareCompareTable(resultIds));
              setIsPrinting(true);
            },
            onAfterPrint: () => setIsPrinting(false),
          }}
        >
          Print
        </PrintButton>
      )}
    </Typography>
  );
}

export default function SharePage() {
  const classes = useStyles();
  const { id } = useParams();

  const dispatch = useDispatch();

  const isLoading = useSelector(select.results.isLoading);
  const error = useSelector(select.results.error);
  const results = useSelector(select.results.idList);
  const profileCompareThreshold = useSelector(select.config.profileCompareThreshold); // after this value, switch to a compare display

  useUpdateTitle(`${DEFAULT_DOCUMENT_TITLE} - Share`);

  useEffect(() => {
    dispatch(thunks.results.fetchShare(id));
  }, [dispatch, id]);

  if (isLoading) return <SharePageSkeleton />;
  if (error || results?.length < 1) return <SharePageError />;

  return (
    <div className={classes.root}>
      <ShareHeader
        resultIds={results}
        variant="h2"
        className={classes.title}
        profileCompareThreshold={profileCompareThreshold}
      />
      <ShareContent resultIds={results} profileCompareThreshold={profileCompareThreshold} />
    </div>
  );
}

ShareContent.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileCompareThreshold: PropTypes.number.isRequired,
};

ShareHeader.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileCompareThreshold: PropTypes.number.isRequired,
};
