import React from 'react';
import { useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, ListItem, Card, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LinkToModal from '../../LinkToModal';
import defaultImage from '../../../assets/default-promotion-icon.svg'; // TODO TECH-3847 replace with custom icon

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiCard-root': {
      padding: theme.spacing(2),
      // this is a grid item, so it will occupy 100% of the spacing allowed by it's parent container
      // with that, if the grid item size is set to 4 (giving 3 columns), all items will be 100% of their column width
      // regardless of the content/text size
      width: '100%',
      display: 'flex',
      textDecoration: 'none',
      '&:hover': {
        boxShadow: theme.shadows[4],
      },
    },
  },
  logo: {
    width: '20%',
    minWidth: 60,
    minHeight: 60,
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    marginLeft: theme.spacing(2),
    '& .MuiTypography-root': {
      textAlign: 'left',
      width: '100%',
    },
  },
  cardRoot: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
}));

function PromotionPreviewCard({ promotion, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const handlePromotionEvent = () =>
    dispatch(_analyticsActions.landingPagePromotionClick(promotion.programName));

  // when using mobile, the link should go to the route as a page
  // otherwise the link should open a modal
  const LinkComponent = isSmDown ? RouterLink : LinkToModal;

  return (
    <Grid item component={ListItem} className={classes.root} {...props}>
      <Card
        classes={{ root: classes.cardRoot }}
        component={LinkComponent}
        onClick={handlePromotionEvent}
        to={`/promotion/${promotion.id}`}
        aria-label={`View ${promotion.programName} benefit details`}
        aria-describedby={`${promotion.id}-subname`}
        role="button"
      >
        <img
          src={promotion.logo || defaultImage}
          alt={promotion.programName}
          className={classes.logo}
          aria-hidden
        />
        <div className={classes.textContainer}>
          <Typography noWrap variant="subtitle2" component="strong">
            {promotion.programName}
          </Typography>
          <Typography
            noWrap={!isXsDown}
            variant="body2"
            component="span"
            id={`${promotion.id}-subname`}
          >
            {promotion.programSubname}
          </Typography>
        </div>
      </Card>
    </Grid>
  );
}

export const promotionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  programName: PropTypes.string.isRequired,
  programSubname: PropTypes.string.isRequired,
  logo: PropTypes.string,
  detailedDescription: PropTypes.string.isRequired,
});

PromotionPreviewCard.propTypes = {
  promotion: promotionPropType.isRequired,
};

export default React.memo(PromotionPreviewCard);
