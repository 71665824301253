import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';

import Modal from 'components/Modals/Modal';
import ProviderAutocomplete from 'components/ProviderSearch/ProviderAutocomplete';
import select from 'store/select';
import actions from 'store/actions';

const useStyles = makeStyles((theme) => ({
  backButton: {
    position: 'absolute',
    left: 0,
    color: theme.palette.primary.main,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
    minHeight: '60px',
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    color: theme.palette.primary.main,
  },
  autocompleteWrapper: {
    margin: '0px 10px',
  },
}));

function ProviderSearchModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerSearchModalOpen = useSelector(select.ui.providerSearchModalOpen);

  return (
    <Modal
      fullScreen
      showMobileMenu
      open={providerSearchModalOpen}
      showX={false}
      ariaId="search-modal"
    >
      <Typography component="h1" variant="srOnly">
        Embold Health Provider Guide Search Page
      </Typography>
      <form
        style={{
          overflowY: 'hidden',
          display: 'flex',
          flexFlow: 'column',
        }}
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(actions.ui.closeModal('providerSearch'));
        }}
      >
        <div className={classes.headerContainer}>
          <Button className={classes.backButton} type="submit">
            <LeftArrowIcon />
            Back
          </Button>
          <div className={`${classes.header}`}>Update Search</div>
        </div>

        <div className={classes.autocompleteWrapper}>
          <ProviderAutocomplete />
        </div>
      </form>
    </Modal>
  );
}

export default ProviderSearchModal;
