import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';

export default function PlacePhoneNumberCell({ placeId }) {
  const { phone } = useSelector(select.place(placeId).data) || {};

  if (!phone) return null;

  return (
    <Link role="link" href={`tel:${phone}`}>
      {phone}
    </Link>
  );
}

PlacePhoneNumberCell.propTypes = {
  placeId: PropTypes.string.isRequired,
};
