import { DEFAULT_LOCATIONS, DOLLAR_GENERAL_SUPPORT } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 1c4d058eb180257d25be814441b1ea788ecc7107',
  staging: 'Token d5b5f37e679ac7d73c61465da2115d084c8e641d',
  prod: 'Token 88a12471a0c85a5a8d5c483a864e710f895345d4',
};

const token = selectToken(apiTokens);

export const DG_LOGIN_REGEX = /^(dlgtn|dgiga|dgqnh|degmoks|dlzwi|dlhnj|pdgok|jdldc|dgsfl)$/;

export const dollarGeneralSupportConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'dgbcbst-support',
  EMBOLD_CLIENT: DOLLAR_GENERAL_SUPPORT,
  EMBOLD_CLIENT_SLUG: 'dgbcbst-support',
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Goodlettsville,
  USE_BROWSER_LOCATION: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_NETWORK_NAME: 'dollarGeneralSupportUserId',
  LOGIN_COOKIE_AUTH_REGEX: DG_LOGIN_REGEX, // https://regex101.com/
};
