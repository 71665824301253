import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ASSISTANT, USER } from 'store/slices/chat/chatConstants';
import ReactMarkdown from 'react-markdown';

import { ListItem, ListItemText, Avatar } from '@material-ui/core/';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import EmboldIcon from 'assets/embold-icon.png';

const useStyles = (role) =>
  makeStyles((theme) => ({
    '@keyframes fadeUpAnimation': {
      from: {
        opacity: 0,
        transform: 'translateY(10px)',
      },
      to: {
        opacity: 1,
      },
    },
    listItem: {
      animationName: '$fadeUpAnimation',
      animationDuration: '.2s',
      animationTimingFunction: 'linear',
      justifyContent: role === ASSISTANT ? 'flex-start' : 'flex-end',
      wordWrap: 'break-word',
    },
    message: {
      backgroundColor: role === ASSISTANT ? 'white' : theme.palette.secondary.main,
      color: role === ASSISTANT ? 'black' : 'white',
      borderRadius: 8,
      padding: 8,
      marginRight: role === ASSISTANT ? '1rem' : '0',
      marginLeft: role === USER ? '1rem' : '0',
      flex: 'inherit',
      '& MuiListItemText-primary': {
        display: 'inline-block',
      },
      maxWidth: '75%',
      whiteSpace: 'pre-wrap',
    },
    avatar: {
      position: 'absolute',
      height: 30,
      width: 30,
      bottom: -4,
      left: -4,
      backgroundColor: 'white',
      padding: 4,
    },
  }));

function ChatMessage({ message, error }) {
  const { role, content } = message;
  const classes = useStyles(role)();

  return (
    <ListItem className={classes.listItem}>
      {role === ASSISTANT && (
        <Avatar alt="embold health icon" src={EmboldIcon} className={classes.avatar} />
      )}
      <ListItemText
        className={classes.message}
        primary={<ReactMarkdown components={{ p: 'div', ol: 'dl' }}>{content}</ReactMarkdown>}
        disableTypography
      />
      {(error || message.emergency) && <ErrorIcon style={{ color: '#FE003C' }} />}
    </ListItem>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    role: PropTypes.string,
    content: PropTypes.string,
    emergency: PropTypes.bool,
  }).isRequired,
  error: PropTypes.bool,
};

ChatMessage.defaultProps = {
  error: false,
};

export default React.memo(
  ChatMessage,
  (prev, next) => prev.message.content === next.message.content
);
