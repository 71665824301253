import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { HOSPITAL_SAFETY_GRADE_IMAGE } from 'utils/constants';
import ProfileBadge from './ProfileBadge';

const useStyles = makeStyles({
  hospitalGradeLastUpdated: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 38,
    marginRight: 'auto',
    marginLeft: 'auto',
    color: 'white',
    fontSize: '0.6rem',
    fontWeight: 900,
    zIndex: 2,
  },
});

export default function HospitalSafetyGradeBadge({ placeId }) {
  const classes = useStyles();
  const placeData = useSelector(select.place(placeId).data) || {};
  const { hospitalGrade, hospitalGradeLastUpdated } = placeData;

  if (!hospitalGrade) return null;

  return (
    <ProfileBadge
      iconUrl={HOSPITAL_SAFETY_GRADE_IMAGE[hospitalGrade]}
      iconAlt={hospitalGrade}
      iconOverlay={hospitalGradeLastUpdated}
      iconOverlayClass={classes.hospitalGradeLastUpdated}
      title="Hospital Safety Grade"
      TooltipProps={{
        title: 'Hospital Safety Grade',
        message:
          'The Hospital Safety Grade assigns an ‘A’, ‘B’, ‘C’, ‘D’ or ‘F’ grade to nearly 3,000 hospitals across the country based on their performance in preventing medical errors, injuries, accidents, infections and other harms to patients in their care.',
        link: {
          to: '/terms#hospital-safety-grades',
          label: 'more information',
        },
      }}
    />
  );
}

HospitalSafetyGradeBadge.propTypes = {
  placeId: PropTypes.string.isRequired,
};
