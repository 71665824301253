const fiord = '#414968';
const mediumBlue = '#003A70';
const lightBlue = '#136ADD';
const infoBlue = '#1F78D1';
const linkBlue = '#136ADD';
const lighterBlue = '#D9DEE8';
const coral = '#F25645';
const friendlyTeal = '#57D6CA';
const cardBackgroundColor = '#f5f5f5';
const lightGray = '#E0E0E0';
const mediumGray = '#C4C4C4';
const mediumDarkGray = '#707070';
const darkGray = '#636363';
const mintGreen = '#43D48E';
const successGreen = '#01C265';
const accessibleGreen = '#01A757'; // Not considered a 4.5 : 1 against a white background! Only accessible for non-text elements
const darkGreen = '#236731';
const errorRed = '#FE003C';
const appleRed = '#D73737';
const white = '#FFFFFF';
const black = '#000000';
const navyBlue = '#00205B';
const warningOrange = '#C34515';
const swampGreen = '#377277';

const iconSizes = {
  small: '1rem',
  medium: '1.35rem',
  large: '2.1875rem',
};

const defaultTheme = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
      margin: '0.5rem 0',
      '&.no-margin': {
        margin: 0,
      },
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 1.15,
      margin: '0.5rem 0',
      '&.no-margin': {
        margin: 0,
      },
      '&.no-bold': {
        fontWeight: 'normal',
      },
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0.25rem 0',
      '&.no-margin': {
        margin: 0,
      },
      '&.uppercase': {
        textTransform: 'uppercase',
      },
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
      '&.no-margin': {
        margin: 0,
      },
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '.875rem',
    },
    body1: {
      fontSize: '.875rem',
    },
    body2: {
      fontSize: '.875rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 'bold',
      '&.no-margin': {
        margin: 0,
      },
    },
    subtitle2: {
      fontSize: '.875rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.25,
      display: 'inline-block',
    },
  },
  palette: {
    primary: {
      main: navyBlue,
    },
    secondary: {
      main: infoBlue,
    },
    error: {
      main: appleRed,
    },
    fiord,
    mediumBlue,
    lightBlue,
    linkBlue,
    lighterBlue,
    coral,
    friendlyTeal,
    cardBackgroundColor,
    lightGray,
    mediumGray,
    mediumDarkGray,
    darkGray,
    mintGreen,
    successGreen,
    accessibleGreen,
    darkGreen,
    errorRed,
    appleRed,
    white,
    black,
    navyBlue,
    warningOrange,
    infoBlue,
    swampGreen,
  },
  overrides: {
    MuiDrawer: {
      root: {
        zIndex: '1600!important',
      },
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 0px) and (orientation: landscape) and (max-width: 600px)': {
          minHeight: 56,
        },
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Lato, Arial, sans-serif',
        overflowY: 'hidden',
        overflowX: 'visible',
        '&:focus': {
          outline: `2px solid ${lightBlue}`,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.pulsingBackgroundFocus:focus': {
          outline: 'none',
          '&:after': {
            content: '""',
            top: 0,
            bottom: 0,
            borderRadius: 'inherit',
            background: 'rgba(0,0,0,0.15)',
            animationName: 'pulseAnimationCircular',
            animationDuration: '3s',
          },
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        '&.Mui-focusVisible > span': {
          outline: `2px solid ${lightBlue}`,
        },
      },
    },
    MuiButton: {
      root: {
        lineHeight: 1.2,
        borderRadius: 50,
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '8px 28px 8px',
        textTransform: 'none',
        '&$containedPrimary': {
          color: 'white',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      contained: {
        display: 'inline-block',
        verticalAlign: 'middle',
        boxShadow: 'none',
        minWidth: 140,
        height: 'auto',
        '&:focus': {
          boxShadow: 'none',
          outlineColor: 'white',
          outlineWidth: 1,
          outlineOffset: -4,
        },
        '&:active': {
          boxShadow: 'none',
        },
        '@media (forced-colors: active)': {
          border: '2px solid',
        },
      },
      outlined: {
        display: 'inline-block',
        verticalAlign: 'middle',
        minWidth: 140,
        height: 'auto',
        padding: '8px 28px 8px',
      },
      containedPrimary: {
        '&:focus:after': {
          background: 'rgba(0,0,0,0.5)',
        },
      },
      containedSecondary: {
        '&:focus:after': {
          background: 'rgba(255,255,255,0.15)',
        },
      },
      outlinedPrimary: {
        border: `1px solid ${navyBlue}`,
      },
      outlinedSecondary: {
        border: `1px solid ${lightBlue}`,
      },
      label: {
        position: 'relative',
        zIndex: 1,
      },
    },
    MuiRadio: {
      root: {
        '& > span': {
          borderRadius: '100%',
        },
        '&.Mui-focusVisible > span': {
          outline: `2px solid ${lightBlue}`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-focusVisible > span': {
          outline: `2px solid ${lightBlue}`,
        },
      },
    },
    MuiSelect: {
      outlined: {
        fontSize: '1rem',
        textAlign: 'left',
        padding: '0px 0px 0px 20px',
        border: `2px solid ${mediumGray}`,
        borderRadius: 18,
        background: 'white',
        '&:focus': {
          border: '2px solid transparent',
          borderRadius: 18,
          background: 'white',
        },
      },
    },
    MuiDivider: {
      root: {
        margin: '1rem 0',
      },
    },
    MuiPopover: {
      paper: {
        padding: '0.5rem',
      },
    },
    MuiFormLabel: {
      root: {
        margin: '0.5rem 0',
        textTransform: 'uppercase',
        color: '#222',
        fontWeight: 'bold',
      },
    },
    MuiLink: {
      root: {
        textDecoration: 'underline',
        color: linkBlue,
      },
      underlineHover: {
        textDecoration: 'underline',
      },
    },
    MuiInputBase: {
      input: {
        lineHeight: 2,
        fontSize: '1rem',
      },
    },
    MuiInputLabel: {
      root: {
        margin: 0,
        textTransform: 'none',
        color: '#222',
        fontWeight: 'normal',
      },
      shrink: {
        background: 'white',
        paddingLeft: 2,
        marginTop: 1,
      },
    },
    MuiFormGroup: {
      root: {
        padding: '0.5rem 1rem',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderRadius: 20,
          borderColor: darkGray,
        },
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: lightBlue,
        },
      },
      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 8,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px 8px',
        textAlign: 'center',
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 1600,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: coral,
      },
    },
    MuiMenuItem: {
      root: {
        '&.Mui-focusVisible': {
          outline: `2px solid ${lightBlue}`,
          background: 'none',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        '&.Mui-focusVisible.Mui-selected': {
          background: lightGray,
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: { fontSize: iconSizes.small },
      fontSizeMedium: { fontSize: iconSizes.medium },
      fontSizeLarge: { fontSize: iconSizes.large },
    },
  },
  iconSizes,
};

export default defaultTheme;
