import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import CheckOrXIcon from 'icons/CheckOrXIcon';

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function AcceptingNewPatientsCell({ providerId }) {
  const classes = useStyles();
  const { acceptingNewPatients } = useSelector(select.provider(providerId).closestLocation) || {};

  if (typeof acceptingNewPatients !== 'boolean') return null;

  const alt = acceptingNewPatients ? 'is accepting new patients' : 'not accepting new patients';

  return (
    <div className={classes.iconContainer}>
      <CheckOrXIcon checked={acceptingNewPatients} alt={alt} />
    </div>
  );
}

AcceptingNewPatientsCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
