import { createSlice } from '@reduxjs/toolkit';

import { CARE_CATEGORIES, CARE_CATEGORY_OPTIONS } from 'utils/constants';
import { getAutocompleteSuggestionText } from 'utils/utils';
import {
  clearSearchForm,
  updateStoreFromUrl,
  startOver,
  searchErrorModalClosed,
} from 'store/appActions';
import { SEARCH_SLICE_NAME } from 'store/slices/slicesNames';
import * as chatActions from 'store/slices/chat/chatSlice';
import { setFilterRadius } from '../filters/filtersSlice';
import { searchThisArea } from '../results/resultsThunks';

const initialState = {
  type: '',
  text: '',

  // search type id's
  serviceId: null,
  serviceType: null,
  specialtyId: null,
  subspecialtyId: null,
  entityId: null,

  // Autocomplete
  isSuggestionSelected: false,

  // Expand search
  /** @deprecated This state is moved to the resultsSlice for all modern experience searches */
  expandedSearchRadiusSuggestion: null,

  isBoundingBoxSearch: false,
};

const searchSlice = createSlice({
  name: SEARCH_SLICE_NAME,
  initialState,
  reducers: {
    changeType(state, action) {
      const newSearchType = action.payload;

      const validSearchTypes = Object.values(CARE_CATEGORIES);
      if (validSearchTypes.includes(newSearchType)) {
        state.type = newSearchType;
        state.text = '';
      }
    },
    handleTextInput(state, action) {
      const { value, reason = 'input' } = action.payload;
      state.text = value;

      if (reason === 'input' || reason === 'clear') {
        state.isSuggestionSelected = false;
        state.specialtyId = null;
        state.subspecialtyId = null;
        state.entityId = null;
        state.serviceId = null;
        state.serviceType = null;
      }
    },
    suggestionSelected(state, action) {
      const { suggestion, useNewSpecialtyAutocomplete = false } = action.payload;

      state.isSuggestionSelected = true;
      state.specialtyId = suggestion.specialtyId;
      state.subspecialtyId = suggestion.subspecialtyId > -1 ? suggestion.subspecialtyId : null; // negative subspecialties suggest invalid subspecialty
      state.entityId = suggestion.entityId;
      state.serviceId = suggestion.serviceId || null;
      state.serviceType = suggestion.serviceType || null;
      state.text = getAutocompleteSuggestionText(suggestion, useNewSpecialtyAutocomplete) || '';
    },
    quickSearchSelected(state, action) {
      const { type, name } = action.payload;

      const commonSearch = CARE_CATEGORY_OPTIONS[type].commonSearches.find(
        (cs) => cs.name === name
      );

      if (!commonSearch) throw new Error(`No common search for type ${type} with name ${name}`);

      state.type = type;
      state.text = commonSearch.name;
      state.serviceId = commonSearch.serviceId || null;
      state.serviceType = commonSearch?.serviceType || null;
      state.specialtyId = commonSearch?.specialtyId || null;
      state.subspecialtyId = commonSearch?.subspecialtyId || null;

      state.isSuggestionSelected = true; // all quick searches should be related to an autocomplete suggestion
      state.isBoundingBoxSearch = false;
    },
    overwriteSlice(state, action) {
      return action.payload;
    },
    showMoreProvidersBySpecialty(state, action) {
      const { specialty, specialtyId } = action.payload;
      state.type = CARE_CATEGORIES.PROVIDER_SPECIALTY;
      state.text = specialty;
      state.entityId = null;
      state.specialtyId = specialtyId;
    },
    expandSubspecialtySearchToParentSpecialty(state, action) {
      // TECH-3302 This can be removed, the modern experience search will use breadcrumbClicked instead.
      const { text } = action.payload;
      state.text = text;
      state.subspecialtyId = null;
    },
    /** @deprecated This action is not used in searches performed by Modern Experience. */
    setExpandedRadiusSuggestion(state, action) {
      const radiusSuggestion = action.payload;
      state.expandedSearchRadiusSuggestion = radiusSuggestion;
    },
    setIsBoundingBoxSearch(state, action) {
      const { payload = false } = action;
      state.isBoundingBoxSearch = Boolean(payload);
    },
    breadcrumbClicked(state, action) {
      const { specialtyName, specialtyId } = action.payload;
      if (!specialtyName) throw new Error('Missing specialty name');
      if (!specialtyId) throw new Error('Missing specialty id');

      state.text = specialtyName;
      state.specialtyId = specialtyId;

      state.subspecialtyId = null;
      state.serviceId = null;
      state.serviceType = null;
      state.entityId = null;

      state.isSuggestionSelected = true;
    },
    setEntityId(state, action) {
      state.entityId = action.payload;
    },
    setSingleProviderSearch(state, action) {
      state.type = CARE_CATEGORIES.PROVIDER_NAME;
      state.entityId = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(clearSearchForm, () => initialState);
    builder.addCase(startOver, () => initialState);

    builder.addCase(setFilterRadius, (state) => {
      state.isBoundingBoxSearch = false;
    });

    // chat
    builder.addCase(chatActions.specialtySearchInPg, (state, action) => {
      // specialty search performed by chat
      const { specialtyId, specialtyName, subspecialtyId, subspecialtyName } = action.payload;

      state.subspecialtyId = subspecialtyId || null;
      state.specialtyId = specialtyId;
      state.text = subspecialtyName || specialtyName;
      state.type = CARE_CATEGORIES.PROVIDER_SPECIALTY;

      state.isBoundingBoxSearch = false;
    });

    builder.addCase(searchErrorModalClosed, (state) => {
      state.isBoundingBoxSearch = false;
    });

    builder.addCase(searchThisArea.pending, (state) => {
      state.isBoundingBoxSearch = true;
    });

    // url params
    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const {
        care_category,
        search_input,
        specialtyId,
        subspecialtyId,
        serviceId,
        serviceType,
        entity_id,
        bounding_box,
        isPlaceByExactNameSearch,
      } = action.payload;

      state.type = care_category || '';
      state.text = search_input || '';
      state.specialtyId = specialtyId || null;
      state.subspecialtyId = subspecialtyId || null;
      state.serviceId = serviceId || null;
      state.serviceType = serviceType || null;
      state.entityId = entity_id || null;

      if (specialtyId || subspecialtyId || serviceId || isPlaceByExactNameSearch) {
        // if we receive an autocomplete id from the params, we can assume that this was from an autocomplete suggestion
        state.isSuggestionSelected = true;
      }

      if (entity_id) {
        // handle url params for single provider searches - if we receive an entity_id it would be from a single provider search
        state.isSuggestionSelected = true;
      }

      if (bounding_box) {
        state.isBoundingBoxSearch = true;
      }
    });
  },
});

export default searchSlice;
export const {
  changeType,
  handleTextInput,
  suggestionSelected,
  quickSearchSelected,
  overwriteSlice,
  /** @deprecated  This action can be removed after Modern Experience is live */
  setExpandedRadiusSuggestion,
  showMoreProvidersBySpecialty,
  setIsBoundingBoxSearch,
  breadcrumbClicked,
  setEntityId,
  setSingleProviderSearch,
} = searchSlice.actions;
