/* eslint-disable no-param-reassign, no-use-before-define */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStateValue } from 'state';
import * as Sentry from '@sentry/react';
import { sample } from 'lodash';
import { useQueryParams } from 'use-query-params';

import encodeQueryString from 'hooks/queryString/encodeQueryString';
import { SPECIALISTS_TAB } from 'utils/constants';
import fetchClient from 'utils/fetchClient';
import { useConfigValue } from 'config/state';
import { promotionsActions } from 'store/index';
import { select } from 'store/toolkit';
import {
  getClosestPromotionsWithinRadius,
  specialtyMatch,
  sortFilterByWeight,
  addDistanceValuesAndSort,
} from 'store/slices/promotions/promoteUtils';

const usePromote = () => {
  const dispatch = useDispatch();
  const allPromotionsArray = useSelector(select.promotions.promotions) || []; // This || [] is needed for the App.test.jsx test to pass
  const isFetchingPromotions = useSelector(select.promotions.isLoading);
  const filteredPromotions = useSelector(select.promotions.filteredByRadius);

  const [
    { networkSlug, results, latLong, freeformResults, activeTab, filterCounts, service },
    setState,
  ] = useStateValue();
  const {
    config: { EMBOLD_CLIENT_SLUG },
  } = useConfigValue();
  const { latitude, longitude } = latLong;
  const [{ specialtyId: specialtyIdUrl, subspecialtyId: subspecialtyIdUrl }] =
    useQueryParams(encodeQueryString);

  const specialtyIdState = useSelector(select.results.specialtyId);
  const subspecialtyIdState = useSelector(select.results.subspecialtyId);
  let specialtyId = specialtyIdState || specialtyIdUrl;
  const subspecialtyId = subspecialtyIdState || subspecialtyIdUrl;

  /* ********************* */
  // fetch promotions on load or whenever networkSlug is changed
  /* ********************* */
  useEffect(() => {
    const fetchPromotions = () => {
      const url = `/promotions/?client_slug=${EMBOLD_CLIENT_SLUG}&network_slug=${networkSlug}`;
      dispatch(promotionsActions.fetchPromotionsPending());
      fetchClient(url)
        .then(({ data }) => {
          dispatch(promotionsActions.fetchPromotionsComplete(data));
        })
        .catch((error) => {
          Sentry.captureException(error);
          dispatch(
            promotionsActions.fetchPromotionsFailed(error.message || 'Something went wrong')
          );
        });
    };

    if (networkSlug) {
      fetchPromotions();
    }
  }, [networkSlug, dispatch, EMBOLD_CLIENT_SLUG]);

  /* ********************* */
  // recalculate distances when latlong is changed
  /* ********************* */
  useEffect(() => {
    if (!isFetchingPromotions) {
      const updatedDistances = addDistanceValuesAndSort(allPromotionsArray, latitude, longitude);
      dispatch(promotionsActions.updatePromotions(updatedDistances));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [latitude, longitude, isFetchingPromotions, dispatch]);

  /* ********************* */
  // select most apt promotion based on search
  /* ********************* */
  const selectPromotion = (promotions) => {
    // return null to show no promote
    if (promotions.length === 0) {
      return null;
    }

    // Set sepcialtyId if we are doing a service search and there is a promotion available
    if (!specialtyId && service?.serviceId && filterCounts.specialtyCounts?.length > 0) {
      const serviceSpecialties = filterCounts.specialtyCounts;
      const promotionSpecialtyIds = promotions.flatMap((promotion) =>
        promotion.specialties.map((specialty) => specialty.specialtyId)
      );

      specialtyId = serviceSpecialties?.find((specialty) =>
        promotionSpecialtyIds.includes(specialty.specialtyId)
      )?.specialtyId;
    }

    // 1 & 2. subspecialty and specialty match
    const specialtyToMatch = specialtyId || freeformResults.specialtyId;
    const specialtyMatchPromotions = specialtyMatch(promotions, specialtyToMatch, subspecialtyId);
    if (specialtyMatchPromotions.length === 0) {
      return null;
    }
    if (specialtyMatchPromotions.length === 1) {
      return specialtyMatchPromotions[0];
    }

    // 3. primary override
    const primaryOverride = specialtyMatchPromotions.find((promotion) => promotion.isPrimary);
    if (primaryOverride) {
      return primaryOverride;
    }

    // 4. radius and distance
    const sortedByDistance = getClosestPromotionsWithinRadius(specialtyMatchPromotions);
    if (sortedByDistance.length === 1) {
      return sortedByDistance[0];
    }

    // 5. weight
    const highestWeightedPromotions = sortFilterByWeight(sortedByDistance);
    if (highestWeightedPromotions.length === 1) {
      return highestWeightedPromotions[0];
    }

    // multiple promotions are equally close
    // 6. randomly choose one
    return sample(highestWeightedPromotions);
  };

  const selectAndSetPromotion = (promotions) => {
    setState({
      promotion: selectPromotion(promotions),
    });
  };
  /* ********************* */
  // set promotion when any search occurs
  /* ********************* */

  const stringifiedFirstResult = JSON.stringify(results[0]);
  useEffect(() => {
    const isFreeform = Boolean(activeTab);
    const isFreeformSpecialistsTab =
      isFreeform && activeTab === SPECIALISTS_TAB && freeformResults.specialtyId;
    const isStandardSearch = !isFreeform && results.length > 0;

    if (isFreeformSpecialistsTab || isStandardSearch) {
      selectAndSetPromotion(filteredPromotions);
    } else {
      selectAndSetPromotion([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFirstResult, activeTab, freeformResults.specialtyId, service?.serviceId]);
};

export default usePromote;
