import React, { useRef, useEffect } from 'react';
import PropTypes from 'propTypes';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import { select } from 'store/toolkit';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import RoundedWrapper from '../../ResultCards/StyledWrappers/RoundedWrapper';
import PlaceProfileHeaderButtons from './PlaceProfileHeaderButtons';
import PlaceContactCard from '../../ResultCards/PlaceCard/PlaceContactCard';
import PlaceBadges from './PlaceBadges';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 754,
    paddingBottom: 0,
  },
  padBottom: {
    marginBottom: 15,
  },
  printWrapper: {
    display: 'none',
  },
  basicInfoGridCell: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
  },
  title: {
    margin: 0,
  },
}));

function PlaceProfile({ placeId }) {
  const classes = useStyles();
  const printRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (placeId) {
      dispatch(_analyticsActions.profileView(placeId));
    }
  }, []);

  if (!placeId) return null;

  return (
    <RoundedWrapper additionalStyles={classes.wrapper}>
      <PlaceProfileHeaderButtons placeId={placeId} printRef={printRef} />
      <ProfileContent placeId={placeId} />
      <div className={classes.printWrapper}>
        <ProfileContent ref={printRef} isPrintMode placeId={placeId} />
      </div>
    </RoundedWrapper>
  );
}

const ProfileContent = React.forwardRef(({ isPrintMode, placeId }, ref) => {
  const classes = useStyles();
  const { name } = useSelector(select.place(placeId).data);

  return (
    <Grid container direction="column" wrap="nowrap" ref={ref}>
      <Grid item>
        <Grid container classes={{ root: classes.padBottom }}>
          <Grid item classes={{ root: classes.basicInfoGridCell }}>
            <Typography variant="h2" classes={{ root: classes.title }}>
              {name}
            </Typography>
            <PlaceBadges placeId={placeId} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PlaceContactCard
          isPrintMode={isPrintMode}
          placeId={placeId}
          className={classes.padBottom}
          showMap
          isProfile
        />
      </Grid>
    </Grid>
  );
});

export default PlaceProfile;

PlaceProfile.propTypes = {
  placeId: PropTypes.string.isRequired,
};

ProfileContent.propTypes = {
  placeId: PropTypes.string.isRequired,
  isPrintMode: PropTypes.bool,
};

ProfileContent.defaultProps = {
  isPrintMode: false,
};
