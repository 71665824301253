import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { actions, select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';
import CollapsingProfileList from 'components/ModernExperience/Profile/CollapsingProfileList';
import CompareModalActions from 'components/ModernExperience/CompareTable/CompareModalActions';
import HealthPlanDisclaimer from 'components/HealthPlanDisclaimer';
import Modal from 'components/Modals/Modal';
import CompareTable from './CompareTable';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: '0px auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 850,
    },
  },
  modalFooter: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': { margin: theme.spacing(0.5) },
  },
  disclaimerLink: {
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(1),
  },
}));

export default function CompareModal() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();
  const compareIds = useSelector(select.results.compareListIds);
  const title = useSelector(select.results.compareTitle);
  const isMdDown = useSelector(select.ui.isMdDown);

  const closeModal = useCallback(
    () => history.replace({ ...history.location, pathname: '/results' }),
    [history]
  );
  const clearCompareList = useCallback(() => {
    closeModal();
    dispatch(actions.results.clearCompareList());
  }, [dispatch, closeModal]);

  useEffect(() => {
    dispatch(_analyticsActions.compareView());
    for (const resultId of compareIds) {
      dispatch(_analyticsActions.compareProfileView(resultId));
    }
    // intentionally omitting the compare id's from dependency array because we don't want to send analytics when an id is removed, only when the modal is first rendered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const compare = isMdDown ? (
    <CollapsingProfileList resultIds={compareIds} />
  ) : (
    <CompareTable
      resultIds={compareIds}
      showActionButtons
      showRemoveButtons={compareIds.length > 2}
    />
  );

  return (
    <Modal
      open
      maxWidth="full"
      fullScreen={isMdDown}
      handleClose={closeModal}
      ariaId="compare-results"
      title={title}
    >
      <div className={classes.root}>
        <CompareModalActions showPrintButton={!isMdDown} compareIds={compareIds} title={title} />
        {compare}

        <HealthPlanDisclaimer className={classes.disclaimerLink} />
        <div className={classes.modalFooter}>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Back
          </Button>
          <Button variant="outlined" color="primary" onClick={clearCompareList}>
            Clear Selections
          </Button>
        </div>
      </div>
    </Modal>
  );
}

CompareModal.propTypes = {};

CompareModal.defaultProps = {};
