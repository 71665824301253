import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));

export default function StrengthCell({ providerId, strengthKey }) {
  const classes = useStyles();
  const strengthObj = useSelector(select.provider(providerId)[strengthKey]);

  if (!strengthObj) return <NoDataIcon />;

  return <span className={classes.root}>{strengthObj.adjective}</span>;
}

StrengthCell.propTypes = {
  providerId: PropTypes.string.isRequired,
  strengthKey: PropTypes.oneOf(['diagnosingStrength', 'treatmentStrength', 'outcomesStrength'])
    .isRequired,
};
