import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';

function NoWebGLNotice() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <div>
        <WarningIcon style={{ height: '2rem', width: '2rem' }} />
        <Typography variant="body1" paragraph>
          It looks like WebGL is not supported by your browser. WebGL support is required to display
          maps in this app.
        </Typography>
      </div>
    </div>
  );
}

export default NoWebGLNotice;
