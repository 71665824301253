import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { setLocalStorageExpire } from 'utils/utils';
import { MEMBER_CREDENTIALS, LOGIN_NETWORK_NAME_VERSION } from 'utils/constants';
import getAxiosInstanceFromState from 'fetch/getAxiosInstanceFromState';
import select from './select';

// eventually there will be more thunks so stick to named exports
/* eslint-disable import/prefer-default-export */
export const userLoggedIn = createAsyncThunk('userLoggedIn', async (args, thunkApi) => {
  const { setState, ...formValues } = args;
  const { networkSlug } = formValues;
  const { getState } = thunkApi;

  const state = getState();
  const axios = getAxiosInstanceFromState(state);

  // selectors
  const clientSlug = select.client.slug(state);
  const formVariant = select.client.loginFormVariant(state);
  const defaultLocation = select.networks.defaultLocationBySlug(networkSlug)(state);
  const loginNetworkName = select.client.loginNetworkName(state);
  const networkSelectApiPath = select.config.networkSelectApiPath(state);

  // send analytics
  try {
    const networkSelectPayload = {
      client_slug: clientSlug,
      network_slug: networkSlug,
    };

    if (formVariant === MEMBER_CREDENTIALS) {
      networkSelectPayload.member_id = `${networkSlug.toUpperCase()}-${formValues.id}-${
        formValues.suffix
      }`;
    }

    axios.post(`/${networkSelectApiPath}`, networkSelectPayload);
  } catch (e) {
    Sentry.captureException(e);
  }

  // set local storage network
  let expirationInDays = 90; // all clients require login every 90 days
  if (select.client.slug(state) === 'acme') {
    // on demo client, override so that login is required every 30 minutes
    const halfHourInDays = 1 / 24 / 2; // 1 day, divided by 24 hours, divided by 2 to get a half hour
    expirationInDays = halfHourInDays;
  }
  setLocalStorageExpire(
    loginNetworkName,
    networkSlug,
    expirationInDays,
    LOGIN_NETWORK_NAME_VERSION
  );
  // set context values
  setState({
    showLogin: false,
    networkSlug,
  });

  // return values to reducer
  const payload = {
    ...formValues,
    defaultLocation,
  };
  return payload;
});
