/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, SOCT } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 7aa95f2dd343a8084d6d3203f143d83bbca28149',
  staging: 'Token 321527ba2c48504d40befa570180680db1e18566',
  prod: 'Token 50522284abbed9c05b2a2f676aa3953883c35b41',
};

const token = selectToken(apiTokens);

export const soctConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: SOCT,
  EMBOLD_CLIENT: SOCT,
  EMBOLD_CLIENT_SLUG: SOCT,
  THEME: standardTheme,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX:
    /^(standardaccess|primarycareaccess|expandedaccess|qualityfirstselectaccess|preferredaccessoutofarea)$/,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Hartford,
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'soctUserId',
};
