import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));

export default function CostCell({ providerId }) {
  const classes = useStyles();
  const costText = useSelector(select.provider(providerId).costText);

  if (!costText) return <NoDataIcon />;

  return <span className={classes.root}>{costText}</span>;
}

CostCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
