import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'propTypes';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core/';

import { select } from 'store/toolkit';

import ProfileBadge from '../ProfileBadge';

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
    '& > *': { flex: 1 },
  },
}));

function ProviderBadges({ providerId }) {
  const classes = useStyles();
  const badgeList = useSelector(select.provider(providerId).badgeList);
  return (
    <Grid container classes={classes}>
      {badgeList.map((badge) => (
        <ProfileBadge {...badge} key={`badge-${badge.label}-${providerId}`} />
      ))}
    </Grid>
  );
}

export default ProviderBadges;

ProviderBadges.propTypes = {
  providerId: PropTypes.string.isRequired,
};
