import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: 0,
    width: '100%',
    border: `2px solid ${theme.palette.lightBlue}`,
    borderRadius: 0,
    transform: 'translate(-1000px,-1000px)',
    zIndex: theme.zIndex.tooltip,
    '&:focus': {
      transform: 'none',
      '&:before': {
        display: 'none',
      },
    },
  },
}));

function SkipLink({ label, handleClick, additionalClasses, ...props }) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={clsx(classes.button, {
        [additionalClasses]: Boolean(additionalClasses),
      })}
      onClick={handleClick}
      {...props}
    >
      {label}
    </Button>
  );
}

SkipLink.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  additionalClasses: PropTypes.string,
};

SkipLink.defaultProps = {
  additionalClasses: undefined,
  handleClick: undefined,
};

export default React.forwardRef((props, ref) => <SkipLink innerRef={ref} {...props} />);
