import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { AzureMapPopup } from 'react-azure-maps';

import { useStateValue } from 'state/index';
import { useConfigValue } from 'config/state/index';
import useLocationPhoneNumberOverride from 'hooks/useLocationPhoneNumberOverride';
import { SHOW_PHONE_NUMBER } from 'utils/constants';
import { buildLocationUrl, distance } from 'utils/utils';
import { select } from 'store/toolkit';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: '0',
    paddingBottom: 15,
    textAlign: 'center',
    color: theme.palette.linkBlue,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function LocationPopup({ selectedProviderLocation }) {
  const classes = useStyles();
  const [{ networkSlug, clusterTabs }] = useStateValue();
  const {
    getNetworkConfigValue,
    config: {
      CONTENT: { MAPS_URL },
    },
  } = useConfigValue();
  const latLong = useSelector(select.location.latLong);
  const showPhoneNumber = getNetworkConfigValue(networkSlug, SHOW_PHONE_NUMBER) ?? true;
  const {
    longitude,
    latitude,
    address1,
    address2,
    city,
    state,
    phone,
    zipcode,
    clientFeaturedLocation,
  } = selectedProviderLocation;

  // TODO: Remove when handle this in the redux provider fetch
  const phoneNumber = useLocationPhoneNumberOverride(phone, clientFeaturedLocation);

  if (isEmpty(selectedProviderLocation)) return null;

  const distanceFromLatLong = distance(latLong.latitude, latLong.longitude, latitude, longitude);

  const roundedDistance = parseFloat(distanceFromLatLong)?.toFixed(2);

  return (
    <AzureMapPopup
      isVisible
      options={{
        position: [longitude, latitude],
        closeButton: false,
        pixelOffset: [0, clusterTabs?.length > 0 ? -14 : -32],
      }}
      popupContent={
        <div style={{ padding: '14px' }}>
          <a
            className={classes.link}
            href={buildLocationUrl(selectedProviderLocation, MAPS_URL)}
            target="_blank"
            rel="noreferrer"
            aria-label={`Location address: ${address1} ${city} ${state} ${zipcode}. ${
              distanceFromLatLong && `${roundedDistance} miles away`
            }. opens in new tab.`}
          >
            {/* {name && <br aria-hidden="true" />} */}
            {address1}
            {address2 && <br aria-hidden="true" />}
            {address2}
            <br aria-hidden="true" />
            {city}, {state} {zipcode}
            <br aria-hidden="true" />
          </a>
          {distanceFromLatLong && (
            <span className="profile-address-distance" style={{ display: 'block' }}>
              {roundedDistance} miles away
            </span>
          )}
          {showPhoneNumber && phoneNumber && (
            <>
              <br />
              <a className={classes.link} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            </>
          )}
        </div>
      }
    />
  );
}

export default LocationPopup;

LocationPopup.propTypes = {
  selectedProviderLocation: PropTypes.shape({
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    phone: PropTypes.string,
    zipcode: PropTypes.string,
    clientFeaturedLocation: PropTypes.bool,
  }).isRequired,
};
