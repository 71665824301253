import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import ShowMoreButton from 'components/ShowMoreButton';
import defaultImage from 'assets/default-promotion-icon.svg'; // TODO TECH-3847 replace with custom icon

const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
  },
  headerEndItems: {
    // featured benefit and show more
    display: 'flex',
    alignItems: 'center',
    fontSize: 6,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  showMoreButton: {
    marginLeft: 'auto',
    color: theme.palette.grey[700],
    fontSize: 14,
  },
  logo: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
  },
  inlineTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  stackedTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
  },
}));

export default function FeaturedBenefitHeader({
  logo,
  title,
  subtitle,
  handleShowMore,
  isExpanded,
  headingLevel,
  isMobile,
  noCollapse,
  benefitDetailsId,
}) {
  const classes = useStyles();

  const Logo = <img src={logo} alt="" className={classes.logo} />;
  const Title = (
    <Typography component={headingLevel} variant="h4" className={classes.title}>
      {title}
    </Typography>
  );
  const Subtitle = <Typography>{subtitle}</Typography>;

  const InlineTitle = (
    <Grid item classes={{ root: classes.inlineTitleContainer }}>
      {Logo} {Title} {Subtitle}
    </Grid>
  );

  const StackedTitle = (
    <Grid item xs={12} classes={{ root: classes.stackedTitleContainer }}>
      {Logo}
      <div>
        {Title}
        {Subtitle}
      </div>
    </Grid>
  );

  return (
    <Grid container classes={{ root: classes.header }}>
      <Grid item classes={{ root: classes.headerEndItems }}>
        <GradeRoundedIcon />
        <Typography component="span">Featured Benefit</Typography>
      </Grid>

      {!isMobile && InlineTitle}
      {!noCollapse && (
        <Grid item classes={{ root: classes.headerEndItems }}>
          <ShowMoreButton
            isOpen={isExpanded}
            onClick={handleShowMore}
            additionalClass={classes.showMoreButton}
            data-testid="featured-benefit-show-more"
            ariaControls={benefitDetailsId}
            ariaLabel={title}
          />
        </Grid>
      )}

      {isMobile && StackedTitle}
    </Grid>
  );
}

FeaturedBenefitHeader.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  handleShowMore: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  noCollapse: PropTypes.bool,
  benefitDetailsId: PropTypes.string.isRequired,
};

FeaturedBenefitHeader.defaultProps = {
  headingLevel: 'h3',
  isMobile: false,
  logo: defaultImage,
  noCollapse: false,
};
