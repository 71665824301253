import { createSelector } from '@reduxjs/toolkit';

import { SERVICES_SLICE_NAME } from '../slicesNames';
import { serviceId as selectResultsServiceId } from '../results/selectResults';

export const isLoading = (state) => state[SERVICES_SLICE_NAME].isLoading;
export const error = (state) => state[SERVICES_SLICE_NAME].error;
export const all = (state) => state[SERVICES_SLICE_NAME].services;

/** Returns the service with the matching id */
export function byId(id) {
  return (state) => state[SERVICES_SLICE_NAME].services[id];
}

/** Returns the service matching the service id stored in the results slice */
export const current = createSelector(
  [selectResultsServiceId, all],
  (currentServiceId, allServices) => allServices[currentServiceId]
);
