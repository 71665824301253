import React, { useMemo } from 'react';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { select } from 'store/toolkit';

import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import lgbtqCompetentIcon from 'assets/lgbtq-competent.svg'; // TODO TECH-3847 replace with custom icon
import genderIconMale from 'assets/male-icon.svg'; // TODO TECH-3847 replace with custom icon
import genderIconFemale from 'assets/female-icon.svg'; // TODO TECH-3847 replace with custom icon
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CheckOrXIcon from 'icons/CheckOrXIcon';
import ErrorIcon from '@material-ui/icons/Error';

import { DARK_GREEN, LIGHT_BLUE, APPLE_RED } from 'models/Result/ResultModel';
import Attribute from '../../Attribute';

const useStyles = makeStyles((theme) => ({
  [DARK_GREEN]: {
    color: theme.palette.darkGreen,
  },
  [LIGHT_BLUE]: {
    color: theme.palette.lightBlue,
  },
  [APPLE_RED]: {
    color: theme.palette.appleRed,
  },
  warningOrange: {
    color: theme.palette.warningOrange,
  },
}));

export function getProviderAttributeList({
  showAcceptingNewPatients,
  showNearbyLocations,
  networkData = {},
  providerData = {},
  numberOfLocationsAcceptingNewPatients = 0,
  hasTelehealthAvailable = false,
  classes = '',
  showBenefitDecrease = false,
  benefitsChangeData = {},
}) {
  const list = [];

  // 1. network designation (optional)
  if (networkData && networkData.showNetworkStatus) {
    list.push({
      key: 'attribute-network',
      icon: networkData.networkIcon,
      text: networkData.networkText,
      additionalIconClass: classes[networkData.textColor], // match the network icon color to text color
      TooltipProps: { message: networkData.tooltipMessage },
      TypographyProps: {
        style: {
          fontWeight: 'bold',
        },
        className: classes[networkData.textColor],
      },
    });
  }

  // 2. benefit decrease
  if (showBenefitDecrease) {
    const { cardAttribute } = benefitsChangeData;
    list.push({
      key: 'attribute-benefits',
      text: cardAttribute.text,
      TooltipProps: { message: cardAttribute.tooltip },
      iconAltText: `${cardAttribute.text} icon`,
      icon: <ErrorIcon className={classes.warningOrange} />,
    });
  }

  // 3. gender
  list.push({
    key: 'attribute-gender',
    text: providerData.gender,
    icon: providerData.gender === 'Female' ? genderIconFemale : genderIconMale,
    iconAltText: `${providerData.gender} Provider icon`,
  });

  // 4. Languages Spoken
  if (providerData.languagesArray?.length > 0) {
    list.push({
      key: 'attribute-language',
      text: providerData.languagesArray.join(', '),
      icon: <RecordVoiceOverOutlinedIcon />,
      iconAltText: 'Languages Spoken Icon',
    });
  }

  // 5. LGBTQ+ (optional)
  if (providerData.outcareCompetent) {
    list.push({
      key: 'attribute-outcare',
      text: 'LGBTQ+ Competent',
      icon: lgbtqCompetentIcon,
      iconAltText: 'LGBTQ+ Competent Icon',
    });
  }

  // 6. telehealth
  if (hasTelehealthAvailable) {
    list.push({
      key: 'attribute-telehealth',
      text: 'Telehealth',
      icon: <CheckOrXIcon checked />,
    });
  }

  // 7. accepting new
  if (showAcceptingNewPatients) {
    list.push(
      numberOfLocationsAcceptingNewPatients > 0
        ? {
            key: 'attribute-accepting-patients',
            text: 'Accepting patients',
            icon: <CheckOrXIcon checked />,
          }
        : {
            key: 'attribute-accepting-patients',
            text: 'Not accepting patients',
            icon: <CheckOrXIcon checked={false} />,
          }
    );
  }

  // 8. nearby locations
  if (showNearbyLocations) {
    if (providerData.places?.length > 1) {
      list.push({
        key: 'attribute-locations',
        text: `${providerData.places.length} nearby locations`,
        icon: <RoomOutlinedIcon />,
      });
    }
  }

  return list;
}

function ProviderAttributeList({
  showAcceptingNewPatients,
  showNearbyLocations,
  providerId,
  ...props
}) {
  const classes = useStyles();
  const providerData = useSelector(select.provider(providerId).data);
  const showBenefitDecrease = useSelector(select.provider(providerId).showBenefitDecrease);
  const benefitsChange = useSelector(select.content.benefitsChange);
  const numberOfLocationsAcceptingNewPatients = useSelector(
    select.provider(providerId).numberOfLocationsAcceptingNewPatients
  );
  const hasTelehealthAvailable = useSelector(select.provider(providerId).hasTelehealthAvailable);
  const network = useSelector(select.provider(providerId).networkContent);
  const attributeList = useMemo(
    () =>
      getProviderAttributeList({
        showAcceptingNewPatients,
        showNearbyLocations,
        networkData: network,
        providerData,
        numberOfLocationsAcceptingNewPatients,
        hasTelehealthAvailable,
        classes,
        showBenefitDecrease,
        benefitsChangeData: benefitsChange,
      }),
    [
      network,
      providerData,
      numberOfLocationsAcceptingNewPatients,
      hasTelehealthAvailable,
      classes,
      showBenefitDecrease,
      benefitsChange,
      showAcceptingNewPatients,
      showNearbyLocations,
    ]
  );

  if (!attributeList.length) return null;

  return (
    <List disablePadding {...props}>
      {attributeList.map(({ key, ...attribute }) => (
        <Attribute key={`${key}-${providerId}`} {...attribute} />
      ))}
    </List>
  );
}

ProviderAttributeList.propTypes = {
  providerId: PropTypes.string.isRequired,
  showAcceptingNewPatients: PropTypes.bool,
  showNearbyLocations: PropTypes.bool,
};

ProviderAttributeList.defaultProps = {
  showAcceptingNewPatients: true,
  showNearbyLocations: true,
};

export default React.memo(ProviderAttributeList);
