import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useTableValue from 'components/ModernExperience/CompareTable/useTableValue';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    borderBottom: 'none',
  },
}));

export default function SubheaderRow({ children, additionalClass }) {
  const classes = useStyles();
  const { columns } = useTableValue();

  return (
    <TableRow>
      <TableCell colSpan={columns} className={`${classes.root} ${additionalClass}`}>
        {children}
      </TableCell>
    </TableRow>
  );
}

SubheaderRow.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClass: PropTypes.string,
};

SubheaderRow.defaultProps = {
  additionalClass: '',
};
