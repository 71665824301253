/* eslint-disable no-restricted-globals */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'propTypes';

import { select } from 'store/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import ContactCard from 'components/ModernExperience/ContactCard';

function ProviderContactCard({ providerId, className, showMap, isPrintMode, isProfilePage }) {
  const dispatch = useDispatch();
  const sortedProviderLocations = useSelector(select.provider(providerId).sortedProviderLocations);

  const contactTitle = useMemo(() => {
    if (isProfilePage) {
      return sortedProviderLocations.length > 1 ? 'Locations' : 'Location';
    }
    return sortedProviderLocations.length > 1 ? 'Nearby Locations' : 'Nearby Location';
  }, [isProfilePage, sortedProviderLocations.length]);

  const onAddressClick = useCallback(() => {
    if (isProfilePage) {
      dispatch(_analyticsActions.directionsOpenedFromProfile(providerId));
    } else {
      dispatch(_analyticsActions.directionsOpenedFromResultCard(providerId));
    }
  }, [dispatch, isProfilePage, providerId]);

  const onPhoneClick = useCallback(() => {
    if (isProfilePage) {
      dispatch(_analyticsActions.phoneClickFromProfile(providerId));
    } else {
      dispatch(_analyticsActions.phoneClickFromResultCard(providerId));
    }
  }, [dispatch, isProfilePage, providerId]);

  return (
    <ContactCard
      title={contactTitle}
      locations={sortedProviderLocations}
      className={className}
      showMap={showMap}
      showDisclaimer
      onAddressClick={onAddressClick}
      onPhoneClick={onPhoneClick}
      headingLevel="h4"
      isPrintMode={isPrintMode}
    />
  );
}

ProviderContactCard.propTypes = {
  providerId: PropTypes.string.isRequired,
  className: PropTypes.string,
  showMap: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  isProfilePage: PropTypes.bool,
};

ProviderContactCard.defaultProps = {
  className: undefined,
  showMap: false,
  isPrintMode: false,
  isProfilePage: false,
};

export default React.memo(ProviderContactCard);
