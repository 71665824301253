import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Menu, MenuItem } from '@material-ui/core';
import SwapVertSharpIcon from '@mui/icons-material/SwapVertSharp';

import { uniqueId } from 'lodash';

import useFocusAnchors, { useFocusAnchorKeys } from 'utils/FocusRefContext';

import {
  PLACE_RESULT_TYPE,
  PROVIDER_RESULT_TYPE,
  SORT_OPTIONS,
  SORT_OPTIONS_WITH_COST,
  PLACE_BEST_MATCH_SORT,
} from 'utils/constants';
import { useConfigValue } from 'config/state';
import { actions, select, thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: '600',
    lineHeight: 1.2,
    textAlign: 'center',
    border: `2px solid ${theme.palette.mediumGray}`,
    color: theme.palette.black,
    borderRadius: 25,
    background: 'white',
    fontSize: 13,
    padding: '4px 10px',
    margin: '0 15px',
    minWidth: '130px',
    '& svg': {
      paddingLeft: 3,
      color: theme.palette.black,
    },
    '&.Mui-disabled:disabled': {
      background: theme.palette.lightGray,
      color: theme.palette.darkGray,
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    display: 'flex',
    justifyContent: 'center',
  },
  menuItem: {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const SORT_BUTTON_ID = 'sort-button';
const SORT_MENU_ID = 'sort-menu';

function SortMenu() {
  const classes = useStyles();

  const {
    config: { BEST_MATCH_SORT, SHOW_COST, FEATURED_FACILITY_BANNER_TEXT },
  } = useConfigValue();
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();
  const providerSort = useSelector(select.filters.providerSort);
  const placeSort = useSelector(select.filters.placeSort);
  const resultCount = useSelector(select.results.count);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);
  const isLoading = useSelector(select.results.isLoading);

  const focusAnchorKeys = useFocusAnchorKeys();
  const focusAnchors = useFocusAnchors();

  const [isOpen, setIsOpen] = useState(false);

  const sortValue = wasPlaceSearch ? placeSort : providerSort;

  const disabled = useMemo(() => resultCount <= 1 || isLoading, [resultCount, isLoading]);

  const availableOptions = useMemo(() => {
    // copy object and apply client-specific best match value
    const assignBestMatchValue = (providerSortOptions) => ({
      ...providerSortOptions,
      'Best Match': {
        ...providerSortOptions['Best Match'],
        value: BEST_MATCH_SORT,
      },
    });

    const placeOptions = FEATURED_FACILITY_BANNER_TEXT
      ? { PLACE_BEST_MATCH_SORT, ...SORT_OPTIONS[PLACE_RESULT_TYPE] }
      : SORT_OPTIONS[PLACE_RESULT_TYPE];

    const providerOptions = SHOW_COST
      ? assignBestMatchValue(SORT_OPTIONS_WITH_COST[PROVIDER_RESULT_TYPE])
      : assignBestMatchValue(SORT_OPTIONS[PROVIDER_RESULT_TYPE]);

    if (wasPlaceSearch) {
      return Object.values(placeOptions);
    }
    return Object.values(providerOptions);
  }, [FEATURED_FACILITY_BANNER_TEXT, SHOW_COST, BEST_MATCH_SORT, wasPlaceSearch]);

  const currentLabel = useMemo(
    () => availableOptions.find((sort) => sort.value === sortValue)?.label,
    [availableOptions, sortValue]
  );

  const handleButtonClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  useEffect(() => {
    if (!resultCount) {
      handleClose();
    }
  }, [resultCount, handleClose]);

  const apiSort = useCallback(
    (value) => {
      dispatch(thunks.search.updateSearchFromResults());

      if (wasPlaceSearch) {
        dispatch(actions.filters.setPlaceSort(value));
      } else {
        dispatch(actions.filters.setProviderSort(value));
      }
      searchDispatch(thunks.results.executeSearch());
    },
    [searchDispatch, dispatch, wasPlaceSearch]
  );

  const handleChangeSort = useCallback(
    (value) => {
      dispatch(actions.ui.setReturnFocusToKey(focusAnchorKeys.sortButton));
      apiSort(value);
      handleClose();
    },
    [dispatch, handleClose, apiSort, focusAnchorKeys.sortButton]
  );

  useEffect(() => {
    if (!resultCount && isOpen) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCount]);

  return (
    <>
      <Button
        onClick={handleButtonClick}
        className={`${classes.button} filters-button-sort`}
        aria-label={`Sort results. Current sort order ${currentLabel}`}
        aria-haspopup="true"
        aria-controls={SORT_MENU_ID}
        aria-expanded={isOpen}
        id={SORT_BUTTON_ID}
        disabled={disabled}
        ref={focusAnchors.sortButton}
      >
        <SwapVertSharpIcon />
        Sort{Boolean(currentLabel) && `: ${currentLabel}`}
      </Button>

      <Menu
        id={SORT_MENU_ID}
        aria-labelledby={SORT_BUTTON_ID}
        anchorEl={focusAnchors.sortButton?.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
      >
        {availableOptions.map((opt) => (
          <MenuItem
            className={classes.menuItem}
            key={uniqueId(opt.name)}
            label={opt.label}
            selected={opt.value === sortValue}
            onClick={() => handleChangeSort(opt.value)}
            aria-label={opt.label.replaceAll('-', ' to ')} // improve screen reader to read A-Z as "A to Z" rather than "AZ"
          >
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default SortMenu;
