import { createTheme } from '@material-ui/core/styles';
import defaultTheme from 'themes/defaultTheme';

const overrides = {};

const typography = {};

const palette = {};

export default createTheme({
  props: Object.assign(defaultTheme.props, typography),
  typography: Object.assign(defaultTheme.typography, typography),
  overrides: Object.assign(defaultTheme.overrides, overrides),
  palette: Object.assign(defaultTheme.palette, palette),
  breakpoints: defaultTheme.breakpoints,
  iconSizes: defaultTheme.iconSizes,
});
