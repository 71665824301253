export const ASSISTANT = 'assistant';
export const USER = 'user';
export const CHAT_MAX_MESSAGES = 32;

export const RESTART = 'restart';
export const DISMISS_EMERGENCY = 'dismiss';
export const SEARCH_IN_PG = 'searchInPg';
export const START_OVER = 'startOver';
export const TRY_AGAIN = 'tryAgain';
export const RETRY = 'retry';

export const GENERIC_ERROR_MESSAGE = 'There was a problem processing your request';
export const CHAT_GENERIC_ERROR_MESSAGE =
  'There was an unexpected error and we were not able to process your message. Would you like to Start Over, Try Again, or Retry the same message?';
export const CHAT_CONTENT_ERROR_MESSAGE =
  "If you think you're experiencing a medical emergency, dial 911 immediately. Your message has triggered OpenAI's content filtering and cannot be processed. If you think you’ve received this response by mistake, please reword your message any try again.";
export const API_CONTENT_ERROR_MESSAGE = 'OpenAI API request was invalid';

export const CONVERSATION_STARTER = {
  role: ASSISTANT,
  content:
    'Hi, my name is Eva. I am here to help you find the right doctor. In a few sentences, tell me whats up?',
};

export const MAX_CHAT_LENGTH_MESSAGE = {
  role: ASSISTANT,
  content:
    'Unfortunately we were not able to make any provider recommendations based off of our current conversation. Please restart the chat to try again or try searching in Provider Guide.',
};

export const EMBOLD_FACTS_TIMEOUT = 3000; // time before showing facts carousel
export const EMBOLD_FACTS_INTERVAL = 8000; // time before showing next fact
export const EMBOLD_FACTS = [
  'Eva leverages the power of a large language model (LLM) to connect you with high-quality providers.',
  'Tell Eva the symptoms you are experiencing, a condition you have, or a procedure you need to find the best providers to address your unique needs.',
  'Once Eva determines what type of provider you need, you will be presented with a list of top providers near you.',
  'With Eva, you can close the chat and re-open it during the same session to resume the conversation right where you left off.',
  'Unlike complicated benefit navigation tools, Eva processes language in an intuitive and natural way to streamline the experience of finding the best providers.',
  'Unlike customer service lines, Eva is available 24 hours a day and seven days a week.',
  'EVA can detect and respond to multiple languages. Simply send a message in your native language and EVA will reply in the same language.',
  'If you are looking for a provider with specific qualities, just include the criteria in your message and Eva will custom tailor your provider results.',
  'During your conversation, Eva may remind you of additional benefits available, such as virtual health services.',
  'Eva may ask you to enter your geographic location and maximum distance you are willing to travel to narrow down your results.',
  'Eva adheres to the standards and principles intended to protect you and promote your general well-being. Always defer to the recommendations of a medical professional when making healthcare decisions.',
  'Eva is designed to guide you to the appropriate specialist and is NOT intended to replace the advice of medical professionals.',
  'Eva operates on data and might not capture all personal nuances. We encourage you to share your healthcare needs with a medical professional who can offer clinically appropriate recommendations.',
  "For the most accurate information, please confirm details with a medical professional. Your health is our utmost priority and we're here to support you on your journey of seeking quality care.",
  'For decisions about your health, the insight of a medical professional is invaluable. Eva is not intended to diagnose or provide treatment recommendations.',
  'We value your patience as we continuously improve our services. We strive to ensure a safe and consistent experience.',
  'Like any technology, Eva has its limits. For specific treatment recommendations, always talk to a medical professional first.',
  'Eva is designed to streamline finding the right doctors that can address your needs. Please defer to a medical professional for recommendations on appropriate care and treatment',
  'Always consult with a medical professional for your healthcare issues.',
  'If you believe you are experiencing an emergency, seek emergency medical evaluation right away.',
];

export const EMBOLD_ANALYSIS_MESSAGE_INTERVAL = 4000; // timeout for final loading messages
export const EMBOLD_ANALYSIS_MESSAGES = [
  'Searching healthcare database',
  'Sorting for best matched providers',
  'Delivering your results',
];
