/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, EMBOLD } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 4545d3e11a2b6a9591a3264693175ee3ce9a1e04',
  staging: 'Token b100d0637af11c6e960cef89c71c03a488201e9c',
  prod: 'Token d3d2ac680a2fba072de7cb9b969be3c13ece59f6',
};

const token = selectToken(apiTokens);

export const emboldConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'embold',
  EMBOLD_CLIENT: EMBOLD,
  EMBOLD_CLIENT_SLUG: 'embold',
  THEME: standardTheme,

  // toggles
  SHOW_COST: true,
  SHOW_TELEHEALTH: false,
  SHOW_WHEELCHAIR_ACCESSIBLE: false,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: /embold/i,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Nashville,
  LOGIN_NETWORK_NAME: 'emboldUserId',
};
