import walmartLogo from 'assets/pg-walmart-header-logo.png';
import walmartLoginLogo from 'assets/wmt.png';
import { DROPDOWN_AUTOCOMPLETE } from 'utils/constants';

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
export const WMToverrides = {
  SCHEDULE_PHONE_NUMBER: null,
  HEADER_LOGO: walmartLogo,
  SUPPORT_LINK: 'https://walmartsupport.emboldhealth.com/portal/home',
  MEMBER_ID_LINK:
    'https://walmartmybenefits.blueadvantagearkansas.net/login/login.aspx?ReturnUrl=%2fsecure%2fid.aspx',
  SHOW_CONTACT_BCBS_ROUTE: true,
  ADD_ICON: true,
  NO_WELCOME_LINK: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Walmart and Embold Health have partnered to bring you Embold’s Provider Guide, your guide
  to quickly and easily finding the doctors in your network and area that have proven to
  provide the best care. Select your region to find Embold Health’s highest quality
  providers in your area that are also in-network within your plan.`,
  LOCAL_LOGO_ASSET: walmartLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
};
