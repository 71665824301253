/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, BNSF } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 8154722fbd4aa7371bf1acdc9830b0cbe02173b4',
  staging: 'Token 7f6c7cf4386c3749f26be078870317681144233a',
  prod: 'Token b2cb3a9e10e7294e432b62d31103afdc71a3b298',
};

const token = selectToken(apiTokens);

export const bnsfConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: BNSF,
  EMBOLD_CLIENT: BNSF,
  EMBOLD_CLIENT_SLUG: BNSF,
  THEME: standardTheme,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: /^(salariedemployee|logisticsemployee|mrlunionemployee)$/,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.FortWorth,
  DEFAULT_SEARCH_RADIUS: 50,
  LOGIN_NETWORK_NAME: 'bnsfUserId',
};
