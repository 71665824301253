import { useStateValue } from 'state/index';
import { useConfigValue } from 'config/state/index';

import { SHOW_CLIENT_FEATURED, IS_SURGERY_PLUS } from 'utils/constants';

/**
 * This returns a function for getting a location's phone number.
 * This is only used to override the location's phone number if the parent provider is featured,
 * the user's network is a SurgeryPlus network and shows featured providers, and the SURGEY_PLUS_PHONE_NUMBER config value is set.
 * @returns {Function} A function for getting a location's phone number
 */
export default function useLocationPhoneNumberOverride(
  originalPhoneNumber,
  isFeaturedProviderLocation
) {
  const [{ networkSlug }] = useStateValue();
  const {
    config: { SURGERY_PLUS_PHONE_NUMBER },
    getNetworkConfigValue,
  } = useConfigValue();
  const showClientFeatured = getNetworkConfigValue(networkSlug, SHOW_CLIENT_FEATURED) ?? false;
  const isSurgeryPlusNetwork = getNetworkConfigValue(networkSlug, IS_SURGERY_PLUS) ?? false;

  const phoneNumber =
    isFeaturedProviderLocation &&
    showClientFeatured &&
    isSurgeryPlusNetwork &&
    SURGERY_PLUS_PHONE_NUMBER
      ? SURGERY_PLUS_PHONE_NUMBER
      : originalPhoneNumber;

  return phoneNumber;
}
