import React from 'react';
import 'whatwg-fetch';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import geoTag from 'assets/geo-tag.svg';

import Location from './Location';
import { GridIconRow } from '../Profile';

/** @deprecated */
function LocationList({
  profileData,
  allLocations,
  latLong,
  isPlaceSearch,
  handleDirectionsClick,
  handlePhoneClick,
  locationInputParam,
  classes,
  roundedDistance,
  distanceFromLatLong,
  showDistance,
  showProviderStrengthsSection,
  MAPS_URL,
  hasMultipleValidLocations,
  showAllLocations,
  handleShowAllLocations,
  handleDisclaimerLink,
  isSurgeryPlusNetwork,
  showClientFeatured,
}) {
  const { telehealthAvailable, affiliation, clientFeatured } = profileData;
  return (
    <>
      <div role="list">
        <div role="listitem">
          <Location
            latLong={latLong}
            isPlaceSearch={isPlaceSearch}
            handleDirectionsClick={handleDirectionsClick}
            handlePhoneClick={handlePhoneClick}
            MAPS_URL={MAPS_URL}
            closestLocation={allLocations[0]}
            locationInputParam={locationInputParam}
            geoTag={geoTag}
            classes={classes}
            showProviderStrengthsSection={showProviderStrengthsSection}
            showDistance={showDistance}
            distanceFromLatLong={distanceFromLatLong}
            roundedDistance={roundedDistance}
            GridIconRow={GridIconRow}
            affiliation={affiliation}
            telehealthAvailable={telehealthAvailable}
          />
          {hasMultipleValidLocations && <Divider />}
        </div>
        {allLocations.slice(1).map((loc, i) => (
          <Collapse in={showAllLocations} timeout={400} role="listitem">
            <Location
              // eslint-disable-next-line react/no-array-index-key
              key={`${loc.latitude}-${loc.longitude}-${i}`}
              latLong={latLong}
              isPlaceSearch={isPlaceSearch}
              handleDirectionsClick={handleDirectionsClick}
              handlePhoneClick={handlePhoneClick}
              MAPS_URL={MAPS_URL}
              closestLocation={loc}
              locationInputParam={locationInputParam}
              geoTag={geoTag}
              classes={classes}
              showProviderStrengthsSection={showProviderStrengthsSection}
              showDistance={showDistance}
              distanceFromLatLong={distanceFromLatLong}
              roundedDistance={roundedDistance}
              GridIconRow={GridIconRow}
              affiliation={affiliation}
              telehealthAvailable={telehealthAvailable}
            />
            <Divider />
          </Collapse>
        ))}
      </div>
      <GridIconRow img={null} id="disclaimer">
        {clientFeatured && showClientFeatured && isSurgeryPlusNetwork ? (
          <Typography>This provider accepts your SurgeryPlus benefit.</Typography>
        ) : (
          <Button
            role="link"
            className={`${classes.buttonLink} profile-disclaimer-link`}
            onClick={handleDisclaimerLink}
          >
            Always confirm that the provider accepts your health plan
          </Button>
        )}
      </GridIconRow>
      {hasMultipleValidLocations && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="srOnly" role="status">
            {showAllLocations &&
              `showing ${allLocations.length - 1} additional location${
                allLocations.length - 1 !== 1 ? 's' : ''
              }`}
          </Typography>
          <Button
            color="primary"
            onClick={handleShowAllLocations}
            className={classes.clearButton}
            role="button"
          >
            {showAllLocations ? 'Show Less' : 'Show More'}
          </Button>
        </div>
      )}
    </>
  );
}

export default LocationList;
