import { CONFIG_SLICE_NAME } from '../slicesNames';

/** The entire configSlice object. Should be used sparingly, prefer more granular selectors
 * whenever possible.
 * @returns {Object} configSlice object */
export const slice = (state) => state[CONFIG_SLICE_NAME];

/**  The state of the client config fetch call
 * @returns {boolean} True while the fetch call is taking place */
export const isLoading = (state) => state[CONFIG_SLICE_NAME].isLoading;

/** Error messages related to the client config fetch call.
 * Non-null values indicate the client config fetch failed.
 * @returns {string|null} Error message when present, or null if no error is present */
export const error = (state) => state[CONFIG_SLICE_NAME].error;

/** An object of all configurable values. This is considered the large "bucket"
 * of unorganized configurable values. Should be used sparingly, prefer more
 * granular selectors when possible.
 * @returns {Object} configSlice.values */
export const values = (state) => state[CONFIG_SLICE_NAME].values;

/** @returns {string} The name of the embold client */
export const displayName = (state) => state[CONFIG_SLICE_NAME].values.DISPLAY_NAME;

/** @returns {number} The default search radius for the embold client */
export const defaultSearchRadius = (state) => state[CONFIG_SLICE_NAME].values.DEFAULT_SEARCH_RADIUS;

/** @returns {number} The maximum number of providers we want allow a user to compare */
export const maxCompareCount = (state) =>
  state[CONFIG_SLICE_NAME].values.MAX_COMPARE_COUNT_PROVIDERS;

/** @returns {number} Threshold for showing compare table verses individual profile view in static profile page */
export const profileCompareThreshold = (state) =>
  state[CONFIG_SLICE_NAME].values.PROFILE_COMPARE_THRESHOLD;

/** @returns {string} The name of the client config JSON file to fetch */
export const name = (state) => state[CONFIG_SLICE_NAME].values.CONFIG_NAME;

/** Closed access clients require some sort of authentication to have access to the app.
 * @returns {boolean} True for clients that require authentication */
export const isClosedAccess = (state) => state[CONFIG_SLICE_NAME].values.CLOSED_ACCESS;
export const apiUrl = (state) => state[CONFIG_SLICE_NAME].values.API_URL;
export const networkSelectApiPath = (state) =>
  state[CONFIG_SLICE_NAME].values.NETWORK_SELECT_API_PATH;

/** THe base url for map links
 * @returns {string}
 */
export const mapsUrl = (state) => state[CONFIG_SLICE_NAME].values.MAPS_URL;
