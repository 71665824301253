/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import selectProvider from 'store/slices/results/selectProvider';
import selectPlace from 'store/slices/results/selectPlace';
import { isPlaceSearch } from '../store/slices/search/selectSearch';
import { getProviderAttributes } from './analyticsUtils';

function selectProviderAnalyticsAttributes(id) {
  return createSelector(
    [selectProvider(id).data, selectProvider(id).closestLocation],
    (providerData, closestLocation) => {
      if (!providerData) return {};
      const { distance } = closestLocation || {};
      const providerAttributes = getProviderAttributes(providerData);

      return {
        ...providerAttributes,
        distance_to_result: distance,
      };
    }
  );
}

function selectPlaceAnalyticsAttributes(id) {
  return createSelector(
    [selectPlace(id).data, selectPlace(id).distanceInMiles],
    (placeData, distance) => {
      if (!placeData) return {};

      return {
        place_id: placeData.id,
        entityName: placeData.name,
        distance_to_result: distance,
      };
    }
  );
}

export default function selectResultAnalyticsAttributes(id) {
  if (!id) return {};
  return createSelector(
    [isPlaceSearch, selectProviderAnalyticsAttributes(id), selectPlaceAnalyticsAttributes(id)],
    (isPlaceResult, providerAttributes, placeAttributes) =>
      isPlaceResult ? placeAttributes : providerAttributes
  );
}
