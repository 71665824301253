import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useConfigValue } from 'config/state/index';
import useReturnFocus from 'hooks/useReturnFocus';
import blueBanner from 'assets/blue_banner_badge.svg';
import Badge from './Badge';
import BadgeModal from './BadgeModal';

function BenefitDiffBadge({ hasBenefitDiff, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { ref, returnFocus } = useReturnFocus();
  const { config } = useConfigValue();

  const { BENEFIT_DIFF_COPY, BENEFIT_DIFF_TITLE, BENEFIT_DIFF_TOOLTIP } = config.CONTENT;
  const hasModal = Boolean(BENEFIT_DIFF_TOOLTIP && BENEFIT_DIFF_TITLE);

  if (!BENEFIT_DIFF_COPY || !hasBenefitDiff) return null;

  return (
    <>
      <Badge src={blueBanner} onClick={() => setModalIsOpen(true)} ref={ref} {...props}>
        {BENEFIT_DIFF_COPY}
      </Badge>
      {hasModal && (
        <BadgeModal
          open={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          returnFocus={returnFocus}
          title={BENEFIT_DIFF_TITLE}
          text={BENEFIT_DIFF_TOOLTIP}
        />
      )}
    </>
  );
}

BenefitDiffBadge.propTypes = {
  hasBenefitDiff: PropTypes.bool,
};

BenefitDiffBadge.defaultProps = {
  hasBenefitDiff: false,
};

export default BenefitDiffBadge;
