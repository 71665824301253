import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logDevMessage, getLocalStorageExpire } from 'utils/utils';
import { WMT_BENEFITS_NOTIFIER_VERSION } from 'utils/constants';
import * as chatActions from 'store/slices/chat/chatSlice';
import {
  catchAppError,
  startOver,
  handleNetworkChange,
  searchErrorModalClosed,
} from '../../appActions';
import { UI_SLICE_NAME } from '../slicesNames';
import { executeSearch } from '../results/resultsThunks';
import * as uiConstants from './uiConstants';

const VALID_PROFILE_MODALS = ['feedback', 'share', 'call', 'cost', 'specialties', 'info'];

const showBenefitModalCount = getLocalStorageExpire(
  'wmtBenefitsNotifier',
  WMT_BENEFITS_NOTIFIER_VERSION
)
  ? parseInt(getLocalStorageExpire('wmtBenefitsNotifier', WMT_BENEFITS_NOTIFIER_VERSION), 10)
  : 0;

const initialState = {
  profileModal: {
    // type can be any value from VALID_PROFILE_MODALS
    type: null,
    // data can be any
    data: null,
  },

  modals: {
    // error modals
    appError: false,
    searchError: false,
    subspecialtySearchError: false,

    // mobile search modals
    location: false,
    providerSearch: false,
    updateRegion: false,

    // misc modals
    wmtBenefitsNotifier: showBenefitModalCount < 2,
    chat: false,
  },

  showCookieBanner: false,
  // other ui flags
  isCssEnabled: true,
  returnFocusToKey: null,
  feedbackPrompted: Boolean(getLocalStorageExpire('feedbackPrompted')),

  breakpoint: 'xs',
  showResultsMap: false,
};

const uiSlice = createSlice({
  name: UI_SLICE_NAME,
  initialState,
  reducers: {
    openModal(state, action) {
      const modalName = action.payload;

      // loop through all modals and open the matching one. Set all others to false, ensuring that multiple modals are not opened at once
      for (const modalKey in state.modals) {
        if (modalKey === modalName) {
          state.modals[modalKey] = true;
        } else {
          state.modals[modalKey] = false;
        }
      }
    },
    closeModal(state, action) {
      const modalToClose = action.payload;

      if (!modalToClose) {
        // if no modal key is passed, close all modals
        for (const modalKey in state.modals) {
          if (state.modals[modalKey] !== undefined) state.modals[modalKey] = false;
        }
        // also reset the profile modal
        state.profileModal = initialState.profileModal;
      } else if (state.modals[modalToClose] !== undefined) {
        state.modals[modalToClose] = false;
      } else {
        logDevMessage(`No modal with key ${modalToClose}`);
      }
    },
    showCookieBanner(state) {
      state.showCookieBanner = true;
    },
    hideCookieBanner(state) {
      state.showCookieBanner = false;
    },
    setIsCssEnabled(state, action) {
      state.isCssEnabled = action.payload;
    },
    setReturnFocusToKey(state, action) {
      state.returnFocusToKey = action.payload;
    },
    closeFeedbackModal(state) {
      state.feedbackPrompted = true;
      state.profileModal = initialState.profileModal;
    },
    closeProfileModal(state, action) {
      const { promptFeedback } = action.payload || {};
      const feedbackModalAlreadyShown = state.feedbackPrompted;
      if (promptFeedback && !feedbackModalAlreadyShown) {
        state.profileModal = { type: 'feedback' };
      } else {
        state.profileModal = initialState.profileModal;
      }
    },
    openProfileModal(state, action) {
      let type;
      let data = null;

      if (typeof action.payload === 'string') {
        // in this case someone has passed dispatch(actions.ui.openProfileModal('feedback'))
        // meaning that no data was required
        type = action.payload;
      } else {
        // in this case someone has passed
        // dispatch(actions.ui.openProfileModal({ type: 'call', data: [] }))
        type = action.payload?.type;
        data = action.payload?.data;
      }

      if (VALID_PROFILE_MODALS.includes(type)) {
        state.profileModal.type = type;
        state.profileModal.data = data;
      } else {
        logDevMessage(`Invalid profile modal type: ${type}`);
      }
    },
    breakpointChanged(state, action) {
      state.breakpoint = action.payload;
    },
    toggleShowResultsMap(state) {
      state.showResultsMap = !state.showResultsMap;
    },
    setShowResultsMap(state, action) {
      const showMap = action.payload;
      state.showResultsMap = showMap;
    },
  },
  extraReducers(builder) {
    builder.addCase(catchAppError, (state) => {
      state.modals.appError = true;
    });

    builder.addCase(startOver, (state) => {
      // this should handle all state resets that need to happen on a caught error, or when the logo is clicked
      state.modals.appError = false;
      state.modals.location = false;
      state.modals.searchError = false;
      state.modals.subspecialtySearchError = false;
    });

    builder.addCase(handleNetworkChange, (state) => {
      state.modals.updateRegion = false;
    });

    builder.addCase(searchErrorModalClosed, (state) => {
      state.modals.searchError = false;
      state.modals.subspecialtySearchError = false;
    });

    builder.addCase(executeSearch.fulfilled, (state, action) => {
      if (uiConstants.lgUpBreakpoints.includes(state.breakpoint)) {
        // on desktop sized screens, automatically open the map when results come in
        const { response = {} } = action.payload;

        // only open the map if there is at least 1 returned result
        if (response.count) state.showResultsMap = true;
      }
    });

    builder.addMatcher(
      isAnyOf(chatActions.specialtySearchInPg, chatActions.goToHomepage),
      (state) => {
        state.modals.chat = false;
      }
    );
  },
});

export default uiSlice;
export const {
  openModal,
  closeModal,
  showCookieBanner,
  hideCookieBanner,
  closeFeedbackModal,
  closeProfileModal,
  openProfileModal,
  setIsCssEnabled,
  setReturnFocusToKey,
  breakpointChanged,
  toggleShowResultsMap,
  setShowResultsMap,
} = uiSlice.actions;
