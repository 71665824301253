import soctLoginLogo from 'assets/soct_logo.svg';
import soctHeaderLogo from 'assets/soct_header_logo.png';

import { DROPDOWN } from 'utils/constants';

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
export const soctOverrides = {
  SCHEDULE_PHONE_NUMBER: '833-740-3258',
  HEADER_LOGO: soctHeaderLogo,
  WELCOME_MESSAGE:
    'State of Connecticut and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: soctLoginLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};
