import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import PropTypes from 'propTypes/index';
import { Typography, makeStyles, Collapse, Grid, Box } from '@material-ui/core';

import ShowMoreButton from 'components/ShowMoreButton';
import { select } from 'store/toolkit';
import procedureIconBlue from 'assets/procedure-icon-blue.svg'; // TODO TECH-3847 replace with custom icon
import CostEstimator from '../Results/ResultCard/CostEstimator';

const useStyles = makeStyles((theme) => ({
  fillWidth: {
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius * 6,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  serviceLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconLabel: {
    fontSize: 15,
  },
  heading: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  showMore: {
    marginLeft: 'auto',
    color: theme.palette.grey[700],
    fontSize: 14,
  },
  content: {
    alignItems: 'start',
  },
  costEstimatorWrapper: {
    textAlign: 'center',
  },
  costEstimator: {
    marginRight: 10,
    marginTop: -10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  costEstimatorDescription: {
    marginTop: -10,
    marginLeft: -10,
  },
  serviceDesc: {
    backgroundColor: theme.palette.cardBackgroundColor,
    borderRadius: 11,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  headingIconAndLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 6,
    '& img': {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
  },
  serviceName: {
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  serviceNameMobile: {
    textTransform: 'capitalize',
  },
}));

export default function ServiceInfoContent({ service, mobileView, fullWidthServiceDescription }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const serviceContentId = 'service-details';

  const COST_ESTIMATOR_DESCRIPTION = useSelector(select.featureFlags.costEstimatorDescription);

  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened((prev) => !prev);
  };

  useEffect(() => {
    if (service && opened) {
      dispatch(_analyticsActions.serviceProfileView(service.serviceName));
    }
  }, [dispatch, opened, service]);

  if (!service) return null;

  return (
    <Grid className={classes.fillWidth}>
      <Grid container className={classes.heading}>
        <Grid item className={classes.headingIconAndLabel}>
          <img src={procedureIconBlue} alt="" />
          <Typography component="span">Service</Typography>
        </Grid>
        {!mobileView && (
          <Grid item>
            <Typography variant="h3" className={classes.serviceName}>
              {service?.serviceName}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <ShowMoreButton
            isOpen={opened}
            onClick={toggleOpen}
            additionalClass={classes.showMore}
            ariaControls={serviceContentId}
            ariaLabel={service?.serviceName}
          />
        </Grid>
        {mobileView && (
          <Grid item xs={12}>
            <Typography variant="h3" component="h3" className={classes.serviceNameMobile}>
              {service?.serviceName}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Collapse in={opened}>
        <Grid
          className={classes.content}
          container
          justifyContent={mobileView ? 'center' : 'space-between'}
          id={serviceContentId}
        >
          <Grid item className={classes.costEstimatorWrapper} xs="auto" md={mobileView ? 6 : 4}>
            <Box className={classes.costEstimator} display="flex" justifyContent="center">
              <CostEstimator />
            </Box>
            {Boolean(COST_ESTIMATOR_DESCRIPTION) && (
              <Typography className={classes.costEstimatorDescription}>
                {COST_ESTIMATOR_DESCRIPTION}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={fullWidthServiceDescription ? 12 : 8}>
            <Box className={classes.serviceDesc}>
              <Typography variant="h4">About this service:</Typography>
              <Typography>{service.descriptionPlainLanguage}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}

ServiceInfoContent.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  service: PropTypes.shape({
    descriptionPlainLanguage: PropTypes.string,
    serviceName: PropTypes.string,
  }).isRequired,
  fullWidthServiceDescription: PropTypes.bool.isRequired,
};
