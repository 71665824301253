/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, SURGERY_PLUS } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token d7b1fd5b743e814c1ced5308af3e9816d81fa43a',
  staging: 'Token 8aabcb9b67e4a4bc9082b9dcc47ca5b762ffd2aa',
  prod: 'Token 3ebb19b307fe12abd5d4b25914782b63729e9ea6',
};

const token = selectToken(apiTokens);

export const surgeryPlusConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'surgeryplus',
  EMBOLD_CLIENT: SURGERY_PLUS,
  EMBOLD_CLIENT_SLUG: 'surgeryplus',
  THEME: standardTheme,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: false,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: /surgeryplus/i,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Dallas,
  DEFAULT_SEARCH_RADIUS: 50,
  LOGIN_NETWORK_NAME: 'surgeryPlusUserId',
};
