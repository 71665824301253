import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Tooltip, { TooltipPropsShape } from 'components/Tooltip';

const useStyles = makeStyles(() => ({
  titleGrid: {
    alignItems: 'center',
    columnGap: 6,
  },
  title: {
    margin: 0,
    color: '#000',
    fontWeight: 700,
  },
  icon: {
    height: '1rem',
  },
  tooltip: {
    marginLeft: '.35rem',
  },
}));

function ProfileSectionTitle({ title, icon, iconAltText, TooltipProps }) {
  const classes = useStyles();
  const { title: tooltipTitle, message: tooltipText } = TooltipProps;

  const hasTooltip = Boolean(tooltipText);

  return (
    <Grid container classes={{ root: classes.titleGrid }}>
      {icon && <img src={icon} alt={iconAltText || ''} className={classes.icon} />}
      <Tooltip
        title={tooltipTitle}
        message={tooltipText}
        additionalClasses={{ icon: classes.tooltip }}
        deactivated={!hasTooltip}
      >
        <Typography classes={{ root: classes.title }} variant="h3">
          {title}
        </Typography>
      </Tooltip>
    </Grid>
  );
}

export default ProfileSectionTitle;

ProfileSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconAltText: PropTypes.string,
  TooltipProps: TooltipPropsShape,
};

ProfileSectionTitle.defaultProps = {
  icon: undefined,
  iconAltText: undefined,
  TooltipProps: {},
};
