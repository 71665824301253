import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, IconButton, Collapse, Toolbar, Tab, Button, Paper } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

import { thunks, actions, select } from 'store/toolkit';
import useToggleState from 'hooks/useToggleState';
import AskEvaTool from 'components/Modals/ChatModal/DevTools/AskEvaTool';
import AnalyzeChatTool from 'components/Modals/ChatModal/DevTools/AnalyzeChatTool';
import ChatProviderSearchTool from 'components/Modals/ChatModal/DevTools/ChatProviderSearchTool';

const useStyles = makeStyles((theme) => ({
  spacer: { height: 0, [theme.breakpoints.down('sm')]: { height: 30 } },
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.mobileStepper,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '& .MuiTabPanel-root': {
      padding: theme.spacing(1),
    },
    '& .MuiGrid-container': {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius * 3,
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      '& button': { maxHeight: 30, margin: 'auto' },
    },
  },
  toolbar: {
    minHeight: 0,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': { padding: 4 },
  },
  expanded: {
    transition: '.5s ease',
  },
  collapsed: {
    transform: 'rotate(180deg)',
    transition: '.5s ease',
  },
  tabList: {
    color: theme.palette.primary.main,
    minHeight: 0,
    '& button': {
      maxWidth: 120,
      minHeight: 0,
      padding: 6,
      textTransform: 'none',
      ...theme.typography.caption,
      letterSpacing: 1,
    },
    '& .MuiTab-root:focus': {
      outline: 'none',
      '& span': { textDecoration: '<underline></underline>' },
    },
  },
}));

export default function ChatDevTools() {
  const classes = useStyles();
  const [isOpen, toggleIsOpen] = useToggleState();
  const [tab, setTab] = useState(thunks.chat.askEva.typePrefix);

  const termsAccepted = useSelector(select.chat.termsAccepted);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => dispatch(actions.chat.toggleDevTools()), [dispatch]);

  const skipDisclaimer = useCallback(() => {
    dispatch(actions.chat.acceptTerms());
    dispatch(actions.chat.setScenarioId(999));
  }, [dispatch]);

  return (
    <>
      <div className={classes.spacer} />
      <Paper className={classes.root} elevation={5}>
        <Toolbar className={classes.toolbar} variant="dense" disableGutters>
          <div>
            <IconButton onClick={toggleIsOpen}>
              <ExpandMoreIcon className={isOpen ? classes.expanded : classes.collapsed} />
            </IconButton>
            <Typography variant="overline">Dev Tools</Typography>
          </div>

          {!termsAccepted && (
            <Button variant="text" size="small" onClick={skipDisclaimer}>
              Skip Disclaimer
            </Button>
          )}

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <Collapse in={isOpen}>
          <TabContext value={tab}>
            <TabList onChange={(e, val) => setTab(val)} classes={{ root: classes.tabList }}>
              <Tab
                value={thunks.chat.askEva.typePrefix}
                index={0}
                label={thunks.chat.askEva.typePrefix}
              />
              <Tab
                value={thunks.chat.analyzeChat.typePrefix}
                index={1}
                label={thunks.chat.analyzeChat.typePrefix}
              />
              <Tab
                value={thunks.chat.performAllSearches.typePrefix}
                index={2}
                label={thunks.chat.performAllSearches.typePrefix}
              />
            </TabList>
            <TabPanel value={thunks.chat.askEva.typePrefix}>
              <AskEvaTool />
            </TabPanel>
            <TabPanel value={thunks.chat.analyzeChat.typePrefix}>
              <AnalyzeChatTool />
            </TabPanel>
            <TabPanel value={thunks.chat.performAllSearches.typePrefix}>
              <ChatProviderSearchTool />
            </TabPanel>
          </TabContext>
        </Collapse>
      </Paper>
    </>
  );
}
