import React, { useRef, useCallback, useMemo } from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'propTypes';
import clsx from 'clsx';

import {
  defaultPillProps,
  pillProps,
} from 'components/ModernExperience/ResultCards/ProviderCard/Pill';
import { select, actions } from 'store/toolkit';
import { makeStyles } from '@material-ui/styles';

import useFocusAnchors from 'utils/FocusRefContext';
import useThemeColor from 'hooks/useThemeColor';

const useStyles = (color, inverted) =>
  makeStyles((theme) => ({
    boxRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(2),
    },
    label: {
      lineHeight: 1.25,
      fontSize: '.9rem',
      textAlign: 'center',
    },
    labelWithTooltip: {
      textDecoration: 'underline',
    },
    clickable: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    avatar: {
      height: 48,
      width: 48,
    },
    avatarWithImgOrText: {
      backgroundColor: inverted ? theme.palette.white : color,
      color: inverted ? color : theme.palette.white,
      border: inverted && 'solid',
      borderWidth: inverted ? 2 : 0,
      borderColor: inverted && color,
      '& > img': {
        width: '75%',
        height: '75%',
      },
    },
    avatarWithIcon: {
      backgroundColor: inverted ? color : theme.palette.grey[100],
      color: inverted ? theme.palette.white : color,
      '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    },
  }));

function ProfileBadge({
  label,
  icon,
  iconUrl,
  iconAlt,
  color,
  inverted,
  additionalIconClass,
  TooltipProps,
  AvatarProps,
}) {
  const themeColor = useThemeColor(color);
  const classes = useStyles(themeColor, inverted)();
  const tooltipRef = useRef(null);
  const focusAnchors = useFocusAnchors();

  const dispatch = useDispatch();
  const tooltipOpen = useSelector(select.tooltip.isOpen);

  const { title: tooltipTitle, message: tooltipMessage, link: tooltipLink } = TooltipProps;

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (tooltipOpen) {
        // close an existing tooltip if open
        dispatch(actions.tooltip.close());
      }

      focusAnchors.tooltip = tooltipRef;

      const payload = { title: tooltipTitle || label, message: tooltipMessage };
      if (tooltipLink?.to && tooltipLink?.label) {
        payload.link = tooltipLink;
      }

      dispatch(actions.tooltip.open(payload));
    },
    [dispatch, focusAnchors, tooltipTitle, label, tooltipMessage, tooltipOpen, tooltipLink]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === 'Enter' || e.code === 'Space') {
        e.preventDefault();
        handleClick(e);
      }
    },
    [handleClick]
  );

  const hasTooltip = Boolean(TooltipProps.message);
  const clickableProps = hasTooltip
    ? {
        role: 'button',
        tabIndex: 0,
        onClick: handleClick,
        onKeyDown: handleKeyDown,
        ref: tooltipRef,
      }
    : {};

  const hasIcon = Boolean(icon || iconUrl);

  const avatarClasses = useMemo(
    () =>
      clsx(classes.avatar, {
        [classes.avatarWithImgOrText]: Boolean(iconUrl) || typeof icon !== 'object',
        [classes.avatarWithIcon]: Boolean(icon) && typeof icon === 'object',
        [additionalIconClass]: additionalIconClass,
      }),
    [classes, iconUrl, icon, additionalIconClass]
  );

  return (
    <Box
      {...clickableProps}
      classes={{ root: clsx(classes.boxRoot, { [classes.clickable]: tooltipMessage }) }}
    >
      {hasIcon && (
        <Avatar className={avatarClasses} src={iconUrl} alt={iconAlt} {...AvatarProps}>
          {icon}
        </Avatar>
      )}
      <Typography
        component="span"
        className={clsx(classes.label, { [classes.labelWithTooltip]: hasTooltip })}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default ProfileBadge;

/** This component should accept all of the same props as the <Pill /> prop. It can accept additional props */
ProfileBadge.propTypes = {
  ...pillProps,
  AvatarProps: PropTypes.shape({}),
};

ProfileBadge.defaultProps = {
  ...defaultPillProps,
  AvatarProps: {},
};
