/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import affiliationIcon from 'assets/place.svg';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import femaleIcon from 'assets/female-icon.svg'; // TODO TECH-3847 replace with custom icon
import maleIcon from 'assets/male-icon.svg'; // TODO TECH-3847 replace with custom icon

function ProviderSummary({
  GridIconRow,
  affiliation,
  telehealthAvailable,
  handleWebsiteClick,
  isValidURL,
  website,
  classes,
  languages,
  gender,
}) {
  return (
    <>
      {affiliation && (
        <>
          {/* <Typography
            variant="body1"
            style={{
              fontSize: '21px',
              color: '#555',
              marginLeft: '5px',
              fontVariant: 'all-small-caps',
              fontWeight: '600',
            }}
          >
            Affiliation
          </Typography> */}

          <GridIconRow img={affiliationIcon} id="affiliation">
            <Typography variant="body1" className="profile-gender">
              {affiliation}
            </Typography>
          </GridIconRow>
        </>
      )}
      {languages?.length > 0 && (
        <GridIconRow Icon={RecordVoiceOverOutlinedIcon} id="language">
          <Typography variant="body1">{languages.join(', ')}</Typography>
        </GridIconRow>
      )}
      {telehealthAvailable && (
        <GridIconRow Icon={CheckCircleOutlineOutlinedIcon} id="telehealth">
          <Typography variant="body1">Telehealth</Typography>
        </GridIconRow>
      )}
      {(gender === 'Female' || gender === 'Male') && (
        <GridIconRow img={gender === 'Male' ? maleIcon : femaleIcon} id="gender">
          <Typography variant="body1" className="profile-gender">
            {gender}
          </Typography>
        </GridIconRow>
      )}
      {website && isValidURL(website) && (
        <GridIconRow Icon={OpenInBrowserOutlinedIcon} id="web">
          <Button
            className={`${classes.buttonLink} profile-website-link`}
            onClick={handleWebsiteClick}
            role="link"
          >
            Website
          </Button>
        </GridIconRow>
      )}
    </>
  );
}

ProviderSummary.propTypes = {
  classes: PropTypes.object,
  GridIconRow: PropTypes.func,
  affiliation: PropTypes.string,
  telehealthAvailable: PropTypes.bool,
};

export default ProviderSummary;
