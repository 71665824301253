import { createSelector } from '@reduxjs/toolkit';

import { logDevMessage } from 'utils/utils';
import { BOOL_FILTER_TYPE, ARRAY_FILTER_TYPE } from './filterConstants';
import { FILTERS_SLICE_NAME } from '../slicesNames';
import filterKeyMap from './filterKeyMap';

// Basic selectors
export const slice = (state) => state[FILTERS_SLICE_NAME];
export const all = (state) => state[FILTERS_SLICE_NAME].filters;
export const providerSort = (state) => state[FILTERS_SLICE_NAME].sort.provider;
export const placeSort = (state) => state[FILTERS_SLICE_NAME].sort.place;
export const predictedResults = (state) => state[FILTERS_SLICE_NAME].predictedResults;
export const optionsLoading = (state) => state[FILTERS_SLICE_NAME].options.isLoading;
export const optionsInvalidated = (state) => state[FILTERS_SLICE_NAME].options.isInvalidated;
export const optionsError = (state) => state[FILTERS_SLICE_NAME].options.error;
export const radius = (state) => state[FILTERS_SLICE_NAME].radius;

// Advanced selectors
export function byKey(key) {
  return createSelector([all], (filters) => filters[key] || undefined);
}

export const values = createSelector([all], (filters) => {
  const result = {};

  for (const [filterKey, filter] of Object.entries(filters)) {
    /* TODO TECH-2876
     * Once  client config is been migrated to Redux, we should uncomment the IF check here.
     * It is removed because on URL direct searches, the search was executing before the client
     * config was loaded. The means that filters that are disabled by default may have a value from the
     * URL, but not be enabled yet and therefore not applied to the results slice.
     * ...
     * For the time being, resultsSlice.request.filters will be passed several values that are actually disabled filters.
     * This is applied in resultsThunks.executeSearch
     */

    // if (!filter.disabled) {
    result[filterKey] = filter.value;
    // }
  }
  return result;
});

export const count = createSelector([all], (filters) => {
  let result = 0;

  for (const filter of Object.values(filters)) {
    if (filter.type === ARRAY_FILTER_TYPE) {
      result += filter.value.length;
    } else if (filter.type === BOOL_FILTER_TYPE && filter.value) {
      result += 1;
    }
  }
  return result;
});

// showExclusions should return true if any filter with a prefix of 'exclude' is NOT disabled
export const showExclusions = createSelector([all], (filters) => {
  for (const filterKey in filters) {
    if (filterKey.startsWith('exclude') && !filters[filterKey].disabled) {
      return true;
    }
  }

  return false;
});

// query string builder
export const asQueryParams = createSelector([all], (filters) => {
  const paramStrings = [];

  // iterate through filters.
  for (const [filterKey, filter] of Object.entries(filters)) {
    /* TODO TECH-2876
     * Once  client config is been migrated to Redux, we should uncomment the IF check here.
     * It is removed because on URL direct searches, the search was executing before the client
     * config was loaded. The means that filters that are disabled by default may have a value from the
     * URL, but not be enabled yet and therefore not applied to the results slice.
     * ...
     * For the time being, resultsSlice.request.filters will be passed several values that are actually disabled filters.
     * This is applied in resultsThunks.executeSearch
     */
    // if (!filter.disabled) {
    const fusionFilterKey = filterKeyMap[filterKey];
    if (!fusionFilterKey) {
      logDevMessage(
        `No fusion filterKey specified for "${filterKey}". Add this property to filterKeyMap.js`
      );
      continue;
    }
    switch (filter.type) {
      case ARRAY_FILTER_TYPE:
        // array types should add param if length is > 0
        if (filter.value.length > 0) {
          let paramString = `${fusionFilterKey}=`; // add key
          paramString += filter.value.join(','); // add values
          paramStrings.push(paramString);
        }
        break;
      case BOOL_FILTER_TYPE:
        // boolean types add the filter if value is true
        if (filter.value === true) {
          const paramString = `${fusionFilterKey}=true`; // add key
          paramStrings.push(paramString);
        }
        break;
      default:
        logDevMessage(`Unhandled filter type ${filter.type} in asQueryParams`);
        break;
    }
    // }
  }

  let queryParamsString = paramStrings.join('&'); // add & between each filter param and join to 1 string
  // handle unique filter case of healthConnectPlan. health_plan=true should be changed to health_plan=health_connect
  queryParamsString = queryParamsString.replace('health_plan=true', 'health_plan=health_connect');
  // handle inNetworkPreferred when value is true, query should be false
  queryParamsString = queryParamsString.replace('curated_out=true', 'curated_out=false');

  return queryParamsString;
});

export const forUrlDirectSearch = createSelector([all], (filters) => {
  const result = {};

  for (const [filterKey, filter] of Object.entries(filters)) {
    /* TODO TECH-2876
     * Once  client config is been migrated to Redux, we should uncomment the IF check here.
     * It is removed because on URL direct searches, the search was executing before the client
     * config was loaded. The means that filters that are disabled by default may have a value from the
     * URL, but not be enabled yet and therefore not applied to the results slice.
     * ...
     * For the time being, resultsSlice.request.filters will be passed several values that are actually disabled filters.
     * This is applied in resultsThunks.executeSearch
     */
    // if (!filter.disabled) {
    if (filter.type === ARRAY_FILTER_TYPE && filter.value.length) {
      // for array filters that have a length, add to result
      result[filterKey] = filter.value.join(' ');
    } else if (filter.type === BOOL_FILTER_TYPE && filter.value) {
      // for boolean filters that have a value of true, add text 'true'
      result[filterKey] = 'true';
    }
    // }
  }
  return result;
});

export const radiusInMeters = createSelector([radius], (radiusInMiles) => radiusInMiles * 1609.34);
