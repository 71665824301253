import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import { useFocusAreasMetric } from 'hooks/Provider/index';
import NoDataIcon from './NoDataIcon';

export default function FocusAreasCell({ providerId }) {
  const provider = useSelector(select.provider(providerId).data);
  const focusAreasMetric = useFocusAreasMetric(provider);

  if (!focusAreasMetric) return <NoDataIcon />;

  return focusAreasMetric.text;
}

FocusAreasCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
