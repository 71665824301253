import emboldLogo from 'assets/embold-icon.png';
import emboldLoginLogo from 'assets/embold-login-logo.svg';

import { CHECKMARK } from 'utils/constants';

export const emboldOverrides = {
  SCHEDULE_PHONE_NUMBER: '615-810-8191',
  SCHEDULE_COPY: `Connecting you to Embold Health Customer Service. Click 'Call Now' to continue`,
  HEADER_LOGO: emboldLogo,
  SUPPORT_LINK: 'https://ehemployeesupport.emboldhealth.com/portal/en/home',
  ADD_ICON: false,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE: `Provider Guide is your guide to quickly and easily finding the doctors in your network and
  area that have proven to provide the best care.`,
  LOCAL_LOGO_ASSET: emboldLoginLogo,
  FORM_VARIANT: CHECKMARK,
  NO_WELCOME_LINK: false,
};
