import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { delayByAttempt } from 'utils/fetchRetryDelay';
import { mapCamel } from './utils';

function getStatusCodeRule(code) {
  const statusCodeRule = {
    // default options
    shouldRetry: true,
    disableSentry: false,
    shouldRedirect: false,
  };

  switch (code) {
    case 500:
    case 400:
    case 403:
    case 404:
      statusCodeRule.shouldRetry = false;
      break;
    case 502:
    case 503:
      statusCodeRule.shouldRetry = true;
      break;
    case 410:
      statusCodeRule.shouldRetry = false;
      statusCodeRule.disableSentry = true;
      break;
    case 401:
      statusCodeRule.shouldRetry = false;
      statusCodeRule.shouldRedirect = true;
      break;
    default:
      break;
  }

  return statusCodeRule;
}

const MAX_ATTEMPTS = 3;

export default function createFetchClient(clientConfig) {
  const defaultOptions = {
    baseURL: clientConfig?.API_URL,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    attempts: 0,
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    const localToken = localStorage.getItem('integrationToken');
    const integrationToken = clientConfig?.CLOSED_ACCESS ? localToken : localToken || uuidv4();
    if (!localToken && !clientConfig?.CLOSED_ACCESS && integrationToken) {
      // if there is no local token and it's not closed access, we should set it locally
      localStorage.setItem('integrationToken', integrationToken);
    }

    config.headers.Authorization = clientConfig.API_TOKEN;

    if (integrationToken) config.headers['X-Integration-Token'] = integrationToken;

    return config;
  });
  instance.interceptors.response.use(
    /* on fulfilled */
    (response) => {
      if (response.data) {
        response.data = mapCamel(response.data);
      }
      return response;
    },

    /* on rejected */
    async (error) => {
      const { config, response } = error;

      const statusCodeRule = getStatusCodeRule(response?.status);
      const attemptsMade = config.attempts + 1;

      // Retry
      if (statusCodeRule.shouldRetry && attemptsMade < MAX_ATTEMPTS) {
        await delayByAttempt(attemptsMade);
        return instance.request({ ...config, attempts: attemptsMade });
      }

      // Redirect
      if (statusCodeRule.shouldRedirect) {
        const redirectUrl = response?.data?.redirectUrl || response?.data?.redirect_url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
          return Promise.reject(error);
        }
      }

      // Report
      if (!statusCodeRule.disableSentry) {
        Sentry.withScope((scope) => {
          const url = new URL(`${error?.config?.baseURL}${error?.config?.url}`);
          // group errors together based on their request and response
          scope.setFingerprint([
            error?.config?.method,
            url?.pathname,
            String(error?.response?.status || error?.response?.code),
          ]);
          Sentry.captureException(error);
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
}
