/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Modal from 'components/Modals/Modal';

import DownshiftExample from './DownshiftExample';
import SampleModalContent from './SampleModalContent';

const MODAL_SIZES = ['sm', 'md', 'full'];

function StyleGuide() {
  const [selectValue, setSelectValue] = useState('0');
  const [radioValue, setRadioValue] = useState('1');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleModalOpen = (props) => {
    setModalIsOpen(true);
    setModalProps(props);
  };

  const handleSelect = ({ target: { value } }) => {
    setSelectValue(value);
  };

  const handleRadio = ({ target: { value } }) => {
    setRadioValue(value);
  };

  return (
    <Container style={{ padding: 10 }}>
      <Container>
        <Typography variant="h1">Header 1</Typography>
        <Typography variant="h2">Header 2</Typography>
        <Typography variant="h3">Header 3</Typography>
        <Typography variant="h4">Header 4</Typography>
        <Typography variant="h5">Header 5</Typography>
        <Typography variant="h6">Header 6</Typography>
        <Typography variant="subtitle1">Subtitle 1</Typography>
        <Typography variant="subtitle2">Subtitle 2</Typography>
        <Typography variant="body1">
          Body 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </Typography>
        <Typography variant="body2">
          Body 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </Typography>
        <Typography variant="button">Button</Typography>
        <br />
        <Typography variant="caption">
          Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </Typography>
        <br />
        <Typography variant="overline">Overline</Typography>
      </Container>

      <Divider />

      <Container>
        <Typography variant="h2">
          "Drop-Down" Menu{' '}
          <span role="img" aria-label="eye roll emoji">
            &#x1F644;
          </span>
        </Typography>

        <Grid container>
          <Grid item xs={12} sm={4}>
            <Select value={selectValue} aria-label="Select" onChange={handleSelect}>
              <MenuItem value="0" disabled>
                Default Select
              </MenuItem>
              <MenuItem value="1">Option 1</MenuItem>
              <MenuItem value="2">Option 2</MenuItem>
              <MenuItem value="3">Option 3</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Select
              value={selectValue}
              aria-label="Select"
              onChange={handleSelect}
              variant="outlined"
            >
              <MenuItem value="0" disabled>
                Contained Select
              </MenuItem>
              <MenuItem value="1">Option 1</MenuItem>
              <MenuItem value="2">Option 2</MenuItem>
              <MenuItem value="3">Option 3</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} sm={4}>
            <DownshiftExample />
          </Grid>
        </Grid>
      </Container>
      <Divider />

      <Container>
        <Typography variant="h2">Modals</Typography>

        <Grid container spacing={2}>
          {MODAL_SIZES.map((size) => (
            <Grid key={size} item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleModalOpen({ maxWidth: size })}
              >
                Open modal
              </Button>
              <pre>maxWidth: {size}</pre>
            </Grid>
          ))}
          {MODAL_SIZES.map((size) => (
            <Grid key={size} item xs={12} sm={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleModalOpen({ maxWidth: size, fullWidth: true })}
              >
                Open modal
              </Button>
              <pre>
                maxWidth: {size}
                <br />
                fullWidth: true
              </pre>
            </Grid>
          ))}

          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={() => handleModalOpen({ fullScreen: true })}>
              Open modal
            </Button>
            <pre>fullScreen: true</pre>
          </Grid>
        </Grid>

        <Modal
          open={modalIsOpen}
          ariaId="styleguide-modal"
          handleClose={() => setModalIsOpen(false)}
          title="Modal Styleguide Example"
          {...modalProps}
        >
          <SampleModalContent />
        </Modal>
      </Container>

      <Divider />

      <Container>
        <Typography variant="h2">Contained Variant Text Fields</Typography>

        <Grid container>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              margin="dense"
              label="Default Label"
              placeholder="Placeholder Text"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              margin="dense"
              label="Auto-Shrink Label"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Placeholder Text"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              margin="dense"
              inputProps={{ 'aria-label': 'test label' }}
            />
          </Grid>
        </Grid>

        <Typography variant="h2">Default Variant Text Fields</Typography>

        <Grid container>
          <Grid item xs={12} sm={4}>
            <TextField margin="dense" label="Default Label" placeholder="Placeholder Text" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              margin="dense"
              label="Auto-Shrink Label"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Placeholder Text"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField margin="dense" inputProps={{ 'aria-label': 'test label' }} />
          </Grid>
        </Grid>
      </Container>

      <Divider />

      <Container>
        <Typography variant="h2">Radio Buttons</Typography>

        <RadioGroup value={radioValue} onChange={handleRadio}>
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Radio 1 (primary)"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="secondary" />}
            label="Radio 2 (secondary)"
          />
          <FormControlLabel value="3" control={<Radio disabled />} label="Radio 3 (disabled)" />
        </RadioGroup>
      </Container>

      <Divider />

      <Container>
        <Typography variant="h2">Checkboxes</Typography>

        <FormControlLabel
          value="1"
          control={<Checkbox />}
          label="Checkbox 1 (primary)"
          labelPlacement="end"
        />

        <FormControlLabel
          value="2"
          control={<Checkbox color="secondary" />}
          label="Checkbox 2 (secondary)"
          labelPlacement="end"
        />

        <FormControlLabel
          value="3"
          control={<Checkbox disabled />}
          label="Checkbox 3 (disabled)"
          labelPlacement="end"
        />
      </Container>

      <Divider />

      <Container>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Typography variant="h2">Default Variant Buttons</Typography>

            <Button>Default Button</Button>
            <br />
            <br />
            <Button color="primary">Primary Button</Button>
            <br />
            <br />
            <Button color="secondary">Secondary Button</Button>
            <br />
            <br />
            <Button disabled>Disabled Button</Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h2">Contained Variant Buttons</Typography>

            <Button variant="contained" color="primary">
              CP Button
            </Button>
            <br />
            <br />
            <Button variant="contained" color="secondary">
              CS Button
            </Button>
            <br />
            <br />
            <Button variant="contained" disabled>
              CD Button
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h2">Outlined Variant Buttons</Typography>

            <Button variant="outlined" color="primary">
              OP Button
            </Button>
            <br />
            <br />
            <Button variant="outlined" color="secondary">
              OS Button
            </Button>
            <br />
            <br />
            <Button variant="outlined" disabled>
              OD Button
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Divider />

      <Container>
        <Grid container>
          <Typography variant="h2">Button Group</Typography>

          <Grid item xs={12}>
            <Typography variant="h3">Text</Typography>
            <ButtonGroup variant="text">
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Contained</Typography>
            <ButtonGroup variant="contained" color="primary">
              <Button id="test">One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Outlined (default)</Typography>
            <ButtonGroup variant="outlined" color="primary">
              <Button>One</Button>
              <Button>Two</Button>
              <Button>Three</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default StyleGuide;
