import React from 'react';
import PropTypes from 'propTypes';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius * 2,
    minWidth: 85,
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: theme.typography.body1.lineHeight,
    fontSize: theme.typography.body1.fontSize,
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: '1rem',
  },
}));

/**
 * A styled wrapper for "details" buttons on the "Modern Experience Larger Provider Cards"
 */
function DetailsButton({ accessibleLabel, buttonClasses, onClick }) {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: `${classes.buttonRoot} ${buttonClasses}`, label: classes.buttonLabel }}
      onClick={onClick}
      variant="contained"
      color="secondary"
    >
      <span className="button-details" aria-hidden="true">
        Details
      </span>
      <Typography variant="srOnly">
        Show details for {accessibleLabel}. Opens in new tab.
      </Typography>
      <OpenInNewIcon className={classes.icon} />
    </Button>
  );
}

export default DetailsButton;

DetailsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  accessibleLabel: PropTypes.string,
  buttonClasses: PropTypes.string,
};

DetailsButton.defaultProps = {
  accessibleLabel: '',
  buttonClasses: '',
};
