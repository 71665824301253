import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { FileCopy } from '@material-ui/icons';

const useStyles = makeStyles({
  textButton: {
    // this selector centers the icon and the button text
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

function CopyTextButton({ textToCopy, buttonText, className, anchorOrigin, ...props }) {
  const classes = useStyles();
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [isCopyError, setIsCopyError] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setSnackbarMessage('Copied to Clipboard');
      setIsCopyError(false);
    } catch (e) {
      setIsCopyError(true);
      setSnackbarMessage('Failed to copy to clipboard');
    } finally {
      // this clears the message after 2s to hide the snackbar
      setTimeout(() => {
        setSnackbarMessage(null);
      }, 2000);
    }
  }, [textToCopy]);

  const buttonProps = {
    'aria-label': 'Copy text to clipboard',
    variant: 'outlined',
    color: 'primary',
    onClick: handleClick,
  };

  const CopyButton = buttonText ? (
    <Button
      className={`${classes.textButton} ${className}`}
      startIcon={<FileCopy />}
      {...buttonProps}
      {...props}
    >
      {buttonText}
    </Button>
  ) : (
    <IconButton className={className} {...buttonProps} {...props}>
      <FileCopy />
    </IconButton>
  );

  return (
    <>
      {CopyButton}
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={Boolean(snackbarMessage)}
        message="Copied to Clipboard"
      >
        <Alert severity={isCopyError ? 'error' : 'success'}>{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

CopyTextButton.propTypes = {
  textToCopy: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

CopyTextButton.defaultProps = {
  textToCopy: '',
  buttonText: '',
  className: '',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export default React.memo(CopyTextButton);
