import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { PROVIDER_RESULT_TYPE } from 'utils/constants';
import ScoreIcon from 'components/ScoreIcon';
import { select } from 'store/toolkit';

import { useConfigValue } from 'config/state';

const useStyles = makeStyles((theme) => ({
  specialtyArea: {
    paddingLeft: '8px',
    paddingBottom: '0px',
  },
  showMoreButton: {
    fontWeight: 'heavy',
    fontSize: '0.9em',
    textDecoration: 'underline',
    color: theme.palette.black,
  },
  expandCollapseButton: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
  },
  collapseButtonWithScores: {
    marginTop: '10px',
  },
  specializationRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dividerWithScores: {
    margin: 'auto',
  },
  subspecialtyName: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));

function SubspecialtyName({ subspecialty, showScores }) {
  const classes = useStyles();
  const activeSubspecialtyId = useSelector(select.results.subspecialtyId);
  return showScores ? (
    <div style={{ display: 'flex' }}>
      {Boolean(
        subspecialty.subspecialtyScore && subspecialty.subspecialtyId !== activeSubspecialtyId
      ) && (
        <div style={{ paddingRight: '7px' }}>
          <ScoreIcon score={subspecialty.subspecialtyScore} />{' '}
        </div>
      )}
      <div className={classes.subspecialtyName}>{subspecialty.subspecialtyName}</div>
    </div>
  ) : (
    <div className={classes.subspecialtyName}>{subspecialty.subspecialtyName}</div>
  );
}

SubspecialtyName.propTypes = {
  subspecialty: PropTypes.shape({
    subspecialtyName: PropTypes.string.isRequired,
    subspecialtyScore: PropTypes.number,
  }).isRequired,
  showScores: PropTypes.bool.isRequired,
};

function ProviderSpecialization({
  subspecialty,
  lastSubspecialty,
  featureSubspecialty,
  npi,
  showScores,
  openAll,
}) {
  const classes = useStyles();
  const subspecialties = useSelector(select.specialties.subspecialties);
  const failedToLoadSpecialties = useSelector(select.specialties.failedToLoadSpecialties);
  const loadingSpecialties = useSelector(select.specialties.loadingSpecialties);

  const [expand, setExpand] = useState(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    setExpand(openAll);
  }, [npi, openAll]);

  const subspecialtyDescription =
    subspecialties[PROVIDER_RESULT_TYPE]?.[subspecialty.subspecialtyId]?.description;

  const descriptionId = `subspecialty-${subspecialty.subspecialtyId}-description`;
  return featureSubspecialty ? (
    <>
      <Typography variant="h4" className="profile-subspecialty">
        <SubspecialtyName subspecialty={subspecialty} showScores={showScores} />
      </Typography>
      <div>{subspecialtyDescription}</div>
    </>
  ) : (
    <>
      <Grid className={classes.specializationRowContainer}>
        <Typography variant="h4" className="profile-subspecialty">
          <SubspecialtyName subspecialty={subspecialty} showScores={showScores} />
        </Typography>
        {!failedToLoadSpecialties && !loadingSpecialties && !!subspecialtyDescription && (
          <Button
            onClick={handleExpand}
            className={`${classes.expandCollapseButton} ${classes.collapseButtonWithScores}`}
            aria-controls={descriptionId}
            aria-expanded={expand ? 'true' : 'false'}
            aria-label={`${subspecialty.subspecialtyName} details`}
          >
            {expand ? <CollapseIcon /> : <ExpandIcon />}
          </Button>
        )}
      </Grid>
      <Collapse in={expand} timeout={400}>
        <div style={{ paddingBottom: '10px' }} id={descriptionId}>
          {subspecialtyDescription}
        </div>
      </Collapse>
      {!lastSubspecialty && <Divider className={classes.dividerWithScores} />}
    </>
  );
}

ProviderSpecialization.propTypes = {
  subspecialty: PropTypes.shape({
    subspecialtyName: PropTypes.string,
    subspecialtyId: PropTypes.number,
  }).isRequired,
  lastSubspecialty: PropTypes.bool.isRequired,
  featureSubspecialty: PropTypes.bool.isRequired,
  npi: PropTypes.number.isRequired,
  showScores: PropTypes.bool.isRequired,
};

function ProviderSpecializations({ subspecialties, isSubspecialtySearch, npi, openAll }) {
  const classes = useStyles();
  const [showAllSpecializations, setShowAllSpecializations] = useState(false);
  const activeSubspecialtyId = useSelector(select.results.subspecialtyId);

  const {
    config: { SHOW_SUBSPECIALTY_SCORES },
  } = useConfigValue();

  const handleShowAllSpecializations = () => {
    setShowAllSpecializations(!showAllSpecializations);
  };

  useEffect(() => {
    if (openAll) {
      setShowAllSpecializations(true);
    } else {
      setShowAllSpecializations(false);
    }
  }, [npi, openAll]);

  return (
    <div className={classes.specialtyArea}>
      {isSubspecialtySearch ? (
        <>
          <ProviderSpecialization
            key={subspecialties[0].subspecialtyId}
            subspecialty={subspecialties[0]}
            lastSubspecialty={subspecialties[0] === subspecialties[subspecialties.length - 1]}
            featureSubspecialty={subspecialties[0].subspecialtyId === activeSubspecialtyId}
            npi={npi}
            showScores={SHOW_SUBSPECIALTY_SCORES}
            openAll={openAll}
          />
          <Collapse in={showAllSpecializations} timeout={400}>
            <Divider className={subspecialties && classes.dividerWithScores} />
            {subspecialties.slice(1).map((subspecialty) => {
              const lastSubspecialty = subspecialty === subspecialties[subspecialties.length - 1];
              const featureSubspecialty =
                subspecialty === subspecialties[0] &&
                subspecialty.subspecialtyId === activeSubspecialtyId;
              return (
                <ProviderSpecialization
                  key={subspecialty.subspecialtyId}
                  subspecialty={subspecialty}
                  lastSubspecialty={lastSubspecialty}
                  featureSubspecialty={featureSubspecialty}
                  npi={npi}
                  showScores={SHOW_SUBSPECIALTY_SCORES}
                  openAll={openAll}
                />
              );
            })}
          </Collapse>
          {subspecialties.length > 1 && (
            <>
              <Divider className={classes.dividerWithScores} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  color="primary"
                  onClick={handleShowAllSpecializations}
                  className={classes.showMoreButton}
                  role="button"
                  aria-label={`${
                    showAllSpecializations ? 'show-less' : 'show-more'
                  }-specializations`}
                >
                  {showAllSpecializations ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        subspecialties.map((subspecialty) => {
          const lastSubspecialty = subspecialty === subspecialties[subspecialties.length - 1];
          return (
            <ProviderSpecialization
              key={subspecialty.subspecialtyId}
              subspecialty={subspecialty}
              lastSubspecialty={lastSubspecialty}
              featureSubspecialty={false}
              npi={npi}
              showScores={SHOW_SUBSPECIALTY_SCORES}
              openAll={openAll}
            />
          );
        })
      )}
    </div>
  );
}

ProviderSpecializations.propTypes = {
  subspecialties: PropTypes.arrayOf(
    PropTypes.shape({
      subspecialtyId: PropTypes.number.isRequired,
      subspecialtyName: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  isSubspecialtySearch: PropTypes.bool.isRequired,
  npi: PropTypes.number.isRequired,
};

export default ProviderSpecializations;
