import polkLogo from 'assets/polk-icon-only.svg';
import polkFullLogo from 'assets/polk-full.png';

import { DROPDOWN } from 'utils/constants';

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
export const POLKoverrides = {
  UPDATE_REGION_TEXT: 'Who am I?',
  HEADER_LOGO: polkLogo,
  SUPPORT_LINK: 'https://polksupport.emboldhealth.com/portal/',
  FORM_VARIANT: DROPDOWN,
  SHOW_CONTACT_BCBS_ROUTE: true,
  SCHEDULE_COPY: `Connecting you to the Blue Cross Blue Shield customer service to assist with scheduling. Click 'Call Now' to continue.`,
  SCHEDULE_PHONE_NUMBER: '855-630-6824',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Polk County Public Schools and Embold Health have partnered to bring you Embold’s Provider
  Guide, your guide to quickly and easily finding the doctors in your network and area that
  have proven to provide the best care.`,
  LOCAL_LOGO_ASSET: polkFullLogo,
  NO_WELCOME_LINK: true,
};
