import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, Paper, Typography } from '@material-ui/core';

import Colonoscopy from 'assets/colonoscopy.svg';
import Cardiology from 'assets/cardiology.svg';
import Pediatrics from 'assets/pediatrics.svg';
import PrimaryCare from 'assets/primarycare.svg';
import OrthoJoint from 'assets/orthojoint.svg';
import AllergyTest from 'assets/allergytest.svg';
import Dermatology from 'assets/dermatology.svg';
import Gastroenterology from 'assets/gastroenterology.svg';

import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { useFocusAnchorKeys } from 'utils/FocusRefContext';
import { CARE_CATEGORIES, COMMON_SEARCH_NAMES } from 'utils/constants';
import { actions, select, thunks } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    [theme.breakpoints.up('lg')]: { flex: 1 },
  },
  paper: {
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[4],
    },
  },
  image: {
    width: '100%',
    maxHeight: 90,
  },
  text: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontSize: '1rem',
  },
}));

export const quickSearches = [
  {
    img: PrimaryCare,
    text: 'Primary Care',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.PRIMARY_CARE,
    },
  },
  {
    img: Pediatrics,
    text: 'Pediatrics',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.PEDIATRICS,
    },
  },
  {
    img: Colonoscopy,
    text: 'Colonoscopy',
    payload: {
      type: CARE_CATEGORIES.SERVICE,
      name: COMMON_SEARCH_NAMES.COLONOSCOPY,
    },
  },
  {
    img: Cardiology,
    text: 'Cardiology',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.CARDIOLOGY,
    },
  },
  {
    img: OrthoJoint,
    text: 'Ortho-Joint',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.ORTHO_JOINT,
    },
  },
  {
    img: Dermatology,
    text: 'Dermatology',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.DERMATOLOGY,
    },
  },
  {
    img: Gastroenterology,
    text: 'Gastroenterology',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.GASTROENTEROLOGY,
    },
  },
  {
    img: AllergyTest,
    text: 'Allergy Test',
    payload: {
      type: CARE_CATEGORIES.SERVICE,
      name: COMMON_SEARCH_NAMES.ALLERGY_TEST,
    },
  },
];

function TopSearches() {
  const classes = useStyles();
  const searchDispatch = useSearchDispatchWithHistory();
  const dispatch = useDispatch();
  const focusAnchorKeys = useFocusAnchorKeys();

  const locationInput = useSelector(select.location.locationInput);

  const handleClick = (selectedSearch) => {
    if (locationInput) {
      searchDispatch(thunks.results.executeTopSearch(selectedSearch.payload));

      if (
        selectedSearch.payload.type === CARE_CATEGORIES.FACILITY_NAME ||
        selectedSearch.payload.type === CARE_CATEGORIES.FACILITY_TYPE
      ) {
        // TODO, this should really be handled by a useEffect in the SortMenu component
        dispatch(actions.filters.setPlaceSort('distance'));
      }
    } else {
      dispatch(
        actions.notifications.create({
          message: 'Please enter a valid address, city, or zip code',
          severity: 'info',
          duration: 7000,
          returnFocusKey: focusAnchorKeys.locationInput,
        })
      );
    }
  };

  return (
    <Grid container justifyContent="space-between" spacing={1} component={List}>
      {quickSearches.map((quickSearch, i) => (
        <Grid
          item
          component="li"
          id={quickSearch.text}
          className={classes.gridItem}
          key={quickSearch.text.concat(i)}
          xs={6}
          md={3}
        >
          <Paper
            component="div"
            tabIndex="0"
            role="button"
            ref={quickSearch.ref}
            className={classes.paper}
            title={`Search for ${quickSearch.text}`}
            onClick={() => handleClick(quickSearch)}
            onKeyPress={(e) => (e?.key === 'Enter' ? handleClick(quickSearch) : null)}
            aria-label={`Search for ${quickSearch.text}`}
          >
            <img className={classes.image} src={quickSearch?.img} alt={quickSearch.text} />
            <Typography component="span" className={classes.text}>
              {quickSearch.text}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default TopSearches;
