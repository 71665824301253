import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, MICROSOFT } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 4811089698748406a665f4f4761e43bdbcdfda67',
  staging: 'Token 6c6f3aac043a87ece9b28e5af88dbbcf04990202',
  prod: 'Token c5abc04e074368a1249aa076596f93a97c9267cb',
};

const token = selectToken(apiTokens);

export const MSFT_LOGIN_REGEX = /MSJ|YMJ|MSQ/i;

export const microsoftConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'microsoft',
  EMBOLD_CLIENT: MICROSOFT,
  EMBOLD_CLIENT_SLUG: 'msft',
  THEME: standardTheme,

  // toggles
  SHOW_DISCLAIMER: true,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  ENABLE_PLACE_SEARCH: true,

  // login
  LOGIN_COOKIE_AUTH_REGEX: MSFT_LOGIN_REGEX,
  LOGIN_NETWORK_NAME: 'msftUserId',
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},

  // other
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Seattle,
  BEST_MATCH_SORT: 'best_match',
};
