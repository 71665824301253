/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Typography, Container, Link } from '@material-ui/core';

import PageTemplate from 'components/Pages/PageTemplate';

function PrivacyPolicy() {
  return (
    <Container>
      <Typography variant="h1" className="privacy-title">
        Privacy Statement
      </Typography>

      <Typography variant="body2">
        <em>Effective: June 1, 2020</em>
      </Typography>

      <Typography variant="body2">
        <em>Latest revision: February 23, 2024</em>
      </Typography>

      <Typography variant="h2">Introduction</Typography>
      <Typography variant="body2" paragraph>
        Embold Health Inc., ("<strong>Embold Health</strong>" or "<strong>We</strong>") respect your
        privacy and are committed to protecting it.
      </Typography>
      <Typography variant="body2" paragraph>
        This Privacy Statement describes the types of information we may collect from you or that
        you may provide when you use emboldhealth.com including Embold Health Provider Guide or
        visit another website that is operated by Embold Health that displays a link to this Privacy
        Statement (collectively, the “<strong>Website</strong>”), and our practices for collecting,
        using, maintaining, protecting and disclosing that information.
      </Typography>
      <Typography variant="body2" paragraph>
        This Privacy Statement describes the types of information we collect from you or that you
        provide when you visit or communicate with us through Embold Health’s Website. The Privacy
        Statement does not apply to information collected by Embold Health offline or through any
        other means, including on any other website we operate that does not link to this Privacy
        Statement or any outside entity that may link to or be accessible from or on the Website.
      </Typography>
      <Typography variant="body2" paragraph style={{ wordWrap: 'break-word' }}>
        Please read this statement carefully to understand our policies and practices regarding your
        information and how we will treat it. If you do not agree with our practices, you may choose
        not to use our Website. By accessing or using the Website, you agree to this Privacy
        Statement, where permitted by law. You are also subject to the Embold Health Terms of Use
        available at{' '}
        <Link target="_blank" rel="noreferrer" href={window.location.origin.concat('/terms')}>
          {window.location.origin.concat('/terms')}
        </Link>{' '}
        relating to the Website, and this Privacy Statement is part of, and incorporated into, those
        Terms of Use.
      </Typography>
      <Typography variant="body2" paragraph>
        This Privacy Statement may change from time to time (see Changes to our Privacy Statement).
        Your continued use of this Website after we make changes is deemed to be acceptance of those
        changes, where permitted by law, so please check the Privacy Statement periodically for
        updates.
      </Typography>
      <Typography variant="h2">Information Subject to HIPAA</Typography>
      <Typography variant="body2" paragraph>
        Embold Health is not a “covered entity” as that term is defined in the Health Insurance
        Portability and Accountability Act (“HIPAA”). To the extent that Embold Health receives
        “protected health information” as defined by HIPAA, it does so as permitted by HIPAA, for
        example, pursuant to a Business Associate Agreement with the covered entity. To the extent
        that you wish to exercise any rights under HIPAA, you should review the Notice of Privacy
        Practices of the relevant covered entity and/or contact such entity.
      </Typography>

      <Typography variant="h2">Information We Collect About You</Typography>
      <Typography variant="body2" paragraph>
        We obtain information about you and your use of our Website when you visit our Website or
        sign up to receive communications from us. The information we collect can include Personal
        Information or non-Personal Information. “Personal Information” is any information that may
        identify, relate to, or be capable of being reasonably linked, directly or indirectly, with
        a particular identified or identifiable person or household. The Personal Information we may
        collect includes information such as your name, email address, telephone number, and other
        information identified in this Privacy Statement or that you provide to us. Personal
        Information does not include “aggregate” or other de-identified or non-personally
        identifiable information. We may retain, use and disclose aggregate information, and other
        de-identified, non-personally identifiable information, for various purposes. We collect
        information from you and any devices (including mobile devices) both when you provide it
        voluntarily and also automatically when you use the Website. In addition, we also collect
        Personal Information about you from third parties in connection with the Services, including
        from our service providers, business partners, or other third parties.
      </Typography>
      <Typography variant="body2" paragraph>
        We may collect the following information from users of our Website:
      </Typography>
      <ul>
        <li>
          <strong>Account Information.</strong> We collect Personal Information, when you register
          or access to the Provider Guide, which may include your name, address, your subscriber or
          member ID or other information{' '}
        </li>
        <li>
          <strong>Communication Information.</strong> We may collect Personal Information, such as
          contact information, or other information you provide, when you communicate with us
          through our Website, our support channels, and through email or other online services.
          Additionally, we collect your telephone number and your email address when you opt to use
          our SMS and Email Services, respectively;
        </li>
        <li>
          <strong>Search Information.</strong> We collect information about your search activities
          on the Embold Health product site available to you, including name, type, or geographic
          information of searched healthcare professionals;
        </li>
        <li>
          <strong>Chat Information.</strong> We may collect, monitor and record information you
          enter into any chat features, including our Embold Virtual Assistant. This information
          includes your name, symptoms, medication history, and any other information you choose to
          enter.
        </li>
        <li>
          <strong>Location Information.</strong> We may collect general location information or your
          geolocation if you use features on our Website that provide location-based services; and
        </li>
        <li>
          <strong>Usage Information.</strong> Information about your Internet connection, the
          equipment you use to access our Website, and usage details. For more information see the{' '}
          <strong>“Tracking Technologies”</strong> section below.{' '}
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        We collect this information when you fill in forms on our Website, including when you create
        an account, complete a survey for research purposes, or request further services, such as to
        view healthcare provider information, or otherwise communicate with us. We also may ask you
        for information when you report a problem with our Website.
      </Typography>
      <Typography variant="h2">Information We Collect Automatically</Typography>
      <Typography variant="body2" paragraph>
        As you navigate through and interact with our Website, we use automatic data collection
        technologies to collect certain information about your equipment, browsing actions and
        patterns, including your Internet protocol address, browser type, domain names, access
        times, and operating system, and details of your visits to our Website, including traffic
        data, location data, logs and other communication data, and the resources that you access
        and use on the Website. We also use these technologies to collect information about how you
        use our Website.
      </Typography>
      <Typography variant="body2" paragraph>
        We collect this information in order to improve our Website and to deliver a better and more
        personalized service, including by enabling us to:
      </Typography>
      <ul>
        <li>Estimate our audience size and usage patterns;</li>
        <li>Store information about your preferences, allowing us to customize our Website; or</li>
        <li>Recognize you when you return to our Website.</li>
      </ul>
      <Typography variant="h3">Tracking Technologies</Typography>
      <Typography variant="body2" paragraph>
        The technologies we use for this automatic data collection include:
      </Typography>
      <ul>
        <li>
          <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard
          drive of your computer. You may refuse to accept browser cookies by activating the
          appropriate setting on your browser. However, if you select this setting you may be unable
          to access certain parts of our Website. You must accept essential cookies to access Embold
          Health as a registered user. Unless you have adjusted your browser setting so that it will
          refuse cookies, our system will issue cookies when you direct your browser to our Website.
        </li>
        <li>
          <strong>Web Beacons.</strong> Pages of the Website and our e-mails contain small
          electronic files known as web beacons (also referred to as clear gifs. pixel tags and
          single-pixel gifs) that permit Embold Health, for example, to count users who have visited
          those pages or opened an e-mail and for other related website statistics (for example,
          recording the popularity of certain website content and verifying system and server
          integrity).
        </li>
        <li>
          <strong>Web Analytics.</strong> We use analytics software to track aggregate and anonymous
          user behavior on our Website.
        </li>
      </ul>

      <Typography variant="h2">How We Use Your Information</Typography>
      <Typography variant="body2" paragraph>
        We use Personal Information that we collect about you or that you provide to us:
      </Typography>
      <ul>
        <li>To present our Website and its content to you.</li>
        <li>
          To provide you with information, products or services that you request from us, including
          information about providers or facilities.
        </li>
        <li>To identify healthcare providers relevant to your search or chat inquiries,</li>
        <li>To fulfill any other purpose for which you provide it.</li>
        <li>To provide you with notices about your account.</li>
        <li>
          To notify you about changes to our Website or any products or services we offer or provide
          through it.
        </li>
        <li>In any other way we may describe when you provide the information.</li>
        <li>For any other purpose with your consent.</li>
      </ul>

      <Typography variant="h2">How We Share Your Information</Typography>
      <Typography variant="body2" paragraph>
        We may share Personal Information with third parties under the following circumstances:
      </Typography>
      <ul>
        <li>
          <strong>To our Service Providers.</strong> We use service providers to operate our
          software and infrastructure, in particular providers which host, store, manage, and
          maintain our Website, its content and the data we process. This includes hosting
          providers, communications service providers, and marketing service providers. We may share
          Personal Information with these service providers to enable them to provide services to
          us;
        </li>
        <li>
          <strong>To comply with legal obligations.</strong> We may share Personal Information to
          comply with our legal obligations, regulations or contracts, or to respond to a court
          order, administrative or judicial process, such as a subpoena, government audit or search
          warrant or in response to lawful requests by public authorities (such as national security
          or law enforcement. This Personal Information may be shared with counter-parties to
          contracts, judicial and governmental bodies
        </li>
        <li>
          <strong>To receive professional advice.</strong> We may share Personal Information to seek
          legal advice from external lawyers and advice from other professional advisers such as
          accountants, management consultants, etc.;
        </li>
        <li>
          <strong>In the course of litigation.</strong> We may disclose Personal Information if it
          is necessary to establish, exercise or defend against potential, threatened or actual
          litigation (such as adverse parties in litigation);
        </li>
        <li>
          <strong>To protect you or ourselves.</strong> We will share Personal Information where
          necessary to protect rights, property, or safety of Embold Health, our customers, your
          vital interests, such as safety and security, or those of others;
        </li>
        <li>
          <strong>To a buyer, successor of our business, or our affiliates.</strong> In connection
          with the sale, assignment or other transfer of all or part of our business (such as a
          potential purchaser and its legal / professional advisers); or
        </li>
        <li>
          <strong>With your consent.</strong> We may also share Personal Information for other
          purposes with your consent.
        </li>
        <li>
          <strong>Embold Virtual Assistant Information.</strong> We may disclose information you
          enter into our Embold Virtual Assistant (including any Personal Information in such chat
          inputs) to our service providers who assist us in providing that service. Information you
          enter into the chat may be retained for a short period of time by our service providers to
          monitor for harmful or abusive content in chat prompts. These service providers delete the
          information afterwards.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        Please note that where legal requirements limit the sharing of your data, Embold Health will
        respect such requirements.
      </Typography>

      <Typography variant="h2">Aggregate Information</Typography>
      <Typography variant="body2" paragraph>
        Additionally, Embold Health may disclose aggregated data about our users that does not
        identify any individual for various purposes. Whenever we permit a third party to access
        aggregated data, we will make sure the aggregated data are used in a manner consistent with
        this notice (and any applicable internal data handling guidelines consistent with the
        sensitive and classification of the data).
      </Typography>

      <Typography variant="h2">Your Rights and Choices Regarding Your Information</Typography>
      <Typography variant="body2" paragraph>
        You can review and change your Personal Information by sending us an e-mail to{' '}
        <Link href="mailto:privacy@emboldhealth.com">privacy@emboldhealth.com</Link> to request
        access to, correct, or delete any Personal Information that you have provided to us. In some
        cases, we may not accommodate a request to change, for example, information if we believe
        the change would violate any law or legal requirement or cause the information to be
        incorrect.
      </Typography>

      <Typography variant="body2" paragraph>
        <strong>
          <u>Marketing Preferences.</u>
        </strong>{' '}
        We support the right of visitors to choose. If we collect Personal Information that could be
        used to provide you with marketing information, you will be given the right to opt-out of
        receiving any promotional materials about Embold or its partners. If you do not opt-out at
        that time, but later decide that you do not wish to receive future communications of this
        nature, you can contact us as set forth under “Contact Information” below, indicating that
        you no longer want to receive information relating to the Website. Please allow sufficient
        time for your preferences to be processed. Even if you opt-out of receiving marketing
        messages, we may still contact you for transactional or administrative purposes like
        confirming or following up on a request or report of an event. If you later opt back into
        getting marketing communications from us, we will remove your information from our opt-out
        databases.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>
          <u>Cookies.</u>
        </strong>{' '}
        As noted above in “<strong>Information We Collect Automatically</strong>,” you can choose
        whether to accept cookies by changing the settings on your browser or device. However, if
        you choose to disable cookies, your experience with our Website may be impaired and some
        features may not work as they were intended. Some web browsers include a “do not track”
        setting. These features are not yet uniform, however, so we do not currently respond to such
        features or signals. Therefore, if you select or turn on a “do not track” feature in your
        web browser, we and our third-party providers may continue collecting information about your
        online activities as described in this Privacy Policy. You may opt-out from receiving
        personalized advertising from some third parties by visiting{' '}
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://optout.aboutads.info/?c=2&lang=EN"
          aria-label="opt-out from receiving personalized advertising from some third parties"
        >
          here
        </Link>
        . Please note that these choices will not opt you out of receiving advertisements. You will
        continue to receive generic advertisements.
      </Typography>

      <Typography variant="h2">Your California Privacy Rights</Typography>
      <Typography variant="body2" paragraph>
        Residents of the state of California have the right to request information regarding other
        companies to whom we have disclosed certain categories of information during the preceding
        year for those companies’ direct marketing purposes. At this time, Embold Health does not
        disclose any information to companies for direct marketing purposes. If you are a California
        resident and would like to make such a request, please email{' '}
        <Link href="mailto:privacy@emboldhealth.com">privacy@emboldhealth.com</Link> or write to us
        at 1801 West End Avenue, Suite 800, Nashville, TN 37203.
      </Typography>

      <Typography variant="body2" paragraph>
        The rights above are separate from rights that consumers may have under the California
        Consumer Privacy Act and other state data privacy laws. Please note that, at this time,
        Embold does not qualify as a “business” as defined by the California Consumer Privacy Act
        and does not meet the applicability requirements set forth in other state data privacy laws,
        including in Virginia, Colorado, Utah, and Connecticut, and therefore, Embold is not
        currently subject to such laws. Nonetheless, Embold respects your privacy rights, and you
        can find information about the choices you have regarding your Personal Information further
        above.
      </Typography>

      <Typography variant="h2">Our Practices Regarding Children’s Information</Typography>
      <Typography variant="body2" paragraph>
        Our Website is intended for users age 18 and older. We do not knowingly collect information
        from children. If we discover that we have inadvertently collected information from anyone
        younger than the age of 18, we will delete that information.
      </Typography>

      <Typography variant="h2">Intended for Users in the United States</Typography>
      <Typography variant="body2" paragraph>
        Our Website is provided and operated from the United States and are intended only for users
        in the United States. By using the Website, you acknowledge and agree to the transfer of
        information to the United States and to the application of the laws of the United States.
      </Typography>

      <Typography variant="h2">Do Not Track</Typography>
      <Typography variant="body2" paragraph>
        Do Not Track is a privacy preference that users can set in their web browsers. When a user
        turns on the Do Not Track signal, the browser sends a message to websites requesting them
        not to track the user. Currently, Embold Health does not respond to Do Not Track browser
        settings or signals. In addition, we deploy cookies, web beacons, and other technologies on
        our Website. Those tools may be used by us, our customers, and third parties to collect
        information about you and your Internet activity, even if you have turned on the Do Not
        Track signal.
      </Typography>

      <Typography variant="h2">Data Security and Retention</Typography>
      <Typography variant="body2" paragraph>
        We have implemented measures designed to secure your Personal Information from accidental
        loss and from unauthorized access, use, alteration, and disclosure. All Personal Information
        you provide to us is stored on our secure servers behind firewalls.
      </Typography>
      <Typography variant="body2" paragraph>
        Unfortunately, the transmission of information via the Internet is not completely secure.
        While data is encrypted during the transmission or use of our Website and although we do our
        best to protect your Personal Information, we cannot guarantee the security of your Personal
        Information transmitted to our Website. Any transmission of personal information is at your
        own risk. We are not responsible for circumvention of any privacy settings or security
        measures contained on the Website.
      </Typography>
      <Typography variant="body2" paragraph>
        Embold Health will retain your Personal Information in accordance with our document
        retention policies and for the length of time required by applicable law.
      </Typography>

      <Typography variant="h2">External Links</Typography>
      <Typography variant="body2" paragraph>
        This Privacy Statement does not apply to the practices of third parties that we do not own
        or control, including but not limited to any third party websites, services and applications
        that are linked or made available on our Website (“<strong>Third Party Services</strong>”).
        We do not take responsibility for the content, actions, or privacy policies of those Third
        Party Services. We encourage you to carefully review the terms of use and privacy policies
        of any Third Party Services you access.
      </Typography>

      <Typography variant="h2">External Links</Typography>
      <Typography variant="body2" paragraph>
        This Privacy Statement does not apply to the practices of third parties that we do not own
        or control, including but not limited to any third party websites, services and applications
        that are linked or made available on our Website (“<strong>Third Party Services</strong>”).
        We do not take responsibility for the content, actions, or privacy policies of those Third
        Party Services. We encourage you to carefully review the terms of use and privacy policies
        of any Third Party Services you access.
      </Typography>

      <Typography variant="h2">Changes to Our Privacy Statement</Typography>
      <Typography variant="body2" paragraph>
        It is our policy to post any changes we make to our Privacy Statement on this page. If we
        make material changes to how we treat our users’ personal information, we will notify you
        through a notice on the Website’s home page. The date the Privacy Statement was last revised
        is identified at the top of the page. You are responsible for periodically visiting our
        Website and this Privacy Statement to check for any changes.
      </Typography>

      <Typography variant="h2">Contact Information</Typography>
      <Typography variant="body2" paragraph>
        To ask questions or comment about this privacy policy and our privacy practices, contact us
        at <Link href="mailto:privacy@emboldhealth.com">privacy@emboldhealth.com</Link>.
      </Typography>
      <Typography variant="h4" component="p" align="center">
        Copyright 2020 | &copy; Embold Health
      </Typography>
      <Typography variant="h4" component="p" align="center">
        1801 West End Avenue, Suite 800, Nashville, TN 37203
      </Typography>
      <Typography variant="h4" component="p" align="center">
        <Link target="_blank" rel="noreferrer" href="https://emboldhealth.com/privacy-statement/">
          Privacy Statement
        </Link>
      </Typography>
      <Typography variant="h4" component="p" align="center">
        <Link target="_blank" rel="noreferrer" href="https://emboldhealth.com/terms-of-use/">
          Terms of Use
        </Link>
      </Typography>
    </Container>
  );
}

export default PageTemplate(PrivacyPolicy, 'Privacy Statement');
