import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import clsx from 'clsx';
import PropTypes, { PlaceDataShape, ProviderLocationDataShape } from 'propTypes';
import { uniqueId } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Box,
  Divider,
} from '@material-ui/core/';
import { RoomOutlined, PhoneOutlined, Accessible } from '@material-ui/icons';

import { VALID_HEADING_VARIANTS, distance } from 'utils/utils';

import PaginationController from 'components/PaginationController';
import AddressLink from 'components/Profile/AddressLink';
import CheckOrXIcon from 'icons/CheckOrXIcon';
import HealthPlanDisclaimer from 'components/HealthPlanDisclaimer';
import MiniMap from './MiniMap';
import RoundedWrapper from './ResultCards/StyledWrappers/RoundedWrapper';

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    margin: `${theme.spacing(1)}px 0`,
    justifyContent: 'space-between',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    height: 25,
    marginRight: theme.spacing(1),
  },
  contactContainer: {
    alignItems: 'flex-start',
    '& li': {
      padding: '6px 0',
    },
  },
  fitDetailsToOneColumn: {
    paddingRight: '2rem',
    '& $disclaimer': {
      paddingLeft: 32,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  mapContainer: {
    borderRadius: theme.shape.borderRadius * 4,
    overflow: 'hidden',
    flex: 1,
    minWidth: '33%',
    maxWidth: 500,
    height: 250,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  map: {
    width: '100%',
    height: 250,
  },
  disclaimer: {
    textWrap: 'balance',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 32,
    },
  },
  cardTitle: {
    margin: 0,
  },
  cardSubtitle: {
    color: theme.palette.darkGray,
    paddingLeft: 10,
  },
}));

export default function ContactCard({
  locations,
  title,
  headingLevel,
  className,
  showMap,
  showDisclaimer,
  onAddressClick,
  onPhoneClick,
  isPrintMode,
}) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const resultLatLong = useSelector(select.results.coordinates);
  const locationLatLong = useSelector(select.location.latLong);
  const locationInput = useSelector(select.results.locationInput);
  const { latitude, longitude } = resultLatLong || locationLatLong; // prefer result coordinates when available, fallback to locationSlice

  const locationDistancesArray = useMemo(
    () => locations.map((loc) => distance(loc.latitude, loc.longitude, latitude, longitude)),
    [locations, latitude, longitude]
  );

  const handleMarkerClick = useCallback(
    (evt) => {
      const newIndex = evt.shapes?.[0]?.id;
      if (newIndex >= 0 && newIndex < locations.length) {
        setCurrentIndex(newIndex);
      }
    },
    [locations.length, setCurrentIndex]
  );

  const currentLocation = useMemo(() => locations[currentIndex], [locations, currentIndex]);
  const cardSubtitle = locationInput ? `(from ${locationInput})` : undefined;

  if (!locations.length || !locations[currentIndex]) return null;

  if (isPrintMode) {
    return (
      <RoundedWrapper additionalStyles={className} grey>
        <ContactCardTitle title={title} subtitle={cardSubtitle} headingLevel={headingLevel} />
        <HealthPlanDisclaimer className={classes.disclaimer} />
        {locations.map((loc, i) => (
          <div key={uniqueId()}>
            <Divider />
            <ContactCardInfo
              fitDetailsToOneColumn={false}
              location={loc}
              distanceToLocation={locationDistancesArray[i]}
              onAddressClick={onAddressClick}
              onPhoneClick={onPhoneClick}
              showDisclaimer={false}
            />
          </div>
        ))}
      </RoundedWrapper>
    );
  }

  return (
    <RoundedWrapper additionalStyles={className} grey>
      {/* Card header */}
      <div className={classes.headerRow}>
        <ContactCardTitle title={title} subtitle={cardSubtitle} headingLevel={headingLevel} />

        {locations.length > 1 && (
          <PaginationController
            index={currentIndex}
            length={locations.length}
            label="Location"
            setIndex={setCurrentIndex}
          />
        )}
      </div>

      {/* Card content */}
      <Grid container classes={{ root: classes.containerRoot }}>
        {/* Contact info */}
        <Grid
          item
          xs={12}
          md={showMap ? 6 : 12}
          className={clsx(classes.contactContainer, {
            [classes.fitDetailsToOneColumn]: showMap,
          })}
        >
          <ContactCardInfo
            fitDetailsToOneColumn={showMap}
            location={currentLocation}
            distanceToLocation={locationDistancesArray[currentIndex]}
            onAddressClick={onAddressClick}
            onPhoneClick={onPhoneClick}
            showDisclaimer={showDisclaimer}
          />
        </Grid>

        {/* Map */}
        {showMap && (
          <Grid item xs={12} md={6}>
            <Box className={classes.mapContainer}>
              <MiniMap
                locations={locations}
                selectedIndex={currentIndex}
                onMarkerClick={handleMarkerClick}
                className={classes.map}
                options={{ interactive: true, zoom: 10 }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </RoundedWrapper>
  );
}

function ContactCardInfo({
  location,
  distanceToLocation,
  fitDetailsToOneColumn,
  onAddressClick,
  onPhoneClick,
  showDisclaimer,
}) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={fitDetailsToOneColumn ? 12 : 6}>
        {/* The MUI list styling provides layout but inaccurrate semantic HTML. Roles disabled for a11y */}
        <List role="none" disablePadding>
          <ListItem disableGutters role="none">
            <RoomOutlined className={classes.icon} />
            <div>
              <AddressLink address={location} multiline onClick={onAddressClick} />
              <Typography variant="caption">{distanceToLocation} miles</Typography>
            </div>
          </ListItem>

          {Boolean(location.phone) && (
            <ListItem disableGutters role="none">
              <PhoneOutlined className={classes.icon} />
              <Link href={`tel:${location.phone}`} onClick={onPhoneClick}>
                {location.phone}
              </Link>
            </ListItem>
          )}

          {location.acceptingNewPatients !== undefined && (
            <ListItem disableGutters role="none">
              <CheckOrXIcon checked={location.acceptingNewPatients} className={classes.icon} />
              <ListItemText>
                {location.acceptingNewPatients
                  ? 'Accepting New Patients'
                  : 'Not Accepting Patients'}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={12} md={fitDetailsToOneColumn ? 12 : 6}>
        {location.isWheelchairAccessible && (
          <List role="none" disablePadding>
            <ListItem disableGutters role="none">
              <Accessible className={classes.icon} />
              <ListItemText>Handicap Accessible</ListItemText>
            </ListItem>
          </List>
        )}

        {showDisclaimer && <HealthPlanDisclaimer className={classes.disclaimer} />}
      </Grid>
    </Grid>
  );
}

function ContactCardTitle({ title, subtitle, headingLevel }) {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Typography variant="h3" component={headingLevel} classes={{ root: classes.cardTitle }}>
        {title}
      </Typography>
      <Typography component="span" classes={{ root: classes.cardSubtitle }}>
        {subtitle}
      </Typography>
    </Grid>
  );
}

ContactCard.propTypes = {
  locations: PropTypes.oneOfType([
    PropTypes.arrayOf(PlaceDataShape),
    PropTypes.arrayOf(ProviderLocationDataShape),
  ]).isRequired,
  title: PropTypes.string,
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS),
  className: PropTypes.string,
  showMap: PropTypes.bool,
  showDisclaimer: PropTypes.bool,
  onPhoneClick: PropTypes.func.isRequired,
  onAddressClick: PropTypes.func.isRequired,
  isPrintMode: PropTypes.bool,
};

ContactCard.defaultProps = {
  title: 'Contact',
  headingLevel: 'h4',
  className: undefined,
  showMap: false,
  showDisclaimer: false,
  isPrintMode: false,
};

ContactCardInfo.propTypes = {
  location: PropTypes.oneOfType([PlaceDataShape, ProviderLocationDataShape]).isRequired,
  distanceToLocation: PropTypes.number.isRequired,
  fitDetailsToOneColumn: PropTypes.bool,
  onAddressClick: PropTypes.func.isRequired,
  onPhoneClick: PropTypes.func.isRequired,
  showDisclaimer: PropTypes.bool,
};

ContactCardInfo.defaultProps = {
  fitDetailsToOneColumn: false,
  showDisclaimer: false,
};

ContactCardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  headingLevel: PropTypes.string,
};

ContactCardTitle.defaultProps = {
  headingLevel: undefined,
  subtitle: undefined,
};
