import React from 'react';
import PropTypes from 'propTypes';
import { select } from 'store/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';

import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import CardIconButton from '../StyledWrappers/CardIconButton';

function PlaceCallButton({ placeId }) {
  const dispatch = useDispatch();
  const placeData = useSelector(select.place(placeId).data);

  const handlePhoneClick = (e) => {
    e.stopPropagation();
    document.location.href = `tel:${placeData.phoneNumber}`;

    dispatch(_analyticsActions.phoneClickFromResultCard(placeId));
  };

  return <CardIconButton text="Call" onClick={handlePhoneClick} icon={<CallOutlinedIcon />} />;
}

export default PlaceCallButton;

PlaceCallButton.propTypes = {
  placeId: PropTypes.string.isRequired,
};
