/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';

function SampleModalContent() {
  const [extraContent, setExtraContent] = useState(false);
  return (
    <>
      <Typography variant="body1" id="styleguide-modal-description">
        Use the button to see how the modal responds to child element width
      </Typography>
      {extraContent && (
        <Typography variant="body1">
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      )}
      <Button
        style={{ display: 'inline-block' }}
        variant="outlined"
        color="primary"
        onClick={() => setExtraContent((prev) => !prev)}
      >
        {extraContent ? 'Hide' : 'Show'} extra content
      </Button>
    </>
  );
}

export default SampleModalContent;
