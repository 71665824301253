import React, { useCallback, useRef } from 'react';
import PropTypes, { SubspecialtyDataShape } from 'propTypes';
import { useDispatch } from 'react-redux';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { actions } from 'store/toolkit';
import { SCORE_COLORS_BY_TIER } from 'utils/constants';
import useFocusAnchors from 'utils/FocusRefContext';
import useSubspecialtyExplanation from 'hooks/Provider/useSubspecialtyExplanation';
import useThemeColor from 'hooks/useThemeColor';

const useStyles = makeStyles((theme) => ({
  link: {
    ...theme.typography.subtitle2,
    verticalAlign: 'bottom',
    color: theme.palette.text.primary, // this color gets overridden by an inline style when available
  },
}));

export default function DynamicFocusAreaText({ subspecialty, isActive }) {
  const { subspecialtyName, subspecialtyScoreTier } = subspecialty;
  const classes = useStyles();
  const link = useRef(null);
  const focusAnchors = useFocusAnchors();
  const dispatch = useDispatch();

  const scoreColorName = SCORE_COLORS_BY_TIER[subspecialtyScoreTier];
  const linkColor = useThemeColor(scoreColorName);

  const tooltipMessage = useSubspecialtyExplanation(subspecialty);

  const openTooltip = useCallback(() => {
    focusAnchors.tooltip = link;
    dispatch(actions.tooltip.open({ title: subspecialtyName, message: tooltipMessage }));
  }, [dispatch, focusAnchors, subspecialtyName, tooltipMessage]);

  // plain text subspecialty
  if (!isActive) {
    return (
      <Typography component="span" variant="body1">
        {subspecialtyName}
      </Typography>
    );
  }

  // matching subspecialty with no tooltip
  if (!tooltipMessage) {
    return (
      <Typography component="strong" variant="subtitle2">
        {subspecialtyName}
      </Typography>
    );
  }

  // matching subspecialty with clickable tooltip
  return (
    // lint rule is disabled as MUI allows for <Link> to be used as <button>
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      component="button"
      color="primary"
      className={classes.link}
      ref={link}
      onClick={openTooltip}
      style={{ color: linkColor }}
    >
      {subspecialtyName}
    </Link>
  );
}

DynamicFocusAreaText.propTypes = {
  subspecialty: SubspecialtyDataShape.isRequired,
  isActive: PropTypes.bool,
};

DynamicFocusAreaText.defaultProps = {
  isActive: false,
};
