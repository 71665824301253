import { createSelector } from '@reduxjs/toolkit';
import { DROPDOWN, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import { CONFIG_SLICE_NAME } from '../slicesNames';

export const all = (state) => state[CONFIG_SLICE_NAME].values.REGION_SELECTOR_CODES;
export const currentSlug = (state) => state[CONFIG_SLICE_NAME].currentNetworkSlug;

export const availableSlugs = createSelector([all], (networks) => Object.keys(networks));

export function bySlug(networkSlug) {
  return createSelector([all], (networks) => networks[networkSlug]);
}

export function configBySlug(networkSlug) {
  return createSelector([all], (networks) => networks[networkSlug].config);
}

// eslint-disable-next-line arrow-body-style
export const networkInputVariant = createSelector([all], (networks) => {
  // determines if network selector should be autcomplete or dropdown
  if (!networks) return null;

  let hasNetworkGroupName = false;
  for (const network in networks) {
    if (networks[network].networkGroupName) {
      hasNetworkGroupName = true;
      break;
    }
  }
  return hasNetworkGroupName ? DROPDOWN_AUTOCOMPLETE : DROPDOWN;
});

/**
 * @typedef {Object} DefaultLocation
 * @property {String} city
 * @property {String} state
 * @property {Number} latitude
 * @property {Number} longitude
 */

/**
 * Returns the default location for a given network slug
 * @param {String} networkSlug
 * @returns {DefaultLocation}
 */
export function defaultLocationBySlug(networkSlug) {
  return createSelector(
    [all, (state) => state[CONFIG_SLICE_NAME].values.DEFAULT_LOCATION],
    (networks, clientDefault) => {
      const networkConfig = networks[networkSlug].config;
      const hasValidNetworkDefaultLocation =
        networkConfig &&
        networkConfig.DEFAULT_LOCATION_CITY &&
        networkConfig.DEFAULT_LOCATION_STATE &&
        networkConfig.DEFAULT_LOCATION_LAT &&
        networkConfig.DEFAULT_LOCATION_LONG;

      if (!hasValidNetworkDefaultLocation)
        return {
          city: clientDefault.city,
          state: clientDefault.state,
          latitude: clientDefault.lat,
          longitude: clientDefault.long,
        };

      return {
        city: networkConfig.DEFAULT_LOCATION_CITY,
        state: networkConfig.DEFAULT_LOCATION_STATE,
        latitude: networkConfig.DEFAULT_LOCATION_LAT,
        longitude: networkConfig.DEFAULT_LOCATION_LONG,
      };
    }
  );
}

export const currentNetwork = createSelector(
  [currentSlug, all],
  (slug, networks) => networks[slug]
);
export const currentConfig = createSelector(
  [currentSlug, all],
  (slug, networks) => networks[slug].config
);
