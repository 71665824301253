import emboldWatermark from 'assets/pwrd-emboldhealth-footer.svg';

export default function formatPrintContent(ref) {
  const clonedRef = ref.current.cloneNode(true);
  clonedRef.classList.add('printedComponent');
  const PrintElem = document.createElement('div');
  const watermark = `<div class="page-footer"><img src="${emboldWatermark}" alt="powered by embold health" class="watermark"/></div>`;
  PrintElem.innerHTML = watermark;
  PrintElem.appendChild(clonedRef);
  return PrintElem;
}
