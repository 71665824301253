import React from 'react';
import PropTypes from 'propTypes/index';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

import CollapsablePromotion from './CollapsablePromotion';

function PromotionBySpecialty({ specialtyId, subspecialtyId }) {
  const selectedPromotion = useSelector(
    select.promotions.fromSpecialtyIds(specialtyId, subspecialtyId)
  );
  if (!selectedPromotion) {
    return null;
  }

  return <CollapsablePromotion promotion={selectedPromotion} />;
}

PromotionBySpecialty.propTypes = {
  specialtyId: PropTypes.number.isRequired,
  subspecialtyId: PropTypes.number,
};

PromotionBySpecialty.defaultProps = {
  subspecialtyId: null,
};

export default PromotionBySpecialty;
