import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConfigValue } from 'config/state/index';
import { actions, select } from 'store/toolkit';
import {
  ICUBA,
  MICROSOFT,
  SHOW_BOARD_CERTIFIED,
  SHOW_CLIENT_FEATURED,
  SHOW_TELEHEALTH,
  SHOW_WHEELCHAIR_ACCESSIBLE,
  SURGERY_PLUS,
  WALMART,
} from 'utils/constants';
import { useStateValue } from 'state/index';

/**
 * There are several parts of the filtersSlice and resultsSlice filters that need to be set after client configuration loads.
 * Certain filters need to be disabled based on feature flags.
 * Certain filters need to have labels updated.
 * This hook watches necessary values and updates the filtersSlice as needed.
 */
export default function useInitializeFilters() {
  const dispatch = useDispatch();
  const [{ networkSlug }] = useStateValue();
  const {
    loadingClientConfig,
    config: {
      SHOW_OUTCARE,
      EMBOLD_CLIENT,
      SHOW_PROVIDER_CREDENTIALS_FILTER,
      FEATURED_PROVIDER_BANNER_TEXT,
      DEFAULT_SEARCH_RADIUS,
    },
    getNetworkConfigValue,
  } = useConfigValue();
  const showTelehealth = getNetworkConfigValue(networkSlug, SHOW_TELEHEALTH);
  const showBoardCertified = getNetworkConfigValue(networkSlug, SHOW_BOARD_CERTIFIED);
  const showWheelchairAccessible = getNetworkConfigValue(networkSlug, SHOW_WHEELCHAIR_ACCESSIBLE);
  const showClientFeatured = getNetworkConfigValue(networkSlug, SHOW_CLIENT_FEATURED);

  const radius = useSelector(select.filters.radius);

  const filtersToEnable = useMemo(() => {
    const filters = {};

    // feature flagged filters
    filters.telehealthAvailable = showTelehealth;
    filters.featured = showClientFeatured;
    filters.boardCertified = showBoardCertified;
    filters.outcareCompetent = SHOW_OUTCARE;
    filters.isWheelchairAccessible = showWheelchairAccessible;
    filters.credentials = SHOW_PROVIDER_CREDENTIALS_FILTER;

    // client specific filter overrides
    switch (EMBOLD_CLIENT) {
      case WALMART:
        filters.highPerforming = true;
        filters.inNetworkPreferred = true;
        break;
      case MICROSOFT:
        if (networkSlug === 'ymj') filters.healthConnectPlan = true;
        break;
      case ICUBA:
        filters.hasBenefitDiff = true;
        break;
      case SURGERY_PLUS:
        filters.featured = false;
        filters.excludeClientFeatured = true;
        break;
      default:
        break;
    }
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkSlug, loadingClientConfig]);

  const labelsToUpdate = useMemo(() => {
    const filterLabels = {};

    if (FEATURED_PROVIDER_BANNER_TEXT) {
      filterLabels.featured = FEATURED_PROVIDER_BANNER_TEXT;
      filterLabels.excludeClientFeatured = `Exclude ${FEATURED_PROVIDER_BANNER_TEXT}`;
    }
    return filterLabels;
  }, [FEATURED_PROVIDER_BANNER_TEXT]);

  // Enable filters based on config values
  useEffect(() => {
    if (!loadingClientConfig) {
      dispatch(
        actions.filters.updateFromClientConfig({
          enabled: filtersToEnable,
          labels: labelsToUpdate,
          radius: radius || DEFAULT_SEARCH_RADIUS,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkSlug, loadingClientConfig]);
}
