import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import HospitalSafetyGradeBadge from './PlaceBadges/HospitalSafetyGradeBadge';
import CoverageBadge from './PlaceBadges/CoverageBadge';

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
    '& > *': { flex: 1, maxWidth: '50%' },
  },
}));

export default function PlaceBadges({ placeId }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <HospitalSafetyGradeBadge placeId={placeId} />
      <CoverageBadge placeId={placeId} />
    </Grid>
  );
}

PlaceBadges.propTypes = {
  placeId: PropTypes.string.isRequired,
};
