/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'propTypes/index';
import { useQueryParams } from 'use-query-params';
import { select, thunks } from 'store/toolkit';

import queryParamConfig from 'hooks/queryString/queryParamConfig';
import useUpdateTitle from 'hooks/useUpdateTitle';
import { CARE_CATEGORIES } from 'utils/constants';

import ProfileSkeleton from '../../LoadingSkeletons/ProfileSkeleton';
import PlaceProfile from './PlaceProfile';
import ProfileError from '../ProfileError';

export default function PlaceProfilePage({ id }) {
  const [error, setError] = useState(false);
  const [fetchingProfile, setFetchingProfile] = useState(true);
  const dispatch = useDispatch();
  const resultIds = useSelector(select.results.idList);
  const placeData = useSelector(select.place(resultIds[0]).data);

  const [{ location, location_input, city, state, network_slug }] =
    useQueryParams(queryParamConfig);
  useUpdateTitle(placeData?.name || 'Facility Profile');

  useEffect(() => {
    dispatch(
      thunks.results.fetchProfileFromUrl({
        location,
        location_input,
        city,
        state,
        network_slug,
        entity_id: id,
        care_category: CARE_CATEGORIES.FACILITY_NAME,
      })
    )
      .unwrap()
      .catch(() => setError(true))
      .finally(() => setFetchingProfile(false));
  }, [city, dispatch, location, location_input, network_slug, id, state]);

  if (error || (!fetchingProfile && resultIds?.length === 0))
    return (
      <ProfileError
        heading="No Results"
        text="We were unable to find a facility that matched your search
      criteria. Here are a couple of solutions that might be able to help."
        buttons={['newsearch', 'refresh']}
      />
    );

  return fetchingProfile ? (
    <ProfileSkeleton isPlaceProfile />
  ) : (
    <PlaceProfile placeId={resultIds[0]} />
  );
}

PlaceProfilePage.propTypes = {
  id: PropTypes.string.isRequired,
};
