import surgeryPlusHeader from 'assets/surgery_plus_header.png';
import surgerPlusLogo from 'assets/surgery_plus_logo.png';

import { CHECKMARK } from 'utils/constants';

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
export const surgeryPlusOverrides = {
  SCHEDULE_PHONE_NUMBER: '800-555-1234',
  HEADER_LOGO: surgeryPlusHeader,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  WELCOME_MESSAGE:
    'Surgery Plus and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: CHECKMARK,
  LOCAL_LOGO_ASSET: surgerPlusLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};
