import React from 'react';
import PropTypes from 'propTypes';
import { List } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { useMetricsList } from 'hooks/Provider/useProviderMetrics';
import Metric from '../../Metric';

function ProviderMetricList({ providerId, MetricProps = {}, ...props }) {
  const providerData = useSelector(select.provider(providerId).data);
  const metricsList = useMetricsList(providerData);

  if (!Array.isArray(metricsList) || !metricsList.length) return null;

  return (
    <List disablePadding {...props}>
      {metricsList.map((metric, i) => (
        <Metric
          listItem
          marginBottom={i < metricsList.length - 1}
          key={metric.key}
          icon={metric.icon}
          title={metric.title}
          TooltipProps={{ message: metric.description }}
          {...MetricProps}
        >
          {metric.text || ''}
        </Metric>
      ))}
    </List>
  );
}

ProviderMetricList.propTypes = {
  className: PropTypes.string,
  providerId: PropTypes.string.isRequired,
  MetricProps: PropTypes.shape({}),
};

ProviderMetricList.defaultProps = {
  className: '',
  MetricProps: {},
};

export default React.memo(ProviderMetricList);
