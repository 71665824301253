/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';

import historyIcon from 'assets/history_icon.svg';
import geoTagIcon from 'assets/geotag_icon_gray.svg';

function renderSuggestion(suggestionProps, fromHistory) {
  const { itemProps, isHighlighted, inputValue, labelText, key } = suggestionProps;

  return (
    <MenuItem {...itemProps} key={key} selected={isHighlighted} component="div">
      <span>
        <img
          src={fromHistory ? historyIcon : geoTagIcon}
          style={{
            height: 20,
            width: 20,
            marginRight: 8,
            verticalAlign: 'bottom',
          }}
          alt=""
        />
      </span>

      <span
        style={{
          display: 'inline-block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {highlightSearchTerms(inputValue, labelText)}
      </span>
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  itemProps: PropTypes.shape({
    'aria-selected': PropTypes.bool,
    id: PropTypes.string,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    role: PropTypes.string,
  }).isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

// @TODO: figure out how to abstract this function since it's redefined in multiple places (logic is the same but output is different)
const highlightSearchTerms = (searchString, originalString) => {
  if (!get(originalString, 'length')) return;

  if (!searchString.length) {
    return originalString;
  }

  const index = originalString.toLowerCase().indexOf(searchString.toLowerCase());

  if (index === -1) {
    return originalString;
  }

  const highlightedString = (
    <span>
      {index > 0 && originalString.slice(0, index)}
      <b>{originalString.slice(index, index + searchString.length)}</b>
      {originalString.slice(index + searchString.length)}
    </span>
  );

  return highlightedString;
};

const AutocompleteSuggestions = ({
  suggestions,
  getItemProps,
  highlightedIndex,
  inputValue,
  showLocationHistory,
}) =>
  suggestions.map((suggestion, index) =>
    renderSuggestion(
      {
        itemProps: getItemProps({
          item: suggestion,
        }),
        key: suggestion.id,
        isHighlighted: index === highlightedIndex,
        inputValue,
        labelText: suggestion.label || suggestion.locationInput,
      },
      showLocationHistory
    )
  );

AutocompleteSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      locationInput: PropTypes.string,
    })
  ),
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  inputValue: PropTypes.string,
  showLocationHistory: PropTypes.bool,
};

export default AutocompleteSuggestions;
