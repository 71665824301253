import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Fab, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select, thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: `0px ${theme.spacing(3)}px`,
    height: 35,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  label: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export default function SearchThisAreaButton(props) {
  const classes = useStyles();
  const isValidSearchInput = useSelector(select.search.isValidSearchInput);
  const mapWasMovedByUser = useSelector(select.map.hasMoved);
  const mapCenter = useSelector(select.map.center);
  const hasActiveMapMarker = useSelector(select.results.hasActiveMapMarker);
  const searchDispatch = useSearchDispatchWithHistory();

  const handleSearchThisArea = useCallback(() => {
    searchDispatch(thunks.results.searchThisArea({ mapCenter }));
  }, [searchDispatch, mapCenter]);

  const showButton = isValidSearchInput && mapWasMovedByUser && !hasActiveMapMarker;

  return (
    <Slide in={showButton} direction="down">
      <Fab onClick={handleSearchThisArea} variant="extended" classes={classes} {...props}>
        Search This Area
      </Fab>
    </Slide>
  );
}
