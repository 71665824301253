import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useStateValue } from 'state';
import { select, actions } from 'store/toolkit';
import SearchForm from 'components/ModernExperience/SearchForm';
import Button from '@material-ui/core/Button';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    minHeight: '160px',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    '& h2, & h3': {
      margin: 0,
      color: theme.palette.primary.main,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  updateSearchBanner: {
    minHeight: '60px',
    flexDirection: 'row',
  },
  bannerHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    color: theme.palette.primary.main,
  },
  goBackButton: {
    position: 'absolute',
    paddingLeft: 0,
    left: 0,
    color: theme.palette.primary.main,
  },
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    goBackButton: {
      '& svg': {
        fill: 'white',
      },
    },
  },
}));

function MobileSearchPage() {
  const classes = useStyles();
  const [
    {
      resultType,
      results,
      promotion,
      resultCount,
      filterCounts,
      activeResult,
      clusterTabs,
      activeTab,
    },
    setState,
  ] = useStateValue();

  const locationInput = useSelector(select.location.locationInput);
  const latLong = useSelector(select.location.latLong);
  const locationComponents = useSelector(select.location.locationComponents);

  const [cachedSearchSlice] = useState(useSelector(select.search.slice)); // cache the entire searchSlice on page mount
  const [initialSearchState, setInitialSearchState] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBack = () => {
    setState({ ...initialSearchState });
    // if back was pressed, then no search was performed, and we want to return the searchSlice to it's previous cached state
    dispatch(actions.search.overwriteSlice(cachedSearchSlice));
    history.goBack();
  };

  useEffect(() => {
    setInitialSearchState({
      locationInput,
      latLong,
      locationComponents,
      resultType,
      results,
      promotion,
      resultCount,
      filterCounts,
      activeResult,
      clusterTabs,
      activeTab,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ background: 'white', paddingBottom: 40 }}>
      <Container
        component="section"
        maxWidth="md"
        id="mobile-search-update"
        className={`${classes.banner} ${classes.updateSearchBanner} banner`}
      >
        <Button onClick={handleBack} className={classes.goBackButton}>
          <LeftArrowIcon />
          Back
        </Button>
        <div className={`${classes.bannerHeader} ${classes.updateSearchHeader} banner-header`}>
          Update Search
        </div>
      </Container>
      <Container maxWidth="md" id="mobile-landing-container" style={{ margin: '1.5rem auto' }}>
        <SearchForm mobileView showClearAll autoSearch />
      </Container>
    </div>
  );
}

export default MobileSearchPage;
