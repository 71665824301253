import React from 'react';
import { FilterMenuProps } from 'propTypes/index';
import { Typography, Chip } from '@material-ui/core';
import RadiusSlider from './RadiusSlider';

function PlaceFilterMenu({ classes, chipOpen, handleCloseChip }) {
  return (
    <div className={classes.modalContent}>
      <div>
        <div>
          <Typography className={classes.distanceLabel} component="strong">
            Distance in Miles{' '}
            {chipOpen && ': ' && (
              <Chip
                className={classes.chip}
                label="CUSTOM"
                aria-label="Custom search area"
                onDelete={handleCloseChip}
              />
            )}
          </Typography>
          <div className={classes.sliderContainer}>
            <RadiusSlider chipOpen={chipOpen} />
          </div>
        </div>
      </div>
    </div>
  );
}

PlaceFilterMenu.propTypes = FilterMenuProps;

export default PlaceFilterMenu;
