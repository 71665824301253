import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import Attribute from '../../Attribute';

const useStyles = (networkTextColor) =>
  makeStyles((theme) => ({
    networkText: {
      color: theme.palette[networkTextColor],
    },
  }))();

function PlaceAttributeList({ placeId }) {
  const networkContent = useSelector(select.place(placeId).networkContent);
  const classes = useStyles(networkContent?.textColor);

  if (!networkContent?.showNetworkStatus) return null;

  return (
    <Attribute
      isListItem={false}
      text={networkContent.networkText}
      icon={networkContent.networkIcon}
      TypographyProps={{
        style: {
          fontWeight: 'bold',
        },
        className: classes.networkText,
      }}
      additionalIconClass={classes.networkText}
    />
  );
}

PlaceAttributeList.propTypes = {
  placeId: PropTypes.string.isRequired,
};

export default React.memo(PlaceAttributeList);
