/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CSSTransition } from 'react-transition-group';

import { select, actions } from 'store/toolkit';
import { useFocusTo } from 'utils/FocusRefContext';
import { useStateValue } from 'state';
import { useConfigValue } from 'config/state';
import { getCookie, getLocalStorageExpire, setCookie, setLocalStorageExpire } from 'utils/utils';
import { INTRO_MODAL_VERSION } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.cookiebar-transition-enter': {
      marginBottom: '-100%',
    },
    '.cookiebar-transition-enter-active, .cookiebar-transition-enter-done': {
      transition: 'margin-bottom linear 800ms',
      marginBottom: 0,
    },
    '.cookiebar-transition-exit': {
      marginBottom: 0,
    },
    '.cookiebar-transition-exit-active': {
      marginBottom: '-100%',
      transition: 'margin-bottom linear 800ms',
    },
  },
  cookieBar: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    padding: '12px 16px',
    background: 'rgba(0,0,0,0.8)',
    maxHeight: '55vh',
    overflow: 'scroll',
    zIndex: theme.zIndex.tooltip,
    [theme.breakpoints.up('md')]: {
      padding: '12px 40px',
    },
  },
  cookieBarText: {
    color: 'white',
    lineHeight: 1.4,
    paddingRight: 25,
    '& a': {
      color: 'white',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      display: 'inline-block',
      paddingRight: 0,
    },
  },
  buttonWrap: {
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      float: 'right',
      width: '40%',
      marginTop: 0,
    },
  },
  button: {
    margin: '0 10px 10px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
    '& span': {
      fontSize: '1rem',
    },
  },
  decline: {
    background: 'white',
    '&:hover': {
      background: 'white',
      boxShadow: '0 0 10px rgba(0,0,0,1)',
    },
    '&:focus': {
      outline: 'none',
      '&:before': {
        content: '""',
        position: 'absolute',
        border: `2px solid ${theme.palette.lightBlue}`,
        top: 2,
        left: 2,
        right: 2,
        bottom: 2,
        borderRadius: 50,
      },
    },
  },
  accept: {
    background: theme.palette.lightBlue,
    '&:hover': {
      background: theme.palette.lightBlue,
      boxShadow: '0 0 10px rgba(0,0,0,1)',
    },
  },
  iconButton: {
    color: 'white',
    position: 'absolute',
    top: 65,
    right: 0,
    '&:focus': {
      outlineColor: 'white',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 0,
    },
  },
}));

function CookieAgreement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const focusTo = useFocusTo();
  const [{ showLogin }] = useStateValue();
  const showCookieBanner = useSelector(select.ui.showCookieBanner);

  useEffect(() => {
    if (getCookie('cookiesAccepted') === 'true') {
      return;
    }
    dispatch(actions.ui.showCookieBanner());
  }, [dispatch]);

  function rejectCookies() {
    dispatch(actions.ui.hideCookieBanner());
  }

  function acceptCookies() {
    // set login and intro cookies
    setCookie('cookiesAccepted', 'true', 3, true);
    // hide banner
    dispatch(actions.ui.hideCookieBanner());
  }

  const slideIn = showCookieBanner && !showLogin;

  return (
    <CSSTransition
      in={slideIn}
      timeout={800}
      classNames="cookiebar-transition"
      unmountOnExit
      onExiting={focusTo.logo}
    >
      <aside className={`${classes.cookieBar} cookiebar`}>
        <Typography variant="body1" className={`${classes.cookieBarText} cookiebar-text`}>
          This website stores cookies on your computer to improve your experience on the site. To
          find out more about the cookies we use, see our{' '}
          <Link to="/privacy">Privacy Statement</Link>.
        </Typography>
        <div className={`${classes.buttonWrap} cookiebar-button-wrap`}>
          <Button
            onClick={acceptCookies}
            variant="contained"
            color="secondary"
            className={`${classes.button} ${classes.accept} cookiebar-button-accept`}
          >
            Accept
          </Button>
          <Button
            onClick={rejectCookies}
            variant="outlined"
            color="secondary"
            className={`${classes.button} ${classes.decline} cookiebar-button-decline`}
          >
            Decline
          </Button>
          <IconButton
            aria-label="close"
            onClick={rejectCookies}
            className={`${classes.iconButton} cookiebar-icon-button-close`}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </aside>
    </CSSTransition>
  );
}

export default CookieAgreement;
