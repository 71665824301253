import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select, thunks } from 'store/toolkit';
import SingleProviderSpecialtyButton from './SingleProviderSpecialtyButton';
import SkeletonList from './LoadingSkeletons/SkeletonList';
import ResultCardList from './ResultCardList';

const useStyles = makeStyles((theme) => ({
  loadMoreRoot: {
    marginBottom: theme.spacing(1),
    minHeight: 35,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  loadMoreLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    marginRight: 15,
  },
}));

export default function ResultsList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const view = useSelector(select.ui.cardView);
  const resultIds = useSelector(select.results.idList);
  const paginationLink = useSelector(select.results.paginationLink);
  const wasSingleProviderSearch = useSelector(select.results.wasSingleResultSearch);
  const wasProviderSearch = useSelector(select.results.wasProviderSearch);
  const isLoading = useSelector(select.results.isLoading);
  const isLoadingMore = useSelector(select.results.isLoadingMore);

  if (!resultIds.length && !isLoading) return null;

  return (
    <>
      {!isLoading && (
        <>
          <div className="screenReaderText">
            <h2>{wasProviderSearch ? 'Provider' : 'Place'} Results Section</h2>
          </div>
          {wasSingleProviderSearch && <SingleProviderSpecialtyButton />}
          <ResultCardList view={view} resultIds={resultIds} />
          {Boolean(paginationLink) && (
            <Button
              onClick={() => dispatch(thunks.results.loadMoreResults())}
              variant="outlined"
              classes={{ root: classes.loadMoreRoot, label: classes.loadMoreLabel }}
              disabled={isLoadingMore}
            >
              {isLoadingMore && <CircularProgress size={25} className={classes.circularProgress} />}
              Load More
            </Button>
          )}
        </>
      )}
      {/* always render the skeleton list so the animation is smooth */}
      <SkeletonList view={view} isVisible={isLoading || isLoadingMore} />
    </>
  );
}
