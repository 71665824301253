import { makeStyles } from '@material-ui/core';
import { APP_MAIN_TAG_ID } from 'utils/constants';
import React, { useEffect, useRef, useState } from 'react';
import { FOOTER_HEIGHT, GO_BACK_BAR_HEIGHT, HEADER_HEIGHT } from './constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  sidebar: {
    overflowY: 'scroll',
    paddingBottom: '2rem',
  },
  sidebarFixed: {
    position: 'fixed',
  },
}));

const SIDEBAR_PADDING_TOP = 10;

function StickySidebar({ children, smDown }) {
  const classes = useStyles();
  const [columnDimensions, setColumnDimensions] = useState([0, 0]);
  const [fixedSidebar, setFixedSidebar] = useState(false);

  const FIXED_SIDEBAR_TOP = HEADER_HEIGHT + GO_BACK_BAR_HEIGHT + SIDEBAR_PADDING_TOP;

  const wrapperRef = useRef(null);

  useEffect(() => {
    const updateColumnDimensions = () => {
      const height = window.innerHeight - HEADER_HEIGHT - FOOTER_HEIGHT - GO_BACK_BAR_HEIGHT;
      const width = wrapperRef?.current.clientWidth;
      setColumnDimensions([width, height]);
    };
    updateColumnDimensions();

    const scrollWrapper = document.getElementById(APP_MAIN_TAG_ID);
    const updateFixedSidebar = () => {
      const isSidebarOffscreen =
        wrapperRef?.current.getBoundingClientRect().top < FIXED_SIDEBAR_TOP;
      setFixedSidebar(isSidebarOffscreen);
    };
    updateFixedSidebar();

    window.addEventListener('resize', updateColumnDimensions);
    scrollWrapper.addEventListener('scroll', updateFixedSidebar);

    return () => {
      window.removeEventListener('resize', updateColumnDimensions);
      scrollWrapper.removeEventListener('scroll', updateFixedSidebar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const innerColStyles = smDown
    ? {}
    : {
        width: columnDimensions[0],
        height: columnDimensions[1],
        top: fixedSidebar ? FIXED_SIDEBAR_TOP : 0,
      };

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <div
        style={innerColStyles}
        className={`${classes.sidebar} ${fixedSidebar && classes.sidebarFixed}`}
      >
        {children}
      </div>
    </div>
  );
}

export default StickySidebar;
