import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'whatwg-fetch';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { QueryParamProvider } from 'use-query-params';
import HideIfClosed from 'components/HideIfClosed';
import { StateProvider, reducer } from 'state';
import { ConfigProvider } from 'config/state';
import { initialState } from 'state/initial';

import ScrollToTop from 'components/ScrollToTop';
import ErrorBoundary from 'components/ErrorBoundary';
import Config from 'config';

import { getEnv, getClient, detectWebGLSupport } from 'utils/utils';
import fetchSpecialties from 'fetch/fetchSpecialties';
import { thunks } from 'store/toolkit';
import store from './store/store';
import App from './App';

const env = getEnv();
const client = getClient();
const params = new URLSearchParams(window.location.search);
const token = params.get('token');
const network = params.get('network');

if (params.has('token')) {
  localStorage.setItem('integrationToken', token);
  params.delete('token');
  window.history.replaceState(null, null, window.location.pathname + params.toString());
}

if (params.has('network')) {
  localStorage.setItem('mpiNetwork', network);
  params.delete('network');
  window.history.replaceState(null, null, window.location.pathname + params.toString());
}

if (env !== 'local') {
  Sentry.init({
    dsn: 'https://4acc2d0cb9ba4af586739550b525ea43@sentry.io/1875299',
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_GIT_HASH,
    environment: `${env}`,
    tracesSampleRate: env !== 'production' ? 0.1 : 1.0,
  });
  Sentry.setTag('client', `${client}`);
}

if (!detectWebGLSupport()) {
  Sentry.captureException(new Error('WebGL not supported by browser'));
}

store.dispatch(thunks.config.fetchClientConfig({ network }));

// prefetch the specialties and deploy to redux
fetchSpecialties(store.dispatch);

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <ThemeProvider theme={Config.THEME}>
    <CssBaseline />
    <Provider store={store}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <ConfigProvider>
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <ErrorBoundary>
                <LastLocationProvider>
                  <ScrollToTop>
                    <HideIfClosed>
                      <App />
                    </HideIfClosed>
                  </ScrollToTop>
                </LastLocationProvider>
              </ErrorBoundary>
            </QueryParamProvider>
          </Router>
        </ConfigProvider>
      </StateProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
