import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Typography, Container, Grid, Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';

import { select } from 'store/toolkit';
import emboldLogo from 'assets/embold-login-logo.svg';
import { useConfigValue } from 'config/state';
import useClientImages from 'hooks/useClientImages';
import LoginForm from 'components/Forms/Login/LoginForm';
import useUpdateTitle from 'hooks/useUpdateTitle';
import { DEFAULT_DOCUMENT_TITLE, SOCT, SOCT_PROVIDER_LOOKUP } from 'utils/constants';
import WelcomeMessage from './WelcomeMessage';

const useStyles = makeStyles((theme) => ({
  container: {
    // the grey container that wraps the logos and all content
    backgroundColor: '#E7E7E7',
    width: '100%',
    maxWidth: 'none',
    flex: 1, // grow to the whole height of the page
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '92%',
      flex: 1, // once at SM or above do NOT grow to whole height of page
      padding: theme.spacing(4),
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      borderRadius: 30,
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    '& img': {
      height: 50, // set height, allow width to auto scale to not warp image
      maxWidth: '95%', // will force logos to shrink on the smallest of screens *probably not seen often
      [theme.breakpoints.up('sm')]: { height: 75 },
      [theme.breakpoints.up('md')]: { height: 90 },
      [theme.breakpoints.up('lg')]: { height: 110 },
    },
  },
  innerContainer: {
    // the white container surrounding the text and form
    display: 'flex',
    maxWidth: 1200,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      borderRadius: 30,
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: { marginTop: theme.spacing(7) },
    [theme.breakpoints.up('lg')]: { marginTop: theme.spacing(10) },
  },
  welcomeContainer: {
    '& h1': {
      fontWeight: 500,
      fontSize: 32,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      '& h1': { fontSize: 45 },
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
    width: '100%',
  },
  formContainer: {
    padding: theme.spacing(5),
    borderRadius: 25,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  loginHeader: {
    marginBottom: 20,
    fontWeight: 400,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      marginTop: 25,
    },
  },
  addIcon: {
    margin: `0px ${theme.spacing(1)}px`,
  },
  inputContainer: {
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .MuiInputBase-root': { width: '100%' }, // Dropdown
    '& label': {
      fontSize: '.75rem',
      paddingLeft: theme.spacing(0.5),
      color: theme.palette.primary.main,
      fontWeight: `bold`,
      display: 'block',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  p: {
    paddingBottom: 20,
    textAlign: 'left',
    fontSize: 16,
  },
}));

export default function LoginPage() {
  const classes = useStyles();

  const displayName = useSelector(select.config.displayName);

  const {
    config: {
      EMBOLD_CLIENT,
      CONTENT: { ADD_ICON, EMBOLD_LOGO_PRESENT, NO_WELCOME_LINK },
    },
  } = useConfigValue();
  const clientImages = useClientImages();

  useUpdateTitle(`${DEFAULT_DOCUMENT_TITLE} - Log In`);

  return (
    <Container className={classes.container} id="login-page">
      <Grid container className={classes.imageContainer}>
        <img src={clientImages.login} alt={displayName.concat(' logo')} />
        {ADD_ICON && <AddIcon fontSize="large" className={classes.addIcon} />}
        {EMBOLD_LOGO_PRESENT && <img src={emboldLogo} alt="Embold health logo" />}
      </Grid>

      <Grid container item className={classes.innerContainer}>
        {/* Text Content */}
        <Grid item md={6} className={classes.welcomeContainer}>
          {/* It is with much shame and humiliation that I add this line of code. If any other client would
          like to exclude this section of the login page as well we should make it a client config attribute */}
          {EMBOLD_CLIENT !== SOCT && EMBOLD_CLIENT !== SOCT_PROVIDER_LOOKUP && (
            <Typography variant="h1" component="h1">
              Welcome to <br />
              Provider Guide
            </Typography>
          )}
          <WelcomeMessage />

          {!NO_WELCOME_LINK && (
            <Typography className={classes.p}>
              {'Click '}
              <Link
                href="https://emboldstatichosting.blob.core.windows.net/external/EmboldHealth_WhitePaper_MeasureWhatMatter_20220331.pdf"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Click here to learn more about the Embold Approach"
              >
                here
              </Link>
              {' to find out more about Embold’s method.'}
            </Typography>
          )}
        </Grid>
        <Grid item md={6} className={classes.formContainer}>
          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  );
}
