/* eslint-disable import/no-cycle */
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Config from 'config';
import { buildDynamicConfig } from 'config/dynamicConfig';
import * as Sentry from '@sentry/react';
import { isValidUrl } from 'utils/utils';
import { MAX_RETRIES, delayByAttempt } from 'utils/fetchRetryDelay';

export const ConfigContext = createContext();

export function ConfigProvider({ children }) {
  const [config, setConfig] = useState(Config);
  const [loadingClientConfig, setLoadingClientConfig] = useState(true);
  const [clientConfigError, setClientConfigError] = useState(false);

  const mpiNetwork = localStorage.getItem('mpiNetwork');

  const networks = config.REGION_SELECTOR_CODES;
  const stringifiedNetworks = JSON.stringify(networks);

  useEffect(() => {
    let host = window.location?.host?.includes('localhost')
      ? 'walmart.finder.testing'
      : window.location?.host;

    if (window.location?.host?.includes('feature-three')) {
      host = 'demo.finder.testing';
    } else if (window.location?.host?.includes('feature-two')) {
      host = 'microsoft.finder.testing';
    } else if (window.location?.host?.includes('feature-one')) {
      host = 'walmart.finder.testing';
    }
    const client = host.split('.')?.[0];

    const isValidHttpUrl = (string) => {
      const url = new URL(string);
      return isValidUrl(url.href) && (url.protocol === 'http:' || url.protocol === 'https:');
    };

    const getConfig = (attempts = 0) => {
      // setLoadingClientConfig(true);
      // eslint-disable-next-line no-param-reassign
      attempts += 1;
      const env = ['testing', 'staging', 'localhost'].reduce((acc, val) => {
        if (host.includes(val)) {
          // eslint-disable-next-line no-param-reassign
          acc = val;
        }
        return acc;
      }, 'production');
      const containerEnvMap = {
        testing: 'testing',
        localhost: 'testing',
        staging: 'staging',
        default: 'testing',
        production: '',
      };
      const containerEnv = containerEnvMap[env] ?? 'testing';
      const url = `https://static.${
        containerEnv + (env === 'production' ? '' : '.')
      }emboldhealth.com/client-config/${client}.json`;
      if (isValidHttpUrl(url)) {
        fetch(url)
          .then((fusionConfigPromise) => fusionConfigPromise.json())
          .then((fusionConfigOverrides) => {
            const { dynamicConfig, dynamicContentConfig } =
              buildDynamicConfig(fusionConfigOverrides);

            setConfig({
              ...config,
              ...dynamicConfig,
              CONTENT: { ...config.CONTENT, ...dynamicContentConfig },
            });
            setLoadingClientConfig(false);
          })
          .catch((err) => {
            if (attempts - 1 < MAX_RETRIES) {
              Sentry.captureMessage(
                `Failed to fetch Client Config. Attempting again. Error: ${err}`
              );
              console.error(`Failed to fetch Client Config. Attempting again. Error: ${err}`);
              delayByAttempt(attempts - 1).then(() => getConfig(attempts));
            } else {
              Sentry.captureException(
                new Error(`Unable to fetch Client Config after ${attempts} attempts. Error: ${err}`)
              );
              console.error(
                `Unable to fetch Client Config after ${attempts} attempts. Error: ${err}`
              );
              setLoadingClientConfig(false);
              setClientConfigError(true);
            }
          });
      } else {
        Sentry.captureException('Unable to fetch client config: Invald Url');
        console.error('Unable to fetch client config: Invald Url');
        setLoadingClientConfig(false);
      }
    };

    getConfig();
  }, []);

  useEffect(() => {
    if (config.HAS_PORTAL_INTEGRATION && Object.keys(networks).length > 1 && networks[mpiNetwork]) {
      setConfig({ ...config, SHOW_REGION_SELECTOR: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedNetworks, mpiNetwork]);

  const getNetworkConfigValue = useMemo(
    () => (networkSlug, value) =>
      networks[networkSlug]?.config?.[value] ?? (config.CONTENT[value] || config[value]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedNetworks]
  );

  const getDefaultLocation = useMemo(
    () => (networkSlug) => {
      const networkConfig = networks[networkSlug]?.config;
      const hasValidNetworkDefaultLocation =
        networkConfig &&
        networkConfig.DEFAULT_LOCATION_CITY &&
        networkConfig.DEFAULT_LOCATION_STATE &&
        networkConfig.DEFAULT_LOCATION_LAT &&
        networkConfig.DEFAULT_LOCATION_LONG;

      const defaultLocation = config.DEFAULT_LOCATION;

      if (hasValidNetworkDefaultLocation) {
        defaultLocation.city = networkConfig.DEFAULT_LOCATION_CITY;
        defaultLocation.state = networkConfig.DEFAULT_LOCATION_STATE;
        defaultLocation.lat = networkConfig.DEFAULT_LOCATION_LAT;
        defaultLocation.long = networkConfig.DEFAULT_LOCATION_LONG;
      }

      return defaultLocation;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedNetworks]
  );

  const value = useMemo(
    () => ({
      config,
      loadingClientConfig,
      clientConfigError,
      getNetworkConfigValue,
      getDefaultLocation,
    }),
    [config, getNetworkConfigValue, loadingClientConfig, clientConfigError, getDefaultLocation]
  );

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
}

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useConfigValue = () => useContext(ConfigContext);
