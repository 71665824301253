import React from 'react';
import { FilterMenuProps } from 'propTypes/index';
import { useSelector } from 'react-redux';
import { Toolbar, FormGroup, FormLabel, FormControl, Typography, Chip } from '@material-ui/core';
import { select } from 'store/toolkit';

import SwitchFilter from './SwitchFilter';
import CheckFilterGroup from './CheckFilterGroup';

import RadiusSlider from './RadiusSlider';

function ProviderFilterMenu({ classes, chipOpen, handleCloseChip }) {
  const showExclusions = useSelector(select.filters.showExclusions); // true if any exclusion filter is not disabled

  return (
    <div className={classes.modalContent}>
      <Toolbar
        style={{
          width: '100%',
          padding: 0,
        }}
      >
        <Typography variant="h2" id="filter-modal-title" className={classes.toolbarHeader}>
          Filters
        </Typography>
      </Toolbar>
      <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <FormControl
            component="fieldset"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <FormGroup row>
              <Typography component="legend" variant="srOnly">
                Provider Filters
              </Typography>
              {/* There is no need to conditionally render switches. The SwitchFilter will render null if filter is disabled in Redux */}
              <SwitchFilter filter="highPerforming" />
              <SwitchFilter filter="inNetworkPreferred" />
              <SwitchFilter filter="hasBenefitDiff" />
              <SwitchFilter filter="healthConnectPlan" />
              <SwitchFilter filter="acceptingNewPatients" />
              <SwitchFilter filter="telehealthAvailable" />
              <SwitchFilter filter="boardCertified" />
              <SwitchFilter filter="outcareCompetent" />
              <SwitchFilter filter="isWheelchairAccessible" />
              <SwitchFilter filter="featured" />
            </FormGroup>
          </FormControl>
        </div>

        <div>
          <Typography className={classes.distanceLabel} component="strong">
            Distance in Miles{' '}
            {chipOpen && ': ' && (
              <Chip
                className={classes.chip}
                label="CUSTOM"
                aria-label="Custom search area"
                onDelete={handleCloseChip}
              />
            )}
          </Typography>
          <div className={classes.sliderContainer}>
            <RadiusSlider chipOpen={chipOpen} />
          </div>
        </div>

        <CheckFilterGroup filter="specialties" hideAfter={4} />
        <CheckFilterGroup filter="gender" />
        <CheckFilterGroup filter="languages" hideAfter={4} />
        <CheckFilterGroup filter="credentials" hideAfter={4} />
        {
          // EXCLUSIONS
          showExclusions && (
            <FormGroup component="fieldset" style={{ display: 'block' }}>
              <FormLabel component="legend" className={classes.filterLabel}>
                Exclude
              </FormLabel>
              <SwitchFilter filter="excludeClientFeatured" />
            </FormGroup>
          )
        }
      </div>
    </div>
  );
}

ProviderFilterMenu.propTypes = FilterMenuProps;

export default ProviderFilterMenu;
