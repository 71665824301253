import { createSlice } from '@reduxjs/toolkit';

import { getEnv, getLocalStorageExpire } from 'utils/utils';
import { handleNetworkChange, updateStoreFromUrl } from 'store/appActions';
import { userLoggedIn } from 'store/appThunks';
import { fetchShare } from 'store/slices/results/resultsThunks';
import { LOGIN_NETWORK_NAME_VERSION } from 'utils/constants';
import { CONFIG_SLICE_NAME } from '../slicesNames';
import defaultConfig from './localConfigurations/defaultConfig';
import configThunks from './configThunks';
import getClientConfig from './localConfigurations/getClientConfig';

const localClientConfig = getClientConfig();
const combinedLocalConfig = { ...defaultConfig, ...localClientConfig };

const loginRegex = new RegExp(...combinedLocalConfig.LOGIN_COOKIE_AUTH_REGEX);
const cookieValue = getLocalStorageExpire(
  combinedLocalConfig.LOGIN_NETWORK_NAME,
  LOGIN_NETWORK_NAME_VERSION
);
const validLoginCookie = loginRegex.test(cookieValue);

let localStorageNetworkSlug = null;
if (combinedLocalConfig.LOGIN_NETWORK_NAME === 'msftUserId') {
  const memberId = validLoginCookie ? cookieValue : null;
  localStorageNetworkSlug = validLoginCookie ? memberId?.substring(0, 3)?.toLowerCase() : null;
} else {
  localStorageNetworkSlug = validLoginCookie ? cookieValue : null;
}

const initialState = {
  isLoading: false,
  error: null,
  currentNetworkSlug: localStorageNetworkSlug,
  values: {
    ...combinedLocalConfig,
  },
};

// setup local, testing and staging specifics
const env = getEnv();
if (env === 'local' || env === 'testing') {
  initialState.values.API_URL =
    process.env.REACT_APP_API_URL || 'https://fusion.dev.emboldhealth.com/api/pg';
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;
  initialState.values.FUSION_ENABLED = true;
  if (process.env.REACT_APP_API_TOKEN) {
    initialState.values.API_TOKEN = process.env.REACT_APP_API_TOKEN;
  }
} else if (env === 'staging') {
  initialState.values.API_URL = 'https://fusion.staging.emboldhealth.com/api/pg';
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;
  initialState.values.FUSION_ENABLED = true;
}

const configSlice = createSlice({
  name: CONFIG_SLICE_NAME,
  initialState,
  reducers: {
    setNetworkSlug(state, action) {
      state.currentNetworkSlug = action.payload;
    },
    overrideValue(state, action) {
      if (process.env.NODE_ENV === 'development') {
        state.values = { ...state.values, ...action.payload };
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(configThunks.fetchClientConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(configThunks.fetchClientConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.values = { ...state.values, ...action.payload };

        // now check that our current network slug is actually a valid networkSlug
        const { REGION_SELECTOR_CODES } = action.payload;
        const availableNetworks = Object.keys(REGION_SELECTOR_CODES);
        if (!availableNetworks.includes(state.currentNetworkSlug)) {
          // if current slug is invalid, clear it
          state.currentNetworkSlug = null;
        } else {
          // hide region selector when SSO is used
          state.values.SHOW_REGION_SELECTOR = false;
        }
      })
      .addCase(configThunks.fetchClientConfig.rejected, (state, action) => {
        state.isLoading = false;
        // error message could be passed with rejectWithValue which puts the message in the action.payload
        // OR errors can be thrown which puts them in action.error
        state.error = action.payload || action.error?.message || 'Unknown Error';
      });

    builder.addCase(handleNetworkChange, (state, action) => {
      const networkSlug = action.payload;
      state.currentNetworkSlug = networkSlug;
    });

    builder.addCase(userLoggedIn.fulfilled, (state, action) => {
      const { networkSlug } = action.payload;
      state.currentNetworkSlug = networkSlug;
    });

    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const { network_slug } = action.payload;

      const validSlugs = Object.keys(state.values?.REGION_SELECTOR_CODES || {});
      if (network_slug && validSlugs.includes(network_slug)) {
        state.currentNetworkSlug = network_slug;
      }
    });

    builder.addCase(fetchShare.fulfilled, (state, action) => {
      const { networkSlug } = action.payload;

      state.currentNetworkSlug = networkSlug;
    });
  },
});

export default configSlice;
export const { setNetworkSlug } = configSlice.actions;
