import React from 'react';
import PropTypes from 'propTypes';

import { withStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import select from 'store/select';

function H3Link({ classes, resultId, children, LinkProps = {}, TypographyProps = {} }) {
  const profileLink = useSelector(select.results.profileDetailsLink(resultId));

  return (
    <Link classes={classes} href={profileLink} target="_blank" rel="noreferrer" {...LinkProps}>
      <Typography variant="h3" id={`result-title-${resultId}`} {...TypographyProps}>
        {children}
      </Typography>
    </Link>
  );
}

/**
 * A styled wrapper for provider title - "Modern Experience Larger Provider Cards"
 */
const ResultTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:visited': { color: theme.palette.primary.main },
    '& h3': {
      fontSize: '1.125rem', // 18px,
      margin: 0,
    },
  },
}))(H3Link);

export default React.memo(ResultTitle);

H3Link.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  resultId: PropTypes.string.isRequired,
  LinkProps: PropTypes.shape({}),
  TypographyProps: PropTypes.shape({}),
};

H3Link.defaultProps = {
  classes: {},
  LinkProps: {},
  TypographyProps: {},
};
