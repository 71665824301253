import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AzureMapLayerProvider } from 'react-azure-maps';
import { useSelector, useDispatch } from 'react-redux';

import { select, actions, constants } from 'store/toolkit';
import { getMapBounds } from 'store/slices/map/mapUtils';

const CLUSTER_LAYER_ID = 'result-cluster-layer';
const CLUSTER_COUNT_LAYER_ID = 'result-cluster-count-layer';
const ACTIVE_CLUSTER_LAYER_ID = 'active-result-cluster-layer';
const ACTIVE_CLUSTER_COUNT_LAYER_ID = 'active-result-cluster-count-layer';
const MAX_CLUSTER_LEAVES = 50;

/** This component renders two layers. The first layer is used to display a bubble for a cluster. The second layer is used to display the total data points within that cluster. */
export default function ClusterBubbleLayer({ dataProviderId }) {
  const [hoveredClusterId, setHoveredClusterId] = useState(null);
  const isSmDown = useSelector(select.ui.isSmDown);
  const selectedId = useSelector(select.results.selectedId);
  const hoveredId = useSelector(select.results.hoveredId);
  const clusterMarkerId = useSelector(select.results.clusterMarkerId);
  const dispatch = useDispatch();

  const handleClusterClicked = useCallback(
    async (evt) => {
      // validate that the nested properties exist on the event
      if (!(evt && evt.shapes && evt.shapes.length > 0 && evt.shapes[0].properties.cluster)) return;

      // Get the clustered point from the event.
      const cluster = evt.shapes[0];
      const [clusterLong, clusterLat] = cluster.geometry.coordinates;
      const clusterId = cluster.properties.cluster_id;
      const clusterData = await evt.map.sources.getById(dataProviderId); // clusterData api docs here: https://learn.microsoft.com/en-us/azure/azure-maps/clustering-point-data-web-sdk#enabling-clustering-on-a-data-source

      // Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      const expansionZoom = await clusterData.getClusterExpansionZoom(clusterId);
      const leaves = await clusterData.getClusterLeaves(clusterId, MAX_CLUSTER_LEAVES);
      const coordinates = [];
      const resultIds = [];
      leaves.forEach((leaf) => {
        const leafLat = leaf.data?.geometry?.coordinates?.[1];
        const leafLong = leaf.data?.geometry?.coordinates?.[0];
        const resultId = leaf.data?.properties?.resultId;

        // Only push leaves with valid data.
        if (resultId && leafLat && leafLong) {
          resultIds.push(resultId);
          coordinates.push({ latitude: leafLat, longitude: leafLong });
        }
      });

      // If there is room to zoom into the map, move the bounds of the map to fit all points in the cluster.
      if (expansionZoom < constants.map.MAX_ZOOM_LEVEL) {
        dispatch(
          actions.map.expandCluster({
            coordinates,
            offset: isSmDown,
          })
        );
        // If we cannot zoom in any further, show all results in the selected cluster.
      } else {
        const bounds = getMapBounds(evt.map.getCamera());
        const mapHeight = evt.map.getMapContainer()?.clientHeight;
        dispatch(
          actions.app.showClusterResults({
            clusterId,
            resultIds,
            bounds,
            mapHeight,
            latitude: clusterLat,
            longitude: clusterLong,
          })
        );
      }
    },
    [dataProviderId, dispatch, isSmDown]
  );

  const clusterLayerEvents = useMemo(
    () => ({
      mouseover: (e) => {
        e.map.getCanvasContainer().style.cursor = 'pointer';

        if (!isSmDown) setHoveredClusterId(e.shapes[0].properties?.cluster_id);
      },
      mouseleave: (e) => {
        e.map.getCanvasContainer().style.cursor = 'grab';
        if (!isSmDown) setHoveredClusterId(null);
      },
      mousemove: (e) => {
        if (!isSmDown) setHoveredClusterId(e.shapes[0].properties?.cluster_id);
      },
      click: handleClusterClicked,
    }),
    [isSmDown, handleClusterClicked]
  );

  const filterIncludeActive = useMemo(
    () => [
      'all',
      ['has', 'point_count'],
      [
        'any',
        ['==', ['id'], clusterMarkerId],
        ['in', selectedId, ['get', 'resultIds']],
        ['in', hoveredId, ['get', 'resultIds']],
        ['==', ['id'], hoveredClusterId],
      ], // is cluster marker id AND selected result
    ],
    [clusterMarkerId, selectedId, hoveredId, hoveredClusterId]
  );

  const filterExcludeActive = useMemo(
    () => [
      'all',
      ['has', 'point_count'],
      ['!=', ['id'], clusterMarkerId],
      ['!', ['in', selectedId, ['get', 'resultIds']]],
      ['!', ['in', hoveredId, ['get', 'resultIds']]],
    ],
    [clusterMarkerId, selectedId, hoveredId]
  );

  /* Available layer options can be found here: https://samples.azuremaps.com/bubble-layer/bubble-layer-options */
  /* Docs for the data expressions can be found here: https://learn.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk#data-expressions */
  // cluster bubble layers
  const clusterLayerOptions = useMemo(
    () => ({
      color: ['case', ['==', ['id'], hoveredClusterId], '#1A73AA', '#132257'],
      filter: filterExcludeActive,
      iconOptions: {
        image: 'none',
      },
      strokeWidth: 0,
      radius: ['step', ['get', 'point_count'], 18, 3, 20, 5, 22, 7, 24, 10, 26],
      opacity: 1,
    }),
    [hoveredClusterId, filterExcludeActive]
  );
  const activeClusterLayerOptions = useMemo(
    () => ({
      color: '#1A73AA',
      filter: filterIncludeActive,
      iconOptions: {
        image: 'none',
      },
      strokeWidth: 0,
      radius: ['step', ['get', 'point_count'], 18, 3, 20, 5, 22, 7, 24, 10, 26],
      opacity: 1,
    }),
    [filterIncludeActive]
  );

  // count layers
  const countLayerOptions = {
    filter: filterExcludeActive, // only include data points that has the "point_count" property
    iconOptions: {
      image: 'none',
      allowOverlap: true,
    },
    textOptions: {
      textField: ['get', 'point_count_abbreviated'], // use the "point_count_abbreviated" property of the cluster for display
      offset: [0, 0.4],
      color: 'white',
    },
  };
  const activeCountLayerOptions = {
    filter: filterIncludeActive, // only include data points that has the "point_count" property
    iconOptions: {
      image: 'none',
      allowOverlap: true,
    },
    textOptions: {
      textField: ['get', 'point_count_abbreviated'], // use the "point_count_abbreviated" property of the cluster for display
      offset: [0, 0.4],
      color: 'white',
    },
  };
  return (
    <>
      {/* Provides the circular "bubble" */}
      <AzureMapLayerProvider
        id={CLUSTER_LAYER_ID}
        type="BubbleLayer"
        options={clusterLayerOptions}
        events={clusterLayerEvents}
      />
      {/* Provides the numeric count on top of the "bubble" */}
      <AzureMapLayerProvider
        id={CLUSTER_COUNT_LAYER_ID}
        type="SymbolLayer"
        options={countLayerOptions}
      />
      {/* Renders active cluster above inactive clusters */}
      <AzureMapLayerProvider
        id={ACTIVE_CLUSTER_LAYER_ID}
        type="BubbleLayer"
        options={activeClusterLayerOptions}
      />
      {/* Provides active numeric count on top all other layers */}
      <AzureMapLayerProvider
        id={ACTIVE_CLUSTER_COUNT_LAYER_ID}
        type="SymbolLayer"
        options={activeCountLayerOptions}
      />
    </>
  );
}

ClusterBubbleLayer.propTypes = {
  dataProviderId: PropTypes.string.isRequired,
};
