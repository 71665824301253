import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Typography } from '@material-ui/core';

import { select } from 'store/toolkit';
import SubmitButton from '../SubmitButton';
import CheckboxInput from '../CheckboxInput';
import AgreeToTermsLabel from './AgreeToTermsLabel';
import { CHECKBOX_ERROR_TEXT, UNABLE_TO_GET_NETWORK } from './errorText';
import FieldErrorText from '../FieldErrorText';

export default function CheckmarkLogin({ classes, onSubmit }) {
  const slugs = useSelector(select.networks.availableSlugs);

  // in checkmark login variants, there should only be ONE available slug
  const initialValues = {
    networkSlug: slugs[0] || '', // this is a hidden field but it's needed to pass to our login submission
    termsChecked: false,
  };

  const validationSchema = Yup.object({
    networkSlug: Yup.string().oneOf(slugs, UNABLE_TO_GET_NETWORK).required(UNABLE_TO_GET_NETWORK),
    termsChecked: Yup.bool().oneOf([true], CHECKBOX_ERROR_TEXT),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form className={classes.root}>
        <CheckboxInput name="termsChecked" label={<AgreeToTermsLabel />} />
        {/* This would should an error if for any reason the network slug wasn't there. This should NEVER happen */}
        <FieldErrorText name="networkSlug" />
        <SubmitButton className={classes.submitButton}>Continue</SubmitButton>
      </Form>
    </Formik>
  );
}

CheckmarkLogin.propTypes = {
  classes: PropTypes.shape({
    submitButton: PropTypes.string,
    root: PropTypes.string,
    loginHeader: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
