import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { AzureMapDataSourceProvider, AzureMapFeature, AzureMapPopup } from 'react-azure-maps';

import { select, actions } from 'store/toolkit';

/** This component creates a data provider with the results from Redux. It then renders children layers that can access that data that was added to the provider */
export default function ResultDataProvider({ dataProviderId, children }) {
  const dispatch = useDispatch();
  const resultLocations = useSelector(select.results.mapFeatures);
  const [focusedScreenReaderText, setFocusedScreenReaderText] = useState(null); // this is used to track which screen reader text element is focused

  useEffect(() => {
    if (resultLocations.length > 0) {
      dispatch(actions.map.updateBoundsToFitResults({ coordinates: resultLocations }));
    }
  }, [dispatch, resultLocations]);

  const handleScreenReaderTextClick = useCallback(
    ({ resultId, latitude, longitude }) => {
      dispatch(actions.app.mapLocationClicked({ marker: { resultId }, latitude, longitude }));
      // @TODO get profile details url and open in new tab
    },
    [dispatch]
  );

  const handleScreenReaderTextKeydown = useCallback(
    (e, resultId) => {
      if (e.key === ' ' || e.key === 'Enter') {
        handleScreenReaderTextClick(resultId);
        e.preventDefault();
      }
    },
    [handleScreenReaderTextClick]
  );

  const handleScreenReaderTextFocus = useCallback(
    (mapFeature) => {
      setFocusedScreenReaderText(mapFeature);
      dispatch(actions.app.mapScreenReaderLocationFocused(mapFeature));
    },
    [dispatch, setFocusedScreenReaderText]
  );

  return (
    // Create the data provider
    <AzureMapDataSourceProvider
      id={dataProviderId}
      options={{
        cluster: true,
        clusterRadius: 20,
        clusterProperties: {
          // this creates an aggregation of all of the resultIds within the cluster
          // example: if "123", "456" and "789" are the result id's of each feature in the cluster
          // cluster would have a "resultIds" property with value of "123,456,789,"
          resultIds: ['concat', ['concat', ['get', 'resultId'], ',']],
        },
      }}
    >
      {/* Add data points to the map */}
      {resultLocations.map(({ latitude, longitude, featureId, srText, ...rest }) => (
        <React.Fragment key={featureId}>
          <AzureMapFeature
            id={featureId} // unique id required for azure maps data points
            type="Point"
            variant="shape"
            coordinate={[longitude, latitude]}
            properties={{ featureId, ...rest }} // any data that should be associated with this data point
          />
          {/* For each map feature, additionally include a screen reader text element */}
          <Typography
            tabIndex="0"
            variant="srOnly"
            component="div"
            role="button"
            onFocus={() => handleScreenReaderTextFocus({ latitude, longitude, ...rest })}
            onBlur={() => setFocusedScreenReaderText(null)}
            onKeyDown={(e) => handleScreenReaderTextKeydown(e, rest.resultId)}
            onClick={() =>
              handleScreenReaderTextClick({ resultId: rest.resultId, latitude, longitude })
            }
          >
            {srText}
          </Typography>
        </React.Fragment>
      ))}

      {Boolean(focusedScreenReaderText) && (
        // This is used to display an indicator of which screen reader text is currently focused
        <AzureMapPopup
          isVisible
          options={{
            position: [focusedScreenReaderText?.longitude, focusedScreenReaderText?.latitude],
            closeButton: false,
            showPointer: false,
          }}
          popupContent={
            <div className="a11y-location-popup" style={{ outline: '2px solid #32a1ce' }} />
          }
        />
      )}

      {/* Render children layers that can access the data that was added */}
      {children}
    </AzureMapDataSourceProvider>
  );
}

ResultDataProvider.propTypes = {
  dataProviderId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
