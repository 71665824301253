/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { useConfigValue } from 'config/state/index';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import { Grid, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { actions } from 'store/toolkit';
import { APP_MAIN_TAG_ID } from 'utils/constants';
import useUpdateTitle from 'hooks/useUpdateTitle';
import StickySidebar from './StickySidebar';
import { GO_BACK_BAR_HEIGHT, LAYOUT_CONTAINED, LAYOUT_SIDEBAR } from './constants';

const useStyles = makeStyles(() => ({
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    goBackButton: {
      '& svg': {
        fill: 'white',
      },
    },
  },
  contentMargin: {
    paddingTop: '1rem',
  },
  container: {
    marginTop: '2.5rem',
    marginBottom: '2rem',
  },
}));

const PageTemplate = (WrappedComponent, title, layout = LAYOUT_CONTAINED, Sidebar = () => {}) => {
  function TemplateWrapper(props) {
    const { history, location } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const {
      config: { SHOW_CHAT },
    } = useConfigValue();

    const smoothScrollToTarget = (targetId) => {
      const target = document.getElementById(targetId);
      // scrollable element is <html> for mobile, is <main> tag for desktop
      const wrapper = smDown ? document.documentElement : document.getElementById(APP_MAIN_TAG_ID);
      // offset absolutely positioned "go back" bar element
      const EXTRA_OFFSET = 5;

      if (wrapper && target) {
        const top = target.offsetTop - GO_BACK_BAR_HEIGHT - EXTRA_OFFSET;
        wrapper.scrollTo({ top, behavior: 'smooth' });
      }
    };

    const lastLocation = useLastLocation();
    const handleBack = () => {
      if (lastLocation === null) {
        history.push('/');
      } else {
        if (
          SHOW_CHAT &&
          location.hash === '#beta-testing' &&
          (lastLocation.pathname === '/results' || lastLocation.pathname === '/')
        ) {
          dispatch(actions.ui.openModal('chat'));
        }
        history.goBack();
      }
    };

    function PaddedContent() {
      return (
        <div className={classes.contentMargin}>
          <WrappedComponent {...props} />
        </div>
      );
    }

    const containerProps = {
      maxWidth: 'xl',
      className: `page-template-container ${classes.container}`,
    };

    useUpdateTitle(title);

    useEffect(() => {
      smoothScrollToTarget(location.hash?.replace('#', ''));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash, smDown]);

    return (
      <section>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '2.5rem',
            zIndex: 2,
            background: 'white',
            borderBottom: '1px solid #ddd',
          }}
        >
          <Button onClick={handleBack} className={classes.goBackButton} style={{ paddingLeft: 0 }}>
            <LeftArrowIcon />
            Go Back
          </Button>
        </div>

        {layout === LAYOUT_SIDEBAR ? (
          <Container {...containerProps} maxWidth="xl">
            <Grid container spacing={2} className={classes.contentMargin}>
              {!smDown && <Grid item xs={12} md={3} />}
              <Grid item xs={12} md={6}>
                <WrappedComponent {...props} />
              </Grid>
              <Grid item xs={12} md={3} style={{ paddingTop: '3rem' }}>
                <StickySidebar smDown={smDown}>
                  <Sidebar />
                </StickySidebar>
              </Grid>
            </Grid>
          </Container>
        ) : layout === LAYOUT_CONTAINED ? (
          <Container {...containerProps}>{PaddedContent()}</Container>
        ) : (
          <>{PaddedContent()}</>
        )}
      </section>
    );
  }

  TemplateWrapper.propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
      goBack: PropTypes.func,
    }).isRequired,
  };

  return TemplateWrapper;
};

export default PageTemplate;
