// config
import { defaultConfig } from 'config/client/defaultConfig';
import { demoConfig } from 'config/client/demoConfig';
import { walmartConfig } from 'config/client/walmartConfig';
import { polkConfig } from 'config/client/polkConfig';
import { microsoftConfig } from 'config/client/microsoftConfig';
import { emboldConfig } from 'config/client/emboldConfig';
import { icubaConfig } from 'config/client/icubaConfig';
import { dollarGeneralConfig } from './client/dollarGeneral';
import { dollarGeneralSupportConfig } from './client/dollarGeneralSupportConfig';
import { emsanaCareConfig } from './client/emsanaCareConfig';
import { surgeryPlusConfig } from './client/surgeryPlusConfig';
import { bnsfConfig } from './client/bnsfConfig';
import { soctConfig } from './client/soctConfig';
import { soctProviderLookupConfig } from './client/soctProviderLookupConfig';
import { jbhuntConfig } from './client/jbhuntConfig';
import { clrConfig } from './client/clrConfig';
import { customerDemoConfig } from './client/customerDemoConfig';

// content
import {
  copyDefaults,
  demoOverrides,
  WMToverrides,
  POLKoverrides,
  MSFToverrides,
  emboldOverrides,
  icubaOverrides,
  dollarGeneralOverrides,
  emsanaCareOverrides,
  surgeryPlusOverrides,
  bnsfOverrides,
  soctOverrides,
  jbhuntOverrides,
  clrOverrides,
  customerDemoOverrides,
} from './content';

const Config = defaultConfig;
const hostname = window?.location?.hostname || '';

// client configs
if (hostname.includes('walmart') || hostname === 'wpg.emboldhealth.com') {
  Object.assign(copyDefaults, WMToverrides);
  Object.assign(Config, walmartConfig);
} else if (hostname.includes('microsoft')) {
  Object.assign(copyDefaults, MSFToverrides);
  Object.assign(Config, microsoftConfig);
} else if (hostname.includes('polk')) {
  Object.assign(copyDefaults, POLKoverrides);
  Object.assign(Config, polkConfig);
} else if (hostname.includes('customerdemo')) {
  Object.assign(copyDefaults, customerDemoOverrides);
  Object.assign(Config, customerDemoConfig);
} else if (hostname.includes('demo')) {
  Object.assign(copyDefaults, demoOverrides);
  Object.assign(Config, demoConfig);
} else if (hostname.split('.')[0] === 'embold') {
  Object.assign(copyDefaults, emboldOverrides);
  Object.assign(Config, emboldConfig);
} else if (hostname.includes('icuba')) {
  Object.assign(copyDefaults, icubaOverrides);
  Object.assign(Config, icubaConfig);
} else if (hostname.includes('bcbst-support')) {
  Object.assign(copyDefaults, dollarGeneralOverrides);
  Object.assign(Config, dollarGeneralSupportConfig);
} else if (hostname.includes('bcbst')) {
  Object.assign(copyDefaults, dollarGeneralOverrides);
  Object.assign(Config, dollarGeneralConfig);
} else if (hostname.includes('emsanacare')) {
  Object.assign(copyDefaults, emsanaCareOverrides);
  Object.assign(Config, emsanaCareConfig);
} else if (hostname.includes('surgeryplus')) {
  Object.assign(copyDefaults, surgeryPlusOverrides);
  Object.assign(Config, surgeryPlusConfig);
} else if (hostname.includes('bnsf')) {
  Object.assign(copyDefaults, bnsfOverrides);
  Object.assign(Config, bnsfConfig);
} else if (hostname.includes('carecompass-providerlookup')) {
  Object.assign(copyDefaults, soctOverrides);
  Object.assign(Config, soctProviderLookupConfig);
} else if (hostname.includes('carecompass')) {
  Object.assign(copyDefaults, soctOverrides);
  Object.assign(Config, soctConfig);
} else if (hostname.includes('jbhunt')) {
  Object.assign(copyDefaults, jbhuntOverrides);
  Object.assign(Config, jbhuntConfig);
} else if (hostname.includes('continental')) {
  Object.assign(copyDefaults, clrOverrides);
  Object.assign(Config, clrConfig);
} else if (hostname.includes('feature-three')) {
  Object.assign(copyDefaults, demoOverrides);
  Object.assign(Config, demoConfig);
} else if (hostname.includes('feature-two')) {
  Object.assign(copyDefaults, MSFToverrides);
  Object.assign(Config, microsoftConfig);
} else if (hostname.includes('feature-one')) {
  Object.assign(copyDefaults, WMToverrides);
  Object.assign(Config, walmartConfig);
}

// add content to config object
Config.CONTENT = copyDefaults;

// staging and testing overrides
if (hostname.includes('testing') || hostname === 'localhost') {
  Config.API_URL = 'https://fusion.dev.emboldhealth.com/api/pg';
  Config.ENABLE_STYLEGUIDE_ROUTE = true;
  Config.FUSION_ENABLED = true;
} else if (hostname.includes('staging')) {
  Config.API_URL = 'https://fusion.staging.emboldhealth.com/api/pg';
  Config.FUSION_ENABLED = true;
  Config.ENABLE_STYLEGUIDE_ROUTE = true;
}

export default Config;
