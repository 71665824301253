import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import targetIcon from 'assets/target.png'; // TODO TECH-3847 replace with custom icon
import { select } from 'store/toolkit';
import { COST_EFFICIENCY_SECTION, SUBSPECIALTY_SECTION, STRENGTHS_SECTION } from 'utils/constants';
import DynamicFocusAreaText from 'components/ModernExperience/DynamicFocusAreaText';

/** Provider metrics are measurable data points of a provider object that are represented by this object
 * @typedef {object} ProviderMetric
 * @property {string} key A unique string identifier for the metric
 * @property {string} icon The URI of the icon displayed for this metric
 * @property {string} title The title or label of this metric
 * @property {string} text A text representation of the metric value.
 * @property {string} description Descriptive text about this metric
 */

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useCostEfficiencyMetric(provider) {
  const showCost = useSelector(select.featureFlags.showCost);
  const costText = useSelector(select.provider(provider.entityId).costText);

  return useMemo(() => {
    if (!showCost || !costText) return null;

    return {
      key: 'costEfficiency',
      icon: COST_EFFICIENCY_SECTION.icon,
      title: COST_EFFICIENCY_SECTION.title,
      text: costText,
      description: COST_EFFICIENCY_SECTION.description,
    };
  }, [showCost, costText]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useFocusAreasMetric(provider) {
  const showSubspecialties = useSelector(select.featureFlags.showSubspecialties);
  const subspecialties = useSelector(select.provider(provider.entityId).sortedSubspecialties);
  const searchedSubspecialtyId = useSelector(select.results.subspecialtyId);

  return useMemo(() => {
    if (!showSubspecialties) return null;
    if (!subspecialties.length) return null;

    const subspecialtyNodes = subspecialties.map((ss, i) => (
      <React.Fragment key={`focus-area-${ss.subspecialtyId}-${provider.entityId}`}>
        <DynamicFocusAreaText
          subspecialty={ss}
          key={ss.subspecialtyName}
          isActive={ss.subspecialtyId === searchedSubspecialtyId}
        />
        {i < subspecialties.length - 1 && ', '}
      </React.Fragment>
    ));

    return {
      key: 'focusAreas',
      icon: targetIcon,
      title: SUBSPECIALTY_SECTION.title,
      text: subspecialtyNodes,
      description: SUBSPECIALTY_SECTION.description,
    };
  }, [subspecialties, showSubspecialties, provider, searchedSubspecialtyId]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useStrengthsMetric(provider) {
  const showProviderStrengths = useSelector(select.featureFlags.showProviderStrengths);
  const strengths = useSelector(select.provider(provider.entityId).providerStrengths);

  return useMemo(() => {
    if (!showProviderStrengths) return null;
    if (!strengths.length) return null; // return null if there are no strengths

    return {
      key: 'strengths',
      icon: STRENGTHS_SECTION.icon,
      title: STRENGTHS_SECTION.title,
      text: strengths.map((strength) => strength.label).join(', '),
      description: STRENGTHS_SECTION.description,
    };
  }, [strengths, showProviderStrengths]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric[]}
 */
export function useMetricsList(provider) {
  const costEfficiency = useCostEfficiencyMetric(provider);
  const focusAreas = useFocusAreasMetric(provider);
  const strengths = useStrengthsMetric(provider);

  return useMemo(() => {
    const metricList = [];

    if (focusAreas) metricList.push(focusAreas);
    if (strengths) metricList.push(strengths);
    if (costEfficiency) metricList.push(costEfficiency);
    return metricList;
  }, [costEfficiency, focusAreas, strengths]);
}
