import React, { useCallback, useState, useMemo } from 'react';
import PropTypes, { RefType } from 'propTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';

import formatPrintProfile from 'utils/formatPrintProfile';
import useReturnFocus from 'hooks/useReturnFocus';
import useFocusAnchors from 'utils/FocusRefContext';
import { actions, select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

const SPINNER_SIZE = 16;
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 500,
    marginLeft: 'auto',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& > *': {
      flex: 1,
    },
  },
  buttonRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: 90,
    maxWidth: 160,
    padding: theme.spacing(1),
    margin: '0px auto',
  },
  buttonLabel: {
    fontSize: '.875rem',
    '& svg': {
      height: 14,
    },
  },
  spinner: {
    height: SPINNER_SIZE,
    width: SPINNER_SIZE,
    marginRight: 5,
  },
}));

function PlaceProfileHeaderButtons({ placeId, printRef }) {
  const classes = useStyles();
  const focusAnchors = useFocusAnchors();
  const placeData = useSelector(select.place(placeId).data);
  const showShareButton = useSelector(select.featureFlags.showShare);
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);

  const dispatch = useDispatch();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();
  const { ref: printButtonRef, returnFocus: focusPrintButton } = useReturnFocus();

  const [isPrinting, setIsPrinting] = useState(false);

  const { pathname } = useLocation();
  const isSharePage = pathname.startsWith('/share/');

  const handleCall = () => {
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: placeData }));
  };

  const handleShare = () => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [placeData] }));
  };

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false);
    focusPrintButton();
  }, [focusPrintButton]);

  const printProfile = useReactToPrint({
    content: () => formatPrintProfile(printRef),
    documentTitle: placeData.name || 'Embold Health Facility',
    onBeforeGetContent: () => setIsPrinting(true),
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = useCallback(() => {
    setIsPrinting(true);
    dispatch(_analyticsActions.printProfile(placeId));
    // need to wait for profile accordion animations to finish before ingesting content
    setTimeout(printProfile, 470);
  }, [dispatch, placeId, printProfile]);

  const buttonProps = useMemo(
    () => ({
      variant: 'outlined',
      color: 'primary',
      classes: { root: classes.buttonRoot, label: classes.buttonLabel },
    }),
    [classes]
  );

  return (
    <Grid container classes={{ root: classes.container }}>
      {/* @TODO print button functionality */}
      <Grid item>
        <Button {...buttonProps} onClick={handlePrint} ref={printButtonRef}>
          {isPrinting ? (
            <>
              <CircularProgress
                thickness={3}
                size={SPINNER_SIZE}
                aria-label=""
                className={classes.spinner}
              />
              Printing
            </>
          ) : (
            <>
              <PrintIcon />
              Print
            </>
          )}
        </Button>
      </Grid>
      {!isSharePage && (
        <>
          {showShareButton && (
            <Grid item>
              <Button {...buttonProps} onClick={handleShare} ref={shareButtonRef}>
                <ShareIcon />
                Share
              </Button>
            </Grid>
          )}

          {showScheduleButton && (
            <Grid item>
              <Button {...buttonProps} onClick={handleCall} ref={callButtonRef}>
                Need Help?
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default PlaceProfileHeaderButtons;

PlaceProfileHeaderButtons.propTypes = {
  placeId: PropTypes.string.isRequired,
  printRef: RefType.isRequired,
};
