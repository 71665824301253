import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'whatwg-fetch';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { useStateValue } from 'state';
import { useConfigValue } from 'config/state/index';
import './App.scss';
import { FocusAnchorProvider } from 'utils/FocusRefContext';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'azure-maps-control/dist/atlas.min.css';

import LandingPage from 'components/Pages/LandingPage';
import MobileSearchPage from 'components/Pages/MobileSearchPage';
import ProfilePage from 'components/ModernExperience/Profile/ProfilePage';
import CookieAgreement from 'components/CookieAgreement';
import UpdateRegionModal from 'components/Modals/UpdateRegionModal';
import ProfileModals from 'components/Modals/ProfileModals';
import WMTBenefitsNotifierModal from 'components/Modals/WMTBenefitsNotifierModal';
import AppErrorModal from 'components/Modals/AppErrorModal';
import GeoErrorModal from 'components/Modals/GeoErrorModal';
import ClientConfigErrorModal from 'components/Modals/ClientConfigErrorModal';
import TooltipInfo from 'components/TooltipInfo';
import CssCanary from 'components/CssCanary';
import SharePage from 'components/Pages/SharePage';
import ContactBCBS from 'components/Pages/ContactBCBS';
import Config from 'config';
import fetchClient from 'utils/fetchClient';
import usePromote from 'hooks/usePromote';
import PromotionProfilePage from 'components/Pages/PromotionProfilePage';
import StyleguideApp from 'components/Pages/StyleGuide/StyleguideApp';
import { useQueryParams } from 'use-query-params';
import queryParamConfig from 'hooks/queryString/queryParamConfig';
import IEErrorModal from 'components/Modals/IEErrorModal';
import ChatModal from 'components/Modals/ChatModal/ChatModal';
import ModalRouter from 'components/Modals/ModalRouter';
import LoginPage from 'components/Pages/LoginPage';
import ResultsPage from 'components/Results/ResultsPage';
import SupportPage from 'components/Pages/SupportPage/SupportPage';
import Notification from 'components/Notification';
import BreakpointWatcher from 'components/BreakpointWatcher';

import { APP_MAIN_TAG_ID } from 'utils/constants';

import { _analyticsActions } from 'analytics';
import useInitializeFilters from 'hooks/filters/useInitializeFilters';
import AuthProtectedRoute from 'components/Navigation/AuthProtectedRoute';
import ChatButton from 'components/Modals/ChatModal/ChatButton';
import { select, actions } from 'store/toolkit';
import { LOGIN_ROUTE, SUPPORT_ROUTE } from './navigation/navigationConstants';
import AnalyticsPage from './analytics/AnalyticsPage';

const { ENABLE_STYLEGUIDE_ROUTE, SHOW_DISCLAIMER, CONTENT, HIGH_QUALITY_INTRO_MODAL } = Config;

const {
  PAGES_PRIVACY: PrivacyPolicy,
  PAGES_DISCLAIMER: Disclaimer,
  PAGES_TERMS: Terms,
  SHOW_CONTACT_BCBS_ROUTE,
} = CONTENT;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiTypography-srOnly': {
      whiteSpace: 'nowrap',
    },
    '@media screen and (-ms-high-contrast: black-on-white)': {
      '.MuiTouchRipple-child': {
        backgroundColor: 'rgba(0,0,0,0.8)',
      },
    },
    '@media screen and (-ms-high-contrast: white-on-black)': {
      '.MuiTouchRipple-child': {
        backgroundColor: 'white',
      },
    },
  },
  main: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
    background: 'white',
    marginTop: 56,
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
  },
}));
if (Config.CLOSED_ACCESS && !window.location.pathname.includes('share')) {
  fetchClient('/init/');
}
function App() {
  // styles
  const classes = useStyles();

  // redux
  const dispatch = useDispatch();
  const isMobileLayout = useSelector(select.ui.isMdDown);
  const enableChat = useSelector(select.featureFlags.enableChat);
  const providerLookup = useSelector(select.featureFlags.providerLookup);

  // context
  const [{ showLogin, networkSlug }, setState] = useStateValue();
  const {
    config: { REGION_SELECTOR_CODES, HAS_PORTAL_INTEGRATION },
    getDefaultLocation,
    loadingClientConfig,
  } = useConfigValue();

  // react router
  const location = useLocation();

  // query params
  const [{ location_input: locationInputParam }] = useQueryParams(queryParamConfig);

  // eslint-disable-next-line no-shadow
  const setDefaultLocation = (networkSlug) => {
    const defaultLocation = getDefaultLocation(networkSlug);
    if (!locationInputParam) {
      dispatch(
        actions.location.setDefaultLocation({
          locationInput: `${defaultLocation.city}, ${defaultLocation.state}`,
          latLong: { latitude: defaultLocation.lat, longitude: defaultLocation.long },
          locationComponents: {
            city: defaultLocation.city,
            zip: null,
            state: defaultLocation.state,
          },
          locationResolved: true,
        })
      );
    }
  };

  const stringifiedNetworks = JSON.stringify(Object.keys(REGION_SELECTOR_CODES));

  usePromote();
  useInitializeFilters(); // update filters from client config

  useEffect(() => {
    const mpiNetwork = localStorage.getItem('mpiNetwork');
    if (!loadingClientConfig) {
      if (HAS_PORTAL_INTEGRATION && REGION_SELECTOR_CODES[mpiNetwork]) {
        dispatch(actions.config.setNetworkSlug(mpiNetwork)); // set redux networkSlug
        setState({ networkSlug: mpiNetwork }); // set context networkSlug
        setDefaultLocation(mpiNetwork);
      } else if (networkSlug) {
        setDefaultLocation(networkSlug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedNetworks, loadingClientConfig, showLogin]);

  useEffect(() => {
    if (!showLogin) {
      dispatch(_analyticsActions.returningUserLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setup modal routes see https://v5.reactrouter.com/web/example/modal-gallery for example
  // This piece of state is set when one of the
  // links to modals are clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the previous route in the background, behind
  // the modal.
  const background = location.state && location.state.background;

  const isLoginPage = location.pathname === LOGIN_ROUTE;
  const showHeader = !isLoginPage; // show header: all pages except login
  const showFooter = isLoginPage || !isMobileLayout; // show footer: always on login page, otherwise only on desktop width

  const showChatButton = Boolean(
    enableChat && (location.pathname === '/' || location.pathname.startsWith('/results'))
  );
  const showDesktopChatButton = showChatButton && !isMobileLayout;
  return (
    <FocusAnchorProvider>
      <BreakpointWatcher />
      <CookieAgreement />
      <CssCanary />
      {showHeader && <Header isMobile={isMobileLayout} showChatButton={showChatButton} />}
      <main
        className={`${classes.main} main`}
        id={APP_MAIN_TAG_ID}
        style={isLoginPage ? { marginTop: 0 } : {}}
      >
        {showDesktopChatButton && <ChatButton />}
        <Switch location={background || location}>
          {/* **** PRIVATE ROUTES *** */}
          <AuthProtectedRoute path="/" exact render={(props) => <LandingPage {...props} />} />

          {isMobileLayout ? (
            <AuthProtectedRoute
              path={['/search']}
              exact
              render={(props) => <MobileSearchPage {...props} />}
            />
          ) : null}

          <AuthProtectedRoute
            path={['/results', '/results/compare']}
            exact
            render={(props) => <ResultsPage {...props} />}
          />

          <AuthProtectedRoute
            path={['/profile/:profileType/:id']}
            exact
            render={(props) => <ProfilePage {...props} />}
          />

          <AuthProtectedRoute
            path={['/promotion/:id']}
            exact
            render={(props) => <PromotionProfilePage {...props} />}
          />

          {!providerLookup && (
            <AuthProtectedRoute
              path={SUPPORT_ROUTE}
              render={(props) => <SupportPage {...props} />}
            />
          )}

          {/* *** PUBLIC ROUTES *** */}
          <Route exact path={LOGIN_ROUTE} component={LoginPage} />
          <Route path="/privacy" exact component={PrivacyPolicy} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/share/:id" exact component={SharePage} />
          {SHOW_CONTACT_BCBS_ROUTE && <Route path="/contactbcbs" exact component={ContactBCBS} />}
          {SHOW_DISCLAIMER && <Route path="/disclaimer" exact component={Disclaimer} />}

          {/* *** STYLEGUIDE ROUTES *** */}
          {ENABLE_STYLEGUIDE_ROUTE && <Route path="/styleguide" component={StyleguideApp} />}
          {ENABLE_STYLEGUIDE_ROUTE && (
            <AuthProtectedRoute path="/analytics" exact render={() => <AnalyticsPage />} />
          )}

          {/* *** REDIRECT NON-EXISTENT ROUTES *** */}
          <Redirect to="/" />
        </Switch>
      </main>

      {showFooter && <Footer />}

      {/* This component is the router for modals rendered as routes */}
      <ModalRouter />

      <TooltipInfo />
      {enableChat && <ChatModal />}

      <IEErrorModal />
      <UpdateRegionModal />
      {HIGH_QUALITY_INTRO_MODAL && !showLogin && networkSlug && <WMTBenefitsNotifierModal />}
      <GeoErrorModal />
      <AppErrorModal />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        progressClassName="toastProgress"
      />
      <ClientConfigErrorModal />
      <ProfileModals />
      <Notification />
    </FocusAnchorProvider>
  );
}

export default App;
