/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, EMSANA_CARE } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token a65285f779964781e60c2330528b574379bbb984',
  staging: 'Token cbb1cd9a1fad8d58763cfb8b80aeb6717ebe07bd',
  prod: 'Token 6cf906054f58ebca035f860d68dc1d9a484e6c9a',
};

const token = selectToken(apiTokens);

export const emsanaCareConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'emsanacare',
  EMBOLD_CLIENT: EMSANA_CARE,
  EMBOLD_CLIENT_SLUG: 'emsanacare',
  SHOW_SCHEDULE_BUTTON: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Mesa,
  USE_BROWSER_LOCATION: true,
  CLOSED_ACCESS: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_NETWORK_NAME: 'emsanaCareUserId',
  LOGIN_COOKIE_AUTH_REGEX:
    /^(boeingbcbsarizona|boeingbcbsstlouis|boeingaetnaarizona|boeingcignaarizona|crossoveraetnahdhpwashington|crossoverxofirstwashington|expediaaetnaepowashington|expediaaetnahdhpwashington|expediapremerappowashington|linkedinanthemepowashington|linkedinanthemppowashington)$/,
};
