import * as results from './slices/results/resultsThunks';
import config from './slices/config/configThunks';
import * as filters from './slices/filters/filterThunks';
import * as search from './slices/search/searchThunks';
import * as services from './slices/services/servicesThunks';
import * as location from './slices/location/locationThunks';
import * as chat from './slices/chat/chatThunks';
import * as app from './appThunks';

const thunks = {
  app,
  chat,
  config,
  filters,
  results,
  search,
  services,
  location,
};

export default thunks;
