import React, { useMemo } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Modal from 'components/Modals/Modal';

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    margin: '22px 7px 7px 0',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    color: theme.palette.appleRed,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem',
    },
  },
  modalBody: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '.875rem',
    color: theme.palette.black,
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      marginBottom: 20,
      fontSize: '1.125rem',
    },
  },
}));

// @TODO: write tests for this function
function getIsUnsupportedBrowser() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie !== -1) {
    // IE 10 or older
    return true;
  }
  const trident = ua.indexOf('Trident/');
  if (trident !== -1) {
    // IE 11
    return true;
  }

  return false;
}

function IEErrorModal() {
  const classes = useStyles();
  const isUnsupportedBrowser = useMemo(() => getIsUnsupportedBrowser(), []);

  return (
    <Modal open={isUnsupportedBrowser} showX={false} ariaId="ie-error-modal">
      <>
        <Typography id="ie-error-modal-title" variant="h2" className={classes.modalHeader}>
          Notice
        </Typography>
        <Typography id="ie-error-modal-description" variant="body1" className={classes.modalBody}>
          This site does not support this browser. For the best experience, please use{' '}
          <Link rel="noreferrer" href="https://www.google.com/chrome/">
            Google Chrome
          </Link>{' '}
          or{' '}
          <Link rel="noreferrer" href="https://www.microsoft.com/en-us/windows/microsoft-edge">
            Microsoft Edge
          </Link>
          .
        </Typography>
      </>
    </Modal>
  );
}

export default IEErrorModal;
