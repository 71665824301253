import React from 'react';
import PropTypes from 'propTypes';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { actions, select } from 'store/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import arrowLarge from 'assets/benefits-arrow-large.svg';
import arrowSmall from 'assets/benefits-arrow-small.svg';

import CheckOrXIcon from 'icons/CheckOrXIcon';
import ProfileSection from '../ProfileSection';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    columnGap: 10,
    // there is no breakpoint to target only the smallest screens, we have to use @media
    '@media(max-width:320px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: 10,
    },
  },
  arrow: {
    alignSelf: 'center',
  },
  item: {
    display: 'flex',
    flex: 1,
  },
  imgButton: {
    padding: 0,
  },
  benefitText: {
    marginLeft: 10,
  },
  warningOrange: {
    color: theme.palette.warningOrange,
  },
}));

function SectionBenefitDecrease({ providerId }) {
  const classes = useStyles();
  const showBenefitDecrease = useSelector(select.provider(providerId).showBenefitDecrease);
  const { title, previousBenefit, newBenefit } = useSelector(select.content.benefitsChange) || {};
  const isXsDown = useSelector(select.ui.isXsDown);

  // required attributes for UI
  if (!title || !previousBenefit?.text || !newBenefit?.text) return null;
  // calculated in selector based on client config and provider
  if (!showBenefitDecrease) return null;
  return (
    <ProfileSection TitleProps={{ title: title }}>
      <Grid container classes={{ root: classes.root }}>
        <BenefitDescriptionGrid benefit={previousBenefit}>
          <CheckOrXIcon checked alt="affirmative green checkmark" />
        </BenefitDescriptionGrid>

        <img src={isXsDown ? arrowSmall : arrowLarge} alt="" className={classes.arrow} />

        <BenefitDescriptionGrid benefit={newBenefit}>
          <ErrorIcon
            aria-label="caution orange exclamation point"
            className={classes.warningOrange}
          />
        </BenefitDescriptionGrid>
      </Grid>
    </ProfileSection>
  );
}

export default SectionBenefitDecrease;

SectionBenefitDecrease.propTypes = {
  providerId: PropTypes.string.isRequired,
};

function WrapButton({ isClickable, children, ...props }) {
  return isClickable ? <IconButton {...props}>{children}</IconButton> : children;
}

WrapButton.propTypes = {
  isClickable: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function BenefitDescriptionGrid({ benefit, children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => dispatch(actions.ui.openProfileModal({ type: 'info', data: benefit }));

  return (
    <Grid item classes={{ root: classes.item }}>
      <Grid container justifyContent="center" alignItems="center" wrap="nowrap">
        <WrapButton
          isClickable={Boolean(benefit.modalText)}
          onClick={handleClick}
          classes={{ root: classes.imgButton }}
        >
          {children}
        </WrapButton>
        <Typography classes={{ root: classes.benefitText }}>{benefit.text}</Typography>
      </Grid>
    </Grid>
  );
}

BenefitDescriptionGrid.propTypes = {
  benefit: PropTypes.shape({
    text: PropTypes.string.isRequired,
    modalText: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
