import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';

const useStyles = makeStyles({
  root: {
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export default function SubmitButton({ children = 'Submit', className, ...props }) {
  const { isSubmitting } = useFormikContext();
  const classes = useStyles();

  return (
    <Button
      type="submit"
      className={`${classes.root} ${className}`}
      startIcon={isSubmitting && <CircularProgress size="1rem" />}
      variant="contained"
      color="primary"
      disabled={isSubmitting}
      {...props}
    >
      {children}
    </Button>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SubmitButton.defaultProps = {
  children: 'Submit',
  className: '',
};
