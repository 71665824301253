import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'propTypes/index';
import clsx from 'clsx';
import { Grid, Typography, IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PauseCircle from '@mui/icons-material/PauseCircle';
import PlayCircle from '@mui/icons-material/PlayCircle';
import Cancel from '@mui/icons-material/Cancel';

import { shuffleArray } from 'utils/utils';
import {
  EMBOLD_FACTS,
  EMBOLD_FACTS_INTERVAL,
  EMBOLD_FACTS_TIMEOUT,
} from 'store/slices/chat/chatConstants';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
    },
  },
  carouselRoot: {
    overflow: 'hidden',
    maxHeight: 0,
    transition: '.3s all ease',
  },
  slideInRoot: {
    maxHeight: 120, // approximate max height - this property is used for a smooth "transition"
    margin: 'auto -16px -20px',
  },
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(6)}px`,
    opacity: 0,
    minHeight: 74,
    transform: 'translateY(30px)',
    transition: '.3s all ease',
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      minHeight: 100,
    },
  },
  slideInContainer: {
    transform: 'translateY(0px)',
    opacity: 1,
  },
  fact: {
    animationName: '$fadeIn',
    animationDuration: '.4s',
    animationTimingFunction: 'linear',
    color: theme.palette.darkGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Overlay
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    paddingLeft: theme.spacing(2.5),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    '& $iconButton': {
      opacity: 0,
    },
  },
  hoverable: {
    '&:hover': {
      cursor: 'pointer',
      '& $iconButton': {
        opacity: 1,
      },
    },
  },
  iconButton: {
    height: 14,
    padding: 0,
    '& svg': {
      color: theme.palette.darkGray,
    },
  },
}));

export default function EmboldFactsCarousel({ sendingChatMessage, scrollToBottom }) {
  const classes = useStyles();

  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const [isForcePaused, setIsForcePaused] = useState(false);
  const [index, setIndex] = useState(0);
  const [facts, setFacts] = useState([]);
  const [initialTimeout, setInitialTimeout] = useState(null);
  const [factTimer, setFactTimer] = useState(null);

  const showFacts = useMemo(
    () => isCarouselActive || isForcePaused,
    [isCarouselActive, isForcePaused]
  );

  useEffect(() => {
    // @TODO: get translated messages
    // initialize a random fact order every time this component renders (new chat session)
    setFacts(shuffleArray(EMBOLD_FACTS));
  }, []);

  const safeSetInterval = useCallback(
    (intervalCallback) => {
      // never set an interval without clearing the existing interval to ensure we don't double up
      clearInterval(factTimer);
      const timerId = setInterval(intervalCallback, EMBOLD_FACTS_INTERVAL);
      setFactTimer(timerId);
    },
    [factTimer]
  );

  const safeSetTimeout = useCallback(
    (timeoutCallback) => {
      // never set a timeout without clearing the existing timeout to ensure we don't double up
      clearTimeout(initialTimeout);
      const timeoutId = setTimeout(timeoutCallback, EMBOLD_FACTS_TIMEOUT);
      setInitialTimeout(timeoutId);
    },
    [initialTimeout]
  );

  const isLastItem = useCallback((arrayIndex) => arrayIndex === facts.length - 1, [facts]);
  const incrementFact = useCallback(
    () => setIndex((i) => (isLastItem(i) ? 0 : i + 1)),
    [isLastItem]
  );

  const setFactInterval = useCallback(() => {
    safeSetInterval(incrementFact);
  }, [safeSetInterval, incrementFact]);

  const forcePauseInterval = useCallback(() => {
    setIsForcePaused(true);
    clearInterval(factTimer);
  }, [factTimer]);

  const forceResumeInterval = useCallback(() => {
    setIsForcePaused(false);
    setFactInterval();
  }, [setFactInterval]);

  /* *** Event Handlers *** */
  const handleMouseEnter = useCallback(() => {
    // pause fact carousel by clearing current interval
    clearInterval(factTimer);
  }, [factTimer]);

  const handleMouseLeave = useCallback(() => {
    // do nothing if carousel is manually paused
    if (isForcePaused) return;
    // otherwise resume fact interval
    setFactInterval();
  }, [isForcePaused, setFactInterval]);

  // useMemo that returns a function
  const overlayClickHandler = useMemo(
    () => (isForcePaused ? forceResumeInterval : forcePauseInterval),
    [isForcePaused, forcePauseInterval, forceResumeInterval]
  );

  const overlayKeyDownHandler = useCallback(
    (e) => {
      if (['Enter', ' '].indexOf(e?.key) > -1) {
        e.preventDefault();
        overlayClickHandler();
      }
    },
    [overlayClickHandler]
  );
  /* *** end Event Handlers *** */

  const beginFactTimer = useCallback(() => {
    // always set isMessagePending to true
    setIsCarouselActive(true);
    // in the fact has been paused since the last loading cycle, allow it to remain paused
    if (isForcePaused) return;
    // otherwise, start the fact carousel and scroll the container to show facts
    setFactInterval();
    setTimeout(scrollToBottom, 200);
  }, [setFactInterval, isForcePaused, scrollToBottom]);

  // sendingChatMessage state triggers display and timers
  useEffect(() => {
    if (sendingChatMessage) {
      safeSetTimeout(beginFactTimer);
      // start on a new fact unless the previous fact has been paused
      if (!isForcePaused) incrementFact();
    } else {
      setIsCarouselActive(false);
      clearTimeout(initialTimeout);
      clearInterval(factTimer);
    }

    return () => {
      // clean up setInterval & setTimeout timers
      clearTimeout(initialTimeout);
      clearInterval(factTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingChatMessage]);

  // Only re-render fact when the index changes. This preserves the CSS animation
  // For some reason, including a "key" prop is also necessary here
  const memoizedFact = useMemo(
    () => (
      <Typography
        key={uniqueId()}
        variant="body1"
        className={classes.fact}
        data-testid="embold-facts-text"
      >
        {facts[index]}
      </Typography>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index, facts]
  );

  /* *** Overlay *** */
  const icon = useMemo(() => {
    if (isForcePaused) return isCarouselActive ? PlayCircle : Cancel;
    return PauseCircle;
  }, [isCarouselActive, isForcePaused]);

  const iconLabel = useMemo(
    () =>
      `Click to ${
        // eslint-disable-next-line no-nested-ternary
        isForcePaused ? (isCarouselActive ? 'resume' : 'close') : 'pause'
      } Embold facts carousel`,
    [isCarouselActive, isForcePaused]
  );

  const overlayA11yProps = useMemo(
    () =>
      showFacts
        ? {
            tabIndex: 0,
            onMouseOver: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            onClick: overlayClickHandler,
            onKeyDown: overlayKeyDownHandler,
            role: 'button',
            'aria-label': iconLabel,
          }
        : {},
    [
      showFacts,
      iconLabel,
      handleMouseEnter,
      handleMouseLeave,
      overlayClickHandler,
      overlayKeyDownHandler,
    ]
  );

  return (
    <Grid
      aria-hidden={!showFacts}
      classes={{ root: clsx(classes.carouselRoot, { [classes.slideInRoot]: showFacts }) }}
      data-testid="embold-facts-root"
    >
      <Grid
        classes={{ root: clsx(classes.container, { [classes.slideInContainer]: showFacts }) }}
        container
        direction="row"
        wrap="nowrap"
        data-testid="embold-facts-container"
      >
        {memoizedFact}
        {/* Overlay */}
        <Grid
          container
          classes={{ root: clsx(classes.overlay, { [classes.hoverable]: showFacts }) }}
          data-testid="embold-facts-overlay"
          {...overlayA11yProps}
        >
          <IconButton
            // Overlay div acts as accessible button
            tabIndex="-1"
            role="presentation"
            data-testid="embold-facts-overlay-button"
            onClick={() => {}}
            classes={{ root: classes.iconButton }}
            style={{
              ...(isForcePaused ? { opacity: 1 } : {}),
            }}
          >
            <SvgIcon component={icon} style={{ width: '.875rem', height: '.875rem' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

EmboldFactsCarousel.propTypes = {
  sendingChatMessage: PropTypes.bool.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
};
