import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions } from 'store/toolkit';

import SkipLink from 'components/a11y/SkipLink';
import { APP_MAIN_TAG_ID } from 'utils/constants';

function HeaderSkipLinks() {
  const dispatch = useDispatch();
  const openChatModal = () => dispatch(actions.ui.openModal('chat'));
  const enableChat = useSelector(select.featureFlags.enableChat);
  return (
    <>
      <SkipLink href={`#${APP_MAIN_TAG_ID}`} label="Skip to Main Content" />
      {enableChat && <SkipLink label="Skip to Chatbot" handleClick={openChatModal} />}
    </>
  );
}

export default HeaderSkipLinks;
