import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import EmboldMarkdown from 'components/EmboldMarkdown';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: theme.shape.borderRadius * 3,
    marginBottom: theme.spacing(1),
  },
}));

function WelcomeMessage() {
  const classes = useStyles();
  const welcomeMessage = useSelector(select.featureFlags.welcomeMessageMd);
  const isLoadingClientConfig = useSelector(select.config.isLoading);

  return isLoadingClientConfig ? (
    <Skeleton
      className={classes.skeleton}
      variant="rect"
      height={100}
      aria-label="welcome message is loading"
      animation="wave"
    />
  ) : (
    <EmboldMarkdown>{welcomeMessage}</EmboldMarkdown>
  );
}

export default WelcomeMessage;
