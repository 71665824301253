import { DEFAULT_LOCATIONS, EMBOLD, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import emboldLogo from 'assets/embold-icon.png';
import emboldLoginLogo from 'assets/embold-login-logo.svg';

const apiTokens = {
  dev: 'Token 4545d3e11a2b6a9591a3264693175ee3ce9a1e04',
  staging: 'Token b100d0637af11c6e960cef89c71c03a488201e9c',
  prod: 'Token d3d2ac680a2fba072de7cb9b969be3c13ece59f6',
};

const token = selectToken(apiTokens);

const emboldConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'embold',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'embold',
  EMBOLD_CLIENT: EMBOLD,
  EMBOLD_CLIENT_SLUG: 'embold',

  // toggles
  SHOW_COST: true,
  SHOW_TELEHEALTH: false,
  SHOW_WHEELCHAIR_ACCESSIBLE: false,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: false,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },
  LOGIN_NETWORK_NAME: 'emboldUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['embold', 'i'],

  // content
  DISPLAY_NAME: 'Embold Health',
  SCHEDULE_PHONE_NUMBER: '615-810-8191',
  SCHEDULE_COPY: `Connecting you to Embold Health Customer Service. Click 'Call Now' to continue`,
  HEADER_LOGO: emboldLogo,
  SUPPORT_LINK: 'https://ehemployeesupport.emboldhealth.com/portal/en/home',
  ADD_ICON: false,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE: `Provider Guide is your guide to quickly and easily finding the doctors in your network and
  area that have proven to provide the best care.`,
  LOCAL_LOGO_ASSET: emboldLoginLogo,
  FORM_VARIANT: CHECKMARK,
  NO_WELCOME_LINK: false,
};

export default emboldConfig;
