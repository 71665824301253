import { sortedIndex } from 'lodash';

import { VALID_RADIUS_VALUES } from 'store/slices/filters/filterConstants';

/**
 * Accepts a radius number. Returns a new number rounded up to the nearest valid radius value, OR -1 if
 * @param {number} radius
 * @param {number} validRadius Returns the input up to the nearest valid radius. Returns -1 if input is over max.
 */
// eslint-disable-next-line import/prefer-default-export
export function getNearestValidRadius(radius) {
  if (VALID_RADIUS_VALUES.includes(radius)) {
    return radius;
  }

  const closestIndex = sortedIndex(VALID_RADIUS_VALUES, radius); // get the index of the new radius rounded up to the nearest valid radius value.
  const newRadius = VALID_RADIUS_VALUES[closestIndex]; // new radius value should be the value rounded up, or simply the max radius value
  return newRadius || -1;
}
