/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = (isPrinting = false, isNearestLocationProvider = false) =>
  makeStyles((theme) => ({
    sectionContainer: {
      overflow: 'auto',
      border: `1px solid ${theme.palette.lightGray}`,
      borderRadius: 15,
      margin: '28px 0',
      width: '100%',
      padding: '16px 16px 10px',
      '@media print': isNearestLocationProvider
        ? {
            'page-break-before': 'always !important',
          }
        : null,
    },
    title: {
      position: isPrinting ? 'relative' : 'absolute',
      backgroundColor: 'white',
      marginTop: isPrinting ? 0 : -24,
      padding: '0 5px',
    },
    icon: {
      height: 18,
      marginBottom: -3,
      paddingRight: 5,
    },
  }));

function ProfileSectionWrap({
  titleTooltip,
  title,
  isPrinting,
  isNearestLocationProvider,
  icon,
  children,
  extraClass,
  ...props
}) {
  const classes = useStyles(isPrinting, isNearestLocationProvider)();

  return (
    <div
      {...props}
      className={`${classes.sectionContainer} ${extraClass} ${title
        .toLowerCase()
        .replace(/ /g, '-')}-section`}
    >
      <Typography variant="h3" className={`${classes.title} uppercase`}>
        {icon && <img className={classes.icon} src={icon} alt="Evaluated by Embold Health" />}
        {title} {titleTooltip}
      </Typography>
      {children}
    </div>
  );
}

ProfileSectionWrap.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProfileSectionWrap;
