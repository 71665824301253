import React from 'react';
import 'whatwg-fetch';
import PropTypes, { PromotionDataShape } from 'propTypes/index';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { useStateValue } from 'state';
import { buildLocationUrl, getPlaces, openInNewTab, openInSameTab } from 'utils/utils';
import ProfileSectionWrap from 'components/Profile/ProfileSections/ProfileSectionWrap';
import ProfileMap from 'components/Profile/ProfileSections/ProfileMap';

import geoTag from 'assets/geo-tag.svg';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import { PLACE_RESULT_TYPE } from 'utils/constants';
import { useConfigValue } from 'config/state';
import PromoteBadges from './Badges/PromotionBadges';

import { GridIconRow } from './Profile';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: '0',
    paddingBottom: 15,
    textAlign: 'center',
    color: theme.palette.linkBlue,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonLink: {
    padding: '0 0 4px 0',
    minWidth: 0,
    borderRadius: 0,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: 'underline',
    color: theme.palette.linkBlue,
    display: 'inline',
    textAlign: 'left',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  subtitle: {
    display: 'block',
  },
  goBackButton: {
    zIndex: 7,
    padding: 0,
    background: 'none',
    textDecoration: 'underline',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    marginBottom: 10,
    color: theme.palette.lightBlue,
    marginTop: -3,
  },
  shareScheduleButton: {
    marginBottom: 8,
    marginTop: 5,
    margin: 10,
    '& svg': {
      height: 19,
      width: '1em',
      verticalAlign: 'top',
      position: 'relative',
      right: 2,
    },
  },
  programDescription: {
    // delete once laymans goes love with MSFT
    fontWeight: 400,
    paddingLeft: '8px',
    paddingTop: '0',
    paddingBottom: '8px',
    fontSize: '1.15rem',
    textAlign: 'left',
  },
  detailedDesciption: {
    fontWeight: 400,
    textAlign: 'left',
    paddingLeft: '6px',
    paddingTop: '4px',
    paddingBottom: '4px',
    fontSize: '1rem',
  },
}));

function PromoteProfile({ promotion, isProfilePage }) {
  const classes = useStyles();

  const [{ resultType, selectedPlaceProvider }, setState] = useStateValue();
  const {
    config: {
      CONTENT: { MAPS_URL },
    },
  } = useConfigValue();

  const {
    programDescription,
    website,
    detailedDescription,
    accessEmail,
    logo,
    programSubname,
    programName,
    accessAppLink,
    getStartedLink,
    accessChatLink,
    whenToUse,
    phoneNumber,
    pdfLink,
    telehealthAvailable,
    locations,
  } = promotion;
  const { city, state, zipcode, address1, address2, landmarkGuidance, distanceInMiles } =
    locations[0] || {};

  const allLocations = getPlaces(promotion, true);

  const isValidURL = (url) => {
    const regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&=]*)?/;
    return regex.exec(url) !== null;
  };

  const handleWebsiteClick = (url) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    openInNewTab(url);
  };

  const handlePhoneClick = () => {
    openInSameTab(`tel:${phoneNumber}`);
  };

  return (
    <div style={{ paddingTop: '1rem' }}>
      {resultType === PLACE_RESULT_TYPE && selectedPlaceProvider && (
        <button
          type="button"
          className={classes.goBackButton}
          onClick={() => {
            setState({
              selectedPlaceProvider: null,
            });
          }}
        >
          Back To Place
        </button>
      )}
      <Grid container alignItems="flex-start" className="profile-header">
        {logo && (
          <Grid item>
            <img alt="" style={{ height: 50, width: 50, margin: '4px 8px 0' }} src={logo} />
          </Grid>
        )}
        <Grid item style={{ flex: 1, overflow: 'hidden' }} id="active-result-title">
          <Typography variant="h2" className="no-margin no-bold profile-card-title">
            {programName}
            <Typography
              variant="subtitle1"
              component="span"
              className={`${classes.subtitle} profile-card-subtitle`}
            >
              {programSubname}
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        spacing={1}
        style={{ textAlign: 'center', marginTop: '1rem' }}
      >
        <PromoteBadges promotion={promotion} />
      </Grid>

      <Grid
        container
        justifyContent="center"
        spacing={1}
        style={{ textAlign: 'center', marginTop: 10 }}
      >
        <ProfileSectionWrap title="" style={{ backgroundColor: '#f7f8f8', margin: '4px 0' }}>
          <Typography className={`${classes.programDescription} profile-local-ranking-text`}>
            {programDescription}
          </Typography>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            style={{ textAlign: 'center', marginTop: 10 }}
          >
            <div style={{ display: 'flex' }}>
              {pdfLink && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.shareScheduleButton} profile-schedule-button learn-more-button`}
                  onClick={handleWebsiteClick(pdfLink, 'promote - pdf')}
                  role="link"
                  style={{ backgroundColor: '#fff', padding: '6px 22px 8px' }}
                >
                  Learn More
                </Button>
              )}
              {Boolean(getStartedLink) && (
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.shareScheduleButton} profile-schedule-button`}
                  onClick={handleWebsiteClick(getStartedLink, 'promote - get started')}
                  role="link"
                  style={{ padding: '6px 22px 8px' }}
                >
                  Get Started
                </Button>
              )}
            </div>
          </Grid>
        </ProfileSectionWrap>

        {detailedDescription && (
          <ProfileSectionWrap title="Details" style={{ margin: '25px 0 0 0' }}>
            <Typography className={`${classes.detailedDesciption}`}>
              <ReactMarkdown>{detailedDescription}</ReactMarkdown>
            </Typography>
          </ProfileSectionWrap>
        )}
      </Grid>

      <div className="profile-detail-section">
        {Boolean(address1 || phoneNumber || website) && (
          <ProfileSectionWrap title="Access">
            {phoneNumber && (
              <GridIconRow Icon={CallOutlinedIcon} id="call">
                <Button
                  role="link"
                  className={`${classes.buttonLink} profile-phone-number`}
                  onClick={handlePhoneClick}
                >
                  {phoneNumber}
                </Button>
              </GridIconRow>
            )}
            {address1 && (
              <GridIconRow img={geoTag} id="geo-tag">
                <Typography variant="body1">
                  <Link
                    className={`${classes.link} profile-link-address`}
                    href={buildLocationUrl(promotion.locations[0], MAPS_URL)}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`${address1} ${city} ${state} ${zipcode}. opens in new tab.`}
                  >
                    {address1}
                    {address2 && (
                      <>
                        <br aria-hidden="true" />
                        {address2}
                      </>
                    )}
                    <br aria-hidden="true" />
                    {city}, {state} {zipcode}
                    <br aria-hidden="true" />
                  </Link>
                  {landmarkGuidance && (
                    <span style={{ fontSize: '.85rem' }}>{landmarkGuidance}</span>
                  )}
                  {distanceInMiles && (
                    <span className="profile-address-distance" style={{ display: 'block' }}>
                      {parseFloat(distanceInMiles).toFixed(2)} miles away
                    </span>
                  )}
                </Typography>
              </GridIconRow>
            )}

            {telehealthAvailable && (
              <GridIconRow Icon={CheckCircleOutlineOutlinedIcon} id="telehealth">
                <Typography variant="body1">Telehealth</Typography>
              </GridIconRow>
            )}
            {accessAppLink && (
              <GridIconRow Icon={CallOutlinedIcon} id="AccessApp">
                <Button
                  className={`${classes.buttonLink} profile-website-link`}
                  onClick={handleWebsiteClick(accessAppLink, 'promote - access app')}
                  role="link"
                >
                  Download the app
                </Button>
              </GridIconRow>
            )}
            {accessChatLink && (
              <GridIconRow Icon={CallOutlinedIcon} id="AccessChat">
                <Button
                  className={`${classes.buttonLink} profile-website-link`}
                  onClick={handleWebsiteClick(accessChatLink, 'promote - access chat')}
                  role="link"
                >
                  Download the app
                </Button>
              </GridIconRow>
            )}

            {accessEmail && (
              <GridIconRow Icon={EmailOutlinedIcon} id="AccessEmail">
                <a
                  href={`mailto:${accessEmail}`}
                  className={`${classes.buttonLink} profile-website-link`}
                >
                  Email
                </a>
              </GridIconRow>
            )}
            {website && isValidURL(website) && (
              <GridIconRow Icon={OpenInBrowserOutlinedIcon} id="AccessWebsite">
                <Button
                  className={`${classes.buttonLink} profile-website-link`}
                  onClick={handleWebsiteClick(website, 'promote - website')}
                  role="link"
                >
                  Website
                </Button>
              </GridIconRow>
            )}
          </ProfileSectionWrap>
        )}

        {whenToUse && (
          <ProfileSectionWrap title="When To Use">
            <div className={`${classes.detailedDesciption}`}>
              <ul>
                {whenToUse.map((service, i) => (
                  <li key={Number(1 + i)}>{service}</li>
                ))}
              </ul>
            </div>
          </ProfileSectionWrap>
        )}

        {isProfilePage && locations.length > 0 && (
          <ProfileSectionWrap title="Location">
            <Grid item xs={12}>
              <ProfileMap closestLocation={locations[0]} allLocations={allLocations} />
            </Grid>
          </ProfileSectionWrap>
        )}
      </div>
    </div>
  );
}

PromoteProfile.defaultProps = {
  isProfilePage: false,
};

PromoteProfile.propTypes = {
  promotion: PromotionDataShape.isRequired,
  isProfilePage: PropTypes.bool,
};

export default PromoteProfile;
