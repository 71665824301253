/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { DARK_GREEN, LIGHT_BLUE } from 'models/Result/ResultModel';
import { MICROSOFT, POLK, WALMART } from 'utils/constants';
import LanguageIcon from '@material-ui/icons/Language';

// eslint-disable-next-line import/prefer-default-export
export const getPlaceNetworkContent = (client) => {
  const networkContent = {
    networkText: 'In Network',
    textColor: '',
    networkIcon: <LanguageIcon color="inherit" fontSize="inherit" />,
    showTooltip: false,
    showNetworkStatus: true,
  };

  // Walmart
  if (client === WALMART) {
    networkContent.textColor = DARK_GREEN;
  }

  // Microsoft
  if (client === MICROSOFT) {
    networkContent.showNetworkStatus = false;
  }

  // Polk
  if (client === POLK) {
    networkContent.textColor = LIGHT_BLUE;
  }

  return networkContent;
};
