import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { uniqueId } from 'lodash';

import faqContent from './faqContent';
import SupportPageSection from './SupportPageSection';

const useStyles = makeStyles((theme) => ({
  faq: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  question: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  answer: {},
}));

function FAQ({ question, answer }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.faq}>
      <Typography component="strong" variant="h4" className={classes.question}>
        {question}
      </Typography>
      <Typography className={classes.answer}>{answer}</Typography>
    </ListItem>
  );
}

FAQ.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default function FrequentlyAskedQuestions() {
  return (
    <SupportPageSection title="Frequently Asked Questions" id="faq">
      <List>
        {faqContent.map((faq) => (
          <FAQ question={faq.question} answer={faq.answer} key={`faq-${uniqueId()}}`} />
        ))}
      </List>
    </SupportPageSection>
  );
}
