/* eslint-disable consistent-return */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Modal from 'components/Modals/Modal';
import emboldIcon from 'assets/embold-icon.svg';
import { actions } from 'store/toolkit';
import { SUBSPECIALTY_MAPPING } from 'utils/constants';

const useStyles = makeStyles(() => ({
  modalButton: {
    textAlign: 'center',
    marginTop: 20,
  },
  modalHeader: {
    width: '95%',
    margin: '10px auto',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
  },
  emboldIcon: {
    width: 20,
    marginBottom: -3,
    marginRight: 5,
  },
  modalStyles: {
    '& [data-reach-dialog-content]': {
      padding: '15px 40px',
    },
  },
  specialtyItem: {
    fontSize: '.9rem',
    marginBottom: 10,
    '& img': {
      height: 20,
      marginBottom: -4,
      marginRight: 5,
    },
    color: 'grey',
  },
}));

function SpecialtiesModal({ profileData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { specialty, specialtyId } = profileData;
  const subspecialties = SUBSPECIALTY_MAPPING[specialtyId];

  if (!subspecialties) return;
  return (
    <Modal
      handleClose={() => {
        dispatch(actions.ui.closeProfileModal());
      }}
      open
      ariaId="specialties-modal"
      customStyles={classes.modalStyles}
      fullScreen={smDown}
    >
      <>
        <Typography
          variant="h2"
          className={`${classes.modalHeader} specialties-modal-header`}
          id="specialties-modal-title"
        >
          <img src={emboldIcon} className={classes.emboldIcon} alt="" /> Specialty Areas
        </Typography>

        <Grid item xs={12} container>
          <Grid style={{ textAlign: 'left' }} item id="specialties-modal-description">
            <Typography variant="body1" style={{ marginBottom: 10 }}>
              Specialty areas are calculated by Embold Health based on how frequently providers
              practice in that area.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 20 }}>
              {specialty} providers may practice in the following specialty areas:
            </Typography>
          </Grid>
        </Grid>
        <ul
          style={{
            paddingLeft: 0,
            width: 260,
            margin: '0 auto',
            maxWidth: '100%',
          }}
        >
          {subspecialties.map((spe) => (
            <li className={classes.specialtyItem} key={spe}>
              <p style={{ color: '#000000' }}>{spe}</p>
            </li>
          ))}
        </ul>
      </>
    </Modal>
  );
}

SpecialtiesModal.propTypes = {
  profileData: PropTypes.shape({
    specialty: PropTypes.string,
    specialtyId: PropTypes.number,
  }).isRequired,
};

export default SpecialtiesModal;
