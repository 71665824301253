import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AzureMapsProvider } from 'react-azure-maps';
import { useProviderScore } from 'hooks/Provider/';
import { _analyticsActions } from 'analytics/index';
import useFocusAnchors from 'utils/FocusRefContext';
import useReturnFocus from 'hooks/useReturnFocus';
import { useConfigValue } from 'config/state';
import { useStateValue } from 'state/index';
import { getPlaces } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import {
  PERCENTILE_THRESHOLD,
  IS_SURGERY_PLUS,
  SUBSPECIALTY_SECTION,
  COST_EFFICIENCY_SECTION,
  STRENGTHS_SECTION,
} from 'utils/constants';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button } from '@material-ui/core/';
import ShareIcon from '@material-ui/icons/Share';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import genderIconMale from 'assets/male-icon.svg'; // TODO TECH-3847 replace with custom icon
import genderIconFemale from 'assets/female-icon.svg'; // TODO TECH-3847 replace with custom icon
import outcareIcon from 'assets/outcare-icon.svg'; // TODO TECH-3847 replace with custom icon
import clusterPlaceIcon from 'assets/cluster-place-icon.svg';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import warningIcon from 'assets/decreasingicon.svg';
import ScoreIcon from 'components/ScoreIcon';
import IconLabel from 'components/Results/ResultCard/IconLabel';
import CoverageRibbon from 'components/Results/ResultCard/Ribbons/CoverageRibbon';
import LocationsMinimap from 'components/Modals/ChatModal/LocationsMinimap';
import Tooltip from 'components/Tooltip';
import HighPerformingRibbon from 'components/Results/ResultCard/Ribbons/HighPerformingRibbon';
import CheckOrXIcon from 'icons/CheckOrXIcon';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeDownAnimation': {
    from: {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    to: {
      opacity: 1,
    },
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: `${theme.spacing(1)}px`,
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  cardTitle: {
    lineHeight: 1.2,
    margin: '4px 0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiBox-root': {
      marginLeft: theme.spacing(1),
      flex: 1,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        display: 'block',
        marginTop: 0,
      },
    },
  },
  topRow: {},
  distanceContainer: {
    marginLeft: 'auto',
    width: 'auto',
    flexBasis: 'auto',
  },
  divider: {
    width: 2,
    marginRight: -2,
    marginTop: 0,
    marginBottom: 0,
  },
  horizontalDivider: {
    height: 1,
    margin: '6px 0px',
  },
  row: {
    padding: 2,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  section: {
    padding: '0px 8px',
  },
  specialties: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  costMore: {
    borderTop: '6px solid #39BB6B',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  costAverage: {
    borderTop: '6px solid #989B9D',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  detailsSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  costLess: {
    borderTop: '6px solid #F54650',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  naContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
  },
  shareButton: {
    display: 'flex',
    marginTop: 5,
    marginRight: 10,
    marginBottom: 5,
    minWidth: 110,
    '& svg': {
      marginRight: 4,
      height: 18,
    },
  },
  locationSection: {
    width: '100%',
    backgroundColor: '#f7f8f8',
    padding: 12,
    borderRadius: 10,
  },
  icon: {
    height: 20,
    width: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  specializationContainer: {
    width: '100%',
    backgroundColor: '#f7f8f8',
    padding: 8,
    borderRadius: 10,
    marginBottom: 8,
    display: 'flex',
  },
  iconLabel: {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  coverageDecreasing: {
    '& .MuiAvatar-root': {
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: -1.5,
      marginRight: '.15rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
}));

function ProviderCardLarge({ provider, coords, queryParams, subspecialtyId }) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    entityId,
    entityName,
    title,
    gender,
    subspecialties,
    diagnosingPercentile,
    treatmentPercentile,
    outcomesPercentile,
    scoreCareJourneyCost,
    specialty,
    outcareCompetent,
    languagesArray,
    benefitDecrease,
    schedulingUrl,
  } = provider;

  const score = useProviderScore(provider, subspecialtyId);
  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();

  const locationComponents = useSelector(select.location.locationComponents);
  const BENEFITS_CHANGE = useSelector(select.content.isTodayInBenefitChangeDateRange);
  const { city, state } = locationComponents;

  useEffect(() => {
    dispatch(_analyticsActions.chatProfileView({ coordinates: coords, providerData: provider }));
  }, [coords, dispatch, entityId, provider]);

  const [{ networkSlug }] = useStateValue();
  const {
    config: {
      SHOW_SCHEDULE_BUTTON,
      SHOW_SHARE,
      SHOW_OUTCARE,
      SHOW_HIGH_PERFORMING_RIBBON,
      CONTENT: {
        BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT,
        BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP,
      },
    },
    getNetworkConfigValue,
  } = useConfigValue();
  const isSurgeryPlusNetwork = getNetworkConfigValue(networkSlug, IS_SURGERY_PLUS) ?? false;

  const locationCity = queryParams.city || city;
  const locationState = queryParams.stateAbbreviation || state;

  const uniquePlaces = getPlaces(provider);
  const placesAcceptingCount = uniquePlaces?.reduce((acceptingCount, place) => {
    // eslint-disable-next-line no-param-reassign
    if (place.acceptingNewPatients) acceptingCount += 1;
    return acceptingCount;
  }, 0);
  const nwa = networkSlug === 'northwestarkansas' || networkSlug === 'bcbsarkansas';

  const showDiagnosing = diagnosingPercentile && diagnosingPercentile >= PERCENTILE_THRESHOLD;
  const showTreatment = treatmentPercentile && treatmentPercentile >= PERCENTILE_THRESHOLD;
  const showOutcomes = outcomesPercentile && outcomesPercentile >= PERCENTILE_THRESHOLD;

  const getStrengths = () => {
    let strengths = '';
    if (showDiagnosing) strengths = 'Diagnosing';
    if (showTreatment) strengths = strengths.length ? `${strengths}, Treatment` : 'Treatment';
    if (showOutcomes) strengths = strengths.length ? `${strengths}, Outcomes` : 'Outcomes';

    return strengths;
  };

  const handleShareClick = () => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [provider] }));
  };

  const handleScheduleClick = () => {
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: provider }));
  };

  const costEfficiencyMap = {
    1: 'Less Efficient',
    2: 'Average',
    3: 'More Efficient',
  };

  return (
    <Grid item xs={12} style={{ paddingTop: 0 }}>
      <Grid className={classes.container}>
        {!smDown && (
          <Grid container className={classes.row}>
            <Grid style={{ marginLeft: 'auto' }}>
              <Link
                to={`/profile/provider/${entityId}?network_slug=${networkSlug}&location=${
                  coords.latitude
                },${coords.longitude}&location_input=${locationCity}, ${locationState}&city=${
                  locationCity || ''
                }&state=${locationState || ''}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                More Details <ChevronRight />
              </Link>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.row}>
          <Grid item xs={smDown ? 12 : 6} className={classes.section}>
            {!smDown && (
              <Grid className={classes.titleRow}>
                {score && (
                  <div style={{ marginRight: 8 }}>
                    <Typography variant="srOnly">{score.name}</Typography>
                    <ScoreIcon score={score.value} coloredText emboldRecommendedSize={30} />
                  </div>
                )}

                <Box style={{ marginLeft: 0 }}>
                  <Typography
                    variant="h3"
                    classes={{
                      root: classes.cardTitle,
                    }}
                  >
                    {entityName}
                    {Boolean(title) && ` ${title}`}
                  </Typography>
                  {specialty && (
                    <Typography variant="body1" style={{ fontSize: '.9rem', marginTop: '-8px' }}>
                      {specialty}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            {SHOW_HIGH_PERFORMING_RIBBON && (
              <div style={{ marginLeft: 7 }}>
                <HighPerformingRibbon result={provider} />
              </div>
            )}
            <CoverageRibbon result={provider} />

            {BENEFITS_CHANGE && benefitDecrease && !nwa && (
              <Tooltip
                title={BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT}
                message={BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP}
              >
                <IconLabel img={warningIcon} className={classes.coverageDecreasing}>
                  {BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT}
                </IconLabel>
              </Tooltip>
            )}
            {(gender === 'Female' || gender === 'Male') && (
              <IconLabel img={gender === 'Male' ? genderIconMale : genderIconFemale}>
                {gender}
              </IconLabel>
            )}
            {SHOW_OUTCARE && outcareCompetent && (
              <IconLabel img={outcareIcon} textClass="accepting">
                LGBTQ+ Competent
              </IconLabel>
            )}
            {languagesArray?.length > 0 && (
              <IconLabel
                style={{
                  flexWrap: 'nowrap',
                }}
                img={<RecordVoiceOverOutlinedIcon />}
                id="language"
                TypographyProps={{
                  style: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
                }}
              >
                {languagesArray.join(', ')}
              </IconLabel>
            )}
            {placesAcceptingCount > 0 ? (
              <IconLabel img={<CheckOrXIcon checked />} textClass="accepting">
                Accepting patients
              </IconLabel>
            ) : (
              <IconLabel img={<CheckOrXIcon checked={false} />} textClass="not-accepting">
                Not accepting patients
              </IconLabel>
            )}
            {uniquePlaces?.length > 0 && (
              <IconLabel img={clusterPlaceIcon} textClass="accepting">
                {uniquePlaces?.length} nearby locations
              </IconLabel>
            )}
            {!smDown && (
              <Grid container>
                {SHOW_SHARE && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.shareButton}
                    onClick={handleShareClick}
                    size="small"
                    ref={shareButtonRef}
                  >
                    <ShareIcon />
                    Share
                  </Button>
                )}
                {SHOW_SCHEDULE_BUTTON &&
                  !isSurgeryPlusNetwork &&
                  (schedulingUrl ? (
                    <Button
                      className={classes.shareButton}
                      color="primary"
                      variant="outlined"
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                      href={schedulingUrl}
                    >
                      <CalendarMonthIcon />
                      Schedule
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="outlined"
                      className={classes.shareButton}
                      onClick={handleScheduleClick}
                      size="small"
                      ref={callButtonRef}
                    >
                      Need Help?
                    </Button>
                  ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={smDown ? 12 : 6} className={classes.specialties}>
            {subspecialties && subspecialties.length > 0 && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <img
                    className={classes.icon}
                    src={SUBSPECIALTY_SECTION.icon}
                    alt="provider specialties icon"
                  />
                </Grid>
                <Grid>
                  <Tooltip
                    title={SUBSPECIALTY_SECTION.title}
                    message={SUBSPECIALTY_SECTION.description}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      {SUBSPECIALTY_SECTION.title}:{' '}
                    </Typography>
                  </Tooltip>
                  <Typography>
                    {subspecialties.length === 1
                      ? subspecialties[0].subspecialtyName
                      : subspecialties
                          .map((subspecialty) => subspecialty.subspecialtyName)
                          .join(', ')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(showDiagnosing || showOutcomes || showTreatment) && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <img
                    className={classes.icon}
                    src={STRENGTHS_SECTION.icon}
                    alt="provider strengths icon"
                  />
                </Grid>
                <Grid>
                  <Grid>
                    <Tooltip
                      title={STRENGTHS_SECTION.title}
                      message={STRENGTHS_SECTION.description}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {STRENGTHS_SECTION.title}:{' '}
                      </Typography>
                    </Tooltip>
                    <Typography>{getStrengths()}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {scoreCareJourneyCost && costEfficiencyMap[scoreCareJourneyCost] && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <img
                    className={classes.icon}
                    src={COST_EFFICIENCY_SECTION.icon}
                    alt="provider cost efficiency icon"
                  />
                </Grid>
                <Grid>
                  <Grid>
                    <Tooltip
                      title={COST_EFFICIENCY_SECTION.title}
                      message={COST_EFFICIENCY_SECTION.description}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {COST_EFFICIENCY_SECTION.title}:{' '}
                      </Typography>
                    </Tooltip>
                    <Typography>{costEfficiencyMap[scoreCareJourneyCost]}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item xs={12} className={classes.locationSection}>
            <AzureMapsProvider>
              <LocationsMinimap provider={provider} coords={coords} />
            </AzureMapsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ProviderCardLarge.propTypes = {
  provider: PropTypes.shape({
    entityId: PropTypes.string,
    entityName: PropTypes.string,
    title: PropTypes.string,
    gender: PropTypes.string,
    closestVisibleLocation: PropTypes.shape({
      city: PropTypes.string,
      address_1: PropTypes.string,
      address_2: PropTypes.string,
      is_wheelchair_accessible: PropTypes.bool,
      zipcode: PropTypes.string,
      distanceInMiles: PropTypes.number,
      product_ids: PropTypes.arrayOf(PropTypes.string),
      phone: PropTypes.string,
      name: PropTypes.string,
      specialization: PropTypes.string,
      location: PropTypes.shape({
        lon: PropTypes.number,
        lat: PropTypes.number,
      }),
      state: PropTypes.string,
      fax: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    subspecialties: PropTypes.arrayOf(
      PropTypes.shape({
        subspecialtyId: PropTypes.number.isRequired,
        subspecialtyName: PropTypes.string.isRequired,
        description: PropTypes.string,
      })
    ),
    diagnosingPercentile: PropTypes.number,
    treatmentPercentile: PropTypes.number,
    outcomesPercentile: PropTypes.number,
    scoreCareJourneyCost: PropTypes.number,
    specialty: PropTypes.string,
    places: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    outcareCompetent: PropTypes.bool,
    languagesArray: PropTypes.arrayOf(PropTypes.string),
    benefitDecrease: PropTypes.bool,
    schedulingUrl: PropTypes.string,
  }).isRequired,
  coords: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  queryParams: PropTypes.shape({
    specialties: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyId: PropTypes.number,
        specialtyName: PropTypes.string,
      })
    ),
    subspecialties: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyId: PropTypes.number,
        specialtyName: PropTypes.string,
        subspecialtyId: PropTypes.number,
        subspecialtyName: PropTypes.string,
      })
    ),
    radius: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    lgbtqCompetent: PropTypes.bool,
    lookingForDiagnosis: PropTypes.bool,
    lookingForTreatment: PropTypes.bool,
    gender: PropTypes.string,
    languagesSpoken: PropTypes.arrayOf(PropTypes.string),
    city: PropTypes.string,
    stateAbbreviation: PropTypes.string,
  }).isRequired,
  subspecialtyId: PropTypes.string,
};

ProviderCardLarge.defaultProps = {
  subspecialtyId: undefined,
};

export default ProviderCardLarge;
