import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useStateValue } from 'state';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import { PLACE_RESULT_TYPE } from 'utils/constants';
import IconLabel from 'components/Results/ResultCard/IconLabel';
import { useGetNetworkContent } from 'hooks/Provider';

const LIGHT_BLUE = 'lightBlue';
const DARK_GREEN = 'darkGreen';
const APPLE_RED = 'appleRed';

const useStyles = makeStyles((theme) => ({
  benefitText: {
    fontSize: '.85rem',
    fontWeight: 600,
    marginTop: 2,
  },
  iconWrapper: {
    '& .icon-avatar': {
      height: '1.5rem',
      width: '1.5rem',
    },
  },
  [LIGHT_BLUE]: {
    color: theme.palette.lightBlue,
  },
  [DARK_GREEN]: {
    color: theme.palette.darkGreen,
  },
  [APPLE_RED]: {
    color: theme.palette.appleRed,
  },
}));

function CoverageRibbon({ textOnly, result }) {
  const classes = useStyles();

  const [{ resultType }] = useStateValue();

  const getNetworkContent = useGetNetworkContent();
  const networkContent = getNetworkContent(result);

  const isPlaceSearch = resultType === PLACE_RESULT_TYPE;

  if (textOnly) {
    return networkContent.networkText;
  }

  return networkContent.showNetworkStatus ? (
    <IconLabel
      img={networkContent.networkIcon}
      textClass={`${classes.benefitText} ${
        networkContent.textColor ? classes[networkContent.textColor] : ''
      }`}
      className={cn({
        [classes.iconWrapper]: isPlaceSearch,
      })}
    >
      {networkContent.networkText}
      {networkContent.showTooltip && !isPlaceSearch && (
        <Tooltip
          title={networkContent.networkText}
          message={networkContent.tooltipMessage}
          customAriaLabel={`${result.entityName}. More information about ${networkContent.networkText}`}
        />
      )}
    </IconLabel>
  ) : null;
}

CoverageRibbon.propTypes = {
  textOnly: PropTypes.bool,
  result: PropTypes.shape({
    curatedOutNetwork: PropTypes.string,
    healthPlan: PropTypes.string,
    entityName: PropTypes.string,
    curatedOut: PropTypes.bool,
  }).isRequired,
};

CoverageRibbon.defaultProps = {
  textOnly: false,
};

export default CoverageRibbon;
