/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';

// export const WMT_LOGIN_REGEX =
//   /^(sanantonio|houston|dallasfw|tulsaokc|northeastflorida|centralflorida|northwestarkansas)$/;
const dynamicConfig = {};
const dynamicContentConfig = {};

export const buildDynamicConfig = (config) => {
  const {
    schedule_phone_number: schedulePhoneNumber,
    is_closed_access: isClosedAccess,
    has_portal_integration: hasPortalIntegration,
    show_disclaimer: showDisclaimer,
    support_link: supportLink,
    network_select_text: networkSelectText,
    show_filter_count: showFilterCounts,
    show_share: showShare,
    show_schedule_button: showScheduleButton,
    show_cost: showCost,
    show_provider_credentials_filter: showProviderCredentialsFilter,
    use_new_specialty_autocomplete: useNewSpecialtyAutocomplete,
    networks,
    disclaimer_text: disclaimerText,
    maps_url: mapsURL,
    show_hospital_affiliations: showHospitalAffiliations,
    search_error_text: searchErrorText,
    show_expand_search: showExpandSearch,
    benefits_change_date_range: benefitsChangeDateRange,
    benefits_change_coverage_decreasing_text: benefitsChangeCoverageDecreasingText,
    benefits_change_coverage_decreasing_tooltip: benefitsChangeCoverageDecreasingTooltip,
    benefits_change_coverage_change_title: benefitsChangeCoverageChangeTitle,
    benefits_change_higher_coverage_gauge_text: benefitsChangeHigherCoverageGuageText,
    benefits_change_lower_coverage_gauge_text: benefitsChangeLowerCoverageGuageText,
    benefits_change_higher_benefit_modal_title: benefitsChangeHigherBenefitModalTitle,
    benefits_change_higher_benefit_modal_text: benefitsChangeHigherBenefitModalText,
    benefits_change_lower_benefit_modal_title: benefitsChangeLowerBenefitModalTitle,
    benefits_change_lower_benefit_modal_text: benefitsChangeLowerBenefitModalText,
    header_image: headerImage,
    login_image: loginImage,
    show_service_cost: showServiceCost,
    show_subspecialty_scores: showSubspecialtyScores,
    featured_provider_banner_text: featuredProviderBannerText,
    featured_provider_link: featuredProviderLink,
    featured_provider_tooltip_text: featuredProviderTooltipText,
    featured_facility_banner_text: featuredFacilityBannerText,
    featured_facility_link: featuredFacilityLink,
    featured_facility_tooltip_text: featuredFacilityTooltipText,
    show_embold_recommended: showEmboldRecommended,
    surgery_plus_phone_number: surgeryPlusPhoneNumber,
    show_chat: showChat,
    show_chat_scenario: showChatScenario,
  } = config;

  dynamicConfig.CLOSED_ACCESS = isClosedAccess;
  dynamicConfig.SHOW_DISCLAIMER = showDisclaimer;
  dynamicConfig.HAS_PORTAL_INTEGRATION = hasPortalIntegration;
  dynamicConfig.SHOW_FILTER_COUNTS = showFilterCounts;
  dynamicConfig.USE_NEW_SPECIALTY_AUTOCOMPLETE = useNewSpecialtyAutocomplete ?? false;
  dynamicConfig.DISCLAIMER_TEXT = disclaimerText;
  dynamicConfig.SHOW_SHARE = showShare ?? true;
  dynamicConfig.SHOW_SCHEDULE_BUTTON = showScheduleButton ?? true;
  dynamicConfig.SHOW_COST = showCost;
  dynamicConfig.SHOW_PROVIDER_CREDENTIALS_FILTER = showProviderCredentialsFilter;
  dynamicConfig.SHOW_EXPAND_SEARCH = showExpandSearch;
  // Benefits Change
  dynamicConfig.BENEFITS_CHANGE_DATE_RANGE = benefitsChangeDateRange;
  dynamicConfig.SHOW_SERVICE_COST = showServiceCost ?? false;
  dynamicConfig.SHOW_SUBSPECIALTY_SCORES = showSubspecialtyScores ?? false;
  dynamicConfig.SHOW_EMBOLD_RECOMMENDED = showEmboldRecommended ?? false;
  dynamicConfig.SURGERY_PLUS_PHONE_NUMBER = surgeryPlusPhoneNumber;
  dynamicConfig.SHOW_CHAT = showChat ?? false;
  dynamicConfig.SHOW_CHAT_SCENARIO = showChatScenario ?? false;

  dynamicConfig.REGION_SELECTOR_CODES =
    networks &&
    networks.reduce((acc, val) => {
      acc[val.slug] = {
        text: val.display_name,
        slug: val.slug,
        networkGroupName: val.network_group_name || null,
      };
      if (val.config && !isEmpty(val.config)) {
        // eslint-disable-next-line no-shadow
        acc[val.slug].config = Object.entries(val.config).reduce((config, [key, val]) => {
          // eslint-disable-next-line no-param-reassign
          config[key.toUpperCase()] = val;
          return config;
        }, {});
      }
      return acc;
    }, {});

  // dynamicConfig = {
  // API_TOKEN: token,
  // EMBOLD_CLIENT: 'walmart',
  // EMBOLD_CLIENT_SLUG: 'wmt',
  // HIGH_QUALITY_INTRO_MODAL: false,
  // api & auth
  // MAPBOX_TOKEN:
  //   'pk.eyJ1IjoiYWtuZWlzbGVyIiwiYSI6ImNqdTNrNGFleDBud2EzeW43NGsyZ3I5YmwifQ.IQRKNzzWOjtJu29Gifkxcg',
  // AUTOCOMPLETE_RADIUS: AUTOCOMPLETE_RADIUS_3,

  // toggles
  // SHOW_TELEHEALTH: true,
  // SHOW_WHEELCHAIR_ACCESSIBLE: true,

  // SHOW_BCBS_LINK: false,
  // SHOW_OUTCARE: true,
  // USE_BROWSER_LOCATION: true,
  // USE_GENDER_ICONS: true,
  // ENABLE_STYLEGUIDE_ROUTE: false,
  // ENABLE_PLACE_SEARCH: true,
  // MIN_COMPARE_COUNT: 2,
  // MAX_COMPARE_COUNT: 3,
  // SHOW_REGION_SELECTOR: true,

  // login
  // LOGIN_COOKIE_AUTH_REGEX: WMT_LOGIN_REGEX, // https://regex101.com/
  // LOGIN_NETWORK_NAME: 'wmtLoggedIn', // change to something like ${CLIENT_SLUG}_COOKIE
  // NETWORK_SELECT_API_PATH: 'analytics/network-select/',

  // laymans
  // SHOW_DR_SCORE: true,
  // SHOW_SUBSPECIALTIES: true,
  // SHOW_PROVIDER_STRENGTHS: true,
  // SHOW_HIGH_PERFORMING_RIBBON: false,
  // SHOW_LAYMANS_INTRO_MODAL: true,

  // other
  // HIDE_CLIENT_PROMOTE_HEADER: false,
  // };
  dynamicContentConfig.UPDATE_REGION_TEXT = networkSelectText;
  dynamicContentConfig.SCHEDULE_PHONE_NUMBER = schedulePhoneNumber;
  dynamicContentConfig.SUPPORT_LINK = supportLink;
  if (mapsURL) {
    dynamicContentConfig.MAPS_URL = mapsURL;
  }
  if (searchErrorText) {
    dynamicContentConfig.SEARCH_ERROR_TEXT = searchErrorText;
  }

  if (showHospitalAffiliations) {
    dynamicContentConfig.SHOW_HOSPITAL_AFFILIATIONS = showHospitalAffiliations;
  }
  // Benefits Change
  dynamicContentConfig.BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT =
    benefitsChangeCoverageDecreasingText || '';
  dynamicContentConfig.BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP =
    benefitsChangeCoverageDecreasingTooltip || '';
  dynamicContentConfig.BENEFITS_CHANGE_COVERAGE_CHANGE_TITLE =
    benefitsChangeCoverageChangeTitle || '';
  dynamicContentConfig.BENEFITS_CHANGE_HIGHER_COVERAGE_GAUGE_TEXT =
    benefitsChangeHigherCoverageGuageText || '';
  dynamicContentConfig.BENEFITS_CHANGE_LOWER_COVERAGE_GAUGE_TEXT =
    benefitsChangeLowerCoverageGuageText || '';
  dynamicContentConfig.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TITLE =
    benefitsChangeHigherBenefitModalTitle || '';
  dynamicContentConfig.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TEXT =
    benefitsChangeHigherBenefitModalText || '';
  dynamicContentConfig.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TITLE =
    benefitsChangeLowerBenefitModalTitle || '';
  dynamicContentConfig.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TEXT =
    benefitsChangeLowerBenefitModalText || '';

  if (headerImage) {
    dynamicConfig.HEADER_IMAGE = headerImage;
  }

  if (loginImage) {
    dynamicConfig.LOGIN_IMAGE = loginImage;
  }

  // Featured provider banner config
  dynamicConfig.FEATURED_PROVIDER_BANNER_TEXT = featuredProviderBannerText || '';
  dynamicConfig.FEATURED_PROVIDER_LINK = featuredProviderLink || '';
  dynamicConfig.FEATURED_PROVIDER_TOOLTIP_TEXT = featuredProviderTooltipText || '';
  dynamicConfig.FEATURED_FACILITY_BANNER_TEXT = featuredFacilityBannerText || '';
  dynamicConfig.FEATURED_FACILITY_LINK = featuredFacilityLink || '';
  dynamicConfig.FEATURED_FACILITY_TOOLTIP_TEXT = featuredFacilityTooltipText || '';

  return { dynamicConfig, dynamicContentConfig };
};
