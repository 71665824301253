import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import AddressLink from 'components/Profile/AddressLink';

export default function PlaceAddressCell({ placeId }) {
  const place = useSelector(select.place(placeId).data);

  if (!place) return null;

  return <AddressLink address={place} />;
}

PlaceAddressCell.propTypes = {
  placeId: PropTypes.string.isRequired,
};
