import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import ConditionalWrapper from 'components/ConditionalWrapper';

const useStyles = makeStyles({
  root: {
    margin: '6px 16px 6px 0',
    lineHeight: 1.2,
    fontSize: '1rem',
    '&:last-child': {
      marginRight: 0,
    },
  },
  iconButton: {
    display: 'inline-block',
    padding: 0,
    overflow: 'visible',
    '&:focus:after': {
      top: '-10%',
      bottom: '-10%',
      left: '-20%',
      right: '-20%',
    },
  },
});

function Badge({ children, src, customSvg, onClick, ...props }) {
  const classes = useStyles();

  const button = (childProps) => (
    <Button onClick={onClick} className={classes.iconButton}>
      {childProps}
    </Button>
  );
  return (
    <Grid classes={{ root: classes.root }} {...props}>
      <ConditionalWrapper condition={Boolean(onClick)} wrapper={button(children)}>
        {customSvg || <img src={src} alt="" style={{ width: 56, height: 42 }} aria-hidden="true" />}
        <br aria-hidden="true" />
        <Typography variant="caption" style={{ display: 'block', maxWidth: '125px' }}>
          {children}
        </Typography>
      </ConditionalWrapper>
    </Grid>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  src: PropTypes.node,
  customSvg: PropTypes.node,
  onClick: PropTypes.func,
};

export default Badge;
