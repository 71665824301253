import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import AddressLink from 'components/Profile/AddressLink';

export default function NearestLocationCell({ providerId }) {
  const nearestLocation = useSelector(select.provider(providerId).closestLocation);

  if (!nearestLocation) return null;

  return <AddressLink address={nearestLocation} />;
}

NearestLocationCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
