/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { get, some } from 'lodash';
import {
  AzureMapFeature,
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
} from 'react-azure-maps';

import { useStateValue } from 'state';
import { getBounds, hashForKey } from 'utils/utils';
import { PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';
import { actions, constants } from 'store/toolkit';
import LocationPopup from 'components/Map/LocationPopup';
import useFocusAnchors from 'utils/FocusRefContext';

function ClusterLayer({
  selectedProviderLocation,
  setSelectedProviderLocation,
  setShowAllLocations,
  handleCollapseList,
  providerLocations,
  isMiniMap,
  showPopup = true,
}) {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const selectedLocationId = get(selectedProviderLocation, 'id');
  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();

  const [hoverId, updateHoverId] = useState(null);
  const [locationToShow, setLocation] = useState(selectedProviderLocation);
  const maxClusterLeaves = 50;

  const [{ results, activeResult, showActiveResultLocations, resultType }, setState] =
    useStateValue();

  useEffect(() => {
    if (!isMiniMap) {
      setState({ showActiveResultLocations: false });
      setSelectedProviderLocation({});
      setShowAllLocations(false);
      setLocation(
        activeResult?.closestVisibleLocation
          ? {
              ...activeResult.closestVisibleLocation,
              clientFeaturedLocation: activeResult.clientFeatured,
            }
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeResult]);

  useEffect(() => {
    if (isMiniMap && selectedProviderLocation === null) {
      setLocation(null);
    } else if (selectedProviderLocation?.address1) {
      setLocation(selectedProviderLocation);
    }
  }, [selectedProviderLocation?.address1, isMiniMap]);

  const locationSelectors = {
    [PLACE_RESULT_TYPE]: {
      getKey: (place) => place.id,
      getIsActiveMarker: (place) => place.id === activeResult.id,
      getCoordinates: (place) => [place.longitude, place.latitude],
      getIcon: (place, isActiveMarker) =>
        isActiveMarker || hoverId === place.id ? 'place-icon-blue' : 'place-icon-dark-blue',
    },
    [PROVIDER_RESULT_TYPE]: {
      getKey: (provider) => provider.entityId,
      getIsActiveMarker: (provider) => provider.entityId === activeResult.entityId,
      getCoordinates: (provider) => [
        provider.closestVisibleLocation.longitude,
        provider.closestVisibleLocation.latitude,
      ],
      getIcon: (provider, isActiveMarker) =>
        isActiveMarker || hoverId === provider.entityId ? 'marker-blue' : 'marker-darkblue',
    },
    providerLocation: {
      getKey: (location) => hashForKey(location.address1).toString(),
      getIsActiveMarker: (location) =>
        hashForKey(location.address1).toString() ===
        hashForKey(locationToShow?.address1 || 1).toString(),
      getCoordinates: (location) => [location.longitude, location.latitude],
      getIcon: (location, isActiveMarker) =>
        isActiveMarker || hoverId === hashForKey(location.address1).toString()
          ? 'marker-blue'
          : 'marker-darkblue',
    },
  };

  // click handlers
  const handleMarkerClick = (e) => {
    const { location, selectorType } = e.shapes[0].data.properties;
    if (selectorType === 'providerLocation') {
      handleLocationMarkerClick(e, location);
      return;
    }
    setState({
      activeResult: location,
      clusterTabs: [],
    });
    handleCollapseList();
    e.originalEvent.stopPropagation();
    e.preventDefault();
  };

  const handleLocationMarkerClick = (e, location) => {
    if (!isMiniMap && get(location, 'id') === selectedLocationId) {
      setSelectedProviderLocation({});

      setLocation(location);
    } else {
      setSelectedProviderLocation(location);
    }
    e.originalEvent.stopPropagation();
    e.preventDefault();
  };

  const handleClusterExpand = async (e, clusterId) => {
    e.map.sources
      .getById('cluster-provider')
      .getClusterLeaves(clusterId, maxClusterLeaves)
      .then((clusterLeaves) => {
        const clusterLocales = [];
        const clusterProviders = [];
        focusAnchors.tooltip = null;
        dispatch(actions.tooltip.close());
        clusterLeaves.forEach((clusterProvider) => {
          const { location } = clusterProvider.data.properties;
          const providerLocale = {
            // format for getBounds function
            latitude: get(location.closestVisibleLocation || location, 'latitude'),
            longitude: get(location.closestVisibleLocation || location, 'longitude'),
          };
          clusterProviders.push(location);
          if (!some(clusterLocales, providerLocale)) {
            clusterLocales.push(providerLocale);
          }
        });

        const [swLng, swLat, neLng, neLat] = getBounds(clusterLocales);
        dispatch(
          actions.map.moveBoundsTo({
            bounds: {
              sw: { latitude: swLat, longitude: swLng },
              ne: { latitude: neLat, longitude: neLng },
            },
            offset: smDown,
          })
        );
        setState({ clusterTabs: [] });

        handleCollapseList();
      });
  };

  function getClusterProviders(e, clusterId, clusterCenter) {
    e.map.sources
      .getById('cluster-provider')
      .getClusterLeaves(clusterId, maxClusterLeaves)
      .then((clusterChildren) => {
        const clusterProviders = [];
        clusterChildren.forEach((clusterProvider) => {
          const { location } = clusterProvider.data.properties;
          clusterProviders.push(location);
        });
        const [longitude, latitude] = clusterCenter;

        dispatch(actions.map.moveCenterTo({ latitude, longitude, offset: smDown }));
        setState({
          clusterTabs: clusterProviders,
          clusterTabsIndex: 0,
          activeResult: get(clusterProviders, '[0]', {}),
          lastOpenResult: get(clusterProviders, '[0].id') || get(clusterProviders, '[0].entityId'),
        });
      });
  }

  function clusterClicked(e) {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
      // Get the clustered point from the event.
      const cluster = e.shapes[0];
      const clusterCenter = cluster.geometry.coordinates;
      const clusterId = cluster.properties.cluster_id;
      setState({ activeResult: {} });

      // Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      e.map.sources
        .getById('cluster-provider')
        .getClusterExpansionZoom(clusterId)
        .then((zoom) => {
          const expandCluster = zoom < constants.map.MAX_ZOOM_LEVEL;

          if (!expandCluster) {
            getClusterProviders(e, clusterId, clusterCenter);
          } else {
            handleClusterExpand(e, clusterId);
          }
        });
    }
    e.originalEvent.stopPropagation();
  }

  const locations = showActiveResultLocations || isMiniMap ? providerLocations : results;
  const selectorType = showActiveResultLocations || isMiniMap ? 'providerLocation' : resultType;

  const markerSize = isMiniMap ? 0.6 : 1;
  const activeMarkerSize = isMiniMap ? 0.9 : 1.2;

  return (
    <AzureMapDataSourceProvider
      id="cluster-provider"
      options={{
        cluster: true,
        clusterRadius: 20,
      }}
    >
      <AzureMapLayerProvider
        id="provider-marker-layer"
        type="SymbolLayer"
        options={{
          filter: ['!', ['has', 'point_count']],
          iconOptions: {
            image: ['get', 'icon'],
            size: ['get', 'size'],
          },
        }}
        events={{
          click: handleMarkerClick,
          mouseover: (e) => {
            !smDown && updateHoverId(e.shapes[0].data?.id);
            e.map.getCanvasContainer().style.cursor = 'pointer';
          },
          mouseleave: (e) => {
            !smDown && updateHoverId(null);
            e.map.getCanvasContainer().style.cursor = 'grab';
          },
        }}
      />
      <AzureMapLayerProvider
        id="cluster-layer"
        type="BubbleLayer"
        options={{
          color: ['case', ['==', ['id'], hoverId], '#1A73AA', '#132257'],
          filter: ['has', 'point_count'],
          iconOptions: {
            image: 'none',
          },
          strokeWidth: 0,
          radius: ['step', ['get', 'point_count'], 18, 3, 20, 5, 22, 7, 24, 10, 26],
          opacity: 1,
        }}
        events={{
          click: clusterClicked,
          mouseover: (e) => {
            e.map.getCanvasContainer().style.cursor = 'pointer';
            !smDown && updateHoverId(e.shapes[0].properties?.cluster_id);
          },
          mousemove: (e) => {
            !smDown && updateHoverId(e.shapes[0].properties?.cluster_id);
          },
          mouseleave: (e) => {
            e.map.getCanvasContainer().style.cursor = 'grab';
            !smDown && updateHoverId(null);
          },
        }}
      />
      <AzureMapLayerProvider
        id="cluster-count-layer"
        type="SymbolLayer"
        options={{
          filter: ['has', 'point_count'],
          iconOptions: {
            image: 'none',
          },
          textOptions: {
            textField: ['get', 'point_count_abbreviated'],
            offset: [0, 0.4],
            color: 'white',
          },
        }}
      />
      {locations.map((location) => {
        const isActiveMarker = locationSelectors[selectorType].getIsActiveMarker(location);
        const properties = {
          icon: locationSelectors[selectorType].getIcon(location, isActiveMarker),
          size: isActiveMarker ? activeMarkerSize : markerSize,
          location,
          selectorType,
        };

        return (
          <AzureMapFeature
            key={locationSelectors[selectorType].getKey(location)}
            id={locationSelectors[selectorType].getKey(location)}
            variant="shape"
            type="Point"
            coordinate={locationSelectors[selectorType].getCoordinates(location)}
            properties={properties}
            setProperties={properties}
          />
        );
      })}
      {showPopup && locationToShow && <LocationPopup selectedProviderLocation={locationToShow} />}
    </AzureMapDataSourceProvider>
  );
}

export default ClusterLayer;
