import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { select } from 'store/toolkit';
import LoadingSpinner from 'components/LoadingSpinner';
import PromotionPreviewCard from './PromotionPreviewCard';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingInlineStart: 0, // override the padding from being a ul element
    margin: 0,
  },
  loadingSpinner: {
    // overrides to the loading spinner default styles
    background: 'none',
    position: 'relative',
  },
  emptyText: {
    margin: theme.spacing(2),
    display: 'block',
  },
  caption: {
    fontSize: '.8rem',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[700],
  },
}));

export default function ExploreBenefits() {
  const classes = useStyles();
  const isFetchingPromotions = useSelector(select.promotions.isLoading);
  const filteredPromotions = useSelector(select.promotions.filteredByRadius);

  let listContent;
  if (isFetchingPromotions) {
    listContent = <LoadingSpinner loading className={classes.loadingSpinner} />;
  } else if (!filteredPromotions.length) {
    // this component currently does not render with no filteredPromotions, but this text provides a fallback
    listContent = (
      <Typography
        className={`${classes.sectionText} ${classes.emptyText}`}
        variant="h3"
        component="strong"
      >
        There are no benefits for your current network
      </Typography>
    );
  } else {
    listContent = (
      <Grid container className={classes.list} justifyContent="flex-start" component="ul">
        {filteredPromotions.map((promotion) => (
          <PromotionPreviewCard
            promotion={promotion}
            key={`promotion-preview-card-${promotion.id}`}
            xs={12}
            sm={6}
            md={4}
            lg={3}
          />
        ))}
      </Grid>
    );
  }

  return (
    <Grid>
      {listContent}
      <Typography className={classes.caption}>
        Available benefits may differ by location.
      </Typography>
    </Grid>
  );
}
