import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, POLK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token b436afd7ec0e592afc47d5ccd2b2dd9d90bcba50',
  staging: 'Token e85a85fbb2a772a152b055fb83184a44c4594268',
  prod: 'Token f8c8f063bbb77b10da354cd9dc046a13114577d3',
};

const token = selectToken(apiTokens);

export const POLK_LOGIN_REGEX = /^(polkcountymember|bcbscustomerservice|eversideclinics)$/;

export const polkConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'polk',
  EMBOLD_CLIENT: POLK,
  EMBOLD_CLIENT_SLUG: 'polk',
  THEME: standardTheme,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Lakeland,
  FUSION_ENABLED: true,
  USE_BROWSER_LOCATION: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_NETWORK_NAME: 'polkUserId',
  LOGIN_COOKIE_AUTH_REGEX: POLK_LOGIN_REGEX, // https://regex101.com/
};
