/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, SOCT_PROVIDER_LOOKUP } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 70397bf834f307e965d40b8ebb9a0eaf9807f4e6',
  staging: 'Token 5d594fe981620ea7b15615885c795ec5859ba461',
  prod: 'Token 7e18e7532ed2f16ce9d39318c2cd31f02b2b0570',
};

const token = selectToken(apiTokens);

export const soctProviderLookupConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: SOCT_PROVIDER_LOOKUP,
  EMBOLD_CLIENT: SOCT_PROVIDER_LOOKUP,
  EMBOLD_CLIENT_SLUG: SOCT_PROVIDER_LOOKUP,
  THEME: standardTheme,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: false,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX:
    /^(standardaccess|primarycareaccess|expandedaccess|qualityfirstselectaccess|preferredaccessoutofarea)$/,

  // other
  BEST_MATCH_SORT: 'best_match',
  BENEFITS_CHANGE: false,
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Hartford,
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'soctProviderLookupUserId',
};
