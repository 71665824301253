import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';

import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {},
  autocompleteGroupLabel: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    lineHeight: '28px',
    fontSize: '1rem',
  },
  autocompletePopper: {
    minWidth: '300px',
  },
  label: {
    '& .MuiFormLabel-root': {
      fontSize: '.84rem', // prevents text label from overflowing onto a new line
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 44,
    },
    '& .MuiInputLabel-shrink': {
      right: 'unset',
      paddingLeft: 2,
      backgroundColor: 'white',
    },
  },
}));

function AutocompleteNetworkSelect({ label, name, submitOnChange }) {
  const [field, meta, { setValue }] = useField(name);
  const formik = useFormikContext();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const networks = useSelector(select.networks.all);
  const slugs = useSelector(select.networks.availableSlugs);

  const isError = Boolean(meta.touched && meta.error);
  const helperText = isError ? meta.error : undefined;

  const handleChange = useCallback(
    (evt, value) => {
      setIsOpen(true);
      setValue(value);
      if (submitOnChange) {
        formik.submitForm(evt);
      }
    },
    [setValue, formik, submitOnChange]
  );
  return (
    <Autocomplete
      fullWidth
      blurOnSelect
      autoSelect
      autoHighlight
      className={classes.root}
      classes={{
        popper: classes.autocompletePopper,
        groupLabel: classes.autocompleteGroupLabel,
      }}
      open={isOpen}
      openOnFocus
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={slugs}
      groupBy={(slug) => networks[slug]?.networkGroupName || ''}
      getOptionLabel={(slug) => networks[slug]?.text || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.label}
          label={label}
          variant="outlined"
          size="small"
          error={isError}
          helperText={helperText}
        />
      )}
      value={field.value}
      onChange={handleChange}
    />
  );
}

AutocompleteNetworkSelect.defaultProps = {
  submitOnChange: false,
};

AutocompleteNetworkSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  submitOnChange: PropTypes.bool,
};

export default React.memo(AutocompleteNetworkSelect);
