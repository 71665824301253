import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Collapse, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { _analyticsActions } from 'analytics/index';

import useToggleState from 'hooks/useToggleState';
import { VALID_HEADING_VARIANTS, getNextHeadingLevel } from 'utils/utils';
import FeaturedBenefitHeader from './FeaturedBenefitHeader';
import BenefitDetails from './BenefitDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius * 6,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  details: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default function FeaturedBenefit({
  benefit,
  headingLevel,
  isMobile,
  additionalClass,
  listItem,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isExpanded, toggleExpand] = useToggleState(false);
  const benefitDetailsId = 'featured-benefit-details';

  useEffect(() => {
    dispatch(_analyticsActions.promotionResultView(benefit.programName));
  }, [benefit.programName, dispatch]);

  useEffect(() => {
    if (isExpanded) {
      dispatch(_analyticsActions.promotionProfileView(benefit.programName));
    }
  }, [benefit.programName, dispatch, isExpanded]);

  const Container = listItem ? ListItem : Box;

  if (!benefit) return null;
  return (
    <Container classes={{ root: clsx(classes.root, { [additionalClass]: additionalClass }) }}>
      <FeaturedBenefitHeader
        title={benefit.programName}
        subtitle={benefit.programSubname}
        logo={benefit.logo}
        headingLevel={headingLevel}
        isExpanded={isExpanded}
        handleShowMore={toggleExpand}
        benefit={benefit}
        isMobile={isMobile}
        benefitDetailsId={benefitDetailsId}
      />
      <Collapse in={isExpanded}>
        <BenefitDetails
          classes={{ root: classes.details }}
          headingLevel={getNextHeadingLevel(headingLevel)}
          benefit={benefit}
          isMobile={isMobile}
          data-testid={benefitDetailsId}
          id={benefitDetailsId}
        />
      </Collapse>
    </Container>
  );
}

FeaturedBenefit.propTypes = {
  benefit: PropTypes.shape({
    logo: PropTypes.string,
    programName: PropTypes.string.isRequired,
    programSubname: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS), // This is to set the highest heading level for this component. Nested headings will be based off of this value.
  additionalClass: PropTypes.string,
  listItem: PropTypes.bool,
};

FeaturedBenefit.defaultProps = {
  isMobile: false,
  headingLevel: 'h3',
  additionalClass: undefined,
  listItem: false,
};
