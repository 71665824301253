import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  gutterBottom: { marginBottom: theme.spacing(1.5) },
}));

export const hospitalSafetyGrade = {
  title: 'Hospital Safety Grades',
  description:
    'The Hospital Safety Grade assigns an ‘A’, ‘B’, ‘C’, ‘D’ or ‘F’ grade to nearly 3,000 hospitals across the country based on their performance in preventing medical errors, injuries, accidents, infections and other harms to patients in their care.',
  linkTitle: 'more information',
  linkHref: '/terms#hospital-safety-grades',
};

export default function HospitalSafetyGradeContent({ headerVariant = 'h2', callback }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant={headerVariant} className={classes.gutterBottom}>
        {hospitalSafetyGrade.title}
      </Typography>
      <Typography className={classes.gutterBottom}>{hospitalSafetyGrade.description}</Typography>
      <Link component={RouterLink} to={hospitalSafetyGrade.linkHref} onClick={callback}>
        {hospitalSafetyGrade.linkTitle}
      </Link>
    </>
  );
}

HospitalSafetyGradeContent.propTypes = {
  headerVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  callback: PropTypes.func,
};

HospitalSafetyGradeContent.defaultProps = {
  headerVariant: 'h2',
  callback: undefined,
};
