import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { getScoreArc } from 'utils/utils';
import { QUALITY_COLOR_THRESHOLD } from 'utils/constants';

function ScoreIcon({ score }) {
  const size = 42; // This needs to be kept as a constant unless getScoreArc is made more dynamic
  const theme = useTheme();

  const circleColor =
    score > QUALITY_COLOR_THRESHOLD ? theme.palette.accessibleGreen : theme.palette.appleRed;

  const textColor = theme.palette.black;

  const [arc1, arc2] = getScoreArc(score);
  if (!Number.isInteger(score)) return '';

  if (score < 100) {
    return (
      <svg width={size} height={size}>
        <path fill="none" stroke={circleColor} strokeWidth="4" d={arc1} />
        <path fill="none" stroke={theme.palette.darkGray} strokeWidth="4" d={arc2} />
        <text
          x="50%"
          y="55%"
          fill={textColor}
          style={{ fontSize: 14, fontWeight: 700 }}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {Math.round(score)}
        </text>
      </svg>
    );
  }
  return (
    <svg width={size} height={size}>
      <circle cx="50%" cy="50%" r="18" fill="none" stroke={circleColor} strokeWidth="4" />
      <text
        x="50%"
        y="55%"
        fill={textColor}
        style={{ fontSize: 14, fontWeight: 700 }}
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {Math.round(score)}
      </text>
    </svg>
  );
}

export default ScoreIcon;

ScoreIcon.propTypes = {
  score: PropTypes.number.isRequired,
};
