/* eslint-disable new-cap */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'whatwg-fetch';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import ReactMarkdown from 'react-markdown';
import { get, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OutcareIcon from 'assets/outcare-icon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import Modal from 'components/Modals/Modal';
import { useStateValue } from 'state';
import { getPlaces, distance, openInNewTab, openInSameTab } from 'utils/utils';
import ProfileSectionWrap from 'components/Profile/ProfileSections/ProfileSectionWrap';
import ProfileMap from 'components/Profile/ProfileSections/ProfileMap';
import { ProviderStrengths } from 'components/Profile/ProfileSections/ProviderStrengths';
import ProviderSpecializations from 'components/Profile/ProfileSections/ProviderSpecializations';
import Badge from 'components/Profile/Badges/Badge';
import certification from 'assets/certification.svg';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';

import hospitalIcon from 'assets/carrier.svg'; // TODO TECH-3847 replace with custom icon
import useFocusAnchors, { useFocusTo } from 'utils/FocusRefContext';
import IconLabel from 'components/Results/ResultCard/IconLabel';
import Tooltip from 'components/Tooltip';
import warningIcon from 'assets/decreasingicon.svg';
import limitedBenefitBadge from 'assets/badge-limited-benefit.svg';
import maxBenefitBadge from 'assets/badge-max-benefit.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import emboldIcon from 'assets/embold-icon.svg';
import { useQueryParams } from 'use-query-params';
import encodeQueryString from 'hooks/queryString/encodeQueryString';
import formatPrintContent from 'utils/formatPrintContent';
import { actions, select } from 'store/toolkit';

import { RELIANCE_ON_INFORMATION_POSTED_ID } from 'config/content/Defaults/Terms';
import { withRouter } from 'react-router-dom';
import {
  PLACE_RESULT_TYPE,
  IS_SURGERY_PLUS,
  PERCENTILE_THRESHOLD,
  SHOW_CLIENT_FEATURED,
  SUBSPECIALTY_SECTION,
} from 'utils/constants';
import { useConfigValue } from 'config/state';
import useReturnFocus from 'hooks/useReturnFocus';
import ProviderSummary from './ProviderSummary';

import LocationList from './ProfileSections/LocationList';
import Cost from './ProfileSections/Cost';
import ProfileBadges from './Badges/ProfileBadges';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: '0',
    paddingBottom: 15,
    textAlign: 'center',
    color: theme.palette.linkBlue,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inlineButton: {
    padding: 0,
    marginTop: -3,
    minWidth: 0,
    borderRadius: 0,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: theme.palette.linkBlue,
    borderBottom: '1px dotted',
    display: 'inline',
    '& svg': {
      marginBottom: -3,
      fontSize: '1rem',
    },
  },
  buttonLink: {
    padding: '0 0 4px 0',
    minWidth: 0,
    borderRadius: 0,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: 'underline',
    color: theme.palette.linkBlue,
    display: 'inline',
    textAlign: 'left',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  profileDetailIcon: {
    height: 20,
    width: 20,
    margin: '1px 5px 0 0',
  },
  sectionGridContainer: {
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  subtitle: {
    display: 'block',
  },
  goBackButton: {
    zIndex: 7,
    padding: 0,
    background: 'none',
    textDecoration: 'underline',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    marginBottom: 10,
    color: theme.palette.lightBlue,
    marginTop: -3,
  },
  profileActionButtons: {
    // share, print, need help button grid
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& .MuiButtonBase-root': {
      // individual buttons
      minWidth: 1, // override mui outlined btn default minimum
      display: 'flex',
      height: 50,
      padding: theme.spacing(1),
      width: '100%',
      '& svg': {
        // the icon inside the share and print buttons
        height: 20,
        marginRight: theme.spacing(0.5),
      },
    },
  },
  scoreWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '0 -20px',
  },
  hoverCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  // special styling for desktop profile page
  scoreWrapProfile: {
    '@media (min-width:376px)': {
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: 0,
      '& > div': {
        // individual score wrapper
        display: 'block',
        width: '100%',
        textAlign: 'center',
      },
    },
  },
  localRankingHeadline: {
    // delete once laymans goes love with MSFT
    fontWeight: 700,
    paddingLeft: '15px',
    paddingTop: '0',
    fontSize: '.85rem',
  },
  localRankingText: {
    // delete once laymans goes love with MSFT
    paddingLeft: '15px',
    paddingBottom: '8px',
    fontSize: '.85rem',
    '& span': {
      fontWeight: 700,
    },
  },
  coverageDecreasingWrap: {
    background: '#EEF7F9',
    margin: '20px -16px 10px',
    padding: '3px 0',
  },
  coverageDecreasing: {
    justifyContent: 'center',
    '& .MuiAvatar-root': {
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: -1.5,
      marginRight: '.15rem',
    },
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
  coverageDecreasingBadges: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 3,
    '& .MuiTypography-caption': {
      fontSize: '.875rem',
    },
  },
  badgesContainer: {
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '1rem',
  },
  providerCardBadge: {
    '& img': {
      width: '72px !important',
      height: '60px !important',
    },
  },
  expandMoreIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  careJourney: {
    paddingTop: '5px',
    paddingBottom: '5px',
    marginLeft: '-3px',
  },
  specialtyArea: {
    paddingLeft: '8px',
    paddingBottom: '0px',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearButton: {
    fontWeight: 'heavy',
    fontSize: '0.9em',
    textDecoration: 'underline',
    color: theme.palette.black,
  },
  locationDetailSubheader: {
    margin: '-.5rem 0 1.25rem 6px',
    color: '#666',
    lineHeight: 1.25,
  },
}));

export function GridIconRow({ id, img, Icon, noMargin, children }) {
  const classes = useStyles();
  return (
    <Grid
      xs={12}
      item
      container
      alignItems="flex-start"
      className={!noMargin ? classes.sectionGridContainer : ''}
    >
      <Grid item style={{ textAlign: 'center' }}>
        {img && (
          <img src={img} className={`${classes.profileDetailIcon} profile-icon-${id}`} alt="" />
        )}
        {Icon && <Icon className={`${classes.profileDetailIcon} profile-icon-${id}`} />}
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
}

GridIconRow.defaultProps = {
  noMargin: undefined,
  img: null,
  Icon: null,
};

GridIconRow.propTypes = {
  id: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  img: PropTypes.string,
  noMargin: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.shape({}).isRequired,
};

/** @deprecated */
function Profile({
  profileData,
  isProfilePage,
  showDistance,
  history,
  multipleProviders,
  hideShareAndHelp,
  triggerStaticSharePrint,
}) {
  const [{ location_input: locationInputParam }] = useQueryParams(encodeQueryString);

  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();
  const { ref: printButtonRef, returnFocus: focusPrintButton } = useReturnFocus();
  const focusAnchors = useFocusAnchors();
  const focusTo = useFocusTo();
  const printRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [{ resultType, selectedPlaceProvider, networkSlug }, setState] = useStateValue();
  const activeSubspecialtyId = useSelector(select.results.subspecialtyId);
  const latLong = useSelector(select.location.latLong);

  const {
    config: {
      SHOW_SCHEDULE_BUTTON,
      SHOW_SUBSPECIALTIES,
      SHOW_PROVIDER_STRENGTHS,
      BENEFITS_CHANGE,
      SHOW_OUTCARE,
      SHOW_SHARE,
      SHOW_COST,
      CONTENT: {
        MAPS_URL,
        BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT,
        BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP,
        BENEFITS_CHANGE_COVERAGE_CHANGE_TITLE,
        BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TITLE,
        BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TEXT,
        BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TITLE,
        BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TEXT,
        BENEFITS_CHANGE_HIGHER_COVERAGE_GAUGE_TEXT,
        BENEFITS_CHANGE_LOWER_COVERAGE_GAUGE_TEXT,
        SHOW_HOSPITAL_AFFILIATIONS,
      },
    },
    getNetworkConfigValue,
  } = useConfigValue();
  const dispatch = useDispatch();

  const showClientFeatured = getNetworkConfigValue(networkSlug, SHOW_CLIENT_FEATURED) ?? false;
  const isSurgeryPlusNetwork = getNetworkConfigValue(networkSlug, IS_SURGERY_PLUS) ?? false;
  const [maxBenefitDialogOpen, setMaxBenefitDialogOpen] = useState(false);
  const [limitedBenefitDialogOpen, setLimitedBenefitDialogOpen] = useState(false);
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const [showAllLocations, setShowAllLocations] = useState(false);
  const {
    npi,
    entityName,
    name,
    title,
    specialty,
    website,
    medicalSchool,
    boardCertified,
    gender,
    closestVisibleLocation,
    subspecialties,
    outcareCertified,
    outcareCompetent,
    outcareUrl,
    diagnosingPercentile,
    treatmentPercentile,
    outcomesPercentile,
    benefitDecrease,
    yearsExperience,
    scoreCareJourneyCost,
    affiliation,
    hospitalAffiliations,
    languagesArray,
    overallPercentile,
    schedulingUrl,
    clientFeatured,
  } = selectedPlaceProvider || profileData;

  const isSubspecialtySearch = activeSubspecialtyId && activeSubspecialtyId > 0;
  const isPlaceSearch = resultType === PLACE_RESULT_TYPE && !selectedPlaceProvider;

  // this is a safe get for profile location data. for 'places', we use root level properties for all of the below, so setting the fallback this way accounts for either search type
  const closestLocation = closestVisibleLocation || profileData;
  const { address1, latitude, longitude, phone } = closestLocation;

  // need to do a safe get on this because when we mount this component on the dedicated profile page we do not receive distanceInMiles from api, defaults to undefined and calculated below
  const distanceInMiles = isPlaceSearch
    ? get(closestLocation, 'distanceInMiles[0]')
    : get(closestLocation, 'distanceInMiles');

  const distanceFromLatLong =
    distanceInMiles || distance(latLong.latitude, latLong.longitude, latitude, longitude);
  const roundedDistance = parseFloat(distanceFromLatLong)?.toFixed(2);

  const allLocations = isPlaceSearch ? [closestLocation] : getPlaces(profileData);
  const hasMultipleValidLocations = !isEmpty(profileData) && allLocations.length > 1;
  const telehealthAvailable = allLocations.some((p) => !!p?.telehealthAvailable);

  const isValidURL = (url) => {
    const regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&=]*)?/;
    return regex.exec(url) !== null;
  };

  const handleWebsiteClick = () => {
    openInNewTab(website);
  };

  const handlePhoneClick = (locationPhoneNumber) => {
    openInSameTab(`tel:${locationPhoneNumber}`);
  };

  const handleShareClick = () => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(actions.ui.openProfileModal({ type: 'share', data: [profileData] }));
  };

  const handleDisclaimerLink = () => {
    history.push(`/terms#${RELIANCE_ON_INFORMATION_POSTED_ID}`);
  };

  const handleMaxBenefitClick = () => {
    setMaxBenefitDialogOpen(true);
  };
  const handleCloseMaxBenefitDialog = () => {
    setMaxBenefitDialogOpen(false);
  };

  const handleLimitedBenefitClick = () => {
    setLimitedBenefitDialogOpen(true);
  };
  const handleCloseLimitedBenefitDialog = () => {
    setLimitedBenefitDialogOpen(false);
  };

  const handleAccordionToggle = () => {
    setAccordionIsOpen(!accordionIsOpen);
  };
  const handleShowAllLocations = () => {
    setShowAllLocations(!showAllLocations);
  };
  const nwa = networkSlug === 'northwestarkansas' || networkSlug === 'bcbsarkansas';

  const withinPercentileThreshold = (percentile) => percentile >= PERCENTILE_THRESHOLD;

  const showProviderStrengthsSection =
    SHOW_PROVIDER_STRENGTHS &&
    Boolean(
      withinPercentileThreshold(diagnosingPercentile) ||
        withinPercentileThreshold(treatmentPercentile) ||
        withinPercentileThreshold(outcomesPercentile) ||
        (activeSubspecialtyId && withinPercentileThreshold(overallPercentile))
    );
  const showSubspecialtiesSection =
    !isPlaceSearch && SHOW_SUBSPECIALTIES && subspecialties?.length > 0;

  useEffect(() => {
    setShowAllLocations(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const handleAccordionKeyDown = (e) => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        handleAccordionToggle();
        break;
      case 'ArrowDown':
        setAccordionIsOpen(true);
        break;
      case 'ArrowUp':
        setAccordionIsOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (typeof printRef.current === 'function') {
      printRef.current();
    }
  }, [printRef]);

  useEffect(() => {
    setIsPrinting(triggerStaticSharePrint);
  }, [triggerStaticSharePrint]);

  useEffect(() => {
    if (isPrinting) {
      if (hasMultipleValidLocations) {
        setShowAllLocations(true);
      }
    }
  }, [isPrinting]);

  // eslint-disable-next-line arrow-body-style
  const handleOnBeforeGetContent = useCallback(() => {
    setIsPrinting(true);
    if (hasMultipleValidLocations) {
      handleShowAllLocations();
    }
  }, [setIsPrinting]);

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false);
    if (hasMultipleValidLocations) {
      setShowAllLocations(false);
    }
    focusPrintButton();
  }, [focusPrintButton, setIsPrinting, setShowAllLocations, hasMultipleValidLocations]);

  const printProfile = useReactToPrint({
    content: () => formatPrintContent(printRef),
    documentTitle: entityName || name || 'Embold Health Provider',
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = useCallback(() => {
    setIsPrinting(true);
    if (hasMultipleValidLocations) {
      handleShowAllLocations();
    }
    // need to wait for profile accordion animations to finish before ingesting content
    setTimeout(printProfile, 470);
  }, [setIsPrinting, handleShowAllLocations, hasMultipleValidLocations, printProfile]);

  return (
    <div
      style={{
        paddingTop: '1rem',
        borderBottom: multipleProviders && smDown ? '1px solid #ccc' : 'none',
        paddingBottom: multipleProviders && smDown ? '2rem' : '0',
      }}
      ref={printRef}
    >
      {resultType === PLACE_RESULT_TYPE && selectedPlaceProvider && (
        <button
          type="button"
          className={classes.goBackButton}
          onClick={() => {
            setState({
              selectedPlaceProvider: null,
            });
          }}
        >
          Back To Place
        </button>
      )}
      {BENEFITS_CHANGE && benefitDecrease && !nwa && (
        <div className={classes.coverageDecreasingWrap}>
          <IconLabel img={warningIcon} className={classes.coverageDecreasing}>
            {BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT}
            <Tooltip
              title={BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT}
              message={BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP}
            />
          </IconLabel>
        </div>
      )}

      <Grid container alignItems="flex-start" className="profile-header">
        <Grid
          item
          style={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
          }}
          id="active-result-title"
          onClick={handleAccordionToggle}
        >
          <Typography variant="srOnly" component="h2">
            {`${name || entityName} ${title || ''} ${specialty}`}
          </Typography>
          <Typography
            variant="h2"
            className="no-margin no-bold profile-card-title"
            style={{ marginRight: '15px' }}
            aria-hidden="true"
          >
            {name || entityName} {title || ''}
            <Typography
              variant="subtitle1"
              component="span"
              className={`${classes.subtitle} profile-card-subtitle`}
            >
              {specialty}
            </Typography>
          </Typography>
          {multipleProviders && smDown && (
            <ExpandMoreIcon
              tabIndex="0"
              onKeyDown={handleAccordionKeyDown}
              onClick={handleAccordionToggle}
              aria-label={`expand details for ${name || entityName} ${title || ''} ${specialty}`}
              className={classes.expandMoreIcon}
              style={{
                transform: accordionIsOpen ? 'rotate(-180deg)' : '',
                transition: 'transform 100ms ease',
              }}
            />
          )}
        </Grid>
      </Grid>
      <Modal
        open={maxBenefitDialogOpen}
        handleClose={handleCloseMaxBenefitDialog}
        ariaId="maxbenefit-tooltip"
      >
        <div style={{ margin: '25px 0 0', overflow: 'hidden' }}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }} id="maxbenefit-tooltip-title">
            {BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TITLE}
          </Typography>

          <Typography
            variant="body1"
            style={{ margin: '0 0 20px' }}
            id="maxbenefit-tooltip-description"
          >
            {BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TEXT}
          </Typography>
        </div>
      </Modal>

      <Modal
        open={limitedBenefitDialogOpen}
        handleClose={handleCloseLimitedBenefitDialog}
        ariaId="limitedbenefit-tooltip"
      >
        <div style={{ margin: '25px 0 0', overflow: 'hidden' }}>
          <Typography
            variant="body1"
            style={{ fontWeight: 'bold' }}
            id="limitedbenefit-tooltip-title"
          >
            {BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TITLE}
          </Typography>

          <Typography
            variant="body1"
            style={{ margin: '0 0 20px' }}
            id="limitedbenefit-tooltip-description"
          >
            {BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TEXT}
          </Typography>
        </div>
      </Modal>

      <Grid container spacing={1} className={classes.badgesContainer}>
        <ProfileBadges profileData={profileData} />
      </Grid>

      {multipleProviders || hideShareAndHelp ? null : (
        <Collapse in={!isPrinting}>
          <Grid container className={classes.profileActionButtons} spacing={1}>
            {SHOW_SHARE && (
              <Grid item xs={4}>
                <Button
                  component={Button}
                  color="primary"
                  onClick={handleShareClick}
                  ref={shareButtonRef}
                  variant="outlined"
                >
                  <ShareIcon />
                  Share
                </Button>
              </Grid>
            )}

            <Grid item xs={4}>
              <Button variant="outlined" color="primary" onClick={handlePrint} ref={printButtonRef}>
                <PrintIcon />
                Print
              </Button>
            </Grid>

            {SHOW_SCHEDULE_BUTTON &&
              !isSurgeryPlusNetwork &&
              (schedulingUrl ? (
                <Grid item xs={4}>
                  <Button
                    color="primary"
                    variant="outlined"
                    target="_blank"
                    rel="noreferrer"
                    href={schedulingUrl}
                  >
                    <CalendarMonthIcon />
                    Schedule
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      focusAnchors.profileModalReturnFocus = focusCallButton;
                      dispatch(actions.ui.openProfileModal({ type: 'call', data: profileData }));
                    }}
                    ref={callButtonRef}
                  >
                    Need Help?
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Collapse>
      )}

      {smDown && multipleProviders && !accordionIsOpen ? null : (
        <div className="profile-detail-section">
          {BENEFITS_CHANGE && benefitDecrease && !nwa && (
            <ProfileSectionWrap
              title={BENEFITS_CHANGE_COVERAGE_CHANGE_TITLE}
              isPrinting={isPrinting}
            >
              <Grid item xs={12} className={classes.coverageDecreasingBadges}>
                <Badge
                  src={maxBenefitBadge}
                  className="profile-badgetext-maxbenefit"
                  onClick={handleMaxBenefitClick}
                >
                  <ReactMarkdown>{BENEFITS_CHANGE_HIGHER_COVERAGE_GAUGE_TEXT}</ReactMarkdown>
                </Badge>
                <ArrowForwardIcon alt="right arrow" />
                <Badge
                  src={limitedBenefitBadge}
                  className="profile-badgetext-maxbenefit"
                  style={{ marginLeft: 16 }}
                  onClick={handleLimitedBenefitClick}
                >
                  <ReactMarkdown>{BENEFITS_CHANGE_LOWER_COVERAGE_GAUGE_TEXT}</ReactMarkdown>
                </Badge>
              </Grid>
            </ProfileSectionWrap>
          )}
          {showSubspecialtiesSection && (
            <ProfileSectionWrap
              title={SUBSPECIALTY_SECTION.title}
              icon={emboldIcon}
              isPrinting={isPrinting}
              titleTooltip={
                <Tooltip
                  title={SUBSPECIALTY_SECTION.title}
                  message={SUBSPECIALTY_SECTION.description}
                />
              }
            >
              <ProviderSpecializations
                subspecialties={subspecialties}
                isSubspecialtySearch={isSubspecialtySearch}
                npi={npi}
                openAll={isPrinting}
              />
            </ProfileSectionWrap>
          )}
          {showProviderStrengthsSection && (
            <ProfileSectionWrap
              title="Provider Strengths"
              icon={emboldIcon}
              style={{ overflow: 'hidden' }}
              isPrinting={isPrinting}
              titleTooltip={
                <Tooltip
                  title="Provider Strengths"
                  message="Provider Strengths highlight a doctor's areas of exceptional care in comparison to their peers. "
                />
              }
            >
              <ProviderStrengths profileData={profileData} />
            </ProfileSectionWrap>
          )}
          {SHOW_COST && scoreCareJourneyCost && (
            <Cost
              scoreCost={scoreCareJourneyCost}
              profileData={profileData}
              isPrinting={isPrinting}
            />
          )}
          {!isPlaceSearch && (
            <ProfileSectionWrap title="Provider Info" isPrinting={isPrinting}>
              <ProviderSummary
                gender={gender}
                languages={languagesArray}
                website={website}
                handleWebsiteClick={handleWebsiteClick}
                telehealthAvailable={telehealthAvailable}
                isValidURL={isValidURL}
                GridIconRow={GridIconRow}
                affiliation={affiliation}
                classes={classes}
              />
            </ProfileSectionWrap>
          )}
          {Boolean(address1 || phone || website) && (
            <ProfileSectionWrap
              title={
                isPlaceSearch
                  ? 'CONTACT'
                  : `Nearest location${hasMultipleValidLocations ? 's' : ''}`
              }
              isNearestLocationProvider={!isPlaceSearch}
              isPrinting={isPrinting}
            >
              {locationInputParam && !isPlaceSearch && (
                <Typography variant="body1" classes={{ root: classes.locationDetailSubheader }}>
                  from {locationInputParam}
                </Typography>
              )}
              <LocationList
                profileData={selectedPlaceProvider || profileData}
                allLocations={allLocations}
                classes={classes}
                latLong={latLong}
                isPlaceSearch={isPlaceSearch}
                handleDirectionsClick={() => {}}
                handlePhoneClick={handlePhoneClick}
                locationInputParam={locationInputParam}
                roundedDistance={roundedDistance}
                distanceFromLatLong={distanceFromLatLong}
                showDistance={showDistance}
                showProviderStrengthsSection={showProviderStrengthsSection}
                MAPS_URL={MAPS_URL}
                hasMultipleValidLocations={hasMultipleValidLocations}
                showAllLocations={showAllLocations}
                handleShowAllLocations={handleShowAllLocations}
                handleDisclaimerLink={handleDisclaimerLink}
                isSurgeryPlusNetwork={isSurgeryPlusNetwork}
                showClientFeatured={showClientFeatured}
              />
            </ProfileSectionWrap>
          )}
          {SHOW_HOSPITAL_AFFILIATIONS && hospitalAffiliations?.length > 0 && (
            <ProfileSectionWrap
              title={
                hospitalAffiliations?.length > 1 ? 'Hospital Affiliations' : 'Hospital Affiliation'
              }
              isPrinting={isPrinting}
            >
              {hospitalAffiliations.map((hospital) => (
                <GridIconRow img={hospitalIcon} id="hospital">
                  <Typography variant="body1" className="profile-affiliation">
                    {hospital}
                  </Typography>
                </GridIconRow>
              ))}
            </ProfileSectionWrap>
          )}
          {/* @TODO: reenable this section once consumer score data is available */}
          {/* {!isEmpty(scores_consumer) && (
            <ProfileSectionWrap title="Consumer Reviews">
              <ConsumerReviews scores_consumer={scores_consumer} />
            </ProfileSectionWrap>
          )} */}
          {SHOW_OUTCARE && outcareCompetent && (
            <ProfileSectionWrap
              title="Cultural Competency"
              titleTooltip={
                <Tooltip
                  title="LGBTQ+ Cultural Competency"
                  message="Having knowledge of how cultural factors affect health and ability to provide informed care regardless
                 of sexual orientation, gender identity, and other social classifications."
                />
              }
              isPrinting={isPrinting}
              icon={OutcareIcon}
              style={{ overflow: 'hidden' }}
            >
              <Grid item xs={12}>
                <Typography variant="body1" style={{ padding: '0 6px 10px' }}>
                  This provider has been identified by OutCare as having the knowledge, skills,
                  strategies and competence regarding LGBTQ+ care.{' '}
                  <Link
                    className={`${classes.link}`}
                    href={outcareUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here
                  </Link>{' '}
                  to review this provider&apos;s OutCare OutList profile.{' '}
                  {outcareCertified && (
                    <GridIconRow Icon={CheckCircleOutlineOutlinedIcon} id="outcareCertified">
                      <Link
                        className={`${classes.link}`}
                        href="https://www.outcarehealth.org/training/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        OutCare Certified{' '}
                      </Link>
                      <Tooltip
                        title="OutCare OutList Certified"
                        message="This provider has also completed OutCare's LGBTQ+ Cultural Competency 101 Training"
                      />
                    </GridIconRow>
                  )}
                </Typography>
              </Grid>
            </ProfileSectionWrap>
          )}
          {(medicalSchool || boardCertified || yearsExperience > 0) && (
            <ProfileSectionWrap title="Credentials + Experience" isPrinting={isPrinting}>
              {medicalSchool && (
                <GridIconRow Icon={SchoolOutlinedIcon} id="degree">
                  <Typography variant="body1" className="profile-medical-school">
                    {medicalSchool}
                  </Typography>
                </GridIconRow>
              )}

              {yearsExperience > 0 && (
                <GridIconRow Icon={AccessTimeOutlinedIcon} id="degree">
                  <Typography variant="body1" className="profile-years-experience">
                    Years of experience: {yearsExperience}
                  </Typography>
                </GridIconRow>
              )}

              {boardCertified && (
                <GridIconRow img={certification} id="certification">
                  <Typography variant="body1" className="profile-certification">
                    Board certification
                  </Typography>
                </GridIconRow>
              )}
            </ProfileSectionWrap>
          )}
          {!multipleProviders && isProfilePage && !isPrinting && (
            <ProfileSectionWrap title="Location">
              <Grid item xs={12}>
                <ProfileMap closestLocation={closestLocation} allLocations={allLocations} />
              </Grid>
            </ProfileSectionWrap>
          )}
        </div>
      )}
    </div>
  );
}

Profile.defaultProps = {
  multipleProviders: undefined,
  isProfilePage: undefined,
  hideShareAndHelp: undefined,
};

Profile.propTypes = {
  profileData: PropTypes.shape({
    closestVisibleLocation: PropTypes.shape({}),
    hospitalGrade: PropTypes.string,
  }).isRequired,
  isProfilePage: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  multipleProviders: PropTypes.bool,
  showDistance: PropTypes.bool.isRequired,
  hideShareAndHelp: PropTypes.bool,
};

/** @deprecated */
export default withRouter(Profile);
