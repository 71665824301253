import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { TableRow, TableCell, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { actions } from 'store/toolkit';
import useTableValue from './useTableValue';

const useStyles = makeStyles((theme) => ({
  row: {
    '& > *': {
      padding: theme.spacing(0.5),
      textAlign: 'center',
      borderBottom: 'none',
    },
  },
  chip: {},
}));
function RemoveButtonCell({ resultId, ...props }) {
  const dispatch = useDispatch();

  const handleRemove = useCallback(() => {
    dispatch(actions.results.toggleIdInCompareList(resultId));
  }, [dispatch, resultId]);

  return (
    <TableCell {...props}>
      <Chip
        label="Remove"
        variant="outlined"
        clickable
        onClick={handleRemove}
        onDelete={handleRemove}
        size="small"
      />
    </TableCell>
  );
}

export default function RemoveButtonRow() {
  const classes = useStyles();
  const { resultIds } = useTableValue();

  return (
    <TableRow className={classes.row}>
      <TableCell>
        <Typography variant="srOnly">Remove Results</Typography>
      </TableCell>
      {resultIds.map((id) => (
        <RemoveButtonCell key={`remove-${id}`} resultId={id} />
      ))}
    </TableRow>
  );
}

RemoveButtonCell.propTypes = {
  resultId: PropTypes.string.isRequired,
};
