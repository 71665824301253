import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { actions, select } from 'store/toolkit';
import { useConfigValue } from 'config/state';

import {
  SLIDER_MARKS,
  SLIDER_VALUE_TO_RADIUS,
  RADIUS_TO_SLIDER_VALUE,
} from 'store/slices/filters/filterConstants';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles({
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 10,
  },
  slider: {
    width: '90%',
  },
});

function RadiusSlider({ chipOpen }) {
  const classes = useStyles();

  const {
    config: { DEFAULT_SEARCH_RADIUS },
  } = useConfigValue();

  const defaultSearchRadius = RADIUS_TO_SLIDER_VALUE[DEFAULT_SEARCH_RADIUS];

  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(defaultSearchRadius);
  const filterRadius = useSelector(select.filters.radius);
  const resultLocation = useSelector(select.results.location);

  useEffect(() => {
    // reset filter & location state to match that of result set
    setSliderValue(RADIUS_TO_SLIDER_VALUE[resultLocation.radius] || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSliderValue]);

  useEffect(() => {
    if (chipOpen) {
      setSliderValue(defaultSearchRadius);
    }
  }, [chipOpen, setSliderValue, defaultSearchRadius]);

  const SLIDER_MARKS_MAXIMUM = SLIDER_MARKS.reduce((acc, obj) => {
    if (obj.value > acc) acc = obj.value;
    return acc;
  }, 0);

  const handleSliderChange = useCallback((e, value) => {
    setSliderValue(value);
  }, []);

  const handleSliderChangeCommitted = useCallback(
    (e, value) => {
      // only perform store updates if the slider is moved to a new value
      if (SLIDER_VALUE_TO_RADIUS[value] !== filterRadius) {
        dispatch(actions.filters.setFilterRadius(SLIDER_VALUE_TO_RADIUS[value]));
      }
    },
    [dispatch, filterRadius]
  );

  return (
    <Slider
      disabled={chipOpen}
      className={classes.slider}
      value={sliderValue}
      onChangeCommitted={handleSliderChangeCommitted}
      onChange={handleSliderChange}
      getAriaValueText={(val) => `Distance in miles, ${SLIDER_VALUE_TO_RADIUS[val]} miles`}
      aria-labelledby="radius-slider-custom"
      max={SLIDER_MARKS_MAXIMUM}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => SLIDER_VALUE_TO_RADIUS[value]}
      marks={SLIDER_MARKS}
      step={null}
    />
  );
}

RadiusSlider.propTypes = {
  chipOpen: PropTypes.bool.isRequired,
};

export default RadiusSlider;
