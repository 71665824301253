import React from 'react';

import Badge from 'components/Profile/Badges/BadgePromote';
import { hashForKey } from 'utils/utils';

function PromotionBadges({ promotion }) {
  const { badge1, badge1Text, badge2, badge2Text, badge3, badge3Text } = promotion;

  const lineBreakAtSemicolon = (text) =>
    text.split(';').map((line, i) => {
      const hashedKey = hashForKey(i);
      return (
        <span key={hashedKey}>
          {line}
          <br />
        </span>
      );
    });

  return (
    <>
      {badge1 && badge1Text && <Badge src={badge1}>{lineBreakAtSemicolon(badge1Text)}</Badge>}
      {badge2 && badge2Text && <Badge src={badge2}>{lineBreakAtSemicolon(badge2Text)}</Badge>}
      {badge3 && badge3Text && <Badge src={badge3}>{lineBreakAtSemicolon(badge3Text)}</Badge>}
    </>
  );
}

export default PromotionBadges;
