/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, JBHUNT } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token ec239e80a893267e51e2a741ac9e25bf7223767f',
  staging: 'Token 50b31f5268c0c6f7d4fc5833d218d81bbbc556c4',
  prod: 'Token 2e5a92c96f2b85545f456d90504bec7c6097c9e3',
};

const token = selectToken(apiTokens);

export const jbhuntConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: JBHUNT,
  EMBOLD_CLIENT: JBHUNT,
  EMBOLD_CLIENT_SLUG: JBHUNT,
  THEME: standardTheme,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: /^(jbhuntpilot|jbhuntpilotcigna)$/,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Lowell,
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'jbhuntUserId',
};
