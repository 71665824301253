import clrHeaderLogo from 'assets/clr_header_logo.svg';
import clrLoginLogo from 'assets/clr_login_logo.svg';

import { CHECKMARK } from 'utils/constants';

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
export const clrOverrides = {
  SCHEDULE_PHONE_NUMBER: '877-694-0407',
  HEADER_LOGO: clrHeaderLogo,
  WELCOME_MESSAGE:
    'Continental Resources and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: CHECKMARK,
  LOCAL_LOGO_ASSET: clrLoginLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};
