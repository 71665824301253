import React from 'react';
import { PromotionDataShape } from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core/';

import PromoteProfile from 'components/Profile/PromoteProfile';

const useStyles = makeStyles(() => ({
  promotionContainer: {
    width: '100%',
    marginBottom: 8,
    boxShadow: 'none',
    padding: 10,
    borderRadius: 8,
  },
}));

/** @deprecated */
function Promotion({ promotion }) {
  const classes = useStyles();
  return (
    <Paper className={classes.promotionContainer}>
      <PromoteProfile promotion={promotion} />
    </Paper>
  );
}

Promotion.propTypes = {
  promotion: PromotionDataShape.isRequired,
};

export default Promotion;
