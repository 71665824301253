import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import fetchClient from 'utils/fetchClient';
import { Collapse } from '@material-ui/core/';
import {
  EASE_OF_USE,
  RECOMMENDATION_RELEVANCY,
  ADAPTATION_PERFORMANCE,
  LOCATION_ACCURACY,
  SPECIALIZATION_RELEVANCY,
  OVERALL_SATISFACTION,
  VIRTUAL_BENEFIT_PRESENTED,
  VIRTUAL_BENEFIT_USE_LIKELYHOOD,
  EMERGENCY_RECOMMENDATION_OUTCOME,
} from 'components/Modals/ChatModal/Feedback/constants';

import ChatFeedbackForm from 'components/Modals/ChatModal/Feedback/ChatFeedbackForm';
import { checkInvalidTextField } from 'utils/utils';
import { actions, select } from 'store/toolkit';

function ChatFeedback({ chatKey, chatLog, success }) {
  const dispatch = useDispatch();
  const feedbackSuccess = () => {
    dispatch(actions.chat.feedbackSuccessfullySubmitted());
  };
  const scenarioId = useSelector(select.chat.scenarioId);
  const initialValues = {
    [EASE_OF_USE]: null,
    [EMERGENCY_RECOMMENDATION_OUTCOME]: null,
    [RECOMMENDATION_RELEVANCY]: null,
    [ADAPTATION_PERFORMANCE]: null,
    [LOCATION_ACCURACY]: null,
    [SPECIALIZATION_RELEVANCY]: null,
    [OVERALL_SATISFACTION]: null,
    [VIRTUAL_BENEFIT_PRESENTED]: null,
    [VIRTUAL_BENEFIT_USE_LIKELYHOOD]: null,
    message: '',
  };

  const validationSchema = Yup.object({
    [EASE_OF_USE]: Yup.bool().required('*Required'),
    [EMERGENCY_RECOMMENDATION_OUTCOME]: Yup.bool().required('*Required'),
    [RECOMMENDATION_RELEVANCY]: Yup.bool().nullable(),
    [ADAPTATION_PERFORMANCE]: Yup.bool().required('*Required'),
    [LOCATION_ACCURACY]: Yup.bool().nullable(),
    [SPECIALIZATION_RELEVANCY]: Yup.bool().nullable(),
    [OVERALL_SATISFACTION]: Yup.number().required('*Required'),
    [VIRTUAL_BENEFIT_PRESENTED]: Yup.bool().nullable(),
    [VIRTUAL_BENEFIT_USE_LIKELYHOOD]: Yup.bool().nullable(),
    message: Yup.string()
      .max(1024, 'Message must be less than 1025 characters.')
      .test('Valid Text Field', 'Invalid TextField', (value, { createError }) => {
        const errorMessage = checkInvalidTextField(value || '');
        if (errorMessage) {
          return createError({ message: errorMessage });
        }

        return true;
      }),
  });

  const handleSubmit = (formValues) => {
    fetchClient
      .post('/chat-feedback/', { ...formValues, chatKey, chatLog, scenarioId })
      .then(() => {
        feedbackSuccess();
      })
      .catch((err) => {
        Sentry.captureException(err);
        feedbackSuccess();

        console.error(err);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form noValidate>
        <Collapse in={!success} timeout={500}>
          <ChatFeedbackForm />
        </Collapse>
      </Form>
    </Formik>
  );
}

ChatFeedback.propTypes = {
  chatKey: PropTypes.string.isRequired,
  chatLog: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
};

export default ChatFeedback;
