import { DEFAULT_LOCATIONS, ICUBA, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import IcubaLoginLogo from 'assets/pg-icuba-logo.png';

const apiTokens = {
  dev: 'Token 4040c474466ff90d2301a21524ba85993018a939',
  staging: 'Token 34e3003e4598dad8a4047a1280f89738f1695c37',
  prod: 'Token e4775d4a455369d7d47c41d1f0c1d68848f4661f',
};

const token = selectToken(apiTokens);

const icubaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'icuba',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'icuba',
  EMBOLD_CLIENT: ICUBA,
  EMBOLD_CLIENT_SLUG: 'icuba',

  // toggles
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  SHOW_BENEFIT_DIFF: true,

  // login
  SHOW_REGION_SELECTOR: false,
  LOGIN_NETWORK_NAME: 'icubaUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['icuba', 'i'],

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.WestPalmBeach },

  // content
  DISPLAY_NAME: 'ICUBA',
  SCHEDULE_PHONE_NUMBER: '877-211-9127',
  HEADER_LOGO: IcubaLoginLogo,
  SHEDULE_COPY: `Connecting you to Embold Health Customer Service. Click 'Call Now' to continue`,
  SUPPORT_LINK: 'https://icubasupport.emboldhealth.com/portal/',
  BENEFIT_DIFF_COPY: '$0 Copay',
  BENEFIT_DIFF_TITLE: 'Preferred Provider',
  BENEFIT_DIFF_TOOLTIP:
    'This provider has been reviewed by medical experts and has proven to consistently deliver care that is among the best in the area in this specialty.  Therefore, you are not responsible for any copay when using this provider.',
  SEARCH_ERROR_TEXT:
    'This version of Provider Guide includes providers within the state of Florida. Please change your search criteria to try again.',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `ICUBA and Embold Health have partnered to bring you Embold's Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: IcubaLoginLogo,
  FORM_VARIANT: CHECKMARK,
  NO_WELCOME_LINK: false,
};

export default icubaConfig;
