import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select, actions, constants } from 'store/toolkit';
import { logDevMessage } from 'utils/utils';
import AntSwitch from 'components/AntSwitch';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 230,
    marginBottom: theme.spacing(1),
  },
  label: {
    width: '100%',
    marginLeft: theme.spacing(1.5),
    color: theme.palette.grey[700],
    fontWeight: theme.typography.h6.fontWeight,
  },
  activeLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function SwitchFilter({ filter: filterKey }) {
  const filter = useSelector(select.filters.byKey(filterKey));
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = useCallback(
    (evt) => {
      const { checked } = evt.target;
      dispatch(actions.filters.handleFilterChange({ key: filterKey, value: checked }));
    },
    [dispatch, filterKey]
  );

  if (!filter) {
    // handle an incorrect or invalid filter key
    logDevMessage(
      `Filter "${filterKey}" does not exist. Check the filter prop passed to SwitchFilter`
    );
    return null;
  }

  if (filter.type !== constants.filters.BOOL_FILTER_TYPE) {
    // handle a filter key being passed for incorrect filter type. Ex: This switch should not be used on an array filter type
    logDevMessage(
      `Attempted to render a SwitchFilter for a filter that is not of type ${constants.filters.BOOL_FILTER_TYPE}`
    );
    return null;
  }

  if (filter.disabled) return null;

  return (
    <FormControlLabel
      labelPlacement="end"
      label={filter.label}
      classes={{
        root: classes.root,
        label: `${classes.label} ${filter.value && classes.activeLabel}`,
      }}
      control={<AntSwitch onChange={handleChange} value={filterKey} checked={filter.value} />}
    />
  );
}

SwitchFilter.propTypes = {
  filter: PropTypes.string.isRequired,
};
