import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EmboldMarkdown from 'components/EmboldMarkdown';
import { VALID_HEADING_VARIANTS, getNextHeadingLevel } from 'utils/utils';
import BenefitWhenToUseList from './BenefitWhenToUseList';
import BenefitAttributeList from './BenefitAttributeList';
import BenefitExternalLinks from './BenefitExternalLinks';
import ContactCard from '../ContactCard';

const useStyles = makeStyles((theme) => {
  const cardRadius = theme.shape.borderRadius * 4;
  const cardBackgroundColor = theme.palette.grey[100];
  const cardPadding = theme.spacing(3);

  return {
    attributesSection: {
      rowGap: theme.spacing(2),
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px 0`,
    },
    attributesSectionMobile: {
      padding: `0 0 ${theme.spacing(2)}px 0`,
    },
    mainDetailsSection: {
      backgroundColor: cardBackgroundColor,
      padding: cardPadding,
      borderRadius: cardRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    programDescription: {
      marginBottom: theme.spacing(2),
      fontSize: '1.125rem', // 18px
      lineHeight: 1.4,
    },
    detailedDescription: {
      marginBottom: theme.spacing(2),
    },
    contactCard: {
      marginTop: theme.spacing(2),
    },
    getStartedButton: {
      width: '100%',
      textAlign: 'center',
    },
  };
});

export default function BenefitDetails({ benefit, isMobile, headingLevel, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const sendPhoneAnalytics = useCallback(() => {
    dispatch(_analyticsActions.phoneClickFromPromotionProfile(benefit.programName));
  }, [benefit, dispatch]);

  const sendDirectionsAnalytics = useCallback(() => {
    dispatch(_analyticsActions.directionsClickFromPromotionProfile(benefit.programName));
  }, [benefit, dispatch]);

  const { programDescription, detailedDescription, getStartedLink, whenToUse = [] } = benefit;

  return (
    <Grid container {...props}>
      {/* Primary Attributes Section */}
      <Grid item xs={isMobile ? 12 : 4} component="section">
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          classes={{
            root: clsx(classes.attributesSection, { [classes.attributesSectionMobile]: isMobile }),
          }}
        >
          <Grid item>
            <BenefitAttributeList benefit={benefit} />
          </Grid>
          <Grid item>
            <BenefitExternalLinks benefit={benefit} />
          </Grid>

          {Boolean(getStartedLink) && (
            <Grid item>
              <Button
                classes={{ root: classes.getStartedButton }}
                variant="contained"
                color="primary"
                href={getStartedLink}
                rel="noreferrer"
                target="_blank"
              >
                Get Started
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Details Section (in the grey box) */}
      <Grid
        item
        xs={isMobile ? 12 : 8}
        component="section"
        classes={{ root: classes.mainDetailsSection }}
      >
        <Typography
          variant="h3"
          component={headingLevel}
          classes={{ root: classes.programDescription }}
        >
          {programDescription}
        </Typography>

        {Boolean(detailedDescription) && (
          <div>
            <Typography variant="h4" component={getNextHeadingLevel(headingLevel)}>
              About this service:
            </Typography>
            <div className={classes.detailedDescription}>
              <EmboldMarkdown>{detailedDescription}</EmboldMarkdown>
            </div>
          </div>
        )}

        <BenefitWhenToUseList
          list={whenToUse}
          headingLevel={getNextHeadingLevel(headingLevel)}
          isMobile={isMobile}
        />
      </Grid>

      {/* Location Section */}
      {benefit.locations?.length > 0 && (
        <Grid
          item
          xs={12}
          component={ContactCard}
          classes={{ root: classes.contactCard }}
          locations={benefit.locations}
          headingLevel={getNextHeadingLevel(headingLevel)}
          showMap
          onPhoneClick={sendPhoneAnalytics}
          onAddressClick={sendDirectionsAnalytics}
        />
      )}
    </Grid>
  );
}

BenefitDetails.propTypes = {
  benefit: PropTypes.shape({
    programName: PropTypes.string.isRequired,
    programDescription: PropTypes.string,
    detailedDescription: PropTypes.string.isRequired,
    getStartedLink: PropTypes.string,
    whenToUse: PropTypes.arrayOf(PropTypes.string),
    locations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS), // This is to set the highest heading level for this component. Nested headings will be based off of this value.
  isMobile: PropTypes.bool,
  // https://v4.mui.com/api/grid/#props
  props: PropTypes.shape({}),
};

BenefitDetails.defaultProps = {
  headingLevel: 'h4',
  isMobile: false,
  props: {},
};
