/* eslint-disable no-dupe-keys */
/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEMO, DEFAULT_LOCATIONS } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 74fec37dc920f23166de2c27c1e6f132dec99e1e',
  staging: 'Token 4f1a091af010806e664f27ffcbd0a9b19cc9a060',
  prod: 'Token 8b54554bda38e770fcdcfe3690c85b8f78a875d9',
};

const token = selectToken(apiTokens);

export const demoConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'acme',
  EMBOLD_CLIENT: DEMO,
  EMBOLD_CLIENT_SLUG: 'acme',
  THEME: standardTheme,

  // toggles
  SHOW_COST: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,

  // login
  LOGIN_COOKIE_AUTH_REGEX: /^(acmehomegoods|acmetechnology|surgeryplus|acmegrocery|demo)$/,
  LOGIN_NETWORK_NAME: 'demoUserId',
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Nashville,
};
