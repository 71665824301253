import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CHAT_BUTTON_Z_INDEX } from 'utils/constants';
import { actions } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

import EmboldIcon from 'assets/embold-icon.png';

const useStyles = makeStyles((theme) => ({
  root: {
    // position
    position: 'fixed',
    top: '45%',
    left: '100%',
    zIndex: CHAT_BUTTON_Z_INDEX,
    transform: 'rotate(-90deg) translateX(-50%)',
    transformOrigin: 'bottom left',

    // size
    width: 285,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 8,

    // color
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '-1px -2px 4px rgba(0, 0, 0, 0.25)',

    // border
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },

    '@media only screen and (max-height: 400px)': {
      // on vertically short windows, switch to absolute positioning so that it's positioned inside the parent <main>
      // this avoids reflow/overflow issues
      position: 'absolute',
      top: 120,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: 4,
    fontWeight: 'normal',
    '& img': {
      marginRight: 8,
      height: 20,
      width: 20,
    },
  },
}));

export default function ChatButton() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const openChat = useCallback(() => {
    dispatch(actions.ui.openModal('chat'));
    dispatch(_analyticsActions.chatModalOpened());
  }, [dispatch]);

  return (
    <Button
      aria-label="ask embold virtual assistant ai chat"
      variant="contained"
      onClick={openChat}
      classes={classes}
    >
      <img src={EmboldIcon} alt="" />
      VIRTUAL ASSISTANT
    </Button>
  );
}
