import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useStateValue } from 'state/index';
import { initialState } from 'state/initial';
import { actions, select } from 'store/toolkit';

export default function useStartOver() {
  const [{ showLogin, memberId, networkSlug }, setState] = useStateValue();
  const locationHistory = useSelector(select.location.locationHistory);

  const lastLocation = useMemo(() => locationHistory[0], [locationHistory]);

  const dispatch = useDispatch();
  const { push } = useHistory(); // destructure push so that the callback is not rewritten anytime the whole history object has a change

  const startOver = useCallback(
    (route = '/') => {
      // reset redux state values
      dispatch(actions.app.startOver({ lastLocation }));

      // reset context state values
      setState({
        ...initialState,
        showLogin,
        memberId,
        networkSlug,
      });

      // this allows us to pass null to this function, in case we want to avoid changing the route
      if (route) {
        push(route);
      }
    },
    [dispatch, setState, showLogin, memberId, networkSlug, push, lastLocation]
  );

  return startOver;
}
