import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';

const useStyles = makeStyles((theme) => ({
  loading: {
    clipPath: 'inset(0 100% 0 0)',
    animation: '$loadingDots 1s steps(4) infinite',
  },
  '@keyframes loadingDots': {
    to: {
      'clip-path': 'inset(0 -34% 0 0)',
    },
  },
}));

export function MapLoadingModal({ text }) {
  const classes = useStyles();

  return (
    <div style={{ overflow: 'hidden', textAlign: 'center', margin: '10px 20px' }}>
      <EmboldLoadingSpinner width={40} height={40} />
      <Typography id="loading-dialog-title">
        {text}
        <span className={classes.loading} aria-hidden="true">
          ...
        </span>
      </Typography>
    </div>
  );
}
