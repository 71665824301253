import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS } from 'utils/constants';
import { selectToken, selectMapKey } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 56851a33b6945f62efed7bffdca6ca8b1541f308',
  staging: 'Token 7ae1972e6e40c43bdddd5ccc61459eacb39a4642',
  prod: 'Token 86759054b3eca3912ee52276ca5687d167d2099d',
};

const azureMapsKeys = {
  dev: 'KzRiwn3ytsCi9Hp_x5utRumI8eCEnsWPDK9ljSWvJcw',
  staging: 'qHn_TowRlwaSwIVj91ehzljIcE7O_sLFeGvBCVSYa2c',
  prod: '74e5OQx_c0l_8xNlwwKD4BCdl7-6KOkARE42uhzfQAY',
};

const token = selectToken(apiTokens);
const azureMapsKey = selectMapKey(azureMapsKeys);

const bcbsPhoneNumber = '866-823-3790';
const bcbsScheduleCopy = `Connecting you to the Blue Cross Blue Shield concierge service to assist with scheduling. Click 'Call Now' to continue.`;
const bcbsSupportLink = 'https://walmartsupport.emboldhealth.com/portal/home';

const aetnaPhoneNumber = '800-525-6257';
const aetnaScheduleCopy = `Connecting you to the Aetna concierge service to assist with scheduling. Click 'Call Now' to continue.`;
const aetnaSupportLink = 'https://walmartsupport.aetna.emboldhealth.com/portal/';

export const WMT_LOGIN_REGEX =
  /^(sanantonio|houston|dallasfw|tulsaokc|northeastflorida|centralflorida|northwestarkansas|aetnaillinois|aetnaflorida|bcbsarkansas|bcbsflorida|bcbsillinoisindiana|bcbsoklahoma|bcbstexas)$/;

export const defaultConfig = {
  API_TOKEN: token,
  EMBOLD_CLIENT: 'walmart',
  EMBOLD_CLIENT_SLUG: 'wmt',
  HIGH_QUALITY_INTRO_MODAL: false,
  THEME: standardTheme,
  GOOGLE_TAG_MANAGER_ENABLED: false,
  // api & auth
  API_URL: 'https://fusion.prod.emboldhealth.com/api/pg',
  FUSION_ENABLED: true,
  AZURE_MAPS_KEY: azureMapsKey,
  NEIGHBOURS_RADIUS: null,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  SHOW_SCHEDULE_BUTTON: true,
  SHOW_BCBS_LINK: false,
  SHOW_OUTCARE: true,
  SHOW_FILTER_COUNTS: false,
  SHOW_EXPAND_SEARCH: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: true,
  ENABLE_STYLEGUIDE_ROUTE: false,
  ENABLE_PLACE_SEARCH: true,
  MIN_COMPARE_COUNT: 2,
  MAX_COMPARE_COUNT_PROVIDERS: 10, // max amount of providers that can be compared
  MAX_COMPARE_COUNT_PLACES: 3, // max amount of places that can be compared
  PROFILE_COMPARE_THRESHOLD: 3, // shows profile compare verses a table of comparisons
  SHOW_REGION_SELECTOR: true,
  SHOW_BENEFIT_DIFF: false,
  REGION_SELECTOR_CODES: {},

  // login
  LOGIN_COOKIE_AUTH_REGEX: WMT_LOGIN_REGEX, // https://regex101.com/
  LOGIN_NETWORK_NAME: 'wmtLoggedIn',
  NETWORK_SELECT_API_PATH: 'analytics/network-select/',

  // laymans
  SHOW_DR_SCORE: true,
  SHOW_SUBSPECIALTIES: true,
  SHOW_PROVIDER_STRENGTHS: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  SHOW_LAYMANS_INTRO_MODAL: true,

  // other
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Dallas,
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT_PROMOTE_HEADER: false,
  HAS_PORTAL_INTEGRATION: false,
  DEFAULT_SEARCH_RADIUS: 25,
};
