import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';

import useGeolocation from 'hooks/useGeolocation';
import { useConfigValue } from 'config/state';

import NearMeIcon from '@material-ui/icons/NearMe';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';

import { actions, select } from 'store/toolkit';

function GeolocateButton() {
  const dispatch = useDispatch();

  const locationResolved = useSelector(select.location.locationResolved);
  const geolocationResolved = useSelector(select.location.geolocationResolved);
  const fetchingGeo = useSelector(select.location.fetchingGeo);
  const userDeniedLocation = useSelector(select.location.userDeniedLocation);

  const { getBrowserLocation } = useGeolocation();
  const {
    config: { USE_BROWSER_LOCATION },
  } = useConfigValue();

  const [title, geoIcon] = useMemo(() => {
    if (locationResolved && userDeniedLocation) {
      return ['Embold does not have permission to show your location', <NearMeIcon />];
    }
    if (geolocationResolved) {
      return ['Using device location', <NearMeIcon color="secondary" />];
    }
    return ['Use my device location', <NearMeOutlinedIcon color="secondary" />];
  }, [locationResolved, userDeniedLocation, geolocationResolved]);

  const handleGeolocate = () => {
    const locationButtonClicked = true;
    if (USE_BROWSER_LOCATION) {
      getBrowserLocation(locationButtonClicked, true);
    } else {
      dispatch(
        actions.location.handleBrowserSupportError(
          "We're having trouble finding your location, please enter an address, city, or zip code in the location search box to proceed."
        )
      );
    }
  };

  if (fetchingGeo) return <CircularProgress thickness={3} size={24} style={{ margin: 4 }} />;

  return (
    <IconButton onClick={handleGeolocate} title={title} disabled={geolocationResolved}>
      {geoIcon}
    </IconButton>
  );
}

export default GeolocateButton;
