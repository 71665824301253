import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Tooltip from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 4,
    display: 'flex',
  },
  icon: {
    height: 20,
    width: 20,
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px auto 0px`,
  },
  content: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  title: {
    fontWeight: 700,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

export default function Metric({
  icon,
  iconAltText,
  iconClass,
  title,
  headingLevel,
  TooltipProps,
  children,
  listItem,
  className,
  marginBottom,
  ...props
}) {
  const classes = useStyles();

  const hasTooltip = Boolean(TooltipProps?.message);

  const Container = listItem ? ListItem : Box; // listItem prop removes the need to wrap the entire component in a list item

  let content;
  if (typeof children === 'string')
    content = <Typography component="span">{children}</Typography>; // Wrap plain text in <span>
  else content = children;

  return (
    <Container
      className={clsx(classes.root, {
        [className]: Boolean(className), // append className from props if present
        [classes.marginBottom]: marginBottom, // append marginBottom class if marginBottom prop is present
      })}
      {...props}
    >
      <img src={icon} alt={iconAltText} className={iconClass || classes.icon} />
      <Box className={classes.content}>
        <Box>
          <Tooltip title={title} deactivated={!hasTooltip} {...TooltipProps}>
            <Typography variant="h6" component={headingLevel} className={classes.title}>
              {title}
              {hasTooltip && ':'}
            </Typography>
          </Tooltip>
        </Box>
        {content}
      </Box>
    </Container>
  );
}

Metric.propTypes = {
  icon: PropTypes.string.isRequired,
  iconAltText: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  TooltipProps: PropTypes.shape({
    message: PropTypes.string,
    link: PropTypes.shape({ to: PropTypes.string, label: PropTypes.string }),
  }),
  children: PropTypes.node.isRequired,
  listItem: PropTypes.bool,
  className: PropTypes.string,
  marginBottom: PropTypes.bool,
};

Metric.defaultProps = {
  iconClass: undefined,
  iconAltText: '',
  headingLevel: 'h4',
  TooltipProps: {},
  listItem: false,
  className: '',
  marginBottom: false,
};
