import React, { useRef, useEffect } from 'react';
import { useStateValue } from 'state';
import { useDispatch } from 'react-redux';
import { actions } from 'store/toolkit';

function CssCanary() {
  const isCssDisabledCanary = useRef(null);
  const [{ results }, setState] = useStateValue();
  const dispatch = useDispatch();

  useEffect(() => {
    // initially set to display: 'none', will default to 'block' if CSS is disabled
    if (window.getComputedStyle(isCssDisabledCanary.current).display === 'block') {
      dispatch(actions.ui.setIsCssEnabled(false));
    }
  }, [results, setState]);

  return (
    <div style={{ display: 'none' }} ref={isCssDisabledCanary}>
      Please enable CSS to see Map View
    </div>
  );
}

export default CssCanary;
