import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AuthenticationType } from 'azure-maps-control';
import {
  AzureMap,
  AzureMapFeature,
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsProvider,
  useAzureMaps,
} from 'react-azure-maps';

import { detectWebGLSupport } from 'utils/utils';
import { constants, select } from 'store/toolkit';
import NoWebGLNotice from 'components/Map/NoWebGLNotice';

const webGlSupported = detectWebGLSupport();

function Map({ locations, selectedIndex, options, onMarkerClick, className }) {
  const { mapRef } = useAzureMaps();
  const subscriptionKey = useSelector(select.tokens.azureMaps);

  const mapOptions = useMemo(
    () => ({
      subscriptionKey,
      authType: AuthenticationType.subscriptionKey,
      view: 'Auto',
      maxZoom: constants.map.MAX_ZOOM_LEVEL,
      interactive: false,
      ...options,
    }),
    [subscriptionKey, options]
  );

  useEffect(() => {
    if (mapRef) {
      /*
      for valid properties that can be added to this, see microsoft docs
      camera options: https://learn.microsoft.com/en-us/javascript/api/azure-maps-control/atlas.cameraoptions?view=azure-maps-typescript-latest
      camera bound options: https://learn.microsoft.com/en-us/javascript/api/azure-maps-control/atlas.cameraboundsoptions?view=azure-maps-typescript-latest
    */
      const currentLocation = locations[selectedIndex];
      const { latitude, longitude } = currentLocation;
      mapRef.setCamera({ center: [longitude, latitude] }); // update the map center if the locations prop changes
    }
  }, [mapRef, locations, selectedIndex]);

  if (!webGlSupported) return <NoWebGLNotice />;

  return (
    <div role="region" aria-label="Provider Location Mini Map" className={className}>
      <AzureMap options={mapOptions}>
        <AzureMapDataSourceProvider
          id="marker-provider"
          options={{
            cluster: false,
            allowOverlap: true,
          }}
        >
          <AzureMapLayerProvider
            id="provider-marker-layer"
            type="SymbolLayer"
            options={{
              iconOptions: {
                image: ['get', 'icon'],
                size: ['get', 'size'],
                allowOverlap: true,
              },
            }}
            events={{
              layeradded: (e) => e.map.resize(),
              click: onMarkerClick,
              mouseover: (e) => {
                e.map.getCanvasContainer().style.cursor = 'pointer';
              },
              mouseleave: (e) => {
                e.map.getCanvasContainer().style.cursor = 'grab';
              },
            }}
          />
          {locations.map((location, i) => {
            const isActiveMarker = selectedIndex === i;
            const properties = {
              icon: isActiveMarker ? 'marker-blue' : 'marker-darkblue',
              size: isActiveMarker ? 0.9 : 0.6,
            };
            return (
              <AzureMapFeature
                // eslint-disable-next-line react/no-array-index-key
                key={`${location.latitude},${location.longitude}-${i}`}
                id={i}
                variant="shape"
                type="Point"
                coordinate={[location.longitude, location.latitude]}
                properties={properties}
                setProperties={properties}
              />
            );
          })}
        </AzureMapDataSourceProvider>
      </AzureMap>
    </div>
  );
}

Map.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    })
  ).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  onMarkerClick: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
};

Map.defaultProps = {
  className: undefined,
  options: {},
};

export default function MiniMap(props) {
  return (
    <AzureMapsProvider>
      <Map {...props} />
    </AzureMapsProvider>
  );
}
