import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { select } from 'store/toolkit';
import { useStateValue } from 'state/index';
import { useConfigValue } from 'config/state/index';

import {
  EMBOLD_RECOMMENDED_THRESHOLD,
  EMBOLD_RECOMMENDED,
  PROVIDER_RESULT_TYPE,
} from 'utils/constants';
import { determineResultType } from 'utils/utils';

/**
 * @deprecated This hook should be replaced by the "calculateScoreFunction" selector in selectProvider.
 * This hook should only be used in legacy components, NOT modern experience components.
 * Legacy components still using this hook should transition away from it.
 *
 * This returns a function for getting a providers score.
 * If you are just needing the value of the score and not the function for getting the value,
 * you should use the useProviderScore hook instead.
 * @returns {Function} A function for getting the provider score
 */
export function useGetProviderScore() {
  const [{ showDrScore }] = useStateValue();
  const showChatModal = useSelector(select.ui.chatModalOpen);
  const activeSubspecialtyId = useSelector(select.results.subspecialtyId);
  const {
    config: { SHOW_EMBOLD_RECOMMENDED },
  } = useConfigValue();

  return useCallback(
    (provider, subspecialtyIdOverride) => {
      const subspecialtyId = showChatModal ? subspecialtyIdOverride : activeSubspecialtyId;
      const resultType = determineResultType(provider);

      // if there is no subspecialty id or the id is -1, then the search is not a subspecialty search
      const isSubspecialtySearch = subspecialtyId && subspecialtyId > 0;

      const overallScore = {
        name: SHOW_EMBOLD_RECOMMENDED ? EMBOLD_RECOMMENDED : 'Doctor Quality',
        value: provider.scoreOverallQuality,
        id: 'doctor-quality',
      };

      // only provider results should show scores.
      // some clients don't show scores based on showDrScore.
      // some providers may not have a scoreOverallQuality
      if (
        (resultType !== PROVIDER_RESULT_TYPE ||
          (!showDrScore && !SHOW_EMBOLD_RECOMMENDED) ||
          (!isSubspecialtySearch &&
            SHOW_EMBOLD_RECOMMENDED &&
            overallScore.value < EMBOLD_RECOMMENDED_THRESHOLD) ||
          !provider.scoreOverallQuality) &&
        (!showChatModal || !showDrScore)
      )
        return null;

      // return overall score if it is not a subspecialty search
      if (!isSubspecialtySearch) return overallScore;

      // find the active subspecialty on the provider object
      if (!provider.subspecialties || !provider.subspecialties.length) return null;
      const matchedSS = provider.subspecialties.find((ss) => ss.subspecialtyId === subspecialtyId);

      // if the subspecialty doesn't exist or doesn't have a score (ex. an unscored subspecialty) return null
      if (
        !matchedSS ||
        !matchedSS.subspecialtyScore ||
        (SHOW_EMBOLD_RECOMMENDED && matchedSS.subspecialtyScore < EMBOLD_RECOMMENDED_THRESHOLD)
      )
        return null;

      return {
        name: SHOW_EMBOLD_RECOMMENDED
          ? EMBOLD_RECOMMENDED
          : `${matchedSS.subspecialtyName} Quality`,
        value: matchedSS.subspecialtyScore,
        id: `${matchedSS.subspecialtyName}-quality`,
      };
    },
    [activeSubspecialtyId, showDrScore, SHOW_EMBOLD_RECOMMENDED, showChatModal]
  );
}

/**
 *  * @deprecated This hook should be replaced by the "score" selector in selectProvider.
 * This hook should only be used in legacy components, NOT modern experience components.
 * Legacy components still using this hook should transition away from it.
 *
 * This calculates the displayed score based on the search type. For subspecialty searches
 * with a scored subspecialty, it returns the subspecialty score. For other searches
 * it returns the Overall Quality Score.
 * @param {Object} provider A provider object returned from the fusion api
 * @param {Object[]} provider.subspecialties An array of subspecialties a provider contains
 * @returns {(Object|null)} A display score for a provider of either overall quality or subspecialty. Or null if conditions are not met.
 */
export default function useProviderScore(provider, subspecialtyIdOverride) {
  const getProviderScore = useGetProviderScore();

  const score = useMemo(
    () => getProviderScore(provider, subspecialtyIdOverride),
    [getProviderScore, provider, subspecialtyIdOverride]
  );
  return score;
}

/**
 *  * @deprecated This hook should be replaced by the "score" selector in selectProvider.
 * This hook should only be used in legacy components, NOT modern experience components.
 * Legacy components still using this hook should transition away from it.
 *
 * This hook is intended for use in the Compare Table, where the score needs to be calculated for a
 * dynamic number of providers.
 * @param {Object[]} providers A provider object returned from the fusion api
 * @param {Object[]} providers.subspecialties An array of subspecialties a provider contains
 * @returns {(Object|null)} An object with a score name, and an array of scores, or null if no score name is found
 */
export function useMultipleProviderScores(providers = []) {
  const getProviderScore = useGetProviderScore();

  return useMemo(() => {
    if (!providers.length) return null;

    // scoreName will represent the name of all of the scores being represented
    // for example, "Overall Quality" or "Hepatology Quality" (a subspecialty)
    let scoreName = null;
    // scores is the array of score objects returned by getProviderScore
    const scores = [];

    for (let i = 0; i < providers.length; i += 1) {
      // get the score using our hook
      const score = getProviderScore(providers[i]);

      if (score) scoreName = score.name;
      scores.push(score);
    }

    // if there was no scoreName, that would mean that all provider scores returned null
    if (!scoreName) return null;

    // return our final object
    return { name: scoreName, values: scores };
  }, [getProviderScore, providers]);
}
