import { getClient } from 'utils/utils';
import demoConfig from './demoConfig';
import dollarGeneralConfig from './dollarGeneral';
import dollarGeneralSupportConfig from './dollarGeneralSupportConfig';
import emboldConfig from './emboldConfig';
import emsanaCareConfig from './emsanaCareConfig';
import icubaConfig from './icubaConfig';
import microsoftConfig from './microsoftConfig';
import polkConfig from './polkConfig';
import surgeryPlusConfig from './surgeryPlusConfig';
import walmartConfig from './walmartConfig';
import bnsfConfig from './bnsfConfig';
import jbhuntConfig from './jbhuntConfig';
import soctConfig from './soctConfig';
import clrConfig from './clrConfig';
import customerDemoConfig from './customerDemoConfig';
import soctProviderLookupConfig from './soctProviderLookupConfig';

const LOCAL_CLIENT_TO_USE = process.env.REACT_APP_CLIENT || 'walmart'; // Change this value to set client config locally

export default function getClientConfig() {
  let client = getClient();

  if (client === 'local') {
    client = LOCAL_CLIENT_TO_USE;
  }

  switch (client) {
    case 'walmart':
      return walmartConfig;
    case 'microsoft':
      return microsoftConfig;
    case 'polk':
      return polkConfig;
    case 'demo':
      return demoConfig;
    case 'customerdemo':
      return customerDemoConfig;
    case 'embold':
      return emboldConfig;
    case 'icuba':
      return icubaConfig;
    case 'bcbst-support':
      return dollarGeneralSupportConfig;
    case 'bcbst':
      return dollarGeneralConfig;
    case 'emsanacare':
      return emsanaCareConfig;
    case 'surgeryplus':
      return surgeryPlusConfig;
    case 'bnsf':
      return bnsfConfig;
    case 'jbhunt':
      return jbhuntConfig;
    case 'carecompass':
      return soctConfig;
    case 'carecompass-providerlookup':
      return soctProviderLookupConfig;
    case 'continental':
      return clrConfig;
    case 'feature-three':
      return demoConfig;
    case 'feature-two':
      return microsoftConfig;
    case 'feature-one':
      return walmartConfig;
    default:
      console.error('Unhandled client configuration case');
      return demoConfig;
  }
}
