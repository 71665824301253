import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, IconButton } from '@material-ui/core';
import { Transition } from 'react-transition-group';

import { select } from 'store/toolkit';
import useFocusAnchors from 'utils/FocusRefContext';

const useStyles = makeStyles((theme) => ({
  '@media (forced-colors: active)': {
    backdrop: {
      '& [data-reach-dialog-content]': {
        background: 'white!important',
        border: '2px solid',
      },
    },
  },
  backdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.5)',
    zIndex: 1300,
    opacity: 0,
    '& [data-reach-dialog-content]': {
      // dialog
      position: 'relative',
      padding: '8px 32px 0px',
      borderRadius: 12,
      width: 'auto',
      display: 'flex',
      maxHeight: 'calc(100% - 64px)',
      flexDirection: 'column',
      overflowY: 'auto',
      margin: 32,
      boxShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
      [theme.breakpoints.only('xs')]: {
        padding: 8,
      },
    },
  },
  widthMd: {
    '& [data-reach-dialog-content]': {
      maxWidth: 960,
    },
  },
  widthSm: {
    '& [data-reach-dialog-content]': {
      maxWidth: 600,
    },
  },
  fullWidth: {
    '& [data-reach-dialog-content]': {
      width: 'calc(100% - 64px)',
    },
  },
  fullScreen: {
    alignItems: 'flex-start',
    '& [data-reach-dialog-content]': {
      width: '100%',
      minHeight: '100%',
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      maxHeight: 'none',
      borderRadius: 0,
      overflow: 'auto',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    margin: '0 0 .75rem',
    padding: '16px 24px 8px',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  errorTitle: {
    color: theme.palette.errorRed,
  },
  flexContent: {
    // overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  iconButton: {
    right: 0,
    top: 0,
    position: 'absolute',
    zIndex: 9,
  },
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    iconButton: {
      '& svg': {
        fill: 'white',
      },
    },
  },
  offsetX: {
    // account for header on small devices
    [theme.breakpoints.down('sm')]: {
      top: 64,
    },
    [theme.breakpoints.only('xs')]: {
      top: 56,
    },
  },
  showMobileMenu: {
    [theme.breakpoints.down('sm')]: {
      zIndex: 1299,
    },
  },
}));

function Modal({
  open,
  handleClose,
  title,
  children,
  showX,
  allowBackdropClick,
  maxWidth,
  fullWidth,
  fullScreen,
  ariaId,
  exitFunction,
  hasDescription,
  errorModal,
  customStyles,
  showMobileMenu,
  headerInModalOnMobile,
  silenceDialogRole,
  ...props
}) {
  const classes = useStyles();
  const xsOnly = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const smOnly = useMediaQuery((theme) => theme.breakpoints.only('sm'));

  const tooltipIsOpen = useSelector(select.tooltip.isOpen);

  // @TODO: simplify modal sizing options
  let widthClass = '';
  if (maxWidth === 'sm') widthClass = classes.widthSm;
  else if (maxWidth === 'md') widthClass = classes.widthMd;
  else if (maxWidth === 'full') widthClass = '';

  const focusAnchors = useFocusAnchors();

  const defaultStyle = {
    transition: '150ms ease all',
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const onExited = () => {
    setTimeout(() => {
      exitFunction();
    }, 0);
  };

  const offsetXbutton = fullScreen && showMobileMenu;

  const dialogRoles = silenceDialogRole
    ? {
        role: undefined,
        'aria-modal': undefined,
      }
    : {
        'aria-labelledby': `${ariaId}-title`,
        'aria-describedby': hasDescription ? `${ariaId}-description` : '',
      };

  return (
    <Transition
      in={open}
      timeout={{ appear: 150, enter: 0, exit: 150 }}
      mountOnEnter
      unmountOnExit
      onExited={exitFunction ? onExited : () => {}}
    >
      {(state) => (
        <DialogOverlay
          className={`
          ${classes.backdrop}    
          ${fullWidth ? classes.fullWidth : ''} 
          ${fullScreen ? classes.fullScreen : ''} 
          ${showMobileMenu ? classes.showMobileMenu : ''} 
          ${widthClass}
          ${customStyles}
          `}
          onDismiss={handleClose}
          dangerouslyBypassFocusLock={tooltipIsOpen}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          {...props}
        >
          <DialogContent
            {...dialogRoles}
            style={{
              paddingTop: headerInModalOnMobile
                ? 0
                : showMobileMenu && fullScreen
                ? xsOnly
                  ? 56
                  : smOnly
                  ? 64
                  : undefined
                : undefined,
            }}
            ref={focusAnchors.modalContent}
            id={ariaId ? `dialog-${ariaId}` : null}
          >
            {showX && (
              <IconButton
                aria-label="dialog close"
                onClick={handleClose}
                className={`${classes.iconButton} ${offsetXbutton ? classes.offsetX : ''}`}
              >
                <CloseIcon />
              </IconButton>
            )}
            {title && (
              <Typography
                variant="h2"
                className={`${classes.title}${errorModal ? ` ${classes.errorTitle}` : ''}`}
                style={{ paddingTop: xsOnly && showX ? 40 : 16 }}
                id={`${ariaId}-title`}
              >
                {title}
              </Typography>
            )}

            <div className={`${classes.flexContent} modal-content-wrapper`}>{children}</div>
          </DialogContent>
        </DialogOverlay>
      )}
    </Transition>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  showX: PropTypes.bool,
  allowBackdropClick: PropTypes.bool,
  ariaId: PropTypes.string.isRequired,
  hasDescription: PropTypes.bool,
  // sets maxWidth for modal. actual width dependent on child elements
  maxWidth: PropTypes.oneOf(['sm', 'md', 'full']),
  // fullWidth sets modal width to maxWidth set in the maxWidth property regardless the size of child elements
  fullWidth: PropTypes.bool,
  // override fullWidth & maxWidth props, full screen takeover
  fullScreen: PropTypes.bool,
  exitFunction: PropTypes.func,
  errorModal: PropTypes.bool,
  customStyles: PropTypes.string,
  showMobileMenu: PropTypes.bool,
  headerInModalOnMobile: PropTypes.bool,
  silenceDialogRole: PropTypes.bool,
};

Modal.defaultProps = {
  showX: true,
  allowBackdropClick: true,
  hasDescription: true,
  errorModal: false,
  headerInModalOnMobile: false,
  silenceDialogRole: false,
  handleClose: () => {},
  title: '',
  exitFunction: () => {},
  customStyles: '',
  showMobileMenu: false,
  maxWidth: 'sm', // 'sm' | 'md' | 'full'
  fullWidth: false,
  fullScreen: false,
};

export default Modal;
