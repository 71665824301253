import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'propTypes';

import { makeStyles } from '@material-ui/styles';
import { Collapse, Box, Typography, Grid, ListItem } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import SectionSubheader from './SectionSubheader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}`,
    display: 'block',
  },
  toggleAccordion: {
    '&:hover': { cursor: 'pointer' },
    '&:focus': { outlineOffset: 2 },
  },
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > :nth-child(2)': { marginLeft: 8 },
  },
  iconWrap: {
    padding: 0,
    marginLeft: 10,
  },
  childrenWrapper: { marginTop: theme.spacing(1) },
}));

function Accordion({ title, icon, children, isOpen, ariaId, isListItem }) {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(isOpen);

  const triggerId = `${ariaId}-trigger`;
  const contentId = `${ariaId}-content`;

  const isExpandable = Boolean(children);

  const handleClick = useCallback(() => setIsExpanded((prev) => !prev), []);
  const handleKeyDown = useCallback(
    (e) => {
      if (e?.key === 'Enter') handleClick();
    },
    [handleClick]
  );

  const triggerA11yProps = useMemo(
    () =>
      isExpandable
        ? {
            role: 'button',
            tabIndex: 0,
            'aria-expanded': isExpanded,
            'aria-controls': contentId,
            id: triggerId,
            'aria-label': `${title} details`,
            onClick: handleClick,
            onKeyDown: handleKeyDown,
          }
        : {},
    [contentId, isExpanded, triggerId, title, isExpandable, handleClick, handleKeyDown]
  );
  const contentA11yProps = useMemo(
    () =>
      isExpandable
        ? {
            id: contentId,
            'aria-labelledby': triggerId,
          }
        : {},
    [contentId, triggerId, isExpandable]
  );

  const Component = isListItem ? ListItem : Box;
  const ChildrenWrapper = typeof children === 'string' ? Typography : Box;

  return (
    <Component classes={{ root: classes.root }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        classes={{ root: classes.toggleAccordion }}
        {...triggerA11yProps}
      >
        <Grid item classes={{ root: classes.titleGrid }}>
          {Boolean(icon) && icon}
          <SectionSubheader component="span" variant="subtitle1">
            {title}
          </SectionSubheader>
        </Grid>

        {isExpandable && (
          <div className={classes.iconWrap}>{isExpanded ? <RemoveIcon /> : <AddIcon />}</div>
        )}
      </Grid>
      {isExpandable && (
        <Collapse in={isExpanded} {...contentA11yProps}>
          <ChildrenWrapper className={classes.childrenWrapper}>{children}</ChildrenWrapper>
        </Collapse>
      )}
    </Component>
  );
}

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  ariaId: PropTypes.string,
  isOpen: PropTypes.bool,
  icon: PropTypes.node,
  isListItem: PropTypes.bool,
};

Accordion.defaultProps = {
  children: null,
  icon: null,
  isOpen: false,
  ariaId: '',
  isListItem: false,
};
