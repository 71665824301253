import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  CHECKMARK,
  DROPDOWN,
  DROPDOWN_AUTOCOMPLETE,
  MEMBER_CREDENTIALS,
  PASSWORD,
} from 'utils/constants';
import { thunks, select } from 'store/toolkit';
import { logDevMessage } from 'utils/utils';
import { useStateValue } from 'state/index';
import useNavToIntendedRoute from '../../../navigation/useNavToIntendedRoute';
import CheckmarkLogin from './CheckmarkLogin';
import NetworkSelectLogin from './NetworkSelectLogin';
import PasswordLogin from './PasswordLogin';
import MemberCredentialsLogin from './MemberCredentialsLogin';

const useStyles = (formVariant) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& fieldset': {
        borderRadius: formVariant === DROPDOWN || formVariant === DROPDOWN_AUTOCOMPLETE ? 30 : 10,
      },
      '& input': {
        marginLeft: formVariant === DROPDOWN || formVariant === DROPDOWN_AUTOCOMPLETE ? 5 : 0,
      },
    },
    submitButton: {
      marginTop: theme.spacing(2),
      maxWidth: 100,
    },
    loadingSpinner: {
      zIndex: 10,
    },
    groupErrorText: {
      marginTop: theme.spacing(1),
    },
    loginHeader: {
      marginBottom: 20,
      fontWeight: 400,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        marginTop: 25,
      },
    },
    termsAgreement: {
      marginTop: theme.spacing(1),
    },
  }));

export default function LoginForm() {
  // redux selectors
  const configLoading = useSelector(select.config.isLoading);
  const formVariant = useSelector(select.client.loginFormVariant);

  const classes = useStyles(formVariant)();

  const dispatch = useDispatch();

  // navigation
  const navToIntendedRoute = useNavToIntendedRoute();

  // context
  const [, setState] = useStateValue();

  const handleSubmit = useCallback(
    async (formValues) => {
      const payload = {
        setState,
        ...formValues,
      };

      // update app state
      dispatch(thunks.app.userLoggedIn(payload)).then((action) => {
        if (action.type.endsWith('/fulfilled')) {
          navToIntendedRoute();
        }
      });
    },
    [navToIntendedRoute, dispatch, setState]
  );

  const formProps = useMemo(
    () => ({
      classes,
      onSubmit: handleSubmit,
    }),
    [classes, handleSubmit]
  );

  if (configLoading) {
    // because these forms rely on networks being available, we should not render them until the client config has loaded
    return <Skeleton width="100%" height={150} aria-label="form is loading" />;
  }
  switch (formVariant) {
    case CHECKMARK:
      return <CheckmarkLogin {...formProps} />;
    case DROPDOWN:
      return <NetworkSelectLogin {...formProps} />;
    case DROPDOWN_AUTOCOMPLETE:
      return <NetworkSelectLogin {...formProps} />;
    case PASSWORD:
      return <PasswordLogin {...formProps} />;
    case MEMBER_CREDENTIALS:
      return <MemberCredentialsLogin {...formProps} />;
    default:
      logDevMessage(`Unhandled form variant in <LoginForm />`);
      return null;
  }
}
