import { createSelector } from '@reduxjs/toolkit';

import { isTodayInDateRange } from 'utils/utils';
import { CONFIG_SLICE_NAME } from '../slicesNames';

/** The text shown in the network selector menu.
 * @returns {string} The UPDATE_REGION_TEXT value */
export const regionSelectorText = (state) => state[CONFIG_SLICE_NAME].values.UPDATE_REGION_TEXT;

/** Text shown as a description in the call modal
 * @returns {string} The SCHEDULE_COPY value */
export const scheduleCopy = (state) => state[CONFIG_SLICE_NAME].values.SCHEDULE_COPY;
// TODO This needs to search the network level first, then the client level
export const memberIdLink = (state) => state[CONFIG_SLICE_NAME].values.MEMBER_ID_LINK;

/** The text shown on the login page.
 * @returns {string} The WELCOME_MESSAGE value */
export const welcomeMessage = (state) => state[CONFIG_SLICE_NAME].values.WELCOME_MESSAGE;

/** The text shown when a search could not be completed.
 * @returns {string} The SEARCH_ERROR_TEXT value */
export const searchErrorText = (state) => state[CONFIG_SLICE_NAME].values.SEARCH_ERROR_TEXT;

/** Phone number used to override any SurgyPlus provider's number at all locations.
 * @returns {string} Value of SURGERY_PLUS_PHONE_NUMBER */
export const surgeryPlusPhoneNumber = (state) =>
  state[CONFIG_SLICE_NAME].values.SURGERY_PLUS_PHONE_NUMBER;

// @TODO: TECH-3491 review benefits changing properties. It may be worth updating the names to be more accurate
// This selects all content related to the benefits change and constructs an object with nested values
/**
 * The benefitsChange selector returns an object. See object shape below
 * @returns {object} benefitsChange An object with the title, text, and tooltip content
 * 
 * Deprecated: guageText properties - the gauge design is no longer in use
 * 
 * {
    dateRange: DATE_RANGE,
    title: COVERAGE_CHANGE_TITLE,
    cardAttribute: {
      text: COVERAGE_DECREASING_TEXT,
      tooltip: COVERAGE_DECREASING_TOOLTIP,
    },
    previousBenefit: {
      text: HIGHER_BENEFIT_MODAL_TITLE,
      modalText: HIGHER_BENEFIT_MODAL_TEXT,
    },
    newBenefit: {
      text: LOWER_BENEFIT_MODAL_TITLE,
      modalText: LOWER_BENEFIT_MODAL_TEXT,
    },
 * }
 */
export const benefitsChange = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_COVERAGE_CHANGE_TITLE,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TITLE,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TITLE,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_DATE_RANGE,
  ],
  (
    COVERAGE_CHANGE_TITLE,
    COVERAGE_DECREASING_TEXT,
    COVERAGE_DECREASING_TOOLTIP,
    HIGHER_BENEFIT_MODAL_TITLE,
    HIGHER_BENEFIT_MODAL_TEXT,
    LOWER_BENEFIT_MODAL_TITLE,
    LOWER_BENEFIT_MODAL_TEXT,
    DATE_RANGE
  ) => ({
    dateRange: DATE_RANGE,
    title: COVERAGE_CHANGE_TITLE,
    cardAttribute: {
      text: COVERAGE_DECREASING_TEXT,
      tooltip: COVERAGE_DECREASING_TOOLTIP,
    },
    previousBenefit: {
      text: HIGHER_BENEFIT_MODAL_TITLE,
      modalText: HIGHER_BENEFIT_MODAL_TEXT,
    },
    newBenefit: {
      text: LOWER_BENEFIT_MODAL_TITLE,
      modalText: LOWER_BENEFIT_MODAL_TEXT,
    },
  })
);

export const isTodayInBenefitChangeDateRange = createSelector(
  [benefitsChange],
  (benefitsConfigObj) => {
    if (!benefitsConfigObj?.dateRange) return null;
    return isTodayInDateRange(
      benefitsConfigObj?.dateRange?.start,
      benefitsConfigObj?.dateRange?.end
    );
  }
);

export const featuredProviderText = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_BANNER_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_TOOLTIP_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_COLOR,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_ICON,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_LINK,
  ],
  (bannerText, tooltipText, color, icon, link) => {
    if (!bannerText) return null; // should never have tooltip text without banner text

    return {
      banner: bannerText,
      tooltip: tooltipText,
      link,
      color, // may be undefined
      icon, // may be undefined
    };
  }
);

export const benefitDiffCopy = (state) => state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_COPY;
export const benefitDiffTooltipTitle = (state) =>
  state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_TITLE;
export const benefitDiffTooltipDescription = (state) =>
  state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_TOOLTIP;
