import { createSelector } from '@reduxjs/toolkit';

import { select } from 'store/toolkit';
import { PLACE_RESULT_TYPE, WALMART, MICROSOFT, POLK } from 'utils/constants';
import { determineResultType } from 'utils/utils';

import emboldLogo from 'assets/embold-logo-transparent.svg';

export const LIGHT_BLUE = 'lightBlue';
export const DARK_GREEN = 'darkGreen';
export const APPLE_RED = 'appleRed';

/** @deprecated */
export default class Result {
  constructor(resultData) {
    const { firstName, entityName, ...rest } = resultData;
    this.type = determineResultType(resultData);
    this.name = determineResultType(resultData) === PLACE_RESULT_TYPE ? entityName : firstName;
    Object.assign(this, rest);
  }

  /* ********************************** */
  /* *********** Selectors ************ */
  /* ********************************** */
  get selectNetworkContent() {
    return createSelector([select.networks.currentSlug, select.client.name], (network, client) => {
      const { healthPlan, curatedOutNetwork } = this;
      const isPlaceSearch = this.type === PLACE_RESULT_TYPE;

      const networkContentOverrides = {
        [WALMART]: {
          inNetwork: {
            tooltipMessage:
              'This provider is in-network and has been designated by your medical plan as a preferred provider. You will receive your highest benefit when you get care from this provider.',
            textColor: DARK_GREEN,
          },
          preferred: {
            networkText: 'In Network Preferred',
          },
          nonPreferred: {
            networkText: 'In Network Non-Preferred',
            textColor: APPLE_RED,
            // networkIcon: extendedNetworkBadge, // ignoring icons for this example
            tooltipMessage:
              'This provider is in-network but has been designated by your medical plan as a non-preferred provider. Your coverage will be lower when you get care from this provider.',
          },
        },
        [MICROSOFT]: {
          inNetwork: {
            textColor: LIGHT_BLUE,
            showTooltip: true,
          },
          healthConnect: {
            networkText: 'Health Connect Plan',
            tooltipMessage:
              'You’ll generally pay less out of pocket when you receive care from a Health Connect provider versus an Extended Network provider.',
          },
          extendedNetwork: {
            networkText: 'Extended Network',
            textColor: DARK_GREEN,
            tooltipMessage:
              "You'll generally pay more out of pocket when you receive care from an Extended Network provider versus a Health Connect provider.",
          },
        },
        [POLK]: {
          inNetwork: {
            textColor: LIGHT_BLUE,
            showTooltip: true,
          },
        },
      };

      let networkContent = {
        networkText: 'In Network',
        textColor: '',
        // networkIcon: inNetworkBadge, // ignoring icons for this example
        tooltipMessage:
          'This provider has been designated as In Network by your medical plan. You will receive the highest benefit when you get care from an In Network provider.',
        showTooltip: false,
        showNetworkStatus: true,
      };

      // Walmart
      if (client === WALMART) {
        const nwa = network === 'northwestarkansas' || network === 'bcbsarkansas';
        const curatedOut = curatedOutNetwork === network || this?.curatedOut;
        networkContent = { ...networkContent, ...networkContentOverrides[WALMART].inNetwork };

        if (!(isPlaceSearch || nwa)) {
          networkContent.showTooltip = true;
          if (curatedOut) {
            networkContent = {
              ...networkContent,
              ...networkContentOverrides[WALMART].nonPreferred,
            };
          } else {
            networkContent = { ...networkContent, ...networkContentOverrides[WALMART].preferred };
          }
        }
      }

      // Microsoft
      if (client === MICROSOFT) {
        const planPrefix = network?.toUpperCase();
        networkContent = { ...networkContent, ...networkContentOverrides[MICROSOFT].inNetwork };

        if (planPrefix === 'YMJ') {
          if (healthPlan === 'health_connect') {
            networkContent = {
              ...networkContent,
              ...networkContentOverrides[MICROSOFT].healthConnect,
            };
          } else {
            networkContent = {
              ...networkContent,
              ...networkContentOverrides[MICROSOFT].extendedNetwork,
            };
          }
        }

        if (isPlaceSearch) {
          networkContent.showNetworkStatus = false;
        }
      }

      // Polk
      if (client === POLK) {
        networkContent = { ...networkContent, ...networkContentOverrides[POLK].inNetwork };
      }

      return networkContent;
    });
  }

  get selectResultPills() {
    // eslint-disable-next-line no-unused-vars
    return createSelector([select.networks.currentSlug, select.client.name], (network, client) => {
      // @TODO: revisit this logic in TECH-3294
      const pills = [];

      pills.push({
        label: 'Above Average Performance',
        iconUrl: emboldLogo,
        color: 'navyBlue',
        tooltipMessage: "Here's a basic tooltip message",
      });
      pills.push({
        label: 'Surgery Plus',
        color: 'swampGreen',
        tooltipMessage: "Here's a basic tooltip message",
      });

      return pills;
    });
  }
}
