import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import LanguageIcon from '@material-ui/icons/Language';

import PhonePicture from 'assets/pg-phone.png';
import Strengths from 'assets/strengths.svg';
import Wellness from 'assets/health_wellness.svg';
import FocusAreas from 'assets/focus-areas.svg';
import { useConfigValue } from 'config/state/index';
import {
  SCORE_COLORS_BY_TIER,
  SCORE_TIER_ICON_COMPONENTS,
  SUBSPECIALTY_SECTION,
} from 'utils/constants';

const ScoreTierIcon = SCORE_TIER_ICON_COMPONENTS[1];

const badges = [
  {
    icon: <LanguageIcon color="primary" />,
    heading: 'Quickly find the\nright doctor',
    subHeading:
      'Search by location, name, and specialty. Filter by the score, distance, etc. See how good they are at diagnosing, treatment planning, and outcomes',
  },
  {
    src: Strengths,
    heading: 'Provider\nStrengths',
    subHeading:
      'When a provider’s quality in Diagnosing, Treatment Plan, or Patient Outcomes is higher than their peers, it is highlighted as a strength in order to help you choose the best match for your needs.',
  },
  {
    src: FocusAreas,
    heading: 'Provider\n'.concat(SUBSPECIALTY_SECTION.title),
    subHeading: SUBSPECIALTY_SECTION.about,
  },
  {
    src: Wellness,
    heading: 'Health &\nWellness Perks',
    subHeading:
      'When you search for a provider, i.e. “counselor,” your employer’s related benefits show up in Provider Guide, reminding you of your effective, low-cost options for specific medical needs.',
    altText: 'Example benefit rating showing a score of 3 out of 4 stars',
  },
];

const drQualityBadge = {
  icon: <ScoreTierIcon htmlColor={SCORE_COLORS_BY_TIER[1]} />,
  heading: 'Doctor\nQuality',
  subHeading:
    'Provider Guide connects you with great local healthcare providers. Doctor Quality will help you evaluate healthcare providers across all aspects of quality.',
};

const useStyles = makeStyles((theme) => ({
  rowTwo: {
    padding: '2.5%',
    borderRadius: '15px',
    width: '100%',
  },

  learnMore: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
    marginTop: 15,
    color: 'white',
    letterSpacing: '.1rem',
    fontWeight: '500',
    minWidth: '200px',
    textAlign: 'center',
    padding: 5,
    borderRadius: '20px',
  },

  badgeHeading: {
    whiteSpace: 'pre-line',
  },
  featureIconList: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    width: '100%',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      height: '90%',
      maxWidth: '90%',
    },
    '& > svg': {
      fontSize: 90,
    },
    // @TODO TECH-3846 Revisit color here
  },
}));

function AboutProviderGuide() {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    config: { SHOW_DR_SCORE },
    loadingClientConfig,
  } = useConfigValue();

  useEffect(() => {
    const hasDrQualityBadge = badges.find((b) => b.heading === drQualityBadge.heading);
    if (!loadingClientConfig && SHOW_DR_SCORE && !hasDrQualityBadge)
      badges.splice(1, 0, drQualityBadge);
  }, [loadingClientConfig, SHOW_DR_SCORE]);

  return (
    <Grid>
      <Grid container direction="row" className={classes.rowTwo} justifyContent="space-between">
        <Grid item md={5} sm={12}>
          <img width="100%" src={PhonePicture} alt="a cellphone displaying provider guide" />
        </Grid>

        <Grid
          item
          container
          direction="column"
          md={6}
          sm={12}
          justifyContent="center"
          alignItems="center"
        >
          {smDown && <br />}
          <Grid>
            <Typography
              className={classes.paragraph}
              style={{
                fontSize: '1rem',
              }}
            >
              Our Provider Guide solution gives people a fast, easy way to find a doctor the way a
              doctor would — through thoughtful examination of facts and a focus on patient
              outcomes. Unlike online reviews or word-of-mouth recommendations, which are based on
              subjective opinions, Provider Guide is based on science.
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Link
              className={classes.learnMore}
              target="_blank"
              rel="noreferrer"
              underline="none"
              href="https://emboldstatichosting.blob.core.windows.net/external/EmboldHealth_WhitePaper_MeasureWhatMatter_20220331.pdf"
              aria-label="Learn more about how Embold Health is transforming healthcare by measuring what matters."
            >
              Learn More
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        component={List}
        direction="row"
        justifyContent={smDown ? 'center' : 'space-between'}
        classes={{ root: classes.featureIconList }}
      >
        {badges.map((asset, i) => (
          <Grid
            container
            item
            direction="column"
            component="li"
            sm={7}
            md={2}
            alignItems="center"
            key={asset.heading.concat(i)}
          >
            <div className={classes.iconContainer}>
              {Boolean(asset.icon) && asset.icon}
              {Boolean(asset.src) && (
                <img
                  src={asset.src}
                  alt={asset.altText || asset.heading}
                  aria-hidden={!asset.altText}
                />
              )}
            </div>
            <Typography
              variant="h5"
              component="h3"
              align="center"
              style={{ width: i === 1 ? '55%' : '70%' }}
              className={classes.badgeHeading}
            >
              {asset.heading}
            </Typography>
            <Typography align="center" style={{ paddingTop: 10, fontSize: smDown ? 13 : 11 }}>
              {asset.subHeading}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AboutProviderGuide;
