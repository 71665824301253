import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { resultIsPlaceType } from 'utils/utils';
import HospitalSafetyGradeBadge from './HospitalSafetyGradeBadge';
import FeaturedResultBadge from './FeaturedResultBadge';
import CoverageBadge from './CoverageBadge';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  badge: {
    maxWidth: 80, // This could potentially cause overflows if the badge text is too long. FeaturedProvider text is configured on the back-end
    '& span': {
      wordWrap: 'break-word', // This allows for a word to break, with the maxWidth of 80 words longer than 11 chars will break to new line
    },
    '& img': {
      width: '70px !important',
      height: '70px !important',
    },
  },
});

export default function PlaceBadges({ place }) {
  const classes = useStyles();
  const isPlaceType = resultIsPlaceType(place);

  if (!isPlaceType) return null;

  return (
    <div className={classes.root}>
      <HospitalSafetyGradeBadge place={place} className={classes.badge} />
      <FeaturedResultBadge isFeatured={place.clientFeatured} isPlace className={classes.badge} />
      {/* Override onClick to be undefined as to not have a modal for places on In-Network */}
      <CoverageBadge provider={place} className={classes.badge} onClick={undefined} />
    </div>
  );
}

PlaceBadges.propTypes = {
  place: PropTypes.shape({
    hospitalGrade: PropTypes.string,
    hospitalGradeLastUpdated: PropTypes.string,
    clientFeatured: PropTypes.bool,
  }),
};

PlaceBadges.defaultProps = {
  place: {},
};
