import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useStateValue } from 'state';

import Modal from 'components/Modals/Modal';

function GeoErrorModal() {
  const [{ fetchingGeoError }, setState] = useStateValue();

  const closeGeoErrorModal = () => {
    setState({ fetchingGeoError: false });
  };

  return (
    <Modal
      open={Boolean(fetchingGeoError)}
      ariaId="geo-error-dialog"
      handleClose={closeGeoErrorModal}
      title="Unable to Find Location"
    >
      <Typography paragraph>{fetchingGeoError}</Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={closeGeoErrorModal}
        style={{ margin: '0 auto 20px', display: 'block' }}
      >
        Ok
      </Button>
    </Modal>
  );
}

export default GeoErrorModal;
