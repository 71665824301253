export const SEARCH_METHODS = {
  PROVIDERS_BY_SPECIALTY: 'providersBySpecialty',
  PROVIDER_BY_ENTITY_ID: 'providerByEntityId',
  PROVIDERS_BY_NAME: 'providersByName',
  PLACES_BY_TYPE: 'placesByType',
  PLACES_BY_LOOSE_NAME: 'placesByLooseName',
  PLACE_BY_NAME_EXACT: 'placeByNameExact',
  PROVIDERS_BY_SERVICE: 'providersByService',
  PLACES_BY_SERVICE: 'placesByService',
  PLACE_BY_ID: 'placeById',
};

export const ENDPOINTS = {
  PROVIDERS: '/providers',
  PLACES: '/places',
  SERVICE: '/service',
  SHARE: '/share',
  CLOSEST_PROVIDERS: '/closest-provider-location',
  CLOSEST_PLACES: '/closest-place-location',
};

export const SEARCH_LIMIT = 50;

export const PARAMS = {
  LOCATION: 'location',
  LIMIT: 'limit',
  OFFSET: 'offset',
  ORDERING: 'ordering',
  NETWORK_SLUG: 'network_slug',
  RADIUS: 'radius',
  BOUNDING_BOX: 'bounding_box',
  ENTITY_TYPE: 'entity_type',
  Q: 'q',
  SPECIALTY_ID: 'specialty_id',
  SUBSPECIALTY_ID: 'subspecialty_id',
  NAME_EXACT: 'name__exact',
  SERVICE_ID: 'service_id',
  STATE: 'state',
};
