import DollarGeneralLoginLogo from 'assets/bcbst-login-logo.svg';
import { DROPDOWN } from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const dollarGeneralOverrides = {
  SCHEDULE_PHONE_NUMBER: '1-800-521-9919',
  HEADER_LOGO: DollarGeneralLoginLogo,
  SUPPORT_LINK: 'https://dgsupport.emboldhealth.com/portal/',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  FORM_VARIANT: DROPDOWN,
  WELCOME_MESSAGE: `Dollar General and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: DollarGeneralLoginLogo,
  NO_WELCOME_LINK: false,
};
