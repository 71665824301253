import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Fab, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';

import { select, actions } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'none',
  },
  mobileText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  desktopText: {
    color: theme.palette.grey[700],
    fontSize: 12,
    textWrap: 'nowrap',
  },
  extended: {
    border: `1px solid ${theme.palette.primary.main}`,
    '& svg': { marginRight: theme.spacing(1) },
  },
  icon: {
    marginRight: theme.spacing(1),
    transition: 'all 0.5s',
  },
  iconMapIsHidden: {
    transform: 'rotate(-180deg)',
  },
}));

function ShowMapButton({ className, isMobile = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapIsOpen = useSelector(select.ui.showResultsMap);
  const hasActiveMapMarker = useSelector(select.results.hasActiveMapMarker);
  const returnedNoResults = useSelector(select.results.returnedNoResults);

  const toggleMap = useCallback(() => dispatch(actions.ui.toggleShowResultsMap()), [dispatch]);

  // extend root class with className prop
  let rootClass = classes.root;
  if (className) rootClass += ` ${className}`;

  if (isMobile) {
    if (returnedNoResults || hasActiveMapMarker) return null;
    return (
      <Fab
        onClick={toggleMap}
        variant="extended"
        classes={{ root: rootClass, extended: classes.extended, label: classes.mobileText }}
      >
        {mapIsOpen ? <ListIcon /> : <MapIcon />}
        {mapIsOpen ? 'Show List' : 'Show Map'}
      </Fab>
    );
  }

  // apply additional class to transform (rotate) icon when map is closed
  let iconClass = classes.icon;
  if (!mapIsOpen) iconClass += ` ${classes.iconMapIsHidden}`;

  return (
    <Button
      onClick={toggleMap}
      classes={{ root: rootClass, label: classes.desktopText }}
      aria-expanded={mapIsOpen}
      aria-controls="map-container"
    >
      <KeyboardTabIcon className={iconClass} />
      {mapIsOpen ? 'Hide Map' : 'Show Map'}
    </Button>
  );
}

ShowMapButton.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

ShowMapButton.defaultProps = {
  className: undefined,
  isMobile: false,
};

export default React.memo(ShowMapButton);
