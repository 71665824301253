import React from 'react';
import PropTypes from 'prop-types';

import { PLACE_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';
import { determineResultType } from 'utils/utils';
import PlaceBadges from './PlaceBadges';
import ProviderBadges from './ProviderBadges';

// This component takes generic profile data, and based on the resultType determines which set of badges to render
export default function ProfileBadges({ profileData }) {
  const resultType = determineResultType(profileData);

  switch (resultType) {
    case PLACE_TYPE:
      return <PlaceBadges place={profileData} />;
    case PROVIDER_RESULT_TYPE:
      return <ProviderBadges provider={profileData} />;
    default:
      // should never reach here unless new result type is added without badges
      console.warn('Unhandled resultType in ProfileBadges');
      return null;
  }
}

/* eslint-disable react/forbid-prop-types */
// without some globally defined result types stored, it's unreasonable to list all possible properties of profileData
ProfileBadges.propTypes = {
  profileData: PropTypes.object.isRequired,
};
