import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'propTypes/index';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { Grid, Typography, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingDots from 'components/LoadingDots';

import {
  EMBOLD_ANALYSIS_MESSAGES,
  EMBOLD_ANALYSIS_MESSAGE_INTERVAL,
} from 'store/slices/chat/chatConstants';
import CheckOrXIcon from 'icons/CheckOrXIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  collapseRoot: {
    maxHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    rowGap: 6,
  },
  icon: {
    width: 24,
    marginRight: 2,
  },
  text: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.darkGray,
  },
}));

export default function EmboldAnalysisStepper({ sendingChatOverview, scrollToBottom }) {
  const classes = useStyles();
  const [showStepper, setShowStepper] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [currentStep, setCurrentStep] = useState(-1);
  // @TODO: get translated messages
  const steps = useMemo(
    () => EMBOLD_ANALYSIS_MESSAGES.map((text) => ({ text, key: uniqueId() })),
    []
  );

  const handleComplete = useCallback(() => {
    setCurrentStep(3);
    clearInterval(intervalId);
    setShowStepper(false);
  }, [intervalId]);

  useEffect(() => {
    // stop incrementing once you reach final step and wait until endpoint sends response
    if (currentStep === 2) clearInterval(intervalId);
  }, [currentStep, intervalId]);

  const beginStepper = useCallback(() => {
    setShowStepper(true);
    setCurrentStep(0);
    const stepIntervalId = setInterval(
      () => setCurrentStep((i) => i + 1),
      EMBOLD_ANALYSIS_MESSAGE_INTERVAL
    );
    setIntervalId(stepIntervalId);
    setTimeout(scrollToBottom, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sendingChatOverview state triggers display and timer
  useEffect(() => {
    if (sendingChatOverview) {
      beginStepper();
    } else if (showStepper) {
      // analyze endpoint has finished
      handleComplete();
    }

    return () => {
      // clean up setInterval timers
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingChatOverview]);

  return (
    <Grid
      aria-hidden={!showStepper}
      classes={{ root: clsx(classes.root, { [classes.collapseRoot]: !showStepper }) }}
      data-testid="embold-analysis-stepper-root"
    >
      <Grid classes={{ root: classes.container }} container direction="column">
        {steps.map(({ text, key }, i) => (
          <Grow in={currentStep >= i} key={key}>
            <Grid container alignItems="center">
              {i === currentStep ? (
                <LoadingDots small additionalClass={classes.icon} />
              ) : (
                <CheckOrXIcon checked className={classes.icon} />
              )}
              <Typography classes={{ root: classes.text }} variant="body1">
                {text}
              </Typography>
            </Grid>
          </Grow>
        ))}
      </Grid>
    </Grid>
  );
}

EmboldAnalysisStepper.propTypes = {
  sendingChatOverview: PropTypes.bool.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
};
