import React from 'react';
import { useSelector } from 'react-redux';

import ShareProvider from 'components/Modals/ProfileModals/ShareProvider';
import ShareFeedback from 'components/Modals/ProfileModals/ShareFeedback';
import CallModal from 'components/Modals/ProfileModals/CallModal';
import { select } from 'store/toolkit';
import { logDevMessage } from 'utils/utils';
import { useFocusTo } from 'utils/FocusRefContext';
import SpecialtiesModal from './SpecialtiesModal';
import CostModal from './CostModal';
import InfoModal from './InfoModal';

function ProfileModals() {
  const profileModal = useSelector(select.ui.profileModal);
  const focusTo = useFocusTo();

  if (!profileModal?.isOpen) {
    // the timeout is necessary because of the order in which redux state is updated, and components are unmounted.
    setTimeout(focusTo.profileModalReturn, 10);
    return null;
  }

  switch (profileModal.type) {
    case 'call':
      return <CallModal profileData={profileModal.data} />;
    case 'share':
      return <ShareProvider profileData={profileModal.data} />;
    case 'feedback':
      return <ShareFeedback />;
    case 'specialties':
      return <SpecialtiesModal profileData={profileModal.data} />;
    case 'cost':
      return <CostModal profileData={profileModal.data} />;
    case 'info':
      return <InfoModal profileData={profileModal.data} />;
    default:
      logDevMessage('Unhandled profileModal.type in <ProfileModals />');
      return null;
  }
}

export default ProfileModals;
