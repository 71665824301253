import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EmboldLogo from 'assets/embold-logo-full-horizontal.svg';
import CopyTextButton from 'components/CopyTextButton';

import { select } from 'store/toolkit';

const useStyles = makeStyles(() => ({
  restartButton: {
    marginLeft: 8,
    minWidth: 40,
  },
  emboldLogo: {
    height: 24,
    margin: '4px 0px',
  },
  copyButton: {
    padding: 0,
    marginLeft: 8,
  },
}));

export default function ChatHeader({ onReset, getConversationAsString }) {
  const resultIds = Object.keys(useSelector(select.chat.results));
  const classes = useStyles();

  return (
    <Grid container alignItems="center" style={{ marginBottom: 10 }}>
      <Typography variant="srOnly" id="chat-modal-title">
        Embold Health AI chat
      </Typography>
      <img src={EmboldLogo} alt="embold health logo" className={classes.emboldLogo} />
      <Button size="small" variant="outlined" onClick={onReset} className={classes.restartButton}>
        Reset
      </Button>
      {resultIds.length > 0 && (
        <CopyTextButton textToCopy={getConversationAsString()} className={classes.copyButton} />
      )}
    </Grid>
  );
}

ChatHeader.propTypes = {
  onReset: PropTypes.func.isRequired,
  getConversationAsString: PropTypes.func.isRequired,
};

ChatHeader.defaultProps = {};
