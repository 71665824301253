import { createSelector } from '@reduxjs/toolkit';
import { AuthenticationType } from 'azure-maps-control';

import { isValidCoords } from 'utils/mapUtils';
import { azureMaps as selectAzureMapsKey } from 'store/slices/config/selectTokens';
import { latLong as selectLatLong } from 'store/slices/location/selectLocation';
import * as mapConstants from './mapConstants';
import { MAP_SLICE_NAME } from '../slicesNames';

/**
 * @returns {Object} The object containing the coordinates of the center of the map.
 * Shape { latitude: 123, longitude: 456 } */
export const center = (state) => state[MAP_SLICE_NAME].map.center;

/**
 * @returns {Object} The object containing the northeast and southwest coordinates of the map.
 * Shape { sw: { latitude: 123, longitude: 456 }, ne: { latitude: 321, longitude: 654 } } */
export const bounds = (state) => state[MAP_SLICE_NAME].map.bounds;

/**
 * @returns {Object} The object containing the coordinates of the southwest corner of the map
 * Shape { latitude: 123, longitude: 456 } */
export const southwestBound = (state) => state[MAP_SLICE_NAME].map.bounds.sw;

/**
 * @returns {Object} The object containing the coordinates of the northeast corner of the map
 * Shape { latitude: 321, longitude: 654 } */
export const northeastBound = (state) => state[MAP_SLICE_NAME].map.bounds.ne;

/** @returns {number} The number value of the maps zoom level */
export const zoom = (state) => state[MAP_SLICE_NAME].map.zoom;

/** @returns {bool} True if the map is currently loading */
export const isLoading = (state) => state[MAP_SLICE_NAME].map.isLoading;

/** @returns {bool} Indicates that the map's centers is vertically offset */
export const isOffset = (state) => state[MAP_SLICE_NAME].isOffset;

/** @returns {bool} Indicates if the map has been moved since the last search */
export const hasMoved = (state) => state[MAP_SLICE_NAME].hasMoved;

/** @returns {bool} */
export const showActiveResultLocations = (state) => state[MAP_SLICE_NAME].showActiveResultLocations;

/** The pending camera updates should only be used by useSyncMapToRedux. It returns
 * the updates to be made to the maps camera position.
 * @returns {Object} */
export const pendingCameraUpdates = (state) => state[MAP_SLICE_NAME].pendingCameraUpdates;

/** This returns the map bounds as a query param in format neLat,neLng__swLat,swLng or an empty string if no valid coords */
export const boundsAsQueryParams = createSelector([southwestBound, northeastBound], (sw, ne) => {
  if (isValidCoords(sw.latitude, sw.longitude) && isValidCoords(ne.latitude, ne.longitude)) {
    return encodeURIComponent(`${ne.latitude},${ne.longitude}__${sw.latitude},${sw.longitude}`);
  }
  return '';
});

/** Returns an object containing the initial setup for the results map. Includes authentication, and initial location/zoom */
export const azureMapOptions = createSelector(
  [selectAzureMapsKey, selectLatLong],
  (mapKey, latLong) => {
    const { latitude, longitude } = latLong;
    if (!latitude || !longitude) return undefined;
    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: mapKey,
      },
      view: 'Auto',
      center: [longitude, latitude],
      maxZoom: mapConstants.MAX_ZOOM_LEVEL,
      zoom: mapConstants.DEFAULT_ZOOM_LEVEL,
      fadeDuration: 0,
    };
  }
);
