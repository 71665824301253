/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthenticationType } from 'azure-maps-control';
import ClusterLayer from 'components/Map/ClusterLayer';
import Link from '@material-ui/core/Link';
import { AzureMap, AzureMapsProvider } from 'react-azure-maps';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { detectWebGLSupport, buildLocationUrl, getBounds } from 'utils/utils';
import NoWebGLNotice from 'components/Map/NoWebGLNotice';
import { useConfigValue } from 'config/state';

const webGlSupported = detectWebGLSupport();

function ProfileMap({ closestLocation, allLocations, options }) {
  const [selectedProviderLocation, setSelectedProviderLocation] = useState(null);

  const locations = allLocations.length ? allLocations : [closestLocation];
  const miniMapBounds = getBounds(locations);
  const {
    config: {
      AZURE_MAPS_KEY,
      CONTENT: { MAPS_URL },
    },
  } = useConfigValue();

  const mapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: AZURE_MAPS_KEY,
    },
    view: 'Auto',
    maxZoom: 17,
    bounds: miniMapBounds,
    ...options,
  };

  return (
    <ConditionalWrapper
      condition={locations.length === 1}
      wrapper={(children) => (
        <Link href={buildLocationUrl(closestLocation, MAPS_URL)} rel="noreferrer" target="_blank">
          {children}
        </Link>
      )}
    >
      <div
        role="region"
        aria-label="Provider Location Mini Map"
        style={{ width: '100%', height: 250, borderRadius: 10, overflow: 'hidden' }}
        onClick={() => setSelectedProviderLocation(null)}
      >
        {webGlSupported ? (
          <AzureMapsProvider>
            <AzureMap maxZoom={10} options={mapOptions}>
              <ClusterLayer
                providerLocations={locations}
                selectedProviderLocation={selectedProviderLocation}
                setSelectedProviderLocation={setSelectedProviderLocation}
                setShowAllLocations={() => {}}
                handleCollapseList={() => {}}
                isMiniMap
              />
            </AzureMap>
          </AzureMapsProvider>
        ) : (
          <NoWebGLNotice />
        )}
      </div>
    </ConditionalWrapper>
  );
}

ProfileMap.propTypes = {
  closestLocation: PropTypes.shape({}).isRequired,
  allLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.shape({}),
};

ProfileMap.defaultProps = {
  options: {},
};

export default ProfileMap;
