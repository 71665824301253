import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AzureMap, useAzureMaps } from 'react-azure-maps';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';

import placeIconBlue from 'assets/place-icon-blue.svg'; // TODO TECH-3847 replace with custom icon
import placeIconDarkBlue from 'assets/place-icon-dark-blue.svg'; // TODO TECH-3847 replace with custom icon

import { select, actions } from 'store/toolkit';
import { detectWebGLSupport } from 'utils/utils';
import useSyncMapToRedux from 'hooks/map/useSyncMapToRedux';
import NoWebGLNotice from 'components/Map/NoWebGLNotice';
import RadiusMapLayer from './Layers/RadiusMapLayer';
import UserLocationMapLayer from './Layers/UserLocationMapLayer';
import ResultDataProvider from './Layers/ResultDataProvider';
import MarkerLayer from './Layers/MarkerLayer';
import ClusterBubbleLayer from './Layers/ClusterBubbleLayer';
import ActiveMarkerLayer from './Layers/ActiveMarkerLayer';
import MapController from './Controls/MapController';

const useStyles = makeStyles((theme) => ({
  controls: {
    marginTop: theme.spacing(1),
    width: '100%',
    padding: `0px ${theme.spacing(4)}px`,
  },

  skeletonWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
  },
  skeleton: {
    height: '100%',
    width: '100%',
  },
}));

const isWebGlSupported = detectWebGLSupport();

const PROVIDER_ID = 'results-provider';

const imageSprites = [
  { id: 'place-blue', icon: placeIconBlue },
  { id: 'place-darkblue', icon: placeIconDarkBlue },
];

function ResultsMap({ containerHeight }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMdDown = useSelector(select.ui.isMdDown);

  const { mapRef } = useAzureMaps(); // access the map's internal state/control
  const azureMapOptions = useSelector(select.map.azureMapOptions); // get the map's initial setup data

  const mapEventHandlers = useSyncMapToRedux(mapRef); // use hook to connect map's state to Redux state

  const events = useMemo(
    () => ({
      // example events can be found at https://samples.azuremaps.com/map/map-events
      ...mapEventHandlers, // these prebuild events are for syncing the map's internal state to redux state
      // additional map events can be added below
      click: () => dispatch(actions.app.mapClicked()),
    }),
    [mapEventHandlers, dispatch]
  );

  /** resize the map canvas if the container height changes (mobile only) */
  useEffect(() => {
    if (containerHeight && isMdDown) mapRef.resize();
  }, [containerHeight, mapRef, isMdDown]);

  if (!isWebGlSupported) return <NoWebGLNotice />;

  return (
    <AzureMap
      LoaderComponent={null}
      events={events}
      options={azureMapOptions}
      imageSprites={imageSprites}
    >
      <MapController className={classes.controls} />

      {/* Map Layers */}
      <UserLocationMapLayer />
      <RadiusMapLayer />

      <ResultDataProvider dataProviderId={PROVIDER_ID}>
        {/* order matters! the last layer to render will be on top of the others */}
        <MarkerLayer />
        <ClusterBubbleLayer dataProviderId={PROVIDER_ID} />
        <ActiveMarkerLayer />
      </ResultDataProvider>
    </AzureMap>
  );
}

ResultsMap.propTypes = {
  containerHeight: PropTypes.number,
};

ResultsMap.defaultProps = {
  containerHeight: undefined,
};

export default React.memo(ResultsMap);
