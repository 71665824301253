import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

import IconLabel from 'components/Results/ResultCard/IconLabel';
import warningIcon from 'assets/decreasingicon.svg';

import Tooltip from 'components/Tooltip';

import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';
import ProfileBadge from 'components/ModernExperience/Profile/ProfileBadge';

import Modal from 'components/Modals/Modal';
import { getLocalStorageExpire, setLocalStorageExpire } from 'utils/utils';
import { useStateValue } from 'state';
import { WALMART, WMT_BENEFITS_NOTIFIER_VERSION } from 'utils/constants';
import { useConfigValue } from 'config/state';
import { actions, select } from 'store/toolkit';
import {
  highPerformingPill as createHighPerformingPillSelector,
  highPerformingBadge as createHighPerformingBadgeSelector,
} from 'store/slices/results/selectProvider';
import { getNetworkContent } from 'store/slices/results/providerUtils';
import Attribute from 'components/ModernExperience/Attribute';
import useThemeColor from 'hooks/useThemeColor';

const useStyles = makeStyles((theme) => ({
  headerText: {
    textAlign: 'center',
    margin: '20px 0',
  },
  bannerExample: {
    textAlign: 'center',
    fontWeight: 700,
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
      marginRight: 5,
      transform: 'translateY(4px)',
    },
  },
  acceptButton: {
    margin: '20px 0',
  },
  coverageDecreasing: {
    color: theme.palette.warningOrange,
    justifyContent: 'center',
    '& .MuiAvatar-root': {
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: -1.5,
      marginRight: '.15rem',
    },
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
  ribbon: {
    display: 'inline-block',
    position: 'relative',
    height: 'auto',
    width: 'auto',
    maxWidth: '150%',
    margin: '2px 12px 2px -7px',
    padding: '0 7px',
    background: '#1D379C',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    zIndex: 1,
    whiteSpace: 'nowrap',
    '&:after': {
      content: '""',
      position: 'absolute',
      border: `12px solid #1D379C`,
      borderRight: '10px solid transparent',
      right: 10,
      top: 0,
      bottom: 0,
      transform: 'translateX(100%)',
    },
  },
  preferred: {
    color: theme.palette.darkGreen,
  },
}));
const exampleProvider = { highPerforming: true }; // mock provider object of a high performing provider
const exampleHighPerformingPillProps = createHighPerformingPillSelector().resultFunc(
  exampleProvider,
  true
);
const exampleHighPerformingBadgeProps = createHighPerformingBadgeSelector().resultFunc(
  exampleHighPerformingPillProps,
  true
);
const exampleNetworkContent = getNetworkContent({
  provider: exampleProvider,
  network: 'bcbstexas',
  client: WALMART,
});

function HighlyRatedIcons() {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  return (
    <Typography type="body1" paragraph={smUp} style={{ textAlign: 'center' }} component="div">
      <Grid
        container
        alignItems="center"
        style={{
          display: smUp ? 'inline-flex' : 'flex',
          flexWrap: smUp ? 'nowrap' : 'wrap',
        }}
      >
        <Grid item xs={12} sm={5}>
          <Pill {...exampleHighPerformingPillProps} />
        </Grid>
        <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
          or
        </Grid>
        <Grid item xs={12} sm={5}>
          <ProfileBadge {...exampleHighPerformingBadgeProps} />
        </Grid>
      </Grid>
    </Typography>
  );
}

function WMTBenefitsChangingModal() {
  const classes = useStyles();
  const [{ networkSlug }] = useStateValue();
  const wmtBenefitsNotifierModalOpen = useSelector(select.ui.wmtBenefitsNotifierModalOpen);
  const configIsLoading = useSelector(select.config.isLoading);
  const inBenefitChangeDateRange = useSelector(select.content.isTodayInBenefitChangeDateRange);
  const benefitsChange = useSelector(select.content.benefitsChange);
  const dispatch = useDispatch();
  const {
    config: {
      CONTENT: { BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT },
    },
  } = useConfigValue();

  const networkColor = useThemeColor(exampleNetworkContent.textColor);

  useEffect(() => {
    if (!configIsLoading && benefitsChange.dateRange) {
      /**
       * Wait until the requisite config values have loaded before checking this.
       *
       * The 'BENEFITS_CHANGE' local storage item should always reflect whether the user last visited the app during the "benefitsChange" date range, as defined by the `inBenefitChangeDateRange` selector.
       * If the 'BENEFITS_CHANGE' local storage item is false and `inBenefitChangeDateRange` is true, the user has entered the "benefitsChange" date range since the last time they used the app.
       * In that case, show them the modal, which will have addittional UI describing the benefit change.
       *
       * Always update 'BENEFITS_CHANGE' local storage reflect if the user is in the date range
       *
       * */
      if (getLocalStorageExpire('BENEFITS_CHANGE') === false && inBenefitChangeDateRange) {
        dispatch(actions.ui.openModal('wmtBenefitsNotifier'));
      }
      setLocalStorageExpire(
        'BENEFITS_CHANGE',
        inBenefitChangeDateRange,
        null,
        WMT_BENEFITS_NOTIFIER_VERSION
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading, benefitsChange, inBenefitChangeDateRange]);

  const nwa = networkSlug === 'northwestarkansas' || networkSlug === 'bcbsarkansas';

  const handleClose = () => {
    dispatch(actions.ui.closeModal('wmtBenefitsNotifier'));

    const modalShownCount = parseInt(
      getLocalStorageExpire('wmtBenefitsNotifier', WMT_BENEFITS_NOTIFIER_VERSION),
      10
    );
    if (modalShownCount) {
      setLocalStorageExpire(
        'wmtBenefitsNotifier',
        modalShownCount + 1,
        null,
        WMT_BENEFITS_NOTIFIER_VERSION
      );
    } else {
      setLocalStorageExpire('wmtBenefitsNotifier', 1, null, WMT_BENEFITS_NOTIFIER_VERSION);
    }
  };
  return (
    <Modal
      open={wmtBenefitsNotifierModalOpen}
      ariaId="wmt-benefits-modal"
      allowBackdropClick={false}
      showX
      handleClose={handleClose}
    >
      <Container component="section">
        <Typography variant="h2" id="wmt-benefits-modal-title" className={classes.headerText}>
          High-Quality Care
        </Typography>

        <div id="wmt-benefits-modal-description">
          {!nwa ? (
            <>
              <Typography type="body1" paragraph align="center" style={{ marginTop: 20 }}>
                We’ve identified local doctors who have consistently delivered the best value and
                quality care. They’re called <i>preferred providers</i>, and when you use them,
                Walmart covers a higher share of eligible costs. When you use the Provider Guide,
                just look for “In-Network/Preferred” in green next to the provider’s name to find a
                preferred doctor.
              </Typography>
              <Grid container alignItems="center" direction="column">
                <Grid item>
                  <Attribute
                    text={exampleNetworkContent.networkText}
                    icon={exampleNetworkContent.networkIcon}
                    additionalIconClass={classes.preferred}
                    TooltipProps={{ message: exampleNetworkContent.tooltipMessage }}
                    TypographyProps={{
                      style: {
                        fontWeight: 'bold',
                        color: networkColor,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography type="body1" paragraph align="center" style={{ marginTop: 20 }}>
                    You can also find preferred doctors in your community who are highly rated.
                    We’ve identified those preferred doctors who have consistently been among the
                    best in the community at delivering quality care. Just look for the blue “highly
                    rated” ribbon next to the provider’s name.
                  </Typography>
                </Grid>
                <Grid item>
                  <Pill {...exampleHighPerformingPillProps} />
                </Grid>
              </Grid>
              {inBenefitChangeDateRange && networkSlug !== 'bcbsarkansas' && (
                <Grid container alignItems="center" direction="column">
                  <Grid item>
                    <Typography type="body1" paragraph align="center" style={{ marginTop: 20 }}>
                      Providers that will not be preferred in 2024 have the following symbol in the
                      Provider Guide:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title="In Network Non-Preferred"
                      message="This provider is in-network but has been designated by your medical plan as a non-preferred provider. Your coverage will be lower when you get care from this provider."
                    >
                      <IconLabel img={warningIcon} className={classes.coverageDecreasing}>
                        {BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT}
                      </IconLabel>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography type="body1" paragraph align="center" style={{ marginTop: 20 }}>
                      You will pay a higher share of eligible costs from this provider in 2024 than
                      you paid in 2023.
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Typography type="body1" paragraph align="center">
                Use the Provider Guide to find highly rated doctors in your local community. We’ve
                identified doctors who have consistently been among the best in the community at
                delivering quality care. Just look for “highly rated” next to the doctor’s name.
              </Typography>

              <HighlyRatedIcons />

              <Typography type="body1" paragraph align="center">
                Available for associates enrolled in the Premier, Contribution, or Saver plans in
                Northwest Arkansas
              </Typography>
            </>
          )}
        </div>

        <Grid container direction="row" justifyContent="center">
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.acceptButton}
          >
            OK
          </Button>
        </Grid>
      </Container>
    </Modal>
  );
}

export default WMTBenefitsChangingModal;
