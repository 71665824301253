/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { DROPDOWN } from 'utils/constants';

// login
import emboldLogo from 'assets/embold-icon.png';

// pages
import PrivacyPolicy from './Defaults/PrivacyPolicy';
import Disclaimer from './Defaults/Disclaimer';
import Terms from './Defaults/Terms';

// local assets

// provider details

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
export const copyDefaults = {
  UPDATE_REGION_TEXT: 'In What Region is Your Work Location?',
  HEADER_LOGO: null,
  SCHEDULE_COPY: `Connecting you to customer service to assist with scheduling. Click 'Call Now' to continue.`,
  SUPPORT_LINK: 'https://support.emboldhealth.com',
  MEMBER_ID_LINK: null,
  PAGES_PRIVACY: PrivacyPolicy,
  PAGES_DISCLAIMER: Disclaimer,
  PAGES_TERMS: Terms,
  FORM_VARIANT: DROPDOWN,
  SHOW_CONTACT_BCBS_ROUTE: false,
  SHOW_COST: false,
  ADD_ICON: false,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE: `Walmart and Embold Health have partnered to bring you Embold’s Provider Guide, your guide
  to quickly and easily finding the doctors in your network and area that have proven to
  provide the best care. Select your region to find Embold Health’s highest quality
  providers in your area that are also in-network within your plan.`,
  NO_WELCOME_LINK: true,
  LOCAL_LOGO_ASSET: emboldLogo,
  SEARCH_ERROR_TEXT:
    'We could not find any providers or places that matched your search. Please change your search criteria.',
};
