import React from 'react';
import PropTypes from 'propTypes';
import { makeStyles } from '@material-ui/styles';
import { uniqueId } from 'lodash';
import { List } from '@material-ui/core';
import ProfileSectionTitle from './ProfileSectionTitle';

import Accordion from './Accordion';
import ProfileSection from './ProfileSection';

export const PROFILE_HORIZONTAL_LI_GUTTERS = '1.375rem'; // 22px

const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: theme.spacing(1),
    '& > :not(:last-child)': { borderBottom: `1px solid ${theme.palette.grey[300]}` },
  },
  liRoot: {
    margin: `0 ${PROFILE_HORIZONTAL_LI_GUTTERS}`,
    padding: `1rem 0`,
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.lightGray}`,
    },
  },
}));

function AccordionList({ TitleProps, accordions, ariaId, isPrintMode }) {
  const classes = useStyles();

  return (
    <ProfileSection TitleProps={TitleProps}>
      <List disablePadding classes={{ root: classes.listRoot }}>
        {accordions.map((accordion, i) => (
          <Accordion
            isListItem
            // this markup is duplicated to facilitate the "print" functionality
            // so we append '-print-only' to maintain unique aria-ids
            ariaId={`${ariaId}-${i}${isPrintMode ? '-print-only' : ''}`}
            {...accordion}
            isOpen={isPrintMode}
            key={uniqueId()}
            classes={{ root: classes.liRoot }}
          />
        ))}
      </List>
    </ProfileSection>
  );
}

export default AccordionList;

AccordionList.propTypes = {
  TitleProps: PropTypes.shape({ ProfileSectionTitle }.propTypes).isRequired,
  accordions: PropTypes.arrayOf(PropTypes.shape({ Accordion }.propTypes)),
  ariaId: PropTypes.string.isRequired,
  isPrintMode: PropTypes.bool,
};

AccordionList.defaultProps = {
  isPrintMode: false,
  accordions: [],
};
