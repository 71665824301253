import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { HOSPITAL_SAFETY_GRADE_IMAGE } from 'utils/constants';
import useReturnFocus from 'hooks/useReturnFocus';
import Badge from './Badge';
import BadgeModal from './BadgeModal';
import HospitalSafetyGradeContent from './HospitalSafetyGradeContent';

const useStyles = makeStyles({
  hospitalGradeLastUpdated: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 53,
    marginRight: 'auto',
    marginLeft: 'auto',
    color: 'white',
    fontSize: '0.6rem',
    fontWeight: 900,
    zIndex: 2,
  },
});

export default function HospitalSafetyGradeBadge({ place, className, ...props }) {
  const classes = useStyles();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { ref, returnFocus } = useReturnFocus();
  const { hospitalGrade = undefined, hospitalGradeLastUpdated } = place;

  if (!hospitalGrade) return null;

  const showLastUpdated = Boolean(hospitalGradeLastUpdated);
  return (
    <>
      <Badge
        src={HOSPITAL_SAFETY_GRADE_IMAGE[hospitalGrade]}
        aria-label={hospitalGrade}
        onClick={() => setModalIsOpen(true)}
        ref={ref}
        className={`${classes.hospitalGradeBadge} ${className}`}
        {...props}
      >
        Hospital Safety Grade
        {showLastUpdated && (
          <Typography className={classes.hospitalGradeLastUpdated}>
            {hospitalGradeLastUpdated.toUpperCase()}
          </Typography>
        )}
      </Badge>
      <BadgeModal
        open={modalIsOpen}
        title="Hospital Safety Grade"
        handleClose={() => setModalIsOpen(false)}
        returnFocus={returnFocus}
      >
        <HospitalSafetyGradeContent />
      </BadgeModal>
    </>
  );
}

HospitalSafetyGradeBadge.propTypes = {
  className: PropTypes.string,
  place: PropTypes.shape({
    hospitalGrade: PropTypes.string,
    hospitalGradeLastUpdated: PropTypes.string,
  }),
};

HospitalSafetyGradeBadge.defaultProps = {
  className: '',
  place: {},
};
