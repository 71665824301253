import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import useUpdateTitle from 'hooks/useUpdateTitle';

import ProviderProfile from 'components/ModernExperience/Profile/ProviderProfile/ProviderProfile';
import PlaceProfile from 'components/ModernExperience/Profile/PlaceProfile/PlaceProfile';
import withDataToggle from './withDataToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function ProfileStyleguide() {
  const classes = useStyles();
  const resultIds = useSelector(select.results.idList);
  const isLoading = useSelector(select.results.isLoading);
  const wasProviderSearch = useSelector(select.results.wasProviderSearch);

  useUpdateTitle('Provider Profile Styleguide');

  if (!resultIds || resultIds.length === 0 || isLoading) return null;

  return (
    <div className={classes.root}>
      {wasProviderSearch ? (
        <ProviderProfile providerId={resultIds[0]} />
      ) : (
        <PlaceProfile placeId={resultIds[0]} />
      )}
    </div>
  );
}

export default withDataToggle(ProfileStyleguide);
