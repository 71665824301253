import { withStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';

const CardDivider = withStyles((theme) => ({
  root: {
    margin: '0.25rem 0 .5rem',
    color: theme.palette.lightGray,
  },
}))(Divider);

export default CardDivider;
