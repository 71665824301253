import React from 'react';
import PropTypes from 'propTypes';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.mediumDarkGray,
    padding: theme.spacing(0.25),
    fontWeight: 'normal',
    fontSize: '.875rem',
    borderRadius: 0,
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  label: {
    minHeight: 18,
  },
  icon: {
    '& svg': {
      height: 20,
      width: 20,
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    color: theme.palette.secondary.main,
  },
}));

/**
 * A styled wrapper for "directions" and "call" buttons on the "Modern Experience Larger Provider Cards"
 */
function CardIconButton({ onClick, text, icon, additionalClasses, ...props }) {
  const classes = useStyles();
  return (
    <Button
      classes={{
        root: `${classes.root} ${additionalClasses}`,
        label: classes.label,
        startIcon: classes.icon,
      }}
      variant="text"
      onClick={onClick}
      startIcon={icon}
      {...props}
    >
      {text}
    </Button>
  );
}

export default CardIconButton;

CardIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string,
};

CardIconButton.defaultProps = {
  additionalClasses: '',
};
