import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    minWidth: 120,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    transition: 'all 0.3s ease',
    color: theme.palette.info.main,
  },
  iconFlipped: {
    transform: 'rotate(-180deg)',
  },
}));

export default function ShowMoreButton({
  showMoreText,
  showLessText,
  isOpen,
  additionalClass,
  ariaControls,
  ariaLabel,
  ...props
}) {
  const classes = useStyles();

  let text = showMoreText;
  if (isOpen) {
    text = showLessText;
  }

  return (
    <Button
      endIcon={
        <KeyboardArrowUpIcon className={clsx(classes.icon, { [classes.iconFlipped]: isOpen })} />
      }
      classes={{
        root: clsx(classes.root, { [additionalClass]: additionalClass }),
        label: classes.label,
      }}
      aria-expanded={isOpen}
      aria-controls={ariaControls}
      aria-label={`${text} information about ${ariaLabel}`}
      {...props}
    >
      {text}
    </Button>
  );
}

ShowMoreButton.propTypes = {
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  ariaControls: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

ShowMoreButton.defaultProps = {
  showMoreText: 'Show More',
  showLessText: 'Show Less',
  additionalClass: undefined,
};
