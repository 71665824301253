import React, { useState, useEffect } from 'react';
import PropTypes from 'propTypes/index';
import { actions, select } from 'store/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import fetchClient from 'utils/fetchClient';
import { Link } from 'react-router-dom';

import {
  Typography,
  Grid,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
} from '@material-ui/core';

import ChatDisclaimer from 'components/Modals/ChatModal/ChatDisclaimer';

const useStyles = makeStyles((theme) => ({
  continueButton: {
    maxWidth: 140,
  },
  disclaimer: {
    borderRadius: 8,
    padding: 10,
    background: 'white',
    maxHeight: 300,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 500,
    },
    overflow: 'auto',
  },
  scenarioDropdown: {
    width: 300,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiSelect-outlined': {
      padding: '4px 14px',
      border: 'none',
    },
    '& .MuiInputBase-input': {
      fontSize: '1rem',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

function TermsAgreement({ handleTermsAccepted, closeChat }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const [monitoringChecked, setMonitoringChecked] = useState(false);
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [loadingScenarios, setLoadingScenarios] = useState(false);
  const enableChatScenario = useSelector(select.featureFlags.enableChatScenario);
  const scenarioId = useSelector(select.chat.scenarioId);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingScenarios(true);
    fetchClient('/chat-scenarios')
      .then((result) => setScenarioOptions(result.data))
      .finally(() => setLoadingScenarios(false));
  }, []);

  const toggleTermsCheck = () => {
    setTermsChecked(!termsChecked);
  };

  const toggleMonitoringCheck = () => {
    setMonitoringChecked(!monitoringChecked);
  };

  const handleSelectScenario = ({ target: { value } }) => {
    dispatch(actions.chat.setScenarioId(value));
  };

  const showScenarioSelect = !loadingScenarios && scenarioOptions.length > 0 && enableChatScenario;
  const invalidScenario = enableChatScenario && scenarioOptions.length > 0 && !scenarioId;

  const classes = useStyles();
  return (
    <Grid container direction="column" style={{ rowGap: 5 }}>
      <Grid>
        <Grid classes={{ root: classes.disclaimer }}>
          <ChatDisclaimer />
        </Grid>
        <FormControlLabel
          control={<Checkbox onChange={toggleTermsCheck} checked={termsChecked} />}
          label={
            <Typography>
              I agree to the{' '}
              <Link onClick={() => closeChat()} to="/privacy#beta-testing">
                Privacy Statement
              </Link>{' '}
              and{' '}
              <Link onClick={() => closeChat()} to="/terms#beta-testing">
                Terms of Use
              </Link>
              .
            </Typography>
          }
        />
        <FormControlLabel
          control={<Checkbox onChange={toggleMonitoringCheck} checked={monitoringChecked} />}
          label={
            <Typography style={{ marginBottom: 8 }}>
              I consent to the monitoring and recording of this chat by Embold Health [and its
              third-party service provider, Microsoft], [for quality and training purposes and
              purposes of responding to your questions and requests] according to Embold’s{' '}
              <Link onClick={() => closeChat()} to="/privacy#beta-testing">
                Privacy Statement
              </Link>{' '}
              and{' '}
              <Link onClick={() => closeChat()} to="/terms#beta-testing">
                Terms of Use
              </Link>
              .
            </Typography>
          }
        />
      </Grid>
      {showScenarioSelect && (
        <TextField
          select
          id="scenario-dropdown"
          value={scenarioId || ''}
          variant="outlined"
          onChange={handleSelectScenario}
          label="Scenario"
          classes={{ root: classes.scenarioDropdown }}
        >
          {scenarioOptions.map((option) => (
            <MenuItem value={option.scenarioId} key={option.scenarioId}>
              {`${option.scenarioId} - ${option.scenarioLabel}`}
            </MenuItem>
          ))}
        </TextField>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!termsChecked || !monitoringChecked || invalidScenario}
        classes={{ root: classes.continueButton }}
        onClick={handleTermsAccepted}
      >
        Continue
      </Button>
    </Grid>
  );
}

TermsAgreement.propTypes = {
  handleTermsAccepted: PropTypes.func.isRequired,
  closeChat: PropTypes.func.isRequired,
};

export default TermsAgreement;
