/* eslint-disable camelcase */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStateValue } from 'state/index';

import { CARE_CATEGORIES } from 'utils/constants';
import { select } from 'store/toolkit';

/** TODO RTK This hook is a temporary use hook for generating the query string for the /filter-options/ endpoint. Eventually once all state
 * has been migrated from context to Redux, this hook should be deprecated in favor of a selector.
 * Requires TECH-3031 & TECH-2993
 */
export default function useFilterOptionsQuery() {
  const [{ networkSlug }] = useStateValue();
  const careCategory = useSelector(select.search.type);
  const filterRadius = useSelector(select.filters.radius);
  const searchInput = useSelector(select.results.searchText); // here we want to grab the search input from the last search, not what's in the box now
  const specialtyId = useSelector(select.results.specialtyId);
  const subspecialtyId = useSelector(select.results.subspecialtyId);
  const serviceId = useSelector(select.results.serviceId);
  const latLong = useSelector(select.results.coordinates);
  const { latitude, longitude } = latLong || {};
  const boundingBoxQueryParam = useSelector(select.results.boundsAsQueryParams);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);

  const filterOptionsQuery = useMemo(() => {
    // these three parameters required for network call
    if (!networkSlug || !latitude || !longitude) return undefined;
    // one of these two parameters required for network call
    if (!filterRadius && !boundingBoxQueryParam) return undefined;
    // one of these four parameters required for network call
    if (!searchInput && !serviceId && !specialtyId && !subspecialtyId) return undefined;

    // base params
    let queryString = `location=${latitude},${longitude}&network_slug=${networkSlug}&entity_type=${
      wasPlaceSearch ? 'place' : 'provider'
    }`;

    // location params
    if (filterRadius) {
      queryString += `&radius=${filterRadius}mi`;
    } else {
      queryString += `&bounding_box=${boundingBoxQueryParam}`;
    }

    if (careCategory === CARE_CATEGORIES.PROVIDER_NAME) {
      // this is a search by name, so we need the "q" search param
      queryString += `&q=${encodeURIComponent(searchInput)}`;
    } else {
      // all other searches rely on service, specialty or subspecialty id's
      if (serviceId) queryString += `&service_id=${serviceId}`;
      if (specialtyId) queryString += `&specialty_id=${specialtyId}`;
      if (subspecialtyId) queryString += `&subspecialty_id=${subspecialtyId}`;
    }

    return queryString;
  }, [
    wasPlaceSearch,
    filterRadius,
    networkSlug,
    latitude,
    longitude,
    boundingBoxQueryParam,
    careCategory,
    serviceId,
    specialtyId,
    subspecialtyId,
    searchInput,
  ]);

  return filterOptionsQuery;
}
