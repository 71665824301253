import React, { useState } from 'react';
import { PromotionDataShape } from 'propTypes/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Collapse, Fade } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import defaultImage from 'assets/default-promotion-icon.svg'; // TODO TECH-3847 replace with custom icon
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';

import PromoteProfile from 'components/Profile/PromoteProfile';

const useStyles = makeStyles(() => ({
  '@keyframes fadeDownAnimation': {
    from: {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    to: {
      opacity: 1,
    },
  },
  promotionContainer: {
    width: '100%',
    marginBottom: 8,
    boxShadow: 'none',
    padding: 10,
    borderRadius: 8,
    animation: '$fadeDownAnimation .4s ease-in',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  headerLogo: {
    height: 20,
    width: 20,
    marginRight: 6,
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
  },
  promotionDetailsContainer: {
    padding: 10,
  },
  programName: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}));

function CollapsablePromotion({ promotion }) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [showDetails, setShowDetails] = useState(false);

  const { logo, programName, programSubname } = promotion || {};

  const toggleShowDetails = () => setShowDetails(!showDetails);

  return (
    <Paper className={classes.promotionContainer}>
      <Grid className={classes.headerContainer} onClick={toggleShowDetails}>
        {!smDown && (
          <Grid className={classes.headerSection}>
            <GradeRoundedIcon className={classes.headerLogo} color="primary" />
            <Typography>Featured Benefit</Typography>
          </Grid>
        )}
        <Fade in={!showDetails} timeout={500}>
          <Grid className={classes.headerSection}>
            <img alt="benefit-logo" src={logo || defaultImage} className={classes.headerLogo} />
            <Typography>
              <span className={classes.programName}>{programName}</span>{' '}
              {!smDown && programSubname ? programSubname : ''}
            </Typography>
          </Grid>
        </Fade>
        <Grid className={classes.headerSection}>
          {!smDown && (
            <Typography style={{ width: 71 }}>Show {showDetails ? 'Less' : 'More'}</Typography>
          )}
          <ExpandMoreIcon
            tabIndex="0"
            aria-label={`expand details for ${programName}`}
            className={classes.expandMoreIcon}
            style={{ transform: showDetails ? 'rotate(180deg)' : '' }}
          />
        </Grid>
      </Grid>
      <Collapse in={showDetails} timeout={500}>
        <Grid className={classes.promotionDetailsContainer}>
          <PromoteProfile promotion={promotion} />
        </Grid>
      </Collapse>
    </Paper>
  );
}

CollapsablePromotion.propTypes = {
  promotion: PromotionDataShape.isRequired,
};

export default CollapsablePromotion;
