import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useConfigValue } from 'config/state/index';
import { HIGH_PERFORMING_TITLE, HIGH_PERFORMING_TOOLTIP_TEXT } from 'utils/constants';
import blueBanner from 'assets/blue_banner_badge.svg';
import useReturnFocus from 'hooks/useReturnFocus';
import Badge from './Badge';
import BadgeModal from './BadgeModal';

function HighPerformingBadge({ isHighPerforming, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { ref, returnFocus } = useReturnFocus();
  const { config } = useConfigValue();

  const { SHOW_HIGH_PERFORMING_RIBBON } = config;
  const hasModal = Boolean(HIGH_PERFORMING_TOOLTIP_TEXT);

  if (!SHOW_HIGH_PERFORMING_RIBBON || !isHighPerforming) return null;

  return (
    <>
      <Badge src={blueBanner} onClick={() => setModalIsOpen(true)} ref={ref} {...props}>
        {HIGH_PERFORMING_TITLE}
      </Badge>
      {hasModal && (
        <BadgeModal
          open={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          returnFocus={returnFocus}
          title={HIGH_PERFORMING_TITLE}
          text={HIGH_PERFORMING_TOOLTIP_TEXT}
        />
      )}
    </>
  );
}

HighPerformingBadge.propTypes = {
  isHighPerforming: PropTypes.bool,
};

HighPerformingBadge.defaultProps = {
  isHighPerforming: false,
};

export default HighPerformingBadge;
