/* eslint-disable import/no-cycle */
import msftHeaderLogo from 'assets/Microsoft-logo-white.svg';
import MSFTLoginLogo from 'assets/Microsoft_logo.svg';
import { PASSWORD } from 'utils/constants';

// use camelCase for strings, arrays, objects, and CamelCaps for functional components
// eslint-disable-next-line import/prefer-default-export
export const MSFToverrides = {
  SUPPORT_LINK: 'https://microsoftsupport.emboldhealth.com/portal/home',
  HEADER_LOGO: msftHeaderLogo,
  FORM_VARIANT: PASSWORD,
  SCHEDULE_COPY: `Connecting you to Embold customer service to assist with finding a provider and Provider Guide technical support.`,
  SCHEDULE_PHONE_NUMBER: '877-671-1471',
  SEARCH_ERROR_TEXT:
    'This pilot version of Provider Guide currently evaluates providers from 9 specialties within the Puget Sound. Please change your search criteria to try again.',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Microsoft and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: MSFTLoginLogo,
  NO_WELCOME_LINK: false,
};
