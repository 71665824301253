import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promotions: [],
  isLoading: false,
  error: null,
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    /* eslint no-param-reassign: 0 */
    /* RTK allows for direct state mutation in slice reducer actions: https://redux-toolkit.js.org/usage/immer-reducers#redux-toolkit-and-immer */
    /* TODO all of these individual actions could be replaced with a thunk once location is migrated to redux */
    fetchPromotionsPending(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchPromotionsComplete(state, action) {
      state.isLoading = false;
      state.promotions = action.payload;
    },
    fetchPromotionsFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updatePromotions(state, action) {
      state.promotions = action.payload;
    },
  },
});

export default promotionsSlice;
export const promotionsActions = promotionsSlice.actions;
