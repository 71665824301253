/* eslint-disable consistent-return */
import * as Sentry from '@sentry/react';
import { StringParam, NumberParam, withDefault } from 'use-query-params';
import { validateBounds, validateLatLong } from 'utils/utils';
import { AUTOCOMPLETE_SEARCH_TYPE, VALID_SORT_ORDERS } from 'utils/constants';

import Config from 'config';

const { DEFAULT_SEARCH_RADIUS } = Config;

const DecodeLatLng = {
  encode: (latLng) => {
    if (latLng) {
      return `${latLng.latitude},${latLng.longitude}`;
    }
  },
  decode: (latLng) => {
    if (latLng) {
      const latitude = Number(latLng.substring(0, latLng.indexOf(',')));
      const longitude = Number(latLng.substring(latLng.indexOf(',') + 1, latLng.length));
      if (!validateLatLong(latitude, longitude)) {
        Sentry.captureMessage(
          `Invalid lat/long URL params on profile or results page: ${window.location.href}`
        );
        return null;
      }
      return {
        latitude,
        longitude,
      };
    }
  },
};

const DecodeCompare = {
  // accepts an array of strings
  encode: (compare) => (compare?.length > 0 ? compare.join(',') : undefined),
  // accepts a string
  decode: (compare) => (compare ? compare.split(',') : []),
};

const DecodeBounds = {
  encode: (bounds) => {
    if (validateBounds(bounds)) {
      return `${bounds.ne.latitude},${bounds.ne.longitude}__${bounds.sw.latitude},${bounds.sw.longitude}`;
    }
  },
  decode: (bounds) => {
    if (bounds) {
      const [ne, sw] = bounds.split('__');
      const [neLat, neLng] = ne.split(',');
      const [swLat, swLng] = sw.split(',');
      if (!validateLatLong(neLat, neLng) || !validateLatLong(swLat, swLng)) {
        Sentry.captureMessage(
          `Invalid lat/long URL params on profile or results page: ${window.location.href}`
        );
        return null;
      }
      return {
        sw: { longitude: parseFloat(swLng), latitude: parseFloat(swLat) },
        ne: { longitude: parseFloat(neLng), latitude: parseFloat(neLat) },
      };
    }
  },
};

const ValidateOrdering = {
  encode: (order) => order,
  decode: (order) => (VALID_SORT_ORDERS.indexOf(order) !== -1 ? order : null),
};

const ValidateSearchType = {
  encode: (search_type) => search_type,
  decode: (search_type) =>
    [AUTOCOMPLETE_SEARCH_TYPE].indexOf(search_type) !== -1 ? search_type : null,
};

/** @deprecated */
const queryConfig = {
  location: DecodeLatLng,
  bounding_box: DecodeBounds,
  ordering: ValidateOrdering,
  search_type: ValidateSearchType,
  specialtyId: withDefault(NumberParam, null),
  subspecialtyId: withDefault(NumberParam, null),
  serviceId: withDefault(NumberParam, null),
  serviceType: withDefault(StringParam, null),
  location_input: withDefault(StringParam, ''),
  search_input: withDefault(StringParam, ''),
  gender: withDefault(StringParam, ''),
  languages: withDefault(StringParam, ''),
  credentials: withDefault(StringParam, ''),
  specialties: withDefault(StringParam, ''),
  acceptingNewPatients: withDefault(StringParam, ''),
  telehealthAvailable: withDefault(StringParam, ''),
  boardCertified: withDefault(StringParam, ''),
  outcareCompetent: withDefault(StringParam, ''),
  highPerforming: withDefault(StringParam, ''),
  inNetworkPreferred: withDefault(StringParam, ''),
  hasBenefitDiff: withDefault(StringParam, ''),
  healthConnectPlan: withDefault(StringParam, ''),
  isWheelchairAccessible: withDefault(StringParam, ''),
  excludeClientFeatured: withDefault(StringParam, ''),
  city: withDefault(StringParam, ''),
  state: withDefault(StringParam, ''),
  zip: withDefault(StringParam, ''),
  network_slug: withDefault(StringParam, ''),
  radius: withDefault(NumberParam, DEFAULT_SEARCH_RADIUS),
  compare: DecodeCompare,
  entity_id: withDefault(NumberParam, null),
  care_category: withDefault(StringParam, null),
  isPlaceByExactNameSearch: withDefault(StringParam, ''),
};

export default queryConfig;
