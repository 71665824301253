import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { _analyticsActions } from 'analytics/index';
import PropTypes from 'propTypes';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import SectionCost from 'components/ModernExperience/Profile/ProviderProfile/SectionCost';
import ProviderAttributeList from 'components/ModernExperience/ResultCards/ProviderCard/ProviderAttributeList';
import RoundedWrapper from '../../ResultCards/StyledWrappers/RoundedWrapper';
import SectionCredentialsExperience from './SectionCredentialsExperience';
// import SectionAffiliations from '../AffiliationsList';
import SectionSpecializations from './SectionSpecializations';
import SectionStrengths from './SectionStrengths';
// import SectionExperience from './SectionExperience';
import ProviderProfileHeaderButtons from './ProviderProfileHeaderButtons';
import ProviderProfileBasicInfo from './ProviderProfileBasicInfo';
import ProviderContactCard from '../../ResultCards/ProviderCard/ProviderContactCard';
import ProviderBadges from './ProviderBadges';
import SectionBenefitDecrease from './SectionBenefitDecrease';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 754,
    paddingBottom: 0,
  },
  padBottom: {
    marginBottom: 25,
  },
  printWrapper: {
    display: 'none',
  },
  basicInfoGridCell: {
    flexGrow: 1,
    paddingRight: 20,
    borderRight: `1px solid ${theme.palette.lightGray}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      marginBottom: 20,
      borderRight: 'none',
    },
  },
  attributesGridCell: {
    flexGrow: 1,
    paddingLeft: 20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
}));

function ProviderProfile({ providerId }) {
  const classes = useStyles();
  const printRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (providerId) {
      dispatch(_analyticsActions.profileView(providerId));
    }
  }, []);

  if (!providerId) return null;

  return (
    <RoundedWrapper additionalStyles={classes.wrapper}>
      <ProviderProfileHeaderButtons providerId={providerId} printRef={printRef} />
      <ProfileContent providerId={providerId} />
      <div className={classes.printWrapper} aria-hidden="true">
        <ProfileContent ref={printRef} isPrintMode providerId={providerId} />
      </div>
    </RoundedWrapper>
  );
}

const ProfileContent = React.forwardRef(({ isPrintMode, providerId }, ref) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap" ref={ref}>
      <Grid item>
        <Grid container classes={{ root: classes.padBottom }}>
          <Grid xs={12} sm={6} item classes={{ root: classes.basicInfoGridCell }}>
            <ProviderProfileBasicInfo providerId={providerId} />
            <ProviderBadges providerId={providerId} />
          </Grid>
          <Grid xs={12} sm={6} item classes={{ root: classes.attributesGridCell }}>
            <Typography variant="h3">Provider Information</Typography>
            <ProviderAttributeList
              showAcceptingNewPatients={false}
              showNearbyLocations={false}
              providerId={providerId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SectionBenefitDecrease providerId={providerId} />
      </Grid>
      <Grid item>
        <ProviderContactCard
          isPrintMode={isPrintMode}
          providerId={providerId}
          className={classes.padBottom}
          showMap
          isProfilePage
        />
      </Grid>
      <Grid item>
        <SectionSpecializations providerId={providerId} isPrintMode={isPrintMode} />
      </Grid>
      <Grid item>
        <SectionStrengths providerId={providerId} />
      </Grid>
      <Grid item>
        <SectionCost providerId={providerId} />
      </Grid>
      <Grid item>
        <SectionCredentialsExperience providerId={providerId} />
      </Grid>
      {/*
        @TODO: not yet available in data
        <Grid item>
          <SectionAffiliations providerId={providerId} />
        </Grid>
      */}
    </Grid>
  );
});

export default ProviderProfile;

ProviderProfile.propTypes = {
  providerId: PropTypes.string.isRequired,
};

ProfileContent.propTypes = {
  providerId: PropTypes.string.isRequired,
  isPrintMode: PropTypes.bool,
};

ProfileContent.defaultProps = {
  isPrintMode: false,
};
