import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Link } from '@material-ui/core';

import SupportTicketForm from 'components/Forms/SupportTicket/SupportTicketForm';
import { useConfigValue } from 'config/state/index';
import { DEFAULT_SUPPORT_MAILBOX } from 'utils/constants';
import SupportPageSection from './SupportPageSection';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h2': {
      textAlign: 'center',
    },
  },
  formContainer: {
    width: '100%',
    maxWidth: 900,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(7),
    },
  },
}));

export default function SupportTicketSection() {
  const classes = useStyles();
  const { clientConfigError } = useConfigValue();

  return (
    <SupportPageSection title="Submit a Ticket" className={classes.root} id="ticket">
      <Paper className={classes.formContainer}>
        {!clientConfigError ? (
          <SupportTicketForm />
        ) : (
          <Typography>
            Please email{' '}
            <Link href={`mailto:${DEFAULT_SUPPORT_MAILBOX}`}>{DEFAULT_SUPPORT_MAILBOX}</Link> to
            submit your ticket.
          </Typography>
        )}
      </Paper>
    </SupportPageSection>
  );
}
