import React, { useMemo, useState } from 'react';
import { AzureMapLayerProvider } from 'react-azure-maps';
import { useDispatch, useSelector } from 'react-redux';
import { getMapBounds } from 'store/slices/map/mapUtils';
import { actions, select } from 'store/toolkit';

const MARKER_LAYER_ID = 'result-marker-layer';

/**
 * This component inserts a map layer that will render a marker icon at each data point in the data provider
 */
export default function MarkerLayer() {
  const dispatch = useDispatch();
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
  const selectedResultId = useSelector(select.results.selectedId);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);

  const markerLayerEvents = useMemo(
    () => ({
      /* Available layer events can be found here: https://samples.azuremaps.com/symbol-layer/symbol-layer-events */
      mousemove: (evt) => {
        const hoveredId = evt.shapes[0].data.id;
        if (hoveredId) {
          evt.map.getCanvasContainer().style.cursor = 'pointer';
          setHoveredMarkerId(evt.shapes[0].data.id);
        }
      },
      mouseleave: (evt) => {
        evt.map.getCanvasContainer().style.cursor = 'grab';
        setHoveredMarkerId(null);
      },
      click: (evt) => {
        const { shapes = [], map } = evt;
        const marker = shapes[0];
        if (marker) {
          const markerProperties = marker.getProperties();
          const coordinates = marker.getCoordinates();
          const bounds = getMapBounds(map.getCamera());
          const mapHeight = map.getMapContainer()?.clientHeight;

          dispatch(
            actions.app.mapLocationClicked({
              marker: markerProperties,
              bounds,
              mapHeight,
              longitude: coordinates[0],
              latitude: coordinates[1],
            })
          );
        }
      },
    }),
    [dispatch]
  );

  const lightBlueIcon = wasPlaceSearch ? 'place-blue' : 'marker-blue';
  const darkBlueIcon = wasPlaceSearch ? 'place-darkblue' : 'marker-darkblue';

  /* Available layer options can be found here: https://samples.azuremaps.com/symbol-layer/symbol-layer-options */
  /* Docs for the data expressions can be found here: https://learn.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk#data-expressions */
  const markerLayerOptions = useMemo(
    () => ({
      filter: ['all', ['!', ['has', 'point_count']], ['!=', ['get', 'resultId'], selectedResultId]], // filter out clusters, all clusters contain the "point_count" property
      iconOptions: {
        image: ['case', ['!=', ['get', 'resultId'], hoveredMarkerId], darkBlueIcon, lightBlueIcon],
        size: 1,
        allowOverlap: true,
      },
    }),
    [selectedResultId, hoveredMarkerId, lightBlueIcon, darkBlueIcon]
  );
  return (
    <AzureMapLayerProvider
      id={MARKER_LAYER_ID}
      type="SymbolLayer"
      options={markerLayerOptions}
      events={markerLayerEvents}
    />
  );
}
