import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container } from '@material-ui/core';

import useUpdateTitle from 'hooks/useUpdateTitle';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
import { select } from 'store/toolkit';

import SearchForm from 'components/ModernExperience/SearchForm';
import TopSearches from 'components/Pages/LandingPageSections/TopSearches';
import AboutProviderGuide from 'components/Pages/LandingPageSections/AboutProviderGuide';

import ExploreBenefits from './LandingPageSections/ExploreBenefits';
import LandingPageSectionWrapper from './LandingPageSections/LandingPageSectionWrapper';
import WelcomeMessage from './WelcomeMessage';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    marginBottom: 20, // pushes away from the footer
    paddingTop: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  header: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  subHeader: {
    marginBottom: 28,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sectionWrapper: {
    marginTop: '50px',
    padding: theme.spacing(5),
    backgroundColor: '#f7f8f8',
    width: '100%',
    maxWidth: 1800,
    borderRadius: '30px',
    rowGap: theme.spacing(9), // 72px
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  searchWrap: {
    width: '73%',
    maxWidth: 978,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  welcomeMessageContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    maxWidth: 1800,
  },
}));

function LandingPage() {
  const classes = useStyles();
  const isXsDown = useSelector(select.ui.isXsDown);
  const providerLookup = useSelector(select.featureFlags.providerLookup);
  const noPromotionsInRadius = useSelector(select.promotions.noPromotionsInRadius);

  useUpdateTitle(`${DEFAULT_DOCUMENT_TITLE} - Home`);

  return (
    <Grid id="landing-page" classes={{ root: classes.container }} container justifyContent="center">
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Container id="landing-page-search" classes={{ root: classes.searchContainer }}>
          <Typography classes={{ root: classes.header }} variant="h1" component="strong">
            Find a Quality Provider
          </Typography>
          <Typography classes={{ root: classes.subHeader }}>
            Smarter, more informed decisions about care providers starts here.
          </Typography>
          <div className={classes.searchWrap}>
            <SearchForm mobileView={isXsDown} />
          </div>
        </Container>
        <Grid
          container
          direction="column"
          id="landing-page-sections"
          classes={{ root: classes.sectionWrapper }}
        >
          <LandingPageSectionWrapper
            title="Top Searches"
            subtitle="Quickly find a quality provider with commonly searched items"
            id="topsearches"
          >
            <TopSearches />
          </LandingPageSectionWrapper>
          <LandingPageSectionWrapper
            title="Explore Benefits"
            subtitle="Take a closer look into the benefits that are accessible through the Embold Provider Guide"
            id="benefits"
            hidden={providerLookup || noPromotionsInRadius}
          >
            <ExploreBenefits />
          </LandingPageSectionWrapper>
          <LandingPageSectionWrapper
            title="About Provider Guide"
            id="about"
            hidden={providerLookup}
          >
            <AboutProviderGuide />
          </LandingPageSectionWrapper>
        </Grid>
        {providerLookup && (
          <Grid className={classes.welcomeMessageContainer}>
            <WelcomeMessage />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default LandingPage;
