import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import logo from 'assets/pwrd-emboldhealth-footer.svg';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { useConfigValue } from 'config/state';
import { SCHEDULE_PHONE_NUMBER } from 'utils/constants';
import { useStateValue } from 'state';
import { actions } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  '@media screen and (-ms-high-contrast: black-on-white)': {
    footerIconWrap: {
      background: theme.palette.primary.main,
    },
  },
  '@media (forced-colors: active) and (prefers-color-scheme: light)': {
    footerIconWrap: {
      forcedColorAdjust: 'none',
      background: 'black',
    },
  },
  footer: {
    flex: '0 0 auto',
    padding: '1.25rem',
    position: 'relative',
    zIndex: 20,
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.up('md')]: {
      padding: '20px 20px 4px',
    },
  },
  linkRoot: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    padding: '0 4px',
    lineHeight: 1.43,
    '& svg': {
      fontSize: 12,
      margin: '3px 0 0 3px',
    },
  },
  linkFocus: {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  footerIconWrap: {
    position: 'absolute',
    top: 30,
    right: 14,
    [theme.breakpoints.up('md')]: {
      top: 22,
      right: 25,
    },
  },
  footerNav: {
    maxWidth: '80%',
    textAlign: 'left',
    display: 'flex',
    paddingBottom: '10px',
  },
  listRoot: {
    margin: '0rem 0',
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
    minWidth: '50%',
  },
  listItemRoot: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: 'auto',
      padding: '0px 14px',
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },
  },
  listItemTextRoot: {
    fontSize: '.875rem',
  },
  copyright: {
    fontSize: 9,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
    },
    paddingTop: '5px',
  },
  disclaimerTxt: {
    fontSize: 9,
    padding: '0 !important',
    marginTop: '1%',
    textAlign: 'center',
  },
}));

function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const [{ networkSlug, showLogin }] = useStateValue();
  const dispatch = useDispatch();
  const {
    config: { SHOW_DISCLAIMER, DISCLAIMER_TEXT },
    loadingClientConfig,
    getNetworkConfigValue,
  } = useConfigValue();
  const schedulePhoneNumber = getNetworkConfigValue(networkSlug, SCHEDULE_PHONE_NUMBER);
  const handleFeedback = () => {
    dispatch(actions.ui.openProfileModal('feedback'));
  };
  const handleCall = (e) => {
    e.stopPropagation();
    document.location.href = `tel:${schedulePhoneNumber}`;
  };
  const isCurrentPage = (slug) => (window.location.pathname.includes(slug) ? 'page' : undefined);

  const isAuthenticated = !showLogin;

  return (
    <footer className={`${classes.footer}`} role="contentinfo" id="footer">
      <nav aria-label="Footer navigation" className={classes.footerNav}>
        <List classes={{ root: classes.listRoot }}>
          <ListItem classes={{ root: classes.listItemRoot }}>
            <Button
              onClick={() => history.push('/privacy')}
              role="link"
              classes={{
                root: classes.linkRoot,
                focusVisible: classes.linkFocus,
              }}
              aria-current={isCurrentPage('privacy')}
            >
              <ListItemText
                classes={{ root: classes.listItemTextRoot }}
                primary="Privacy Statement"
              />
              <ChevronRight />
            </Button>
          </ListItem>

          <ListItem classes={{ root: classes.listItemRoot }}>
            <Button
              onClick={() => history.push('/terms')}
              role="link"
              classes={{
                root: classes.linkRoot,
                focusVisible: classes.linkFocus,
              }}
              aria-current={isCurrentPage('terms')}
            >
              <ListItemText classes={{ root: classes.listItemTextRoot }} primary="Terms of Use" />
              <ChevronRight />
            </Button>
          </ListItem>

          {SHOW_DISCLAIMER && (
            <ListItem classes={{ root: classes.listItemRoot }}>
              <Button
                onClick={() => history.push('/disclaimer')}
                role="link"
                classes={{
                  root: classes.linkRoot,
                  focusVisible: classes.linkFocus,
                }}
                aria-current={isCurrentPage('disclaimer')}
              >
                <ListItemText classes={{ root: classes.listItemTextRoot }} primary="Disclaimer" />
                <ChevronRight />
              </Button>
            </ListItem>
          )}

          {!loadingClientConfig && schedulePhoneNumber && isAuthenticated && (
            <ListItem classes={{ root: classes.listItemRoot }}>
              <Button
                onClick={handleCall}
                href={`tel:${schedulePhoneNumber}`}
                role="link"
                classes={{
                  root: classes.linkRoot,
                  focusVisible: classes.linkFocus,
                }}
              >
                <ListItemText
                  classes={{ root: classes.listItemTextRoot }}
                  primary={`Need Help? ${schedulePhoneNumber}`}
                />
                <ChevronRight />
              </Button>
            </ListItem>
          )}
          <ListItem classes={{ root: classes.listItemRoot }}>
            <Button
              onClick={handleFeedback}
              role="button"
              classes={{
                root: classes.linkRoot,
                focusVisible: classes.linkFocus,
              }}
            >
              <ListItemText
                classes={{ root: classes.listItemTextRoot }}
                primary="Submit Feedback"
              />
              <ChevronRight />
            </Button>
          </ListItem>
        </List>
      </nav>

      <div className={classes.footerIconWrap}>
        <img className="icon" src={logo} alt="Powered by Embold Health" />
      </div>

      {!DISCLAIMER_TEXT && (
        <Typography component="small" classes={{ root: classes.copyright }} variant="caption">
          &copy;{new Date().getFullYear()} Embold Health. All rights reserved.
        </Typography>
      )}

      {DISCLAIMER_TEXT && (
        <Typography component="small" classes={{ root: classes.copyright }} variant="caption">
          {DISCLAIMER_TEXT}
          <br />
          &copy;{new Date().getFullYear()} Embold Health. All rights reserved.
        </Typography>
      )}
    </footer>
  );
}

export default Footer;
