import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Grow, Button, Fade } from '@material-ui/core/';

const useStyles = (show) =>
  makeStyles((theme) => ({
    container: {
      marginTop: show ? 16 : 0,
      padding: show ? 8 : 0,
      borderRadius: 8,
      width: '100%',
    },
    button: {
      marginRight: 8,
      marginBottom: 8,
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
    message: {
      borderRadius: 8,
      marginLeft: 8,
      fontSize: '1rem',
      fontWeight: 'bold',
      flex: 'inherit',
      '& MuiListItemText-primary': {
        display: 'inline-block',
      },
    },
  }));

function ActionButtons({ actionButtons }) {
  const show = actionButtons.length > 0;
  const classes = useStyles(show)();

  return (
    <Fade in={show} timeout={500}>
      <Grid className={classes.container}>
        {actionButtons.map((action, index) => (
          <div style={{ width: '100%' }} key={action.label}>
            <Grow in timeout={index * 500} key={action.label}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={(e) => action.callback(e)}
              >
                {action.label}
              </Button>
            </Grow>
          </div>
        ))}
      </Grid>
    </Fade>
  );
}

ActionButtons.propTypes = {
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
    })
  ),
};

ActionButtons.defaultProps = {
  actionButtons: [],
};

export default ActionButtons;
