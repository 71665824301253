/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { getEnv, validateLatLong } from 'utils/utils';
import { CHAT_SLICE_NAME } from 'store/slices/slicesNames';

import * as selectNetworks from '../config/selectNetworks';
import * as selectLocation from '../location/selectLocation';
import { buildApiUrl, formatParametersForSearch, sortPrimaryCareToFront } from './chatUtils';

// Basic selectors
export const scenarioId = (state) => state[CHAT_SLICE_NAME].scenarioId;
export const sendingChatMessage = (state) => state[CHAT_SLICE_NAME].sendingChatMessage;
export const sendingChatOverview = (state) => state[CHAT_SLICE_NAME].sendingChatOverview;
export const isPerformingAllSearches = (state) => state[CHAT_SLICE_NAME].isPerformingAllSearches;
export const errorMessage = (state) => state[CHAT_SLICE_NAME].errorMessage;
export const searchParameters = (state) => state[CHAT_SLICE_NAME].searchParameters;
export const promotionId = (state) => state[CHAT_SLICE_NAME].promotionId;
export const actionButtonKeys = (state) => state[CHAT_SLICE_NAME].actionButtonKeys;
export const conversation = (state) => state[CHAT_SLICE_NAME].conversation;
export const termsAccepted = (state) => state[CHAT_SLICE_NAME].termsAccepted;
export const feedbackSuccess = (state) => state[CHAT_SLICE_NAME].feedbackSuccess;
export const chatKey = (state) => state[CHAT_SLICE_NAME].chatKey;
export const showNoResultsMessage = (state) => state[CHAT_SLICE_NAME].showNoResultsMessage;
export const endChat = (state) => state[CHAT_SLICE_NAME].endChat;
export const results = (state) => state[CHAT_SLICE_NAME].results;
export const requests = (state) => state[CHAT_SLICE_NAME].requests;
export const dismissEmergency = (state) => state[CHAT_SLICE_NAME].dismissEmergency;
export const devTools = (state) => state[CHAT_SLICE_NAME].devTools && getEnv() !== 'production';

// Advanced selectors
export const requestsWithResults = createSelector([requests, results], (requests, results) => {
  const requestArray = Object.values(requests);
  return requestArray.map((req) => ({
    ...req,
    // add a populated results array to each request
    results: req.resultList.map((id) => results[id]),
  }));
});
export const requestKeys = createSelector([requests], (requests) => Object.keys(requests));

export const searchesHaveBeenPerformed = createSelector([requestKeys], (requestKeys) =>
  Boolean(requestKeys.length)
);

export const searchCoordinates = createSelector(
  [searchParameters, selectLocation.latLong],
  (searchParameters, globalLatLong) => {
    const { latitude, longitude } = searchParameters.location || {};
    if (validateLatLong(latitude, longitude))
      return {
        latitude,
        longitude,
      };

    return globalLatLong;
  }
);

export const chatPayload = createSelector(
  [
    conversation,
    dismissEmergency,
    chatKey,
    scenarioId,
    selectNetworks.currentSlug,
    selectLocation.locationComponents,
  ],
  (conversation, dismissEmergency, chatKey, scenarioId, networkSlug, locationComponents) => {
    const { city, state } = locationComponents || {};
    return {
      messages: conversation,
      chatKey,
      networkSlug,
      city,
      state,
      scenarioId,
      dismiss_emergency: dismissEmergency,
      analyzeSeparately: true,
    };
  }
);

export const fetchParameters = createSelector([searchParameters], (searchParameters) =>
  formatParametersForSearch(searchParameters)
);

export const combinedSpecialties = createSelector([searchParameters], (searchParameters) => {
  const { specialties = [], subspecialties = [] } = searchParameters || {};

  return specialties.concat(subspecialties);
});

export const exploreMoreSpecialties = createSelector([searchParameters], (searchParameters) => {
  const { specialties = [], subspecialties = [] } = searchParameters || {};

  const result = [...specialties, ...subspecialties];

  // add every parent specialty of subspecialties
  for (const subspecialty of subspecialties) {
    const { subspecialtyId, subspecialtyName, ...parent } = subspecialty;
    result.push(parent);
  }

  sortPrimaryCareToFront(result);
  return result;
});

export const searches = createSelector(
  [fetchParameters, combinedSpecialties, selectNetworks.currentSlug],
  (fetchParameters, combinedSpecialties, networkSlug) => {
    sortPrimaryCareToFront(combinedSpecialties);

    const searches = combinedSpecialties.map((spec) => {
      const search = {
        ...fetchParameters,
        ...spec,
        networkSlug,
      };
      const fetchUrl = buildApiUrl({ endpoint: 'providers', params: search });
      const findClosestLocationUrl = buildApiUrl({
        endpoint: 'closest-provider-location',
        params: search,
        closestLocationSearch: true,
      });
      return { ...search, fetchUrl, findClosestLocationUrl };
    });
    return searches;
  }
);
