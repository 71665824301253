/* eslint-disable no-dupe-keys */
/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEMO, DEFAULT_LOCATIONS } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 72544c7d66f71d808ce57698ee3b1d3e3da7cc06',
  staging: 'Token 626a1d0c06bb79e7962a90a507f0c173c5f55291',
  prod: 'Token 736e081846985b1729bbfbf533e24fe320cf474b',
};

const token = selectToken(apiTokens);

export const customerDemoConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'customerdemo',
  EMBOLD_CLIENT: DEMO,
  EMBOLD_CLIENT_SLUG: 'customerdemo',
  THEME: standardTheme,

  // toggles
  SHOW_COST: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  CLOSED_ACCESS: true,

  // login
  LOGIN_COOKIE_AUTH_REGEX: /acmetechnology/i,
  LOGIN_NETWORK_NAME: 'customerDemoUserId',
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},

  // other
  BEST_MATCH_SORT: 'best_match',
  BENEFITS_CHANGE: false,
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Nashville,
};
