import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import ScoreTierIcon from 'components/ModernExperience/ScoreTierIcon';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 36,
    height: 36,
    margin: theme.spacing(1),
  },
  text: {
    maxWidth: 80,
    textAlign: 'start',
  },
}));

export default function ScoreTierCell({ providerId }) {
  const classes = useStyles();
  const scoreTier = useSelector(select.provider(providerId).scoreTier);

  if (!scoreTier) return <NoDataIcon />;

  return (
    <Box className={classes.container}>
      <ScoreTierIcon
        tier={scoreTier.scoreTier}
        tierColor={scoreTier.color}
        inverted={scoreTier.inverted}
        className={classes.icon}
      />
      <Typography component="span" variant="subtitle2" className={classes.text}>
        {scoreTier.text}
      </Typography>
    </Box>
  );
}

ScoreTierCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
