/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';
import { distance } from 'utils/utils';

export const getProviderAttributes = (providerData) => {
  if (!providerData || isEmpty(providerData)) return {};
  const {
    specialty,
    npi,
    entityName,
    scoreOverallQuality,
    scoreCareJourneyDiagnosing,
    scoreCareJourneyOutcomes,
    scoreCareJourneyTreatment,
  } = providerData;

  return {
    specialty,
    npi,
    entityName,
    scoreOverallQuality,
    scoreCareJourneyDiagnosing,
    scoreCareJourneyOutcomes,
    scoreCareJourneyTreatment,
  };
};

export const getChatProviderAttributes = ({ coordinates, providerData }) => {
  if (!providerData || isEmpty(providerData)) return {};
  const baseAttributes = getProviderAttributes(providerData);
  const { latitude, longitude } = providerData.closestVisibleLocation;
  const distanceToClosestLocation = distance(
    latitude,
    longitude,
    coordinates?.latitude,
    coordinates?.longitude
  );

  return {
    ...baseAttributes,
    distance_to_result: distanceToClosestLocation || 'N/A',
  };
};
