import { createSelector } from '@reduxjs/toolkit';

import { CONFIG_SLICE_NAME } from '../slicesNames';
// eslint-disable-next-line
import { PASSWORD, DROPDOWN, DROPDOWN_AUTOCOMPLETE, CHECKMARK } from 'utils/constants';

/** @returns {string} The EMBOLD_CLIENT value */
export const name = (state) => state[CONFIG_SLICE_NAME].values.EMBOLD_CLIENT;

/** @returns {string} The EMBOLD_CLIENT_SLUG value */
export const slug = (state) => state[CONFIG_SLICE_NAME].values.EMBOLD_CLIENT_SLUG;

/** @returns {string} The DATA_TENANT value */
export const dataTenant = (state) => state[CONFIG_SLICE_NAME].values.DATA_TENANT;

/** The type of login form used on the login page for the client
 * @returns {PASSWORD|DROPDOWN|DROPDOWN_AUTOCOMPLETE|CHECKMARK} The FORM_VARIANT value
 */
export const loginFormVariant = (state) => state[CONFIG_SLICE_NAME].values.FORM_VARIANT;

export const loginNetworkName = (state) => state[CONFIG_SLICE_NAME].values.LOGIN_NETWORK_NAME;

/** This constructs a Regex object from the LOGIN_COOKIE_AUTH_REGEX array.
 * @returns {RegExp} A Regex object
 */
export const loginCookieAuthRegex = createSelector(
  [(state) => state[CONFIG_SLICE_NAME].values.LOGIN_COOKIE_AUTH_REGEX || []],
  (regexArray) => new RegExp(...regexArray)
);

/* ****************************************************** */
// Client level selectors that have Network level overrides
/* ****************************************************** */
const currentNetworkConfig = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].currentNetworkSlug,
    (state) => state[CONFIG_SLICE_NAME].values.REGION_SELECTOR_CODES,
  ],
  (networkSlug, allNetworks) => allNetworks[networkSlug]?.config
);

/** The image shown in the app header.
 * This returns a network level HEADER_IMAGE if available,
 * or a client level HEADER_IMAGE (either from the local config OR fusion),
 * @returns {string} URI string - The HEADER_IMAGE value for the selected network or the HEADER_IMAGE value on the client level
 */
export const headerImage = createSelector(
  [currentNetworkConfig, (state) => state[CONFIG_SLICE_NAME].values.HEADER_IMAGE],
  (networkConfig, clientLevelImage) => {
    const networkLevelImage = networkConfig?.HEADER_IMAGE;
    return networkLevelImage || clientLevelImage;
  }
);

/** The image shown on the login page.
 * This returns a network level LOGIN_IMAGE if available,
 * or a client level LOGIN_IMAGE if available,
 * or lastly the LOCAL_LOGO_ASSET
 * @returns {string} URI string - The login image of the network level, or client level
 */
export const loginImage = createSelector(
  [
    currentNetworkConfig,
    (state) => state[CONFIG_SLICE_NAME].values.LOGIN_IMAGE, // from fusion client config
    (state) => state[CONFIG_SLICE_NAME].values.LOCAL_LOGO_ASSET, // from local client config
  ],
  (networkConfig, fusionImage, localImage) => {
    const networkImage = networkConfig?.LOGIN_IMAGE;
    return networkImage || fusionImage || localImage;
  }
);
