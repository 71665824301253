/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

import { actions } from 'store/toolkit';
import { StateContext } from 'state';
import { initialState } from 'state/initial';

class ErrorBoundary extends Component {
  componentDidCatch(error) {
    // send error to sentry
    Sentry.captureException(error);

    // redirect to homepage with URL params stripped out
    this.props.history.push('/');

    // reset to initialState
    const [{ showLogin, memberId, networkSlug }, setState] = this.context;

    this.props.handleErrorCatch();
    setState({
      ...initialState,
      showLogin,
      memberId,
      networkSlug,
    });
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.contextType = StateContext;

const mapDispatchToProps = (dispatch) => ({
  handleErrorCatch: () => dispatch(actions.app.catchAppError()),
});

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundary));
