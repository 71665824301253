/* eslint-disable import/prefer-default-export */
import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, ICUBA } from 'utils/constants';
import { selectToken } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 4040c474466ff90d2301a21524ba85993018a939',
  staging: 'Token 34e3003e4598dad8a4047a1280f89738f1695c37',
  prod: 'Token e4775d4a455369d7d47c41d1f0c1d68848f4661f',
};

const token = selectToken(apiTokens);

export const ICUBA_LOGIN_REGEX = /icuba/i;

export const icubaConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'icuba',
  EMBOLD_CLIENT: ICUBA,
  EMBOLD_CLIENT_SLUG: 'icuba',
  THEME: standardTheme,

  // toggles
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  SHOW_BENEFIT_DIFF: true,

  // login
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},
  LOGIN_NETWORK_NAME: 'icubaUserId',
  LOGIN_COOKIE_AUTH_REGEX: ICUBA_LOGIN_REGEX,

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.WestPalmBeach,
};
