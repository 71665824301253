/* eslint-disable import/extensions */
import IcubaLoginLogo from 'assets/pg-icuba-logo.png';

import { CHECKMARK } from 'utils/constants';

export const icubaOverrides = {
  SCHEDULE_PHONE_NUMBER: '877-211-9127',
  HEADER_LOGO: IcubaLoginLogo,
  SHEDULE_COPY: `Connecting you to Embold Health Customer Service. Click 'Call Now' to continue`,
  SUPPORT_LINK: 'https://icubasupport.emboldhealth.com/portal/',
  BENEFIT_DIFF_COPY: '$0 Copay',
  BENEFIT_DIFF_TITLE: 'Preferred Provider',
  BENEFIT_DIFF_TOOLTIP:
    'This provider has been reviewed by medical experts and has proven to consistently deliver care that is among the best in the area in this specialty.  Therefore, you are not responsible for any copay when using this provider.',
  SEARCH_ERROR_TEXT:
    'This version of Provider Guide includes providers within the state of Florida. Please change your search criteria to try again.',
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `ICUBA and Embold Health have partnered to bring you Embold's Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: IcubaLoginLogo,
  FORM_VARIANT: CHECKMARK,
  NO_WELCOME_LINK: false,
};
