import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useGetNetworkContent } from 'hooks/Provider';
import useReturnFocus from 'hooks/useReturnFocus';
import Badge from './Badge';
import BadgeModal from './BadgeModal';

export default function CoverageBadge({ provider, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { ref, returnFocus } = useReturnFocus();
  const getNetworkContent = useGetNetworkContent();
  const networkContent = getNetworkContent(provider);

  const hasModal = Boolean(networkContent.tooltipMessage);

  if (!networkContent.showNetworkStatus) return null;

  return (
    <>
      <Badge
        src={networkContent.networkIcon}
        onClick={() => setModalIsOpen(true)}
        ref={ref}
        {...props}
      >
        {networkContent.networkText}
      </Badge>
      {hasModal && (
        <BadgeModal
          open={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          returnFocus={returnFocus}
          title={networkContent.networkText}
          text={networkContent.tooltipMessage}
        />
      )}
    </>
  );
}

CoverageBadge.propTypes = {
  provider: PropTypes.shape({
    healthPlan: PropTypes.string,
    curatedOut: PropTypes.bool,
  }).isRequired,
};
