import { withStyles } from '@material-ui/styles';
import { Switch } from '@material-ui/core';

const size = 24; // This value changes the overall size of the switch

/* This switch component is intended to look similar to that of Ant Designs.
 * Majority of the styling here was copied from https://v4.mui.com/components/switches/#customized-switches
 * There were some customizations made to match the Larger Provider Cards figma project
 * https://www.figma.com/file/lILPXsEKyEfnx3tHBimSHg/Larger-Provider-Cards?type=design&node-id=703-737&mode=design&t=aRVJMHvRekzpcAYt-0
 */

const AntSwitch = withStyles((theme) => ({
  root: {
    width: size * 1.75,
    height: size,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: size / 8,
    color: theme.palette.common.white,
    '&$checked': {
      transform: `translateX(${size * 0.75}px)`,
      color: theme.palette.common.white,
      opacity: 1,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: size * 0.75,
    height: size * 0.75,
    boxShadow: 'none',
  },
  track: {
    borderRadius: size * 0.5,
    opacity: 1,
    backgroundColor: theme.palette.grey[600],
  },
  checked: {},
}))(Switch);

export default AntSwitch;
