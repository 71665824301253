import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import emboldLogo from 'assets/embold-icon.png';

function useStyles(speed) {
  return makeStyles({
    spinner: {
      animation: `$logoSpin infinite ${speed}s linear`,
    },
    '@keyframes logoSpin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  })();
}

export default function EmboldLoadingSpinner({
  className,
  width = 100,
  height = 100,
  speed = 2,
  ...props
}) {
  const classes = useStyles(speed);

  return (
    <img
      src={emboldLogo}
      alt="Loading"
      className={`${classes.spinner} ${className}`}
      width={width}
      height={height}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

EmboldLoadingSpinner.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.number,
};

EmboldLoadingSpinner.defaultProps = {
  className: '',
  height: 100,
  width: 100,
  speed: 2,
};
