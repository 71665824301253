import React, { useRef, useCallback } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'propTypes';

import { select, actions } from 'store/toolkit';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import useFocusAnchors from 'utils/FocusRefContext';
import RoundedWrapper from 'components/ModernExperience/ResultCards/StyledWrappers/RoundedWrapper';
import { TooltipPropsShape } from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: '1 1 0px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
  boxRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    rowGap: 10,
  },
  titleRoot: {
    lineHeight: 1.25,
    fontSize: '.9rem',
    textAlign: 'center',
    textDecoration: 'underline',
    marginLeft: 10,
  },
  noDecoration: {
    textDecoration: 'none',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badgeIcon: {
    height: 52,
    width: 52,
  },
  iconContainer: {
    position: 'relative',
  },
}));

/** TODO Find a way to replace this with the generic ProfileBadge component */
function ProfileBadge({ title, iconUrl, iconAlt, iconOverlay, iconOverlayClass, TooltipProps }) {
  const classes = useStyles();
  const tooltipRef = useRef(null);
  const focusAnchors = useFocusAnchors();

  const dispatch = useDispatch();
  const tooltipOpen = useSelector(select.tooltip.isOpen);

  const { title: tooltipTitle, message: tooltipMessage, link: tooltipLink } = TooltipProps || {};

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (tooltipOpen) {
        // close an existing tooltip if open
        dispatch(actions.tooltip.close());
      }

      focusAnchors.tooltip = tooltipRef;

      const payload = {
        title: tooltipTitle || title,
        message: tooltipMessage,
      };
      if (tooltipLink.to && tooltipLink.label) {
        payload.link = tooltipLink;
      }

      dispatch(actions.tooltip.open(payload));
    },
    [tooltipOpen, focusAnchors, tooltipTitle, title, tooltipMessage, tooltipLink, dispatch]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.code === 'Enter' || e.code === 'Space') {
        e.preventDefault();
        handleClick(e);
      }
    },
    [handleClick]
  );

  const clickableProps = tooltipMessage
    ? {
        role: 'button',
        tabIndex: 0,
        onClick: handleClick,
        onKeyDown: handleKeyDown,
        ref: tooltipRef,
      }
    : {};

  return (
    <RoundedWrapper grey additionalStyles={classes.wrapper}>
      <Box
        {...clickableProps}
        classes={{ root: clsx(classes.boxRoot, { [classes.clickable]: tooltipMessage }) }}
      >
        <>
          <div className={classes.iconContainer}>
            {Boolean(iconOverlay) && (
              <span className={iconOverlayClass}>{iconOverlay.toUpperCase()}</span>
            )}
            <img src={iconUrl} alt={iconAlt} className={classes.badgeIcon} />
          </div>
          <Typography
            component="span"
            classes={{ root: clsx(classes.titleRoot, { [classes.noDecoration]: !tooltipMessage }) }}
          >
            {title}
          </Typography>
        </>
      </Box>
    </RoundedWrapper>
  );
}

export default ProfileBadge;

ProfileBadge.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  iconUrl: PropTypes.string,
  iconOverlay: PropTypes.string,
  iconOverlayClass: PropTypes.string,
  TooltipProps: TooltipPropsShape,
};

ProfileBadge.defaultProps = {
  iconAlt: '',
  icon: null,
  iconUrl: null,
  iconOverlay: '',
  iconOverlayClass: '',
  TooltipProps: {},
};
