import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { AuthenticationType } from 'azure-maps-control';
import { _analyticsActions } from 'analytics';
import { detectWebGLSupport, getBounds, openInSameTab } from 'utils/utils';
import { useConfigValue } from 'config/state/index';
import {
  AzureMap,
  AzureMapFeature,
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsContext,
} from 'react-azure-maps';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';

import geoTag from 'assets/geo-tag.svg';

import Location from 'components/Profile/ProfileSections/Location';
import { GridIconRow } from 'components/Profile/Profile';
import NoWebGLNotice from 'components/Map/NoWebGLNotice';

const useStyles = makeStyles((theme) => ({
  headerRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  selectorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    padding: 0,
  },
  link: {
    margin: '0',
    paddingBottom: 15,
    textAlign: 'center',
    color: theme.palette.linkBlue,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonLink: {
    padding: '0 0 4px 0',
    minWidth: 0,
    borderRadius: 0,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: 'underline',
    color: theme.palette.linkBlue,
    display: 'inline',
    textAlign: 'left',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  locationInfoContainer: {
    marginTop: 8,
  },
  mapContainer: {
    width: '100%',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
}));

function LocationsMinimap({ provider, coords }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { mapRef, isMapReady } = useContext(AzureMapsContext);
  const {
    config: {
      AZURE_MAPS_KEY,
      CONTENT: { MAPS_URL },
    },
  } = useConfigValue();
  const { places } = provider;
  const [selectedPlaceId, setSelectedPlaceId] = useState(places[0].id);
  const selectedPlace = places.find((place) => place.id === selectedPlaceId) || places[0];
  const selectedPlaceIndex = places.findIndex((place) => place.id === selectedPlaceId);
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const increaseSelectedPlaceIndex = () => setSelectedPlaceId(places[selectedPlaceIndex + 1].id);
  const decreaseSelectedPlaceIndex = () => setSelectedPlaceId(places[selectedPlaceIndex - 1].id);

  const handleDirectionsClick = useCallback(() => {
    dispatch(
      _analyticsActions.directionsClickFromChatProfile({
        coordinates: coords,
        providerData: provider,
      })
    );
  }, [coords, dispatch, provider]);

  const handlePhoneClick = useCallback(
    (locationPhoneNumber) => {
      dispatch(
        _analyticsActions.phoneClickFromChatProfile({ coordinates: coords, providerData: provider })
      );
      openInSameTab(`tel:${locationPhoneNumber}`);
    },
    [dispatch, coords, provider]
  );

  const miniMapBounds = getBounds(places);

  const mapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: AZURE_MAPS_KEY,
    },
    view: 'Auto',
    maxZoom: 17,
    bounds: miniMapBounds,
    interactive: false,
  };

  const webGlSupported = detectWebGLSupport();

  useEffect(() => {
    if (mapRef && isMapReady) {
      mapRef.setCamera({
        bounds: getBounds(places),
        type: 'ease',
        duration: 400,
      });
    }
    setSelectedPlaceId(places[0].id);
  }, [provider.entityId]);

  const handlePlaceClick = useCallback(
    (e) => {
      e.originalEvent.preventDefault();
      const clickedPlaceId = e?.shapes?.[0]?.data?.id;
      if (clickedPlaceId) {
        setSelectedPlaceId(clickedPlaceId);
      }
    },
    [provider.entityId]
  );

  return (
    <Grid container>
      <Grid container className={classes.headerRow}>
        <Grid style={{ fontWeight: 'bold', fontSize: '1rem' }}>Contact</Grid>
        {places.length > 1 && (
          <Grid className={classes.selectorContainer}>
            <IconButton
              className={classes.arrowIcon}
              onClick={decreaseSelectedPlaceIndex}
              disabled={selectedPlaceIndex === 0}
            >
              <LeftArrowIcon />
            </IconButton>
            <Grid>{`${selectedPlaceIndex + 1} of ${places.length}`}</Grid>
            <IconButton
              className={classes.arrowIcon}
              onClick={increaseSelectedPlaceIndex}
              disabled={selectedPlaceIndex === places.length - 1}
            >
              <RightArrowIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={smDown ? 12 : 6} className={classes.locationInfoContainer}>
          <Location
            latLong={coords}
            isPlaceSearch={false}
            handleDirectionsClick={handleDirectionsClick}
            handlePhoneClick={handlePhoneClick}
            MAPS_URL={MAPS_URL}
            closestLocation={selectedPlace}
            geoTag={geoTag}
            classes={classes}
            showProviderStrengthsSection={false}
            showDistance
            GridIconRow={GridIconRow}
          />
          <Typography>Always confirm that the provider accepts your health plan</Typography>
        </Grid>
        <Grid item xs={smDown ? 12 : 6} className={classes.mapContainer}>
          <div
            role="region"
            aria-label="Provider Location Mini Map"
            style={{ width: '100%', height: 250, borderRadius: 10, overflow: 'hidden' }}
          >
            {webGlSupported ? (
              <AzureMap options={mapOptions}>
                <AzureMapDataSourceProvider
                  id="marker-provider"
                  options={{
                    cluster: false,
                    allowOverlap: true,
                  }}
                >
                  <AzureMapLayerProvider
                    id="provider-marker-layer"
                    type="SymbolLayer"
                    options={{
                      iconOptions: {
                        image: ['get', 'icon'],
                        size: ['get', 'size'],
                        allowOverlap: true,
                      },
                    }}
                    events={{
                      layeradded: (e) => e.map.resize(),
                      click: handlePlaceClick,
                      mouseover: (e) => {
                        e.map.getCanvasContainer().style.cursor = 'pointer';
                      },
                      mouseleave: (e) => {
                        e.map.getCanvasContainer().style.cursor = 'grab';
                      },
                    }}
                  />
                  {places.map((place) => {
                    const isActiveMarker = place.id === selectedPlace.id;
                    const properties = {
                      icon: isActiveMarker ? 'marker-blue' : 'marker-darkblue',
                      size: isActiveMarker ? 0.9 : 0.6,
                    };
                    return (
                      <AzureMapFeature
                        key={place.id}
                        id={place.id}
                        variant="shape"
                        type="Point"
                        coordinate={[place.longitude, place.latitude]}
                        properties={properties}
                        setProperties={properties}
                      />
                    );
                  })}
                </AzureMapDataSourceProvider>
              </AzureMap>
            ) : (
              <NoWebGLNotice />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

LocationsMinimap.propTypes = {
  provider: PropTypes.shape({
    entityId: PropTypes.string,
    places: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  }).isRequired,
  coords: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
};

export default LocationsMinimap;
