import React from 'react';
import PropTypes from 'propTypes/index';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

import Promotion from './Promotion';

function PromotionById({ id }) {
  const selectedPromotion = useSelector(select.promotions.byId(id));
  if (!selectedPromotion) {
    return null;
  }

  return <Promotion promotion={selectedPromotion} />;
}

PromotionById.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PromotionById;
