/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, CONTINENTAL_RESOURCES, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import clrHeaderLogo from 'assets/clr_header_logo.svg';
import clrLoginLogo from 'assets/clr_login_logo.svg';

const apiTokens = {
  dev: 'Token 0ba4fc3b55ef610541d71d7b1ca10b77eae62564',
  staging: 'Token 62b471a106e75992a1112c96700d538d886a8f4c',
  prod: 'Token e9038e04ff8a978a1fb1c7d53d6519f0785a049e',
};

const token = selectToken(apiTokens);

const clrConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'continental',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: CONTINENTAL_RESOURCES,
  EMBOLD_CLIENT: CONTINENTAL_RESOURCES,
  EMBOLD_CLIENT_SLUG: CONTINENTAL_RESOURCES,

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_BROWSER_LOCATION: true,
  USE_GENDER_ICONS: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},
  LOGIN_COOKIE_AUTH_REGEX: ['clr', 'i'],

  // other
  BEST_MATCH_SORT: 'best_match',
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.OklahomaCity },
  DEFAULT_SEARCH_RADIUS: 25,
  LOGIN_NETWORK_NAME: 'clrUserId',

  // content
  DISPLAY_NAME: 'Continental Resources',
  SCHEDULE_PHONE_NUMBER: '877-694-0407',
  HEADER_LOGO: clrHeaderLogo,
  WELCOME_MESSAGE:
    'Continental Resources and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: CHECKMARK,
  LOCAL_LOGO_ASSET: clrLoginLogo,
  ADD_ICON: true,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default clrConfig;
