import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import ProfileBadge from '../../ProfileBadge';

export default function CoverageBadge({ placeId }) {
  const networkContent = useSelector(select.place(placeId).networkContent);

  if (!networkContent.showNetworkStatus) return null;

  return (
    <ProfileBadge
      icon={networkContent.networkIcon}
      label={networkContent.networkText}
      color="darkGreen"
    />
  );
}

CoverageBadge.propTypes = {
  placeId: PropTypes.string.isRequired,
};
