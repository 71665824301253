import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes, { RefType } from 'propTypes/index';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { useStateValue } from 'state';
import useFocusAnchors from 'utils/FocusRefContext';
import { getClient, openInSameTab } from 'utils/utils';
import {
  SCHEDULE_PHONE_NUMBER,
  MEMBER_ID_LINK,
  SHOW_BCBS_LINK,
  CHOOSE_NETWORK_TEXT,
} from 'utils/constants';
import { useConfigValue } from 'config/state';
import useClientImages from 'hooks/useClientImages';
import useStartOver from 'hooks/useStartOver';
import useIsSharePage from 'hooks/useIsSharePage';
import { actions, select } from 'store/toolkit';

import UpdateNetworkForm from './Forms/NetworkSelects/UpdateNetworkForm';
import HeaderSkipLinks from './a11y/HeaderSkipLinks';
import { SUPPORT_ROUTE } from '../navigation/navigationConstants'; // for some reason eslint can't resolve an absolute path to navigation

const useStyles = makeStyles((theme) => ({
  header: {
    flex: '0 0 auto',
    position: 'fixed',
    width: '100%',
    zIndex: 10,
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down('md')]: {
      zIndex: 1300,
    },
    '& img': {
      height: 30,
    },
  },
  headerLogo: {
    float: 'left',
    height: 30,
  },
  headerWrap: {
    margin: 0,
    textAlign: 'center',
    fontSize: 0,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  '@media screen and (-ms-high-contrast: black-on-white)': {
    headerLogo: {
      background: theme.palette.primary.main,
      height: 40,
      padding: 5,
      marginLeft: -5,
    },
  },
  '@media (forced-colors: active) and (prefers-color-scheme: light)': {
    headerWrap: {
      forcedColorAdjust: 'none',
      background: 'black',
    },
  },
  menuButton: {
    position: 'absolute',
    right: '0.25rem',
    '&:focus': {
      outlineColor: theme.palette.lightBlue,
    },
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  desktopNav: {
    width: '100%',
    textAlign: 'right',
  },
  buttonLabel: {
    display: 'flex',
  },
  listRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& span': {
      fontWeight: 700,
    },
    '& div': {
      margin: 0,
    },
  },
  listItemRoot: {
    display: 'inline-block',
    width: 'auto',
    padding: '0 35px 0 16px',
    '&:last-child': {
      paddingRight: 0,
    },
  },
  buttonRoot: {
    padding: '0 4px',
    color: theme.palette.primary.main,
  },
  iconRoot: {
    marginLeft: 10,
    fontSize: 12,
  },
  closeMobile: {}, // need to define this key in order to use it below
  mobileMenu: {
    zIndex: '1500',
    '& li': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '& a, & button': {
      color: 'black',
      textDecoration: 'none',
      padding: 0,
    },
    '& $closeMobile': {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.palette.darkGray,
      padding: 12,
      zIndex: 1,
    },
    '& .MuiPaper-root:focus': {
      outline: `2px solid ${theme.palette.lightBlue}`,
    },
  },
}));

function LogoLinkWrap({ children, clickable, startOver, logoRef }) {
  return clickable ? (
    <Link
      to="/"
      ref={logoRef}
      // we're passing null to startOver because this fn is being called from a link, which is already pushing to the location history
      onClick={() => startOver(null)}
    >
      {children}
    </Link>
  ) : (
    children
  );
}

LogoLinkWrap.propTypes = {
  startOver: PropTypes.func.isRequired,
  clickable: PropTypes.bool.isRequired,
  logoRef: RefType.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function Header({ isMobile, showChatButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const [menuOpen, setMenuOpen] = useState(false);
  const [{ networkSlug, showLogin }] = useStateValue();
  const clientName = useSelector(select.config.name);
  const isSharePage = useIsSharePage();

  const closeButtonRef = useRef(null);

  const isAuthenticated = !showLogin && !isSharePage;

  // todo: replace all loadingClientConfig values with this
  const reduxConfigLoading = useSelector(select.config.isLoading);
  const providerLookup = useSelector(select.featureFlags.providerLookup);

  const {
    config: { SHOW_REGION_SELECTOR, SHOW_DISCLAIMER },
    loadingClientConfig,
    getNetworkConfigValue,
  } = useConfigValue();
  const client = getClient();

  const schedulePhoneNumber = getNetworkConfigValue(networkSlug, SCHEDULE_PHONE_NUMBER);
  const memberIdLink = getNetworkConfigValue(networkSlug, MEMBER_ID_LINK);
  const showBcbsLink = getNetworkConfigValue(networkSlug, SHOW_BCBS_LINK);

  const clientImages = useClientImages();
  const startOver = useStartOver();

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const resetSearch = () => {
    // we're passing null to startOver because this fn is being called from a link, which is already pushing to the location history
    startOver(null);
    closeMenu();
  };

  const handleMemberIdClick = () => {
    openInSameTab(memberIdLink);
  };
  const handleCall = (e) => {
    e.stopPropagation();
    document.location.href = `tel:${schedulePhoneNumber}`;
  };
  const handleFeedback = () => {
    dispatch(actions.ui.openProfileModal('feedback'));
    closeMenu();
  };
  const handleChatModal = () => {
    dispatch(actions.ui.openModal('chat'));
    closeMenu();
  };
  const isHome = window.location.pathname === '/' || window.location.pathname.includes('results');

  const isCurrentPage = (slug) => (window.location.pathname.includes(slug) ? 'page' : undefined);
  const logoAltText = `${client} Provider Guide logo. Click to go back to home`;

  return (
    <Toolbar component="header" className={`${classes.header}`} id="header">
      <div className={classes.headerWrap}>
        <Typography component="h1" variant="srOnly">
          {`${clientName} Provider Guide`}
        </Typography>
        <HeaderSkipLinks handleChatModal={handleChatModal} />
        <LogoLinkWrap clickable startOver={startOver} logoRef={focusAnchors.logo}>
          <Grid container direction="row" alignItems="center">
            <img src={clientImages.header} alt={logoAltText} className={classes.headerLogo} />
          </Grid>
        </LogoLinkWrap>
      </div>

      {!isMobile ? (
        <nav className={classes.desktopNav} aria-label="Primary navigation">
          <List classes={{ root: classes.listRoot }}>
            {isAuthenticated && !loadingClientConfig && schedulePhoneNumber && (
              <ListItem classes={{ root: classes.listItemRoot }}>
                <Button
                  onClick={handleCall}
                  classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                >
                  <ListItemText primary={`Need Help? ${schedulePhoneNumber}`} />
                  <ChevronRight className={classes.iconRoot} />
                </Button>
              </ListItem>
            )}

            {isAuthenticated && memberIdLink && (
              <ListItem classes={{ root: classes.listItemRoot }}>
                <Button
                  onClick={handleMemberIdClick}
                  classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                >
                  <ListItemText primary="Member ID Card" />
                  <ChevronRight className={classes.iconRoot} />
                </Button>
              </ListItem>
            )}

            {isAuthenticated && !loadingClientConfig && !providerLookup && (
              <ListItem classes={{ root: classes.listItemRoot }}>
                <Button
                  onClick={closeMenu}
                  role="link"
                  component={Link}
                  to={SUPPORT_ROUTE}
                  classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                >
                  <ListItemText primary="Support" />
                  <ChevronRight className={classes.iconRoot} />
                </Button>
              </ListItem>
            )}
            {!reduxConfigLoading && <UpdateNetworkForm />}
          </List>
        </nav>
      ) : (
        <>
          <IconButton
            aria-label="Open navigation menu"
            className={classes.menuButton}
            onClick={openMenu}
          >
            <MenuIcon alt="Navigation menu" className={classes.menuIcon} />
          </IconButton>

          <Drawer
            anchor="top"
            open={menuOpen && isMobile}
            onClose={closeMenu}
            className={classes.mobileMenu}
            SlideProps={{ onEntered: () => closeButtonRef.current?.focus() }}
          >
            <nav aria-label="Primary navigation">
              <IconButton
                ref={closeButtonRef}
                aria-label="close"
                onClick={closeMenu}
                className={classes.closeMobile}
              >
                <CloseIcon />
              </IconButton>
              <List>
                <ListItem>
                  <Link
                    className={classes.mobileLink}
                    to="/"
                    onClick={resetSearch}
                    aria-current={isHome ? 'page' : undefined}
                  >
                    <ListItemText primary="Home" />
                  </Link>
                </ListItem>

                {isAuthenticated && !loadingClientConfig && schedulePhoneNumber && (
                  <ListItem>
                    <Button onClick={handleCall}>
                      <ListItemText primary={`Need Help? ${schedulePhoneNumber}`} />
                    </Button>
                  </ListItem>
                )}
                {isAuthenticated && memberIdLink && networkSlug && (
                  <ListItem>
                    <Button onClick={handleMemberIdClick}>
                      <ListItemText primary="Member ID Card" />
                    </Button>
                  </ListItem>
                )}

                {isAuthenticated && !loadingClientConfig && !providerLookup && (
                  <ListItem>
                    <Link to={SUPPORT_ROUTE} onClick={closeMenu}>
                      <ListItemText primary="Support" />
                    </Link>
                  </ListItem>
                )}

                {showBcbsLink && networkSlug && (
                  <ListItem>
                    <Link
                      to="/contactbcbs"
                      onClick={closeMenu}
                      aria-current={isCurrentPage('contactbcbs')}
                    >
                      <ListItemText primary="Contact Blue Cross Blue Shield" />
                    </Link>
                  </ListItem>
                )}

                {!isCurrentPage('provider') && SHOW_REGION_SELECTOR && isAuthenticated && (
                  <ListItem>
                    <Button
                      className={`${classes.mobileLink} ${classes.mobileLinkButton}`}
                      onClick={() => {
                        closeMenu();
                        dispatch(actions.ui.openModal('updateRegion'));
                      }}
                    >
                      <ListItemText primary={CHOOSE_NETWORK_TEXT} />
                    </Button>
                  </ListItem>
                )}

                <ListItem>
                  <Link
                    className={classes.mobileLink}
                    onClick={closeMenu}
                    to="/privacy"
                    aria-current={isCurrentPage('privacy')}
                  >
                    <ListItemText primary="Privacy Statement" />
                  </Link>
                </ListItem>

                <ListItem>
                  <Link
                    className={classes.mobileLink}
                    onClick={closeMenu}
                    to="/terms"
                    aria-current={isCurrentPage('terms')}
                  >
                    <ListItemText primary="Terms of Use" />
                  </Link>
                </ListItem>

                {SHOW_DISCLAIMER && (
                  <ListItem>
                    <Link
                      className={classes.mobileLink}
                      onClick={closeMenu}
                      to="/disclaimer"
                      aria-current={isCurrentPage('disclaimer')}
                    >
                      <ListItemText primary="Disclaimer" />
                    </Link>
                  </ListItem>
                )}

                <ListItem>
                  <Button onClick={handleFeedback} role="button" className={classes.buttonRoot}>
                    <ListItemText primary="Submit Feedback" />
                  </Button>
                </ListItem>

                {showChatButton && (
                  <ListItem>
                    <Button onClick={handleChatModal} role="button" style={{ minWidth: 0 }}>
                      <ListItemText primary="Embold's Virtual Assistant" />
                    </Button>
                  </ListItem>
                )}
              </List>
            </nav>
          </Drawer>
        </>
      )}
    </Toolbar>
  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  showChatButton: PropTypes.bool.isRequired,
};
export default React.memo(Header);
