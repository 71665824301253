import React, { useMemo } from 'react';
import { AzureMapLayerProvider } from 'react-azure-maps';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

const ACTIVE_LAYER_ID = 'active-marker-layer';

/**
 * This component inserts a map layer that will render the active provider marker (but not the active cluster marker)
 */
export default function ActiveMarkerLayer() {
  const selectedResultId = useSelector(select.results.selectedId);
  const hoveredId = useSelector(select.results.hoveredId);
  const wasPlaceSearch = useSelector(select.results.wasPlaceSearch);

  const lightBlueIcon = wasPlaceSearch ? 'place-blue' : 'marker-blue';

  /* Available layer options can be found here: https://samples.azuremaps.com/symbol-layer/symbol-layer-options */
  /* Docs for the data expressions can be found here: https://learn.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk#data-expressions */
  const activeMarkerLayerOptions = useMemo(
    () => ({
      // can't have hovered marker jumping between two layers
      filter: [
        'all',
        ['!', ['has', 'point_count']],
        [
          'any',
          ['==', ['get', 'resultId'], selectedResultId],
          ['==', ['get', 'resultId'], hoveredId],
        ],
      ], // filter out clusters, all clusters contain the "point_count" property
      iconOptions: {
        image: lightBlueIcon,
        size: ['case', ['==', ['get', 'resultId'], hoveredId], 1, 1.25],
        allowOverlap: true,
      },
    }),
    [selectedResultId, lightBlueIcon, hoveredId]
  );

  return (
    <AzureMapLayerProvider
      id={ACTIVE_LAYER_ID}
      type="SymbolLayer"
      options={activeMarkerLayerOptions}
    />
  );
}
