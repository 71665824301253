import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'propTypes';

import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { PROFILE_HORIZONTAL_LI_GUTTERS } from './AccordionList';

const useStyles = makeStyles((theme) => ({
  paddedList: {
    padding: `${theme.spacing(0.75)}px ${PROFILE_HORIZONTAL_LI_GUTTERS} 0 `,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  liRoot: {
    width: 'auto',
    padding: `${theme.spacing(0.5)}px 0`,
    columnGap: theme.spacing(1),
  },
  liIcon: {
    minWidth: 0,
    color: theme.palette.primary.main,
  },
}));

function DetailIconList({ items, disableGutters, ListItemTextProps, ListItemIconProps }) {
  const classes = useStyles();

  if (items.length === 0) return null;

  return (
    <List className={clsx({ [classes.paddedList]: !disableGutters })}>
      {items.map(({ text, icon }) => (
        <ListItem key={uniqueId()} disableGutters classes={{ root: classes.liRoot }}>
          {Boolean(icon) && (
            <ListItemIcon classes={{ root: classes.liIcon }} {...ListItemIconProps}>
              {icon}
            </ListItemIcon>
          )}
          <ListItemText {...ListItemTextProps}>{text}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

export default DetailIconList;

DetailIconList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      img: PropTypes.string,
    })
  ).isRequired,
  disableGutters: PropTypes.bool,
  ListItemTextProps: PropTypes.shape({
    style: PropTypes.shape(),
  }),
  ListItemIconProps: PropTypes.shape({}),
};

DetailIconList.defaultProps = {
  disableGutters: false,
  ListItemTextProps: {},
  ListItemIconProps: {},
};
