/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import PropTypes from 'prop-types';
import fetchClient from 'utils/fetchClient';
import Config from '../config';

function HideIfClosed({ children }) {
  const [showApp, setShowApp] = useState(!Config.CLOSED_ACCESS);
  if (Config.CLOSED_ACCESS) {
    if (window.location.pathname.includes('share')) {
      !showApp && setShowApp(true);
    } else {
      !showApp &&
        fetchClient('/init/').then(() => {
          setShowApp(true);
        });
    }
  }
  return showApp ? children : null;
}
HideIfClosed.propTypes = {
  children: PropTypes.node,
};
export default HideIfClosed;
