import { useMemo } from 'react';

import { useConfigValue } from 'config/state/index';
import { useStateValue } from 'state/index';

/**
 * There are two client images: the header image and login image. For both of these, most often they are set on the client level (locally or in Fusion).
 * There a some cases (like bnsf) where these two images need to be set by a network level. This hook performs the necessary checks on the network config,
 * and client config and returns the correct images in an object.
 *
 * NOTE: TODO RTK - This hook should be deprecated in favor of a Redux selector in selectClient.js once the network has been migrated to Redux.
 * See /store/slices/config/selectClient = headerImage & loginImage
 * @returns {Object} An object with header and login properties.
 */
export default function useClientImages() {
  const [{ networkSlug }] = useStateValue();
  const { config, getNetworkConfigValue } = useConfigValue();

  // network level logos - The first priority
  const networkHeaderImage = getNetworkConfigValue(networkSlug, 'HEADER_IMAGE');
  const networkLoginImage = getNetworkConfigValue(networkSlug, 'LOGIN_IMAGE');

  // client level-fusion logos - The second priority
  const fusionHeaderImage = config.HEADER_IMAGE;
  const fusionLoginImage = config.LOGIN_IMAGE;

  // client level-front end logos = The last priority
  const { LOCAL_LOGO_ASSET: localLoginImage, HEADER_LOGO: localHeaderImage } = config.CONTENT;

  // return a memoized object with shape { header: "", login: "" }
  return useMemo(
    () => ({
      header: networkHeaderImage || fusionHeaderImage || localHeaderImage,
      login: networkLoginImage || fusionLoginImage || localLoginImage,
    }),
    [
      networkHeaderImage,
      networkLoginImage,
      fusionHeaderImage,
      fusionLoginImage,
      localHeaderImage,
      localLoginImage,
    ]
  );
}
