import standardTheme from 'themes/standardTheme';
import { DEFAULT_LOCATIONS, WALMART } from 'utils/constants';
import { selectToken, getEnv } from 'utils/selectToken';

const apiTokens = {
  dev: 'Token 878f51af12d0432d04d00b538c0289d13a8c33a1',
  staging: 'Token 1dede7e7d6641a20bf5c8176614a500548b17752',
  prod: 'Token cdfb7df55a175e0b6c56064012d3c6c8dac52c2f',
};

const env = getEnv();

const token = selectToken(apiTokens);

// eslint-disable-next-line import/prefer-default-export
export const walmartConfig = {
  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'walmart',
  EMBOLD_CLIENT: WALMART,
  EMBOLD_CLIENT_SLUG: 'wmt',
  THEME: standardTheme,
  GOOGLE_TAG_MANAGER_ENABLED: true,
  GOOGLE_TAG_GTM_CODE: 'GTM-TV5HK7N',
  SHOW_BCBS_LINK: true,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: true,
  HIGH_QUALITY_INTRO_MODAL: true,
  DEFAULT_LOCATION: DEFAULT_LOCATIONS.Bentonville,
  HAS_PORTAL_INTEGRATION: true,
  REGION_SELECTOR_CODES: {},
  CLOSED_ACCESS: env === 'staging' || env === 'testing',
};
