import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { resultIsProviderType } from 'utils/utils';
import FeaturedResultBadge from './FeaturedResultBadge';
import ScoreBadge from './ScoreBadge';
import CoverageBadge from './CoverageBadge';
import HighPerformingBadge from './HighPerformingBadge';
import BenefitDiffBadge from './BenefitDiffBadge';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  badge: {
    maxWidth: 80, // This could potentially cause overflows if the badge text is too long. FeaturedProvider text is configured on the back-end
    '& span': {
      wordWrap: 'break-word', // This allows for a word to break, with the maxWidth of 80 words longer than 11 chars will break to new line
    },
  },
});

export default function ProviderBadges({ provider }) {
  const classes = useStyles();
  const isProviderType = resultIsProviderType(provider);

  if (!isProviderType) return null;

  // each specific badge is responsible for evaluating if it should return null
  // example: HighPerformingBadge checks for SHOW_HIGH_PERFORMING_RIBBON
  return (
    <div className={classes.root}>
      <ScoreBadge provider={provider} className={classes.badge} />
      <HighPerformingBadge isHighPerforming={provider.highPerforming} className={classes.badge} />
      <FeaturedResultBadge isFeatured={provider.clientFeatured} className={classes.badge} />
      <BenefitDiffBadge hasBenefitDiff={provider.hasBenefitDiff} className={classes.badge} />
      <CoverageBadge provider={provider} className={classes.badge} />
    </div>
  );
}

ProviderBadges.propTypes = {
  provider: PropTypes.shape({
    clientFeatured: PropTypes.bool,
    highPerforming: PropTypes.bool,
    hasBenefitDiff: PropTypes.bool,
  }).isRequired,
};
